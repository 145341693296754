import { Flex, Text } from '@chakra-ui/react';
import moment from 'moment';

interface Props {
  published: string | null;
  updated: string | null;
}
const Date = ({ published, updated }: Props) => {
  return (
    <>
      <Flex gap={2}>
        <Text className="le-blog-date" color="#545454" fontSize="10pt">
          Published{' '}
          {published ? moment(published).format('M/D/YY') : moment(updated).format('M/D/YY')}
        </Text>
        {published && published !== updated && (
          <Text
            className="le-blog-date"
            color="#545454"
            fontSize="10pt"
            borderStartWidth={1}
            ps={2}
          >
            Updated {moment(updated).format('M/D/YY')}
          </Text>
        )}
      </Flex>
    </>
  );
};
export default Date;
