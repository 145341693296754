import { Box, Flex } from '@chakra-ui/react';

import '../../assets/estimator.css';
import { Estimator, EstimatorTemplate } from '../../utils/paymentCalculator';
import Main from './Main';

const PaymentEstimator = () => {
  return (
    <>
      <Flex
        width="100%"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        mt="-48px"
        pos="relative"
        zIndex={1}
      >
        {/* <AgentBubble isNav /> */}
        <Box mx="auto" mt="56px" width={Estimator.width} minW={Estimator.width}>
          <Flex flexDirection="column" fontFamily={EstimatorTemplate.fontFamily}>
            <Main />
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default PaymentEstimator;
