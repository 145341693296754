import { Box, BoxProps, Container, Flex, HStack, IconProps, Text } from '@chakra-ui/react';
import { FaFacebookSquare } from '@react-icons/all-files/fa/FaFacebookSquare';
import { FaInstagramSquare } from '@react-icons/all-files/fa/FaInstagramSquare';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaVimeoSquare } from '@react-icons/all-files/fa/FaVimeoSquare';
import { FaYoutubeSquare } from '@react-icons/all-files/fa/FaYoutubeSquare';
import { FaSquareXTwitter } from 'react-icons/fa6';

import { LDFlags } from '../constants/experiments';
import {
  CAREERS,
  CAREERS_PAGE,
  CONTACT_US,
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  OPT_OUT,
  PRESS,
  PRIVACY_POLICY,
  TWITTER,
  VIMEO,
  YOUTUBE,
} from '../constants/urls';
import { useFlag } from '../hooks';
import useLandingPagePhoneNumbers from '../hooks/useSearchPhoneNumbers';
import SocialMediaIconLink from './SocialMediaIconLink';
import { Link as ChakraLink, Link } from './ui/links/Link';

const currentYear = new Date().getFullYear();

interface FooterProps extends BoxProps {
  _socialMediaIcon?: IconProps;
  _isLp?: boolean;
  _prequal?: boolean;
  _simpleLP?: boolean;
}
const Footer = ({
  _socialMediaIcon,
  _isLp = false,
  _prequal = false,
  _simpleLP = false,
  ...props
}: FooterProps) => {
  const LM558 = useFlag(LDFlags.LM558);
  const phoneNumber = useLandingPagePhoneNumbers();

  return (
    <>
      <Box
        bgColor={_prequal || _simpleLP ? 'transparent' : 'grayBackground'}
        mx="-50vw"
        right="50%"
        left="50%"
        position="relative"
        w="100vw"
        h={_isLp ? '180px' : 'auto'}
        {...props}
        borderColor="#eee"
      >
        <HStack pt={6} spacing={{ base: 5, md: 8, lg: 16 }} justifyContent="center">
          <SocialMediaIconLink
            icon={FaInstagramSquare}
            href={INSTAGRAM}
            ariaLabel="Lease End's Instagram Account"
            {..._socialMediaIcon}
          />
          <SocialMediaIconLink
            icon={FaSquareXTwitter}
            href={TWITTER}
            ariaLabel="Lease End's Twitter Account"
            {..._socialMediaIcon}
          />
          <SocialMediaIconLink
            icon={FaYoutubeSquare}
            href={YOUTUBE}
            ariaLabel="Lease End's Youtube Account"
            {..._socialMediaIcon}
          />
          <SocialMediaIconLink
            icon={FaLinkedin}
            href={LINKEDIN}
            ariaLabel="Lease End's LinkedIn Account"
            {..._socialMediaIcon}
          />
          <SocialMediaIconLink
            icon={FaFacebookSquare}
            href={FACEBOOK}
            ariaLabel="Lease End's Facebook Account"
            {..._socialMediaIcon}
          />
          <SocialMediaIconLink
            icon={FaVimeoSquare}
            href={VIMEO}
            ariaLabel="Lease End's Vimeo Account"
            {..._socialMediaIcon}
          />
        </HStack>
        <Container maxW="container.xl">
          {!_isLp && (
            <Flex
              flexDir={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
              mb="50px"
              pt="20px"
            >
              <Link
                m="10px"
                fontSize={12}
                fontWeight="bold"
                to={CONTACT_US}
                aria-label="Contact Us"
                color={props.color}
                _hover={{ color: 'white' }}
              >
                CONTACT US
              </Link>
              <Link
                m="10px"
                fontSize={12}
                fontWeight="bold"
                to={LM558 ? CAREERS_PAGE : CAREERS}
                isExternal={!LM558}
                aria-label="Careers with Lease End"
                color={props.color}
                _hover={{ color: 'white' }}
              >
                CAREERS
              </Link>
              <Link
                m="10px"
                fontSize={12}
                fontWeight="bold"
                to={PRESS}
                aria-label="Press Resources"
                color={props.color}
                _hover={{ color: 'white' }}
              >
                PRESS RESOURCES
              </Link>
              <Link
                m="10px"
                fontSize={12}
                fontWeight="bold"
                to={PRIVACY_POLICY}
                aria-label="Privacy Policy"
                color={props.color}
                _hover={{ color: 'white' }}
              >
                PRIVACY POLICY
              </Link>
              <Link
                m="10px"
                fontSize={12}
                fontWeight="bold"
                to={OPT_OUT}
                aria-label="Opt Out"
                color={props.color}
                _hover={{ color: 'white' }}
              >
                OPT OUT
              </Link>
            </Flex>
          )}
          {!_simpleLP && (
            <Flex pb={_isLp ? 0 : '40px'} mb="0px" flexDir={{ base: 'column', md: 'row' }}>
              {!_isLp && (
                <Box
                  w={{ base: 'full', md: 3 / 5 }}
                  color={props.color ?? 'leaseEndBlue'}
                  fontSize="12px"
                  fontWeight="semibold"
                  mb={{ base: 4, md: 0 }}
                  mr={{ base: 0, md: 4 }}
                >
                  Lease End's mission is to empower auto lease owners with the technology to easily
                  exit their lease. If you'd like to learn more about the lease-end options
                  available to you, please don't hesitate to contact us. Our expert advisors are
                  always prepared to answer your questions and are committed to finding the right
                  plan for your individual needs.
                  <br />
                  <br />©{currentYear} Lease End
                </Box>
              )}
              <Flex
                flexDirection="column"
                alignItems={_isLp ? 'center' : 'flex-start'}
                paddingLeft={{ base: '0px !important', md: '30px !important' }}
                borderLeft={{ base: 'none', md: _isLp ? 'none' : '2px solid' }}
                borderLeftColor="maizeCrayola !important"
                w={{ base: 'full', md: _isLp ? 'full' : 2 / 5 }}
                mt={_isLp ? '8px' : 0}
              >
                <Text fontWeight="semibold">Give Us a Call:</Text>
                <ChakraLink
                  mt={!_isLp ? '5px' : 0}
                  fontWeight="bold"
                  fontSize="2rem"
                  to={`tel:+1-${phoneNumber}`}
                  aria-label="Call US"
                  color={props.color ?? 'leaseEndBlue'}
                  _hover={{ color: 'white' }}
                >
                  {phoneNumber}
                </ChakraLink>
              </Flex>
            </Flex>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Footer;
