import { gql } from '@apollo/client/core';

export const paymentEstimate = gql`
  query paymentEstimate($data: paymentEstimateInput) {
    paymentEstimate(data: $data) {
      paymentEstimateLow
      paymentEstimateHigh
      estimatedTaxesAndFees
      totalEstimatedLoan
      estimatedAnnualPercentageRate
      feeDetails {
        baseTaxAmount
        warrantyTaxAmount
        docFeeTaxAmount
        docFee
        titleFee
        registrationFee
      }
    }
  }
`;

export const sendPaymentEstimateEmailAndText = gql`
  mutation sendPaymentEstimateEmailAndText(
    $data: paymentEstimateInput!
    $email: String
    $phoneNumber: String
  ) {
    sendPaymentEstimateEmailAndText(data: $data, email: $email, phoneNumber: $phoneNumber)
  }
`;
export const estimatePhone = gql`
  mutation temporaryInfoUpdate($info: temporaryInfoInput) {
    temporaryInfoUpdate(info: $info) {
      id
      data {
        phone_number
      }
    }
  }
`;

export const estimateImport = gql`
  mutation temporaryInfoImport($id: ID!, $transformType: transformType!, $importType: importType) {
    temporaryInfoImport(id: $id, transformType: $transformType, importType: $importType) {
      id
      customer {
        id
      }
    }
  }
`;
