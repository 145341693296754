/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Flex, Image, Link, List, ListItem, Text } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';

import { LELoading } from '../../assets/Images';
import StepButton from '../../components/PaymentEstimator/Button';
import StepInput from '../../components/PaymentEstimator/Input';
import { addMessaging } from '../../components/PaymentEstimator/MessagingText';
import StepSubTitle from '../../components/PaymentEstimator/SubTitle';
import Swirl from '../../components/PaymentEstimator/Swirl';
import StepTitle from '../../components/PaymentEstimator/Title';
import { LDFlags } from '../../constants/experiments';
import { FLOW_ENTRY, HOME } from '../../constants/urls';
import { PayoffStatsMakeModelYear } from '../../gql/calculatorGql';
import {
  CreditScore,
  PaymentEstimate,
  TtGetFeesSourceType,
  useGetMarketPrequalificationMutation,
  usePaymentEstimateLazyQuery,
} from '../../gql/generated/graphql';
import {
  estimateImport,
  estimatePhone,
  sendPaymentEstimateEmailAndText,
} from '../../gql/paymentEstimateGql';
import {
  EstimatorAddress,
  EstimatorCarTrim,
  EstimatorConversation,
  EstimatorConversationStrings,
  EstimatorDown,
  EstimatorEstimateVars,
  EstimatorFirstName,
  EstimatorGuid,
  EstimatorImportDone,
  EstimatorLastName,
  EstimatorLicensePlate,
  EstimatorPhone,
  EstimatorPulse,
  EstimatorPulseResult,
  EstimatorShowHow,
  EstimatorSignOff,
  EstimatorTempInfo,
  EstimatorTerm,
  EstimatorVin,
  EstimatorZipCodeFallBack,
} from '../../helpers/Atom';
import { hashUrl } from '../../helpers/Estimator';
import { useFlag } from '../../hooks';
import { CookieKeys, PrequalParams, useCookie } from '../../hooks/useCookie';
import { ESTIMATOR_CONSTANTS } from '../../utils/globals';
import { Estimator, EstimatorDates, EstimatorTemplate } from '../../utils/paymentCalculator';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import { capitalizeWords, currencyFormat } from '../../utils/text/text';
import Container from './Container';
import { MainProps } from './Main';

const Result = ({ move }: MainProps) => {
  const [prequalCookie, setPrequalCookie] = useCookie<PrequalParams>(CookieKeys.PREQUAL_PARAMS);
  const [getPayoff] = useLazyQuery(PayoffStatsMakeModelYear, {
    fetchPolicy: 'no-cache',
  });
  const [getPaymentEstimate] = usePaymentEstimateLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [doImport] = useMutation(estimateImport, {
    context: {
      isErrorHandled: true,
      fetchPolicy: 'no-cache',
    },
  });
  const [updatePhone] = useMutation(estimatePhone, {
    context: {
      isErrorHandled: true,
      fetchPolicy: 'no-cache',
    },
  });
  const [sendEstimateSms] = useMutation(sendPaymentEstimateEmailAndText, {
    context: {
      isErrorHandled: true,
      fetchPolicy: 'no-cache',
    },
  });
  const [getMarketPrequal] = useGetMarketPrequalificationMutation();
  const [animate, setAnimate] = useState<boolean>(false);
  const [unwind] = useState<boolean>(false);
  const [firstName] = useAtom(EstimatorFirstName);
  const [lastName] = useAtom(EstimatorLastName);
  const [address] = useAtom(EstimatorAddress);
  const [pulseResult, setPulseResult] = useAtom(EstimatorPulseResult);
  const [pulse] = useAtom(EstimatorPulse);
  const [info] = useAtom(EstimatorTempInfo);
  const [carTrim, setCarTrim] = useAtom(EstimatorCarTrim);
  const [down] = useAtom(EstimatorDown);
  const [term] = useAtom(EstimatorTerm);
  const [plate] = useAtom(EstimatorLicensePlate);
  const [vin] = useAtom(EstimatorVin);
  const [phone, setPhone] = useAtom(EstimatorPhone);
  const [zipFallBack] = useAtom(EstimatorZipCodeFallBack);
  const [signedOff, setSignedOff] = useAtom(EstimatorSignOff);
  const [guid, setGuid] = useAtom(EstimatorGuid);
  const [estimateVars, setEstimateVars] = useAtom(EstimatorEstimateVars);

  const [, setMessaging] = useAtom(EstimatorConversation);
  const initialMessage = `Don't go anywhere, I work quick 😏...`;
  const [messageStrings, setMessageStrings] = useAtom(EstimatorConversationStrings);

  const [, setMissingInfo] = useState<boolean>(false);

  const [, setPqError] = useState<boolean>(false);
  const [, setPayoffError] = useState<boolean>(false);
  const [, setEstimateError] = useState<boolean>(false);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [importDone, setImportDone] = useAtom(EstimatorImportDone);

  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [importError, setImportError] = useState<boolean>(false);

  const [showHow, setShowHow] = useAtom(EstimatorShowHow);
  const [confetti, setConfetti] = useState<boolean>(false);
  const [, setEnterPhone] = useState<boolean>(false);

  const [paymentEstimate, setPaymentEstimate] = useState<PaymentEstimate | null>(null);
  const [payoffStats, setPayoffStats] = useState<{
    avg_payoff: number;
    min_payoff: number;
    max_payoff: number;
  } | null>(null);
  const messagingDone = `🎉 We did it!! Your payment estimate is below. To see the estimate breakdown enter your phone number a consultant will reach out to get your lease ended.  Thanks for your time today!`;
  const handlePhone = (ph: string) => {
    setPhone(ph || null);
  };
  const LM1755 = useFlag(LDFlags.LM1755);
  const tracking = () => {
    rudderanalytics.track(RudderEvent.LabTest, {
      name: 'LM-1755::Result',
      value: LM1755,
    });
  };

  const handleImport = async () => {
    await doImport({
      variables: {
        id: guid,
        transformType: ESTIMATOR_CONSTANTS.TransformType,
        importType: ESTIMATOR_CONSTANTS.ImportType,
      },
    })
      .then((response) => {
        if (response.errors) {
          setImportError(true);
          return;
        }
        setImportDone(true);
        rudderanalytics.identify({
          deal_id: response.data.temporaryInfoImport.id,
          customer_id: response.data.temporaryInfoImport.customer.id,
        });
        sendEstimateSms({
          variables: {
            data: {
              ...estimateVars,
            },
            email: 'fake@mayl.com', // todo: need to make api email conditional or add new endpoint to exclusive text
            phoneNumber: phone,
          },
        });
      })
      .catch(() => {
        setImportError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const submitPhone = async () => {
    if (!guid) {
      alert("We're having little issue right now, sorry!");
      return null;
    }
    if (!phone || phone === '') {
      alert('We need a phone number to send you the info!');
      return null;
    }
    setSubmitting(true);
    const data = {
      phone_number: phone,
    };
    await updatePhone({
      variables: {
        info: {
          id: guid,
          data,
        },
      },
    })
      .then(() => {
        setPrequalCookie({
          prequalId: prequalCookie?.prequalId || '',
          firstName: prequalCookie?.firstName || '',
          lastName: prequalCookie?.lastName || '',
          address: prequalCookie?.address || '',
          city: prequalCookie?.city || '',
          state: prequalCookie?.state || '',
          zip: prequalCookie?.zip || '',
          email: '',
          phone: phone || '',
        });
        setPhoneError(false);
        handleImport();
      })
      .catch(() => {
        setPhoneError(true);
      });
    return null;
  };

  const run = async (
    make: string,
    model: string | null,
    year: string | null,
    trim: string | null,
  ) => {
    await getPayoff({
      variables: {
        make,
        model: model || null,
        trim: trim || null,
        year: year || null,
        start_date: EstimatorDates.start_date,
        end_date: EstimatorDates.end_date,
      },
    })
      .then((response) => {
        if (!response.data.payoffStatsMakeModelYear[0].avg_payoff) {
          if (make && model && year && trim) {
            run(make, model, year, null);
          } else if (model && year && year) {
            run(make, model, null, null);
          } else {
            run(make, null, null, null);
          }
          return null;
        }
        return response.data;
      })
      .then((result) => {
        if (!result) {
          return null;
        }
        return result.payoffStatsMakeModelYear[0];
      })
      .then((poStats) => {
        setTimeout(() => {
          setPayoffStats(poStats || null);
        }, 1000);
      });
  };

  const skipPrequal = () => {
    setTimeout(() => {
      if (info?.make) {
        run(info?.make, info?.model, info?.year, carTrim);
      }
    }, 1000);
  };

  const prequal = async () => {
    try {
      await getMarketPrequal({
        variables: {
          prequalId: prequalCookie?.prequalId,
          firstName,
          lastName,
          phoneNumber: '',
          addressLine: address?.street,
          city: address?.city,
          state: address?.state,
          zip: address?.zip,
        },
      })
        .then((pq) => {
          setPrequalCookie({
            prequalId: pq.data?.getMarketPrequalification.prequalId || '',
            firstName: firstName || '',
            lastName: lastName || '',
            address: address?.street || '',
            city: address?.city || '',
            state: address?.state || '',
            zip: address?.zip || '',
            email: '',
            phone: '',
          });
          setPulseResult(pq.data?.getMarketPrequalification.creditScoreTier || CreditScore.Good);
        })
        .then(() => {
          if (info?.make) {
            setTimeout(() => {
              run(info?.make, info?.model, info?.year, carTrim);
            }, 1000);
          }
        });
    } catch {
      setPqError(true);
    }
    return null;
  };

  const go = async () => {
    if (pulse) {
      prequal();
      return;
    }
    skipPrequal();
  };
  const estimate = async () => {
    let tier: CreditScore = CreditScore.Good;
    switch (pulseResult) {
      case 'very_good':
        tier = CreditScore.Great;
        break;
      case 'good':
        tier = CreditScore.Good;
        break;
      case 'fair':
        tier = CreditScore.Good;
        break;
      default:
        tier = CreditScore.BelowAverage;
    }
    const paymentEstimateVars = {
      creditScore: tier,
      term,
      moneyDown: down,
      payoff: payoffStats ? payoffStats?.avg_payoff : 0,
      zipCode: address?.zip ? address?.zip : zipFallBack || '',
      ttGetFeesSource: TtGetFeesSourceType.MktPaymentCalculator,
    };
    setEstimateVars(paymentEstimateVars);
    await getPaymentEstimate({
      variables: {
        data: {
          ...paymentEstimateVars,
        },
      },
    }).then((payment) => {
      setPaymentEstimate(payment.data?.paymentEstimate || null);
    });
  };
  const getCreditLabel = (score: string) => {
    const tag =
      score === 'very_good' ? 'Very Good' : score === 'no_response' ? 'Undetermined' : score;
    return capitalizeWords(tag);
  };
  useEffect(() => {
    if (!payoffStats || !pulseResult) {
      return;
    }
    estimate();
  }, [payoffStats]);
  useEffect(() => {
    if (!paymentEstimate) {
      return;
    }
    setConfetti(true);
  }, [paymentEstimate]);
  useEffect(() => {
    tracking();
    if (!confetti) {
      return;
    }
    const ms = messageStrings.concat(messagingDone);
    setMessageStrings(ms);
    setMessaging(addMessaging(messagingDone, ms));
    setTimeout(() => {
      setSignedOff(true);
    }, 5000);
  }, [confetti]);
  useEffect(() => {
    if (!firstName || !lastName || !address || !info) {
      setMissingInfo(true);
    }
    const timer = setTimeout(() => {
      setAnimate(true);
    }, Estimator.animationDuration);
    const ms = messageStrings.concat(initialMessage);
    setMessageStrings(ms);
    setMessaging(addMessaging(initialMessage, ms));
    hashUrl('EstimatorResult');
    go();
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      <Container
        pos="relative"
        className={`calculator-step ${unwind ? 'animate-unwind' : animate ? 'animate' : ''}`}
      >
        {importDone ? (
          <>
            <StepTitle>Thanks {firstName}!</StepTitle>
            <StepSubTitle textAlign="center">
              You will receive a text with your estimate details shortly. One of our skilled
              advisors will reach out soon!
            </StepSubTitle>
            <Flex
              justifyContent="center"
              // my={8}
              fontSize={EstimatorTemplate.fontSizeLarge}
              textAlign="center"
            >
              {/* If you're ready to get started now, you can end your lease online by clicking "GET
              STARTED" below.
              <Text as="br" my={4} /> */}
              👋 Bye, have a great day!
            </Flex>
            <Flex w="auto" mx="auto" flexDir="column" gap={4} alignItems="center">
              {/* <StepButton
                handleClick={() => {
                  window.location.href = `${FLOW_ENTRY}?license_plate=${plate}&state=${address?.state}&vin=${vin}`;
                }}
                py={8}
                label="Get Started!"
                actionable
              />
              <Link href={HOME}>Back Home</Link> */}
              <StepButton
                handleClick={() => {
                  window.location.href = HOME;
                }}
                py={8}
                label="Back Home"
                actionable
              />
            </Flex>
          </>
        ) : (
          <>
            {confetti ? (
              <>
                <Flex pos="absolute" left={0} right={0} top={0} w="100%">
                  <ConfettiExplosion />
                </Flex>
              </>
            ) : (
              <Flex justifyContent="center">
                <Image src={LELoading} w="128px" />
              </Flex>
            )}
            {paymentEstimate && (
              <>
                <Flex
                  bg={EstimatorTemplate.aiGradient}
                  backgroundSize="250%"
                  borderRadius={8}
                  py={8}
                  justifyContent="center"
                  alignItems="center"
                  flexDir="column"
                  overflow="hidden"
                  pos="relative"
                >
                  <Box pos="absolute" left={0} right={0} h="200px" zIndex={0}>
                    <Swirl />
                  </Box>
                  <Flex flexDir="column" pos="relative" zIndex={2} p={4}>
                    <Text as="span" color="white" fontSize={36} fontWeight={700} textAlign="center">
                      {currencyFormat(Number(paymentEstimate.paymentEstimateLow))} -{' '}
                      {currencyFormat(Number(paymentEstimate.paymentEstimateHigh))}
                    </Text>
                    <Text
                      as="span"
                      color="white"
                      fontSize={16}
                      fontWeight={400}
                      display="block"
                      textAlign="center"
                      lineHeight={1}
                    >
                      estimated monthly payment
                    </Text>
                  </Flex>
                </Flex>
                {!showHow && (
                  <Flex justifyContent="center">
                    <Link onClick={() => setShowHow(true)}>How did we get this number?</Link>
                  </Flex>
                )}
                {showHow && (
                  <Flex>
                    <List
                      borderWidth={1}
                      p={4}
                      borderRadius={EstimatorTemplate.borderRadius}
                      fontSize={EstimatorTemplate.fontSizeMed}
                    >
                      <ListItem fontWeight={300}>
                        <Text as="div" fontWeight={700}>
                          {info?.year} {info?.make} {info?.model}
                        </Text>
                        Our propietary machine learning (ML) model estimated your payoff amount
                        based on leased cars similar to yours.
                      </ListItem>
                      <ListItem fontWeight={300} mt={4}>
                        <Text as="div" fontWeight={700}>
                          {address?.stateLabel || address?.state}, home sweet home
                        </Text>
                        Given your state of residence we calculated estimated fees and taxes.
                      </ListItem>
                      <ListItem fontWeight={300} mt={4}>
                        <Text as="div" fontWeight={700}>
                          Interest Rate: {getCreditLabel(pulseResult || '')} credit
                        </Text>
                        {pulseResult === 'no_response' ? (
                          <Text as="div">
                            ⚠️ Unable to fetch credit: assumed credit score{' '}
                            <Text as="span" fontWeight={700}>
                              Good
                            </Text>
                            .
                          </Text>
                        ) : (
                          <>
                            Using your credit score our ML model scanned transactions similar to
                            yours, factored in current market conditions and dervied an estimated
                            interest rate.
                          </>
                        )}
                      </ListItem>
                    </List>
                  </Flex>
                )}
                <Flex
                  flexDir="column"
                  gap={4}
                  p={4}
                  borderRadius={EstimatorTemplate.borderRadius}
                  bg={EstimatorTemplate.lightGray}
                >
                  <Flex>
                    <Text fontWeight={300}>
                      <Text as="span" fontWeight={700}>
                        Want the details?
                      </Text>{' '}
                      Enter your phone below and an advisor will contact you shortly to get a bit
                      more info and lock in this estimate!
                    </Text>
                  </Flex>
                  <StepInput
                    autoFocus
                    name="phoneNumber"
                    label="Phone number"
                    mask="(999) 999-9999"
                    handleChange={(e) => handlePhone(e.target?.value)}
                    bg="white"
                    isDisabled={submitting}
                  />
                </Flex>
                <StepButton
                  handleClick={() => {
                    if (!submitting && phone && phone !== '') submitPhone();
                  }}
                  actionable={!submitting && phone !== null && phone !== ''}
                  label="send details"
                />
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};
export default Result;
