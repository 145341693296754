/* eslint-disable no-return-assign */

/* eslint-disable react/jsx-no-undef */

/* eslint-disable react/jsx-pascal-case */
import { Box, Flex, useBreakpointValue, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Footer from '../../components/Footer';
import Nav from '../../components/Nav/Nav';
import ContinueContainer from '../../components/ui/GetStarted/ContinueContainer';
import { COMErrorCodes } from '../../constants/errors';
import { QueryParams } from '../../constants/queryParam';
import {
  BEST_MONEY,
  BUY_YOUR_LEASED_CAR,
  REDIRECT_TO_CV,
  REDIRECT_TO_TTCL,
} from '../../constants/urls';
import useCheckFlowStatus from '../../hooks/useCheckFlowStatus';
import useRefSize from '../../hooks/useRefSize';
import { createErrorToast } from '../../utils/toast';
import LPSimple from './LPSimple';
import CarDudeBgImage from './components/CarDudeBgImage';
import ExpiredPayoffRequestModal from './components/ExpiredPayoffRequestModal';
import FaqAndArticles from './components/FaqAndArticles';
import HomeHero from './components/HomeHero';
import HowCanWeHelp from './components/HowCanWeHelp';
import MidPageBanner from './components/MidPageBanner';
import MonthlyPayment from './components/MonthlyPayment';
import ReadyToBuyYourLeasedCar from './components/ReadyToBuyYourLeasedCar';
import ReviewLinks from './components/ReviewLinks';
import Reviews from './components/Reviews';
import Steps from './components/Steps';
import TellMeMoreAboutLeasedBuyouts from './components/TellMeMoreAboutLeasedBuyouts';
import WhyBuyYourLeasedCar from './components/WhyBuyYourLeasedCar';

const Home = () => {
  const { pathname } = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const bestMoneyBadge = pathname === BEST_MONEY && !isMobile;
  const consumerVoiceBadge = REDIRECT_TO_CV.includes(pathname);
  const topTenCarLoansVoiceBadge = REDIRECT_TO_TTCL.includes(pathname);
  const [expiredPayoffRequestModalOpen, setExpiredPayoffRequestModalOpen] = useState(false);
  const [imgRef, { height }] = useRefSize<HTMLImageElement>();
  const {
    replace,
    location: { search },
  } = useHistory();
  const toast = useToast();
  const params = new URLSearchParams(search);
  const { inFlow, enterFlow, canStartOver, startOver } = useCheckFlowStatus();

  useEffect(() => {
    const errCode = params.get(QueryParams.ERR_CODE);
    if (errCode === COMErrorCodes.NoDealFound) {
      params.delete(QueryParams.ERR_CODE);
      replace({ search: params.toString() });
      toast(
        createErrorToast({
          description:
            "We weren't able to find any information associated with the email you " +
            'provided. Please enter your License Plate or VIN to get started. If you think this ' +
            'is an error, please get in touch with our support team for help.',
        }),
      );
    }
  }, [search]);

  const tracking = () => {
    return null;
  };
  useEffect(() => {
    tracking();
  });

  return (
    <>
      <Box
        minH="100%"
        bg={
          !consumerVoiceBadge && !bestMoneyBadge && !topTenCarLoansVoiceBadge
            ? 'linear-gradient(180deg, rgba(186,244,255,1) 0%, rgba(186,244,255,0.5) 5%, rgba(255,255,255,1) 50%, rgba(186,244,255,0.5) 95%, rgba(186,244,255,1) 100%)'
            : ''
        }
      >
        <Box>
          <Nav />
        </Box>
        {consumerVoiceBadge || bestMoneyBadge || topTenCarLoansVoiceBadge ? (
          <>
            <CarDudeBgImage ref={imgRef} />
            <HomeHero h={height ? height - height * 0.165 : 910} />
          </>
        ) : inFlow ? (
          <>
            <Flex mx="auto" mt={16} mb={12} justifyContent="center">
              <ContinueContainer
                enterFlow={enterFlow}
                canStartOver={canStartOver}
                startOver={startOver}
              />
            </Flex>
          </>
        ) : (
          <LPSimple />
        )}
        <Steps />
        <ReviewLinks />
        {(consumerVoiceBadge || bestMoneyBadge || topTenCarLoansVoiceBadge) && <HowCanWeHelp />}
        {pathname === BUY_YOUR_LEASED_CAR && (
          <>
            <WhyBuyYourLeasedCar />
            <TellMeMoreAboutLeasedBuyouts />
            <ReadyToBuyYourLeasedCar />
            <MonthlyPayment />
          </>
        )}
        <MidPageBanner />
        <Reviews />
        <FaqAndArticles />
        <Footer bg="matisseBlue" color="white" _socialMediaIcon={{ color: 'white' }} />
      </Box>
      <ExpiredPayoffRequestModal
        isOpen={expiredPayoffRequestModalOpen}
        onClose={() => setExpiredPayoffRequestModalOpen(false)}
      />
    </>
  );
};

export default Home;
