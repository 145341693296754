import { Influencer_BraidenFulcrum } from '../../../assets/Images';
import Wrapper from './Wrapper';

const BraidenFulcrum = () => {
  return (
    <Wrapper
      influencer={{
        name: 'Braiden Fulcrum',
        imagePath: Influencer_BraidenFulcrum,
        imageWidth: 220,
        styleImage: true,
      }}
    />
  );
};

export default BraidenFulcrum;
