/* eslint-disable @typescript-eslint/no-explicit-any */
import { atom } from 'jotai';

import { CreditScore } from '../gql/generated/graphql';
import { SalesProps } from '../pages/Sanity/Model';
import { EstimateDefault } from '../utils/paymentCalculator';

interface VipInfo {
  id: string;
  deal_id: number | string;
  data: {
    firstName?: string;
    make?: string;
    model?: string;
    year?: string;
  };
}

const BlogSearchTerm = atom<string | null>(null);
const BlogSearch = atom<boolean>(false);

const VIPDealId = atom<number | null>(null);
const VIPSetterProfile = atom<SalesProps | null>(null);
const VIPInfo = atom<VipInfo | null>(null);

const VIPHasBeenSet = atom<boolean>(false);

const EstimatorImportDone = atom<boolean>(false);
const EstimatorSignOff = atom<boolean>(false);
const EstimatorShowHow = atom<boolean>(false);
const EstimatorConversation = atom<any>(null);
const EstimatorConversationStrings = atom<string[]>([]);
const EstimatorFirstName = atom<string | null>(null);
const EstimatorLastName = atom<string | null>(null);
const EstimatorPhone = atom<string | null>(null);
const EstimatorEmail = atom<string | null>(null);
const EstimatorAddress = atom<{
  street?: string | null | undefined;
  apt_unit?: string | null | undefined;
  city?: string | null | undefined;
  state?: string | null | undefined;
  stateLabel?: string | null | undefined;
  zip?: string | null | undefined;
  county?: string | null | undefined;
} | null>({
  street: null,
  apt_unit: null,
  city: null,
  state: null,
  zip: null,
  county: null,
});

const EstimatorVin = atom<string | null>(null);
const EstimatorLicensePlate = atom<string | null>(null);
const EstimatorMatters = atom<string | null>(null);
const EstimatorStep = atom<string>('1');
const EstimatorCredit = atom<string>(CreditScore.Good);
const EstimatorCarMake = atom<string | null>(null);
const EstimatorCarModel = atom<string | null>(null);
const EstimatorCarTrim = atom<string | null>(null);
const EstimatorCarYear = atom<string | null>(null);
const EstimatorWrap = atom<boolean>(false);
const EstimatorPulse = atom<boolean>(false);
const EstimatorPulseResult = atom<string | null>(null);
const EstimatorMilesOver = atom<boolean | undefined>(undefined);
const EstimatorGuid = atom<string | null>(null);
const EstimatorZipCodeFallBack = atom<string | null>(null);
const EstimatorTempInfo = atom<{
  id?: string | null;
  vehicle_type?: string;
  make: string;
  model: string;
  year: string;
  vin?: string | null;
} | null>(null);

const EstimatorDown = atom<number>(EstimateDefault.down);
const EstimatorTerm = atom<number>(EstimateDefault.term);

const EstimatorEstimateVars = atom<{
  creditScore: string;
  term: number;
  moneyDown: number;
  payoff: number;
  zipCode: string;
  ttGetFeesSource: string;
}>({
  creditScore: '',
  term: 72,
  moneyDown: 0,
  payoff: 0,
  zipCode: '',
  ttGetFeesSource: '',
});

export {
  BlogSearch,
  BlogSearchTerm,
  EstimatorDown,
  EstimatorTerm,
  EstimatorCarMake,
  EstimatorCarModel,
  EstimatorCarYear,
  EstimatorCarTrim,
  EstimatorSignOff,
  EstimatorConversation,
  EstimatorConversationStrings,
  EstimatorCredit,
  EstimatorFirstName,
  EstimatorLastName,
  EstimatorPhone,
  EstimatorEmail,
  EstimatorMatters,
  EstimatorLicensePlate,
  EstimatorVin,
  EstimatorPulse,
  EstimatorPulseResult,
  EstimatorStep,
  EstimatorGuid,
  EstimatorZipCodeFallBack,
  EstimatorTempInfo,
  EstimatorAddress,
  EstimatorWrap,
  EstimatorMilesOver,
  EstimatorShowHow,
  VIPDealId,
  VIPHasBeenSet,
  VIPInfo,
  VIPSetterProfile,
  EstimatorImportDone,
  EstimatorEstimateVars,
};
