import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PRESS } from '../../constants/urls';
import { objectEmpty } from '../../utils/objectEmpty';
import Loading from '../Loading';
import { releaseFetch } from '../Sanity/Data';
import { PressReleaseProps } from '../Sanity/Model';
import LeasedToLoved from './LeasedToLoved';
import Detail from './ReleaseDetail';

const Release = () => {
  const PR_STATIC = [
    {
      url: '2025-lease-buyout-report',
      asset: <LeasedToLoved />,
    },
  ];

  const Static = (slug: string) => {
    const pr = PR_STATIC.find((prs) => prs.url === slug);
    if (!pr) {
      return null;
    }
    return pr.asset;
  };

  const { pr } = useParams<{ pr: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [release, setRelease] = useState<PressReleaseProps | null>(null);
  const [isStatic, setIsStatic] = useState<boolean>(false);
  const fetch = async () => {
    setLoading(true);
    const data: PressReleaseProps | null = await releaseFetch(pr);
    if (data && objectEmpty(data)) {
      window.location.href = PRESS;
      return;
    }
    setLoading(false);
    setRelease(data);
  };
  useEffect(() => {
    if (PR_STATIC.find((s) => s.url === pr)) {
      setIsStatic(true);
      return;
    }
    fetch();
  }, []);
  if (isStatic) {
    return Static(pr);
  }
  return (
    <>
      {loading ? (
        <Loading />
      ) : release ? (
        <Detail release={release} full />
      ) : (
        <Flex>Something went wrong</Flex>
      )}
    </>
  );
};

export default Release;
