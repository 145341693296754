/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react/jsx-pascal-case */
import { Box, Flex, FlexProps, Icon, Image, Text } from '@chakra-ui/react';
import { BsCarFrontFill, BsPersonFill } from 'react-icons/bs';

import { LogoAutomaticBy, LogoAutomaticIconGradient } from '../../assets/Images';
import '../../assets/estimator.css';
import StepButton from '../../components/PaymentEstimator/Button';
import Swirl from '../../components/PaymentEstimator/Swirl';
import { PAYMENT_ESTIMATOR } from '../../constants/urls';
import { EstimatorTemplate } from '../../utils/paymentCalculator';

const LAYOUT_WIDTH = 1200;
const LAYOUT_WIDTH_NARROW = LAYOUT_WIDTH * 0.75;
const LAYOUT_TEXT_LARGE = {
  fontSize: {
    base: 42,
    md: '3.75rem',
  },
  fontWeight: {
    base: 600,
  },
};
const LAYOUT_TEXT_MED = {
  fontSize: {
    base: 21,
    md: '1.75rem',
  },
  fontWeight: {
    base: 500,
  },
};
const LAYOUT_TEXT_SM = {
  fontSize: {
    base: 16,
    md: '1.2rem',
  },
  fontWeight: {
    base: 400,
  },
};
const LAYOUT_GAP = {
  base: 4,
  md: 8,
};
const LAYOUT_PADDING = {
  base: 8,
  md: 16,
};
const LAYOUT_PADDING_SMALL = {
  base: 4,
  md: 8,
};

const RoundBubble = ({ children = null }: FlexProps) => {
  return !children ? null : (
    <Flex
      backgroundSize="250%"
      fontWeight={600}
      w={{
        base: '32px',
        md: '60px',
      }}
      h={{
        base: '32px',
        md: '60px',
      }}
      borderRadius="50%"
      backgroundImage={EstimatorTemplate.aiGradient}
      color="white"
      justifyContent="center"
      alignItems="center"
      fontSize={EstimatorTemplate.fontSizeMed}
    >
      {children}
    </Flex>
  );
};

interface AutomaticPointProps extends FlexProps {
  icon: any;
  pointTitle: string;
  pointDesc: string;
}
const AutomaticPoint = ({
  icon = null,
  pointTitle = '',
  pointDesc = '',
  ...props
}: AutomaticPointProps) => {
  return (
    <>
      <Flex
        borderRadius={12}
        flexDir="row"
        {...props}
        bg="rgba(255,255,255,0.6)"
        p={LAYOUT_PADDING_SMALL}
        gap={LAYOUT_PADDING_SMALL}
      >
        {icon && (
          <Flex flex={1}>
            <RoundBubble>{icon}</RoundBubble>
          </Flex>
        )}
        <Flex flexDir="column">
          <Text {...LAYOUT_TEXT_MED}>{pointTitle}</Text>
          <Text {...LAYOUT_TEXT_SM} color={EstimatorTemplate.darkText}>
            {pointDesc}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

const LP = () => {
  return (
    <>
      <Box pos="fixed" left={0} right={0} top={0} bottom={0} zIndex={0}>
        <Swirl />
      </Box>
      <Flex flexDir="column" w="100%" gap={LAYOUT_GAP} pos="relative" zIndex={2}>
        <Flex
          className="automatic-hi"
          justifyContent={{
            base: 'center',
          }}
          flex={1}
          p={LAYOUT_PADDING}
          backgroundSize="180%"
          borderRadius={16}
          flexDir="column"
          w={{
            base: '100%',
          }}
          overflow="hidden"
          pos="relative"
        >
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            pos="relative"
            zIndex={2}
            mx="auto"
            w={{
              base: '100%',
              md: `auto`,
            }}
            gap={LAYOUT_GAP}
          >
            <Flex
              flexDir="column"
              w={LAYOUT_WIDTH_NARROW}
              gap={LAYOUT_GAP}
              alignItems="center"
              flexWrap="wrap"
              width="auto"
            >
              <Image src={LogoAutomaticIconGradient} w="64px" />
              <Image src={LogoAutomaticBy} w="300px" />
              <Flex
                textAlign="center"
                {...LAYOUT_TEXT_LARGE}
                color={EstimatorTemplate.darkText}
                mt={LAYOUT_PADDING_SMALL}
              >
                Find Your New Monthly Payment
              </Flex>
              <Text textAlign="center" {...LAYOUT_TEXT_MED} color={EstimatorTemplate.darkText}>
                With only 2 pieces of information, Automatic AI will determine your new monthly
                payment to end your lease and keep your car.
              </Text>
              <Text
                mb={LAYOUT_PADDING_SMALL}
                textTransform="uppercase"
                textAlign="center"
                fontSize={{
                  base: 21,
                  md: '2rem',
                }}
                fontWeight={{
                  base: 600,
                }}
                color={EstimatorTemplate.darkText}
              >
                fast. free. accurate.
              </Text>
              <StepButton
                fontFamily="Monorale !important"
                py={12}
                label="GET YOUR NEW PAYMENT"
                actionable
                fontSize={20}
                fontWeight={700}
                handleClick={() => {
                  window.location.href = PAYMENT_ESTIMATOR;
                }}
                _hover={{
                  background: '#cf3356',
                }}
                bg={EstimatorTemplate.aiGradient}
                bgSize="250%"
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          mx="auto"
          justifyContent="center"
          flexDir="column"
          maxW={`${LAYOUT_WIDTH_NARROW}px`}
          alignItems="center"
          gap={LAYOUT_GAP}
          bg="rgba(255,255,255,0.4)"
          px={LAYOUT_PADDING_SMALL}
          pt={LAYOUT_PADDING_SMALL}
          borderRadius={16}
        >
          <Text
            textAlign="center"
            fontSize={{
              base: 42,
              md: '4rem',
            }}
            fontWeight={{
              base: 400,
            }}
            color={EstimatorTemplate.darkText}
          >
            What is Automatic AI?
          </Text>

          <Text textAlign="center" {...LAYOUT_TEXT_MED} color={EstimatorTemplate.darkText}>
            Automatic AI is Lease End's industry-changing technology that accurately estimates your
            new monthly payment using 2 pieces of info:
          </Text>
          <Flex
            flexDir={{
              base: 'column',
              md: 'row',
            }}
            gap={LAYOUT_GAP}
            mt={LAYOUT_GAP}
          >
            <AutomaticPoint
              icon={
                <Icon
                  as={BsPersonFill}
                  color="white"
                  boxSize={{
                    base: '20px',
                    md: '28px',
                  }}
                />
              }
              pointTitle="Name & Address"
              pointDesc="This allows Automatic to softly check your credit tier to fetch you the best rate."
              flex={1}
            />
            <AutomaticPoint
              icon={
                <Icon
                  as={BsCarFrontFill}
                  color="white"
                  boxSize={{
                    base: '20px',
                    md: '28px',
                  }}
                />
              }
              pointTitle="License Plate or VIN"
              pointDesc="Automatic uses this to retrieve the exact car you're leasing for better accuracy"
              flex={1}
            />
          </Flex>
          <Flex
            flexDir="column"
            {...LAYOUT_TEXT_SM}
            bg="rgba(255,255,255,0.6)"
            borderRadius={EstimatorTemplate.borderRadius}
            p={LAYOUT_PADDING_SMALL}
            mb={LAYOUT_PADDING_SMALL}
          >
            <Text fontWeight={600}>Why does Automatic ask for credit?</Text>
            <Text>
              Your name and address are used to softly (no dings to your credit!) evaluate your
              credit situation to determine an interest rate tailored to you. You don't have to let
              Automatic do this to get your estimate.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default LP;
