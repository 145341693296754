/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLazyQuery, useMutation } from '@apollo/client';
import { Flex, Image, Link, Text, useBreakpoint } from '@chakra-ui/react';
import { useAtom } from 'jotai';
// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

import Button from '../../components/PaymentEstimator/Button';
import Input from '../../components/PaymentEstimator/Input';
import { addMessaging } from '../../components/PaymentEstimator/MessagingText';
import StepOption from '../../components/PaymentEstimator/Option';
import ResultsContainer from '../../components/PaymentEstimator/ResultsContainer';
import StateSelected from '../../components/PaymentEstimator/StateSelected';
import { isProd } from '../../config';
import { LDFlags } from '../../constants/experiments';
import { ERROR_TYPES } from '../../constants/tempInfos';
import { KbbVin } from '../../gql/calculatorGql';
import { CreateTemporaryInfo } from '../../gql/tempInfo';
import {
  EstimatorAddress,
  EstimatorCarTrim,
  EstimatorConversation,
  EstimatorConversationStrings,
  EstimatorFirstName,
  EstimatorGuid,
  EstimatorLastName,
  EstimatorLicensePlate,
  EstimatorTempInfo,
  EstimatorVin,
} from '../../helpers/Atom';
import { hashUrl } from '../../helpers/Estimator';
import { useFlag } from '../../hooks';
import { carIconWhite } from '../../utils/carImage';
import { Estimator, EstimatorTemplate, MILEAGE_PER_YEAR } from '../../utils/paymentCalculator';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import Container from './Container';
import { AUTOMATIC_DIALOG } from './Dialog';
import { MainProps } from './Main';

const LicensePlateVIN = ({ move }: MainProps) => {
  const ldClient = useLDClient();
  const device = useBreakpoint();
  const { pathname } = useLocation();
  const [animate, setAnimate] = useState<boolean>(false);
  const [unwind, setUnwind] = useState<boolean>(false);
  const [plate, setPlate] = useAtom(EstimatorLicensePlate);
  const [vin, setVin] = useAtom(EstimatorVin);
  const [useVin, setUseVin] = useState<boolean>(false);
  const [widgetError, setWidgetError] = useState<boolean>(false);
  const [dealExists, setDealExists] = useState<boolean>(false);
  const [restrictedCar, setRestrictedCar] = useState<boolean>(false);
  const [oldCar, setOldCar] = useState<boolean>(false);
  const [invalidPlate, setInvalidPlate] = useState<boolean>(false);
  const [invalidVin, setInvalidVin] = useState<boolean>(false);
  const [decodingError, setDecodingError] = useState<boolean>(false);
  const [, setType] = useState<boolean>(false);
  const [hideButtonDisabled, setHideButtonDisabled] = useState<boolean>(false);
  const [firstName] = useAtom(EstimatorFirstName);
  const [lastName] = useAtom(EstimatorLastName);
  const [, setGuid] = useAtom(EstimatorGuid);
  const [info, setInfo] = useAtom(EstimatorTempInfo);
  const [address] = useAtom(EstimatorAddress);
  const [carConfirm, setCarConfirm] = useState<boolean | undefined>(undefined);
  const [carChanged, setCarChanged] = useState<boolean>(false);
  const [, setMilesThreshold] = useState<number>(0);
  const LM1755 = useFlag(LDFlags.LM1755);
  const INTRO = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'car-step1');
  const INTRO_CONTROL = INTRO?.control || '';
  const INTRO_EXPERIMENT = INTRO?.experiment || '';
  const initialMessage = LM1755 ? INTRO_EXPERIMENT : INTRO_CONTROL;
  const [messageStrings, setMessageStrings] = useAtom(EstimatorConversationStrings);
  const [carTrim, setCarTrim] = useAtom(EstimatorCarTrim);
  const [, setMessaging] = useAtom(EstimatorConversation);
  const [createTempInfo] = useMutation(CreateTemporaryInfo, {
    context: {
      isErrorHandled: true,
      fetchPolicy: 'no-cache',
    },
  });
  const [getKbb] = useLazyQuery(KbbVin, {
    fetchPolicy: 'no-cache',
  });
  const [creating, setCreating] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const tracking = () => {
    rudderanalytics.track(RudderEvent.LabTest, {
      name: 'LM-1755::LPVin',
      value: LM1755,
    });
  };
  const handleSubmit = async () => {
    setCreating(true);
    const CHECK = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'car-checking');
    const CHECK_CONTROL = CHECK?.control || '';
    const CHECK_EXPERIMENT = CHECK?.experiment || '';
    const CHECK_AGAIN = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'car-checkagain');
    const CHECK_AGAIN_CONTROL = CHECK_AGAIN?.control || '';
    const CHECK_AGAIN_EXPERIMENT = CHECK_AGAIN?.experiment || '';
    const m = carChanged
      ? LM1755
        ? CHECK_AGAIN_EXPERIMENT
        : CHECK_AGAIN_CONTROL
      : LM1755
      ? CHECK_EXPERIMENT
      : CHECK_CONTROL;
    const ms = messageStrings.concat(m);
    setMessageStrings(ms);
    setMessaging(addMessaging(m, ms));
    setWidgetError(false);
    await createTempInfo({
      variables: {
        vin: useVin ? vin : null,
        license_plate_number: useVin ? null : plate,
        license_plate_state: useVin ? null : address?.state,
        device_type: device,
        extra_info: {
          first_name: firstName || 'Payment Estimator',
          last_name: lastName || 'Web',
        },
        query_params: '',
        ignore_existing_deal: !isProd,
      },
    })
      .then((response) => {
        if (response.errors) {
          setWidgetError(true);
        }
        const id = response.data?.createTemporaryInfo?.id as string;
        const tempInfoData = response.data?.createTemporaryInfo?.data;

        if (tempInfoData?.vin) {
          ldClient?.track('Flow Entry', {});
          TagManager.dataLayer({
            dataLayer: {
              VIN: tempInfoData.vin,
              event: 'hotjar',
            },
          });
        }
        setPlate(tempInfoData?.license_plate_number);
        setVin(tempInfoData?.vin);
        getKbb({
          variables: {
            vin: tempInfoData?.vin,
          },
        }).then((kbbResponse) => {
          const vinResults = kbbResponse?.data.kbbVin?.vinResults ?? [];
          if (vinResults.length === 1) {
            setCarTrim(vinResults[0].trimName);
          }
        });
        rudderanalytics.identify({ guid: id });
        rudderanalytics.track(RudderEvent.VinProvided, {
          year: tempInfoData?.year || undefined,
          make: tempInfoData?.make || undefined,
          model: tempInfoData?.model || undefined,
          vin: tempInfoData?.vin || undefined,
          zip: tempInfoData?.zip || undefined,
          license_plate: tempInfoData?.license_plate_number || undefined,
          license_plate_state: tempInfoData?.license_plate_state || undefined,
          fuel_type: tempInfoData?.fuel_type || undefined,
          vehicle_type: tempInfoData?.vehicle_type || undefined,
        });
        setGuid(id);
        setInfo(tempInfoData);
      })
      .catch((error) => {
        setCreating(false);
        const extensions = error.graphQLErrors[0]?.extensions;
        const dealId = extensions?.dealId;
        const dealState = extensions?.dealState;

        rudderanalytics.track(RudderEvent.Error, {
          error_message: error.message,
          vin,
          license_plate: plate,
          license_plate_state: address?.state,
          pathname,
          date: new Date().toISOString(),
          deal_id: dealId,
          deal_state: dealState,
        });

        if (error.message === ERROR_TYPES.DEAL_EXISTS) {
          setDealExists(true);
        }
        if (error.message === ERROR_TYPES.OLD_CAR) {
          setOldCar(true);
        }
        if (error.message === ERROR_TYPES.RESTRICTED_CAR) {
          setRestrictedCar(true);
        }
        if (error.message === ERROR_TYPES.DECODING_SERVICE_ISSUE) {
          setDecodingError(true);
        }
        if (error.message === ERROR_TYPES.INVALID_LICENSE_PLATE) {
          setInvalidPlate(true);
        }
      })
      .finally(() => {
        setCreating(false);
      });
  };

  const resetErrors = () => {
    setWidgetError(false);
    setDealExists(false);
    setOldCar(false);
    setDecodingError(false);
    setRestrictedCar(false);
    setInvalidPlate(false);
  };

  const handleMilesThreshold = (year: string) => {
    const t = new Date();
    const y: number = t.getFullYear() - 1; // adjust for only full years since manufacture
    const m: number = t.getMonth() + 1; // adjust for 0 based
    const baseMiles = (y - 1 - Number.parseInt(year, 10)) * MILEAGE_PER_YEAR;
    const currentYearMiles = (m / 12) * MILEAGE_PER_YEAR;
    setMilesThreshold(Math.round(baseMiles + currentYearMiles));
  };

  const handleCarConfirm = (confirm: boolean) => {
    setCarConfirm(confirm);
    if (confirm) {
      setHideButtonDisabled(true);
      return;
    }
    setHideButtonDisabled(false);
    setCarChanged(true);
  };

  useEffect(() => {
    if (info) {
      const CAR_FOUND = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'car-found');
      const CAR_FOUND_CONTROL =
        CAR_FOUND?.control.replace('{{make}}', info?.make).replace('{{model}}', info?.model) || '';
      const CAR_FOUND_EXPERIMENT =
        CAR_FOUND?.experiment.replace('{{make}}', info?.make).replace('{{model}}', info?.model) ||
        '';
      const m = LM1755 ? CAR_FOUND_EXPERIMENT : CAR_FOUND_CONTROL;
      const ms = messageStrings.concat(m);
      setMessageStrings(ms);
      setMessaging(addMessaging(m, ms));
    }
    if (info && info.year) {
      handleMilesThreshold(info.year);
    }
  }, [info]);

  useEffect(() => {
    setWidgetError(dealExists || oldCar || decodingError || restrictedCar || invalidPlate);
  }, [dealExists, oldCar, decodingError, restrictedCar, invalidPlate]);

  useEffect(() => {
    if (creating) {
      resetErrors();
    }
  }, [creating]);

  useEffect(() => {
    if (vin) {
      hashUrl('EstimatorVIN', true);
      return;
    }
    hashUrl('EstimatorLicensePlate', true);
  }, [vin]);

  useEffect(() => {
    if (!widgetError) {
      return;
    }
    let m = `🚨 Hmm, I ran into an issue...`;
    if (dealExists) {
      m += `looks like we already have something going for this car!`;
    } else if (oldCar) {
      m += `we can't work with a car this old. Sorry!`;
    } else if (decodingError || invalidPlate) {
      m += `I didn't find a car with this plate. Are you sure it's correct? (hint: check the state too!)`;
    } else if (restrictedCar) {
      m += `unfortunately we can't help you buy this particular type of leased car right now.`;
    }
    setCreating(false);
    setCanSubmit(true);
    const ms = messageStrings.concat(m);
    setMessageStrings(ms);
    setMessaging(addMessaging(m, ms));
  }, [widgetError]);

  useEffect(() => {
    if (carConfirm === true) {
      const MOVING_ON = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'car-confirmed');
      const m = LM1755 ? MOVING_ON?.experiment || '' : MOVING_ON?.control || '';
      const ms = messageStrings.concat(m);
      setMessaging(addMessaging(m, ms));
      setMessageStrings(ms);
      setTimeout(() => {
        setUnwind(true);
        move('SoftPull');
      }, Estimator.animationDuration * 8);
      return;
    }
    if (carConfirm === false) {
      const TRY_AGAIN = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'car-tryagain');
      const m = LM1755 ? TRY_AGAIN?.experiment || '' : TRY_AGAIN?.control || '';
      const ms = messageStrings.concat(m);
      setMessaging(addMessaging(m, ms));
      setMessageStrings(ms);
    }
    setInfo(null);
    setCarConfirm(undefined);
  }, [carConfirm]);

  useEffect(() => {
    tracking();
    const timer = setTimeout(() => {
      setAnimate(true);
    }, Estimator.animationDuration);
    const timerType = setTimeout(() => {
      setType(true);
    }, Estimator.animationDuration * 2);
    const ms = messageStrings.concat(initialMessage);
    setMessageStrings(ms);
    setMessaging(addMessaging(initialMessage, ms));
    return () => {
      clearTimeout(timer);
      clearTimeout(timerType);
    };
  }, []);

  return (
    <>
      <Container
        className={`calculator-step ${unwind ? 'animate-unwind' : animate ? 'animate' : ''}`}
      >
        {info ? (
          <>
            <ResultsContainer>
              <Image src={carIconWhite(info?.vehicle_type || '')} width="100px" />
              <Text
                color={EstimatorTemplate.darkText}
                fontSize={EstimatorTemplate.fontSizeXLarge}
                fontWeight={600}
                textAlign="center"
              >
                {info.year} {info.make} {!carTrim && info.model}
                {carTrim && (
                  <>
                    <Text as="br" />
                    <Text
                      textAlign="center"
                      as="span"
                      fontSize={EstimatorTemplate.fontSizeMed}
                      fontWeight={400}
                    >
                      {carTrim}
                    </Text>
                  </>
                )}
              </Text>
            </ResultsContainer>
            {!hideButtonDisabled && (
              <Flex gap={2} mt={2}>
                <StepOption
                  label="no"
                  optionSelected={carConfirm === false}
                  customOnClick={() => handleCarConfirm(false)}
                />
                <StepOption
                  label="yes"
                  optionSelected={carConfirm === true}
                  customOnClick={() => handleCarConfirm(true)}
                  reco
                />
              </Flex>
            )}
          </>
        ) : (
          <>
            <Flex flexDir="column" gap={2}>
              {useVin ? (
                <Input
                  autoFocus
                  label="VIN"
                  name="vin"
                  onChange={(e) => setVin(e.target.value)}
                  isDisabled={creating}
                  defaultValue={vin || ''}
                  debounce="true"
                />
              ) : (
                <>
                  <Input
                    autoFocus
                    label="License Plate"
                    name="lp"
                    onChange={(e) => setPlate(e.target.value)}
                    isDisabled={creating}
                    defaultValue={plate || ''}
                    debounce="true"
                  />
                  <StateSelected minH="60px" />
                </>
              )}
              <Flex>
                {useVin ? (
                  <Link
                    onClick={() => {
                      hashUrl('EstimatorLicensePlate', true);
                      setUseVin(false);
                    }}
                  >
                    Use License Plate
                  </Link>
                ) : (
                  <Link
                    onClick={() => {
                      hashUrl('EstimatorVIN', true);
                      setUseVin(true);
                    }}
                  >
                    Use VIN
                  </Link>
                )}
              </Flex>
              <Button
                className={`btn-dynamic ${!creating && canSubmit ? 'go' : ''}`}
                actionable={
                  (!useVin && plate !== null && address?.state !== null) || (useVin && vin !== null)
                }
                label={creating ? `finding your car...` : 'find car'}
                onClick={() => {
                  if (!creating && canSubmit) {
                    handleSubmit();
                  }
                }}
              />
            </Flex>
            {/* <Flex justifyContent="center">
              <Link
                fontWeight={400}
                fontSize={EstimatorTemplate.fontSizeMed}
                onClick={() => {
                  setUnwind(true);
                  move('MakeModelYear');
                }}
              >
                Don't know your plate?
              </Link>
            </Flex> */}
          </>
        )}
      </Container>
    </>
  );
};

export default LicensePlateVIN;
