export enum LDFlags {
  ACQUISITIONS_FLOW = 'acquisitions-flow',
  LEASE_END_OPT_OUT = 'lease-end-opt-out',
  LEASE_PEACE = 'lease-peace',
  RE_CAPTCHA_ENABLED = 're-captcha-enabled',
  SHOW_HEALTH_TRACKER = 'health-tracker',
  SHOW_VIDEO_CAROUSEL = 'show-video-carousel',
  PAYMENT_CALCULATOR_VARIATION = 'payment-calculator-variations',

  // Careers page
  LM558 = 'LM-558',
  // Dynamic phone numbers
  LM1334 = 'LM-1334',
  // Automatic AI dialog
  LM1755 = 'LM-1755',
  // Tariff H1 (organic home)
  LM1839 = 'LM-1839',
  // Automatic header CTA AB test
  LM1876 = 'LM-1876',
  // NCAA tourney winner home page
  LM1875 = 'LM-1875',
}

export type FlagOverrides = {
  [key in LDFlags]?: unknown;
};
