import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Decimal: { input: any; output: any };
  GUID: { input: any; output: any };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
  Void: { input: any; output: any };
};

export type AffiliateDuplicateFound = {
  __typename?: 'AffiliateDuplicateFound';
  deal_id: Scalars['ID']['output'];
  first_name: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
};

export type Auth0Role = {
  __typename?: 'Auth0Role';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Auth0RoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum Auth0RoleName {
  AccountingTeam = 'AccountingTeam',
  Admin = 'Admin',
  CSuite = 'CSuite',
  CustomerService = 'CustomerService',
  D2dAdmin = 'D2dAdmin',
  D2dCloser = 'D2dCloser',
  D2dDev = 'D2dDev',
  D2dSetter = 'D2dSetter',
  D2dSetterLead = 'D2dSetterLead',
  FinanceTeam = 'FinanceTeam',
  FundingClerk = 'FundingClerk',
  FundingClerkManager = 'FundingClerkManager',
  InboundFinancialSpecialist = 'InboundFinancialSpecialist',
  InboundSalesAdvisor = 'InboundSalesAdvisor',
  InboundSalesPodLead = 'InboundSalesPodLead',
  OutboundAdvisorManager = 'OutboundAdvisorManager',
  OutboundSetter = 'OutboundSetter',
  OutboundWebAdvisor = 'OutboundWebAdvisor',
  PayoffClerk = 'PayoffClerk',
  PayoffManager = 'PayoffManager',
  StructuringAssistant = 'StructuringAssistant',
  StructuringManager = 'StructuringManager',
  TitleClerk = 'TitleClerk',
  TitleManager = 'TitleManager',
  WebAdmin = 'WebAdmin',
}

export type AutoStructureBankLogo = {
  __typename?: 'AutoStructureBankLogo';
  bank_name?: Maybe<Scalars['String']['output']>;
  logo_url?: Maybe<Scalars['String']['output']>;
};

export type Coordinate = {
  __typename?: 'Coordinate';
  height?: Maybe<Scalars['Float']['output']>;
  pageNumber?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  xPosition?: Maybe<Scalars['Float']['output']>;
  yPosition?: Maybe<Scalars['Float']['output']>;
};

export type CreateTransactionInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  conditions?: InputMaybe<TtConditionFilterInput>;
  parameters?: InputMaybe<TtFeesInput>;
  processor?: InputMaybe<Processor>;
  state?: InputMaybe<StateAbbreviation>;
  types?: InputMaybe<Array<InputMaybe<TtProductType>>>;
};

export type CreateUserInput = {
  auth0Roles: Array<InputMaybe<Auth0RoleInput>>;
  email: Scalars['String']['input'];
  huntGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  location?: InputMaybe<LocationType>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  podIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  recruiterId?: InputMaybe<Scalars['String']['input']>;
};

export type CreditAppInput = {
  cobuyer?: InputMaybe<CustomerInput>;
  contact?: InputMaybe<DealContactInput>;
  customer: CustomerInput;
  financial_info: FinancialInfoInput;
  id: Scalars['ID']['input'];
  odometer_status?: InputMaybe<OdometerStatus>;
  second_contact?: InputMaybe<DealContactInput>;
  state: Scalars['String']['input'];
};

export type CreditApplicationSubmission = {
  __typename?: 'CreditApplicationSubmission';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  credit_applications?: Maybe<Array<Maybe<CreditApplication>>>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type CreditApplicationSubmissionFilter = {
  dealIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type D2dDocument = {
  __typename?: 'D2dDocument';
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DdSoftPull = {
  __typename?: 'DDSoftPull';
  cobuyer?: Maybe<Prequalification>;
  customer: Prequalification;
};

export type DashboardDocumentUpload = {
  __typename?: 'DashboardDocumentUpload';
  deal: Deal;
  fileType: Scalars['String']['output'];
};

export type DateFilter = {
  after?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DealDocument = {
  __typename?: 'DealDocument';
  data?: Maybe<Scalars['String']['output']>;
  dealJacketId?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  docStatCode?: Maybe<Scalars['String']['output']>;
  documentId?: Maybe<Scalars['String']['output']>;
  documentName?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  signAttributes?: Maybe<Array<Maybe<DealDocumentSignAttribute>>>;
  signatureStatus?: Maybe<DocumentSignatureStatusEnum>;
  wetSignature?: Maybe<Scalars['Boolean']['output']>;
};

export type DealDocumentSignAttribute = {
  __typename?: 'DealDocumentSignAttribute';
  actorName?: Maybe<Scalars['String']['output']>;
  actorType?: Maybe<ActorType>;
  dateCoordinates?: Maybe<Coordinate>;
  dateFieldName?: Maybe<Scalars['String']['output']>;
  initialsType?: Maybe<Scalars['Boolean']['output']>;
  signCaptureTms?: Maybe<Scalars['String']['output']>;
  signFieldName?: Maybe<Scalars['String']['output']>;
  signPadText?: Maybe<Scalars['String']['output']>;
  signRequired?: Maybe<Scalars['Boolean']['output']>;
  signatureCoordinates?: Maybe<Coordinate>;
};

export type DealImported = {
  __typename?: 'DealImported';
  deal_id: Scalars['ID']['output'];
  first_name: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
};

export enum DealMediaSourceEnum {
  System = 'system',
  User = 'user',
}

export enum DealMediaStateEnum {
  Active = 'active',
  Pending = 'pending',
}

export enum DealSortableColumnsEnum {
  CreatedAt = 'created_at',
  CustomerName = 'customer_name',
  DealStage = 'deal_stage',
}

export enum DealSource {
  All = 'all',
  CallIn = 'call_in',
  D2d = 'd2d',
  Outbound = 'outbound',
  Web = 'web',
}

export enum DealStateEnumV2 {
  AtAuction = 'at_auction',
  Booted = 'booted',
  Closed = 'closed',
  Closing = 'closing',
  Estimate = 'estimate',
  Finalized = 'finalized',
  Floor = 'floor',
  Funded = 'funded',
  PaidOff = 'paid_off',
  ReadyForPickup = 'ready_for_pickup',
  SendPayoff = 'send_payoff',
  SentToProcessor = 'sent_to_processor',
  Signatures = 'signatures',
  Signed = 'signed',
  SoftClose = 'soft_close',
  Sold = 'sold',
  Structuring = 'structuring',
  StructuringInProgress = 'structuring_in_progress',
  TitleReceived = 'title_received',
  TitleSent = 'title_sent',
  WaitingForTitle = 'waiting_for_title',
}

export enum DealType {
  Acquisition = 'acquisition',
  Buyout = 'buyout',
  Refi = 'refi',
}

export enum DealTypeV2 {
  Acquisition = 'acquisition',
  Buyout = 'buyout',
  Refi = 'refi',
}

export type DealWithErrors = {
  __typename?: 'DealWithErrors';
  deal?: Maybe<Deal>;
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DealsConnection = {
  __typename?: 'DealsConnection';
  items: Array<Maybe<Deal>>;
  pagination: PaginationType;
  total?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type DealsFilter = {
  closerIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars['GUID']['input']>>>;
  created_at?: InputMaybe<DateFilter>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  isFinalized?: InputMaybe<Scalars['Boolean']['input']>;
  leaseIds?: InputMaybe<Array<InputMaybe<Scalars['GUID']['input']>>>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  setterIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  source?: InputMaybe<DealSource>;
  state?: InputMaybe<Array<InputMaybe<DealStateEnumV2>>>;
  type?: InputMaybe<DealTypeV2>;
  updated_at?: InputMaybe<DateFilter>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum DocumentProgressStatus {
  Failed = 'failed',
  GenerateDocuments = 'generate_documents',
  PendingLender = 'pending_lender',
  PendingR1 = 'pending_r1',
  ReadyForSignatures = 'ready_for_signatures',
  Required = 'required',
  SentForSignatures = 'sent_for_signatures',
  Signed = 'signed',
  UploadDocuments = 'upload_documents',
}

export enum DocumentSignatureStatusEnum {
  Completed = 'completed',
  InProcess = 'in_process',
  Unstarted = 'unstarted',
}

export type DownPaymentReceived = {
  __typename?: 'DownPaymentReceived';
  amount: Scalars['Float']['output'];
  dealId: Scalars['ID']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type DriversLicenseGql = {
  __typename?: 'DriversLicenseGQL';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type DuplicatedDealImport = {
  __typename?: 'DuplicatedDealImport';
  deal_id: Scalars['ID']['output'];
  first_name: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
};

export type DuplicatedInfo = {
  __typename?: 'DuplicatedInfo';
  deal_id: Scalars['ID']['output'];
  first_name: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
};

export type ExperianOutput = {
  __typename?: 'ExperianOutput';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  riskModel?: Maybe<ExperianRiskModel>;
};

export type ExperianRiskModel = {
  __typename?: 'ExperianRiskModel';
  score?: Maybe<Scalars['String']['output']>;
};

export type ExternalCustomerInput = {
  dashboard_visited?: InputMaybe<Scalars['Boolean']['input']>;
  dl_expiration_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_authenticated_route?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
};

export type ExternalDealInputType = {
  auto_import_variation?: InputMaybe<AutoImportVariation>;
};

export type ExtractedDocumentGql = {
  __typename?: 'ExtractedDocumentGQL';
  documentName?: Maybe<Scalars['ID']['output']>;
  fields?: Maybe<Array<Maybe<ExtractedField>>>;
  key?: Maybe<Scalars['String']['output']>;
};

export type ExtractedField = {
  __typename?: 'ExtractedField';
  confidence?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FeeGql = {
  __typename?: 'FeeGQL';
  baseTaxAmount?: Maybe<Scalars['Float']['output']>;
  docFeeTaxAmount?: Maybe<Scalars['Float']['output']>;
  feeProcessor?: Maybe<Scalars['String']['output']>;
  registrationTransferFee?: Maybe<Scalars['Float']['output']>;
  salesTaxRate?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<StateAbbreviation>;
  taxProcessor?: Maybe<Scalars['String']['output']>;
  totalFeeAmount?: Maybe<Scalars['Float']['output']>;
  warrantyTaxAmount?: Maybe<Scalars['Float']['output']>;
};

export type FilterType = {
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  banks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  closedDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  closedDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  dealStates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hasTitleReceived?: InputMaybe<Scalars['Boolean']['input']>;
  lienholders?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notifications?: InputMaybe<Scalars['Boolean']['input']>;
  problemDeals?: InputMaybe<Scalars['Boolean']['input']>;
  processors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  titleDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  titleDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  titlingPods?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  usStates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vins?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GetRelationshipToDealByPhoneNumberResponse = {
  __typename?: 'GetRelationshipToDealByPhoneNumberResponse';
  relationship: Scalars['String']['output'];
};

export type HuntGroup = {
  __typename?: 'HuntGroup';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  default_queue?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  ring_all?: Maybe<Scalars['Boolean']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type KbbSelectedOptionsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  lendingOptionPrice?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  removed?: InputMaybe<Scalars['Boolean']['input']>;
  retailOptionPrice?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type KbbSelectedOptionsObject = {
  __typename?: 'KbbSelectedOptionsObject';
  selected_options?: Maybe<Array<Maybe<KbbSelectedOptionsType>>>;
};

export type KbbSelectedOptionsObjectInput = {
  selected_options?: InputMaybe<Array<InputMaybe<KbbSelectedOptionsInput>>>;
};

export enum LeExperianErrorCodeEnum {
  Frozen = 'FROZEN',
  Locked = 'LOCKED',
  NoRecordFound = 'NO_RECORD_FOUND',
}

export type Lender = {
  __typename?: 'Lender';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum LocationType {
  Idaho = 'idaho',
  Utah = 'utah',
}

export type NotificationSubscriber = {
  __typename?: 'NotificationSubscriber';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  subscriber_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationSubscriberInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  subscriber_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum OdometerStatus {
  Changed = 'Changed',
  Mismatch = 'Mismatch',
  Verified = 'Verified',
  Warning = 'Warning',
}

export type PaginatedCallHistory = {
  __typename?: 'PaginatedCallHistory';
  calls?: Maybe<Array<Maybe<Call>>>;
  newMissedCallIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedDeals = {
  __typename?: 'PaginatedDeals';
  deals?: Maybe<Array<Maybe<Deal>>>;
  dealsMaxAge?: Maybe<Scalars['DateTime']['output']>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  results?: Maybe<Array<Maybe<User>>>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

export type PaginationInputType = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginationType = {
  __typename?: 'PaginationType';
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
};

export enum PaperworkType {
  Adobe = 'adobe',
  Esign = 'esign',
  Paper = 'paper',
}

export type PaymentOptionsInput = {
  bank?: InputMaybe<Scalars['String']['input']>;
  bankFees?: InputMaybe<Scalars['Float']['input']>;
  banks?: InputMaybe<Array<InputMaybe<BankInput>>>;
  baseTaxAmount?: InputMaybe<Scalars['Float']['input']>;
  buyRate?: InputMaybe<Scalars['Float']['input']>;
  carMileage?: InputMaybe<Scalars['Int']['input']>;
  carYear?: InputMaybe<Scalars['Int']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  closer2Id?: InputMaybe<Scalars['String']['input']>;
  closerId?: InputMaybe<Scalars['String']['input']>;
  daysToPayment?: InputMaybe<Scalars['Int']['input']>;
  docFee?: InputMaybe<Scalars['Float']['input']>;
  gapCost?: InputMaybe<Scalars['Float']['input']>;
  gapPrice?: InputMaybe<Scalars['Float']['input']>;
  lienholder?: InputMaybe<Scalars['String']['input']>;
  moneyDown?: InputMaybe<Scalars['Float']['input']>;
  newRegistrationFee?: InputMaybe<Scalars['Float']['input']>;
  prequalificationCreditScore?: InputMaybe<Scalars['Int']['input']>;
  registrationTransferFee?: InputMaybe<Scalars['Float']['input']>;
  sellRate?: InputMaybe<Scalars['Float']['input']>;
  setterId?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
  titleFee?: InputMaybe<Scalars['Float']['input']>;
  titleRegistrationOption?: InputMaybe<TitleRegistrationOption>;
  userEnteredReserve?: InputMaybe<Scalars['Float']['input']>;
  vehiclePayoff?: InputMaybe<Scalars['Float']['input']>;
  vscCost?: InputMaybe<Scalars['Float']['input']>;
  vscPrice?: InputMaybe<Scalars['Float']['input']>;
  warrantyTaxAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type PayoffChange = {
  __typename?: 'PayoffChange';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  payoff_id?: Maybe<Scalars['Int']['output']>;
  payoff_includes_sales_tax?: Maybe<Scalars['Float']['output']>;
  sales_tax_from_payoff?: Maybe<Scalars['Float']['output']>;
  sales_tax_from_payoff_entered_manually?: Maybe<Scalars['Boolean']['output']>;
  sales_tax_included?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type PayoffChangeInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  payoff_id?: InputMaybe<Scalars['Int']['input']>;
  payoff_includes_sales_tax?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_from_payoff?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_from_payoff_entered_manually?: InputMaybe<Scalars['Boolean']['input']>;
  sales_tax_included?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type PrequalificationCreditReport = {
  __typename?: 'PrequalificationCreditReport';
  addresses?: Maybe<Array<PrequalificationCreditReportAddress>>;
  employments?: Maybe<Array<PrequalificationCreditReportEmployment>>;
  informationalMessages?: Maybe<Array<Scalars['String']['output']>>;
  names?: Maybe<PrequalificationCreditReportNames>;
  scoreFactors?: Maybe<Array<PrequalificationCreditScoreScoreFactor>>;
  tradelines?: Maybe<PrequalificationCreditReportTradelines>;
};

export type PrequalificationCreditReportAddress = {
  __typename?: 'PrequalificationCreditReportAddress';
  city?: Maybe<Scalars['String']['output']>;
  firstReportedDate?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  streetPrefix?: Maybe<Scalars['String']['output']>;
  streetSuffix?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type PrequalificationCreditReportEmployment = {
  __typename?: 'PrequalificationCreditReportEmployment';
  addressExtraLine?: Maybe<Scalars['String']['output']>;
  addressFirstLine?: Maybe<Scalars['String']['output']>;
  addressSecondLine?: Maybe<Scalars['String']['output']>;
  firstReportedDate?: Maybe<Scalars['String']['output']>;
  lastUpdatedDate?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type PrequalificationCreditReportName = {
  __typename?: 'PrequalificationCreditReportName';
  firstName: Scalars['String']['output'];
  generationCode?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  secondSurname?: Maybe<Scalars['String']['output']>;
  surname: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type PrequalificationCreditReportNames = {
  __typename?: 'PrequalificationCreditReportNames';
  aliases: Array<Maybe<PrequalificationCreditReportName>>;
  customerName?: Maybe<PrequalificationCreditReportName>;
};

export type PrequalificationCreditReportTradeline = {
  __typename?: 'PrequalificationCreditReportTradeline';
  accountNumber?: Maybe<Scalars['String']['output']>;
  accountType?: Maybe<Scalars['String']['output']>;
  amount1?: Maybe<Scalars['String']['output']>;
  amount1Qualifier?: Maybe<Scalars['String']['output']>;
  amount2?: Maybe<Scalars['String']['output']>;
  amount2Qualifier?: Maybe<Scalars['String']['output']>;
  amountPastDue?: Maybe<Scalars['String']['output']>;
  balanceAmount?: Maybe<Scalars['String']['output']>;
  balanceDate?: Maybe<Scalars['String']['output']>;
  delinquency30Days?: Maybe<Scalars['String']['output']>;
  delinquency60Days?: Maybe<Scalars['String']['output']>;
  delinquency90to180Days?: Maybe<Scalars['String']['output']>;
  ecoa?: Maybe<Scalars['String']['output']>;
  kob?: Maybe<Scalars['String']['output']>;
  monthlyPaymentAmount?: Maybe<Scalars['String']['output']>;
  monthsHistory?: Maybe<Scalars['String']['output']>;
  openDate?: Maybe<Scalars['String']['output']>;
  openOrClosed?: Maybe<Scalars['String']['output']>;
  paymentHistory?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriberName?: Maybe<Scalars['String']['output']>;
  terms?: Maybe<Scalars['String']['output']>;
};

export type PrequalificationCreditReportTradelines = {
  __typename?: 'PrequalificationCreditReportTradelines';
  installment: Array<PrequalificationCreditReportTradeline>;
  revolving: Array<PrequalificationCreditReportTradeline>;
  unknown: Array<PrequalificationCreditReportTradeline>;
};

export type PrequalificationCreditReports = {
  __typename?: 'PrequalificationCreditReports';
  cobuyer?: Maybe<PrequalificationCreditReport>;
  customer: PrequalificationCreditReport;
};

export type PrequalificationCreditScoreScoreFactor = {
  __typename?: 'PrequalificationCreditScoreScoreFactor';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

export type PrequalificationRequest = {
  __typename?: 'PrequalificationRequest';
  first_name?: Maybe<Scalars['String']['output']>;
  gross_income?: Maybe<Scalars['Float']['output']>;
  income_sources?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  lending_book_value?: Maybe<Scalars['Float']['output']>;
  money_down?: Maybe<Scalars['Float']['output']>;
  pay_frequency?: Maybe<Scalars['String']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  vehicle_payoff?: Maybe<Scalars['Float']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export enum Processor {
  Atc = 'Atc',
  DlrDmv = 'DlrDmv',
  TaterTitle = 'TaterTitle',
  Vitu = 'Vitu',
}

export type R1ContractValidationErrors = {
  __typename?: 'R1ContractValidationErrors';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type R1ContractValidationErrorsInput = {
  errors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type R1ContractValidationWarnings = {
  __typename?: 'R1ContractValidationWarnings';
  warnings?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type R1ContractValidationWarningsInput = {
  warnings?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum ReferralSourceEnum {
  DidNotAsk = 'did_not_ask',
  InternetSearch = 'internet_search',
  Mailer = 'mailer',
  Other = 'other',
  RecommendationFromFriend = 'recommendation_from_friend',
  SocialMediaOrBlogPost = 'social_media_or_blog_post',
  Text = 'text',
  TrustedReview = 'trusted_review',
}

export type RootMutationType = {
  __typename?: 'RootMutationType';
  acquisitionDealInfoUpsert?: Maybe<Deal>;
  addDocumentsToMediaCenter?: Maybe<Scalars['Boolean']['output']>;
  addLendersToCreditApplicationV2: CreditApplication;
  addParticipant?: Maybe<ResultAddParticipant>;
  applyDocumentSignature?: Maybe<Scalars['Void']['output']>;
  archivePod?: Maybe<Scalars['Void']['output']>;
  auctionUpsert?: Maybe<Auction>;
  automateCreditApplication?: Maybe<Deal>;
  buyerCobuyerSwap?: Maybe<Deal>;
  callOut?: Maybe<Call>;
  conditionReportUpsert?: Maybe<ConditionReportUpsert>;
  createAffiliateTemporaryInfo?: Maybe<TemporaryInfo>;
  createArticle?: Maybe<Article>;
  createCreditApplicationV2: CreditApplication;
  createDdDevDeal?: Maybe<Scalars['Boolean']['output']>;
  createDealV2: Deal;
  createDownPaymentLink?: Maybe<Scalars['String']['output']>;
  createExternalDealUploadUrl?: Maybe<ExternalDealUploadUrl>;
  createExternalTempInfoUploadUrl?: Maybe<ExternalTempInfoUploadUrl>;
  createFeedback?: Maybe<Scalars['Void']['output']>;
  createJDPBookSheet?: Maybe<Scalars['Boolean']['output']>;
  createKBBBookSheet?: Maybe<Scalars['Boolean']['output']>;
  createManualEntryTemporaryInfo?: Maybe<TemporaryInfo>;
  createNewEstimateAndSetFollowUp?: Maybe<Deal>;
  createNewEstimateFromFiles?: Maybe<CreateNewEstimateFromFiles>;
  createOptEvent?: Maybe<OptEvent>;
  createPayoffRequest?: Maybe<PayoffRequest>;
  createPotentialUser?: Maybe<PotentialUser>;
  createReview?: Maybe<Review>;
  createStructuringFollowUp?: Maybe<Scalars['Boolean']['output']>;
  createTemporaryInfo?: Maybe<TemporaryInfo>;
  createTransaction?: Maybe<Scalars['Float']['output']>;
  createUser?: Maybe<User>;
  creditAppUpsert?: Maybe<Deal>;
  customDatesUpdate?: Maybe<DealDates>;
  customerInfoUpsert?: Maybe<Deal>;
  dealAssign?: Maybe<Deal>;
  dealCompleted?: Maybe<Deal>;
  dealDetailNetsuite?: Maybe<Deal>;
  dealFinalizeAcquisition?: Maybe<Scalars['Boolean']['output']>;
  dealHasProblemSwitch?: Maybe<Deal>;
  dealInfoUpsert?: Maybe<Deal>;
  dealSeen?: Maybe<Scalars['Boolean']['output']>;
  dealTagsUpsert: Array<Tag>;
  dealUnwound?: Maybe<Deal>;
  dealUpdateAssignments?: Maybe<Deal>;
  dealUpdateMissingRequiredDocuments?: Maybe<Scalars['Boolean']['output']>;
  dealUpdateRequestBoot?: Maybe<Deal>;
  dealUpdateState?: Maybe<DealWithErrors>;
  dealUpdateStateAndSendEmails?: Maybe<Deal>;
  dealUpdateType?: Maybe<Deal>;
  deleteAndReplaceFiles?: Maybe<Scalars['Boolean']['output']>;
  deleteArticle?: Maybe<Article>;
  deleteComFlowFile?: Maybe<Scalars['Void']['output']>;
  deleteContracts?: Maybe<Scalars['Boolean']['output']>;
  deleteCreditApplicationV2: Scalars['ID']['output'];
  deleteD2dDocument?: Maybe<Scalars['Boolean']['output']>;
  deleteDealV2: Scalars['Int']['output'];
  deleteReview?: Maybe<Review>;
  deleteStructuringFollowUp?: Maybe<Scalars['Boolean']['output']>;
  determineApprovalMutation?: Maybe<DetermineApproval>;
  documentsInfoUpdate?: Maybe<Scalars['Void']['output']>;
  editD2dDocumentName?: Maybe<Scalars['Boolean']['output']>;
  endSalesFlow?: Maybe<Scalars['Boolean']['output']>;
  estimateUpsert?: Maybe<Deal>;
  executeCardDownPayment?: Maybe<FinixTransfer>;
  executeDownPayment?: Maybe<FinixTransfer>;
  executeExternalSoftPull?: Maybe<Scalars['Boolean']['output']>;
  externalCustomerUpdate?: Maybe<Customer>;
  externalUpdateDeal?: Maybe<Deal>;
  fileSend?: Maybe<Message>;
  finalizeR1Signing?: Maybe<Scalars['Void']['output']>;
  financialInfoAcquisitionUpsert?: Maybe<FinancialInfoAcquisition>;
  followUpDelete?: Maybe<FollowUp>;
  followUpInsert?: Maybe<FollowUp>;
  followUpUpdate?: Maybe<FollowUp>;
  generateAndUploadRateExceptionForm?: Maybe<Scalars['Boolean']['output']>;
  getMarketPrequalification: MarketPrequalification;
  hangUpCall?: Maybe<Scalars['Boolean']['output']>;
  importDeal?: Maybe<Deal>;
  linkAccount?: Maybe<Scalars['Void']['output']>;
  linkUnlinkedCustomers?: Maybe<Scalars['Boolean']['output']>;
  logSigningError?: Maybe<SigningError>;
  logViewedDocumentEvent?: Maybe<Scalars['Void']['output']>;
  manuallySelectBank: FinancialInfo;
  markMissedSipNotificationAsSeen?: Maybe<Scalars['Boolean']['output']>;
  mediaDelete?: Maybe<Scalars['Boolean']['output']>;
  mediaDeleteFromCom?: Maybe<Scalars['Boolean']['output']>;
  mediaInsert?: Maybe<DealMedia>;
  mediaInsertCom?: Maybe<DealMedia>;
  mediaRename?: Maybe<Scalars['Boolean']['output']>;
  mediaUpdate?: Maybe<DealMedia>;
  mediaUpdateCom?: Maybe<DealMedia>;
  mergeCreditApplicationV2?: Maybe<Scalars['Boolean']['output']>;
  mergeFiles?: Maybe<MergedDocuments>;
  messageSend?: Maybe<Message>;
  mockDealProcessing?: Maybe<Scalars['Void']['output']>;
  newEstimateUpsert?: Maybe<Deal>;
  noteCreate?: Maybe<Note>;
  noteDelete?: Maybe<Note>;
  noteUpdate?: Maybe<Note>;
  notificationSeen?: Maybe<Scalars['Boolean']['output']>;
  notificationsSeen?: Maybe<Scalars['Boolean']['output']>;
  optOut?: Maybe<Scalars['Void']['output']>;
  overrideCalledByFinancialSpecialist?: Maybe<Deal>;
  payoffRequestCancel?: Maybe<PayoffRequest>;
  payoffRequestConfirm?: Maybe<PayoffRequest>;
  payoffRequestFail?: Maybe<PayoffRequest>;
  payoffRequestFailureRequest?: Maybe<PayoffRequest>;
  payoffRequestUpdate?: Maybe<PayoffRequest>;
  payoffUpdate?: Maybe<Payoff>;
  podUpsert?: Maybe<Pod>;
  podUpsertAndPodUsersUpdate?: Maybe<BasePod>;
  processPaperwork?: Maybe<Deal>;
  registerDealer?: Maybe<Scalars['String']['output']>;
  registerProduct?: Maybe<Scalars['String']['output']>;
  removeDuplicateFinancialInfos?: Maybe<Scalars['Boolean']['output']>;
  resendEmails?: Maybe<Scalars['Boolean']['output']>;
  resetPasswordAndSendEmail?: Maybe<Scalars['Void']['output']>;
  reviveBoot?: Maybe<Scalars['Boolean']['output']>;
  revivePod?: Maybe<Pod>;
  saveConsent: SaveConsent;
  saveNewCustomerCallOutcome?: Maybe<Call>;
  selectCreditDecision?: Maybe<SelectCreditDecision>;
  selectCreditDecisionV2?: Maybe<CreditDecision>;
  sendContracts?: Maybe<Scalars['Boolean']['output']>;
  sendDocumentsWithAdobeAndUpdateState?: Maybe<Deal>;
  sendPaymentEstimateEmailAndText?: Maybe<Scalars['Boolean']['output']>;
  setReadyForSignaturesStatus?: Maybe<Scalars['Boolean']['output']>;
  skipTimelineStep?: Maybe<Deal>;
  softDeleteUser?: Maybe<Scalars['Boolean']['output']>;
  ssnLogCreate?: Maybe<SsnLog>;
  storeSignatureData?: Maybe<Scalars['Boolean']['output']>;
  submitCreditApplicationV2: CreditApplication;
  submitToRouteOne?: Maybe<Deal>;
  temporaryInfoImport?: Maybe<Deal>;
  temporaryInfoUpdate?: Maybe<TemporaryInfo>;
  temporaryInfoUpdateAndImport?: Maybe<TemporaryInfo>;
  unlinkTemporaryInfo?: Maybe<TemporaryInfo>;
  updateArticle?: Maybe<Article>;
  updateCar?: Maybe<Car>;
  updateCarRegistrationExpiration?: Maybe<Car>;
  updateCreditAppOnDeal?: Maybe<UpdateCreditAppOnDealResponse>;
  updateCreditApplicationV2: CreditApplication;
  updateCustomer?: Maybe<Customer>;
  updateCustomerAndAddress?: Maybe<Customer>;
  updateDealNeedsElectronicSignatureVerification?: Maybe<Deal>;
  updateDealV2: Deal;
  updateFinancialInfo?: Maybe<FinancialInfo>;
  updateInsuranceInfo?: Maybe<ProofOfInsurance>;
  updatePayoffClerk?: Maybe<Scalars['Boolean']['output']>;
  updateProcessor?: Maybe<FinancialInfo>;
  updateProofOfInsurance?: Maybe<ProofOfInsurance>;
  updateReview?: Maybe<Review>;
  updateStructuringFollowUpNotes?: Maybe<Scalars['Boolean']['output']>;
  updateSubscriptionToNotificationsStatus?: Maybe<NotificationSubscriber>;
  updateUser?: Maybe<User>;
  updateUserCallStatus?: Maybe<User>;
  updateUserSettings?: Maybe<User>;
  uploadDocument?: Maybe<ExtractedDocumentGql>;
  uploadDocumentToRouteOne?: Maybe<Scalars['Boolean']['output']>;
  uploadDriversLicense?: Maybe<DriversLicenseGql>;
  vehicleInfoUpsert?: Maybe<Deal>;
  verifyAccount?: Maybe<Scalars['Void']['output']>;
  voidContract?: Maybe<Scalars['Boolean']['output']>;
};

export type RootMutationTypeAcquisitionDealInfoUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeAddDocumentsToMediaCenterArgs = {
  deal_id: Scalars['ID']['input'];
  files?: InputMaybe<Array<InputMaybe<AddDocumentsToMediaCenterInput>>>;
};

export type RootMutationTypeAddLendersToCreditApplicationV2Args = {
  id: Scalars['ID']['input'];
  lenderIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RootMutationTypeAddParticipantArgs = {
  agent_phone?: InputMaybe<Scalars['String']['input']>;
  conference_command?: InputMaybe<Scalars['String']['input']>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  hunt_group_slug?: InputMaybe<Scalars['String']['input']>;
  new_agent_phone?: InputMaybe<Scalars['String']['input']>;
  new_agent_user_id?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeApplyDocumentSignatureArgs = {
  dealId: Scalars['ID']['input'];
  dealJacketID: Scalars['String']['input'];
  documentID: Scalars['String']['input'];
  documentName: Scalars['String']['input'];
  isCobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  isLastSignature?: InputMaybe<Scalars['Boolean']['input']>;
  signatureData: Scalars['String']['input'];
  signatureFieldName: Scalars['String']['input'];
};

export type RootMutationTypeArchivePodArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type RootMutationTypeAuctionUpsertArgs = {
  auction?: InputMaybe<AuctionInput>;
};

export type RootMutationTypeAutomateCreditApplicationArgs = {
  deal_id: Scalars['ID']['input'];
};

export type RootMutationTypeBuyerCobuyerSwapArgs = {
  cobuyer_id: Scalars['ID']['input'];
  customer_id: Scalars['ID']['input'];
  deal_id: Scalars['ID']['input'];
};

export type RootMutationTypeCallOutArgs = {
  calledNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeConditionReportUpsertArgs = {
  conditionReport?: InputMaybe<ConditionReportUpsertInput>;
};

export type RootMutationTypeCreateAffiliateTemporaryInfoArgs = {
  device_type?: InputMaybe<Scalars['String']['input']>;
  query_params?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeCreateArticleArgs = {
  article?: InputMaybe<ArticleInput>;
};

export type RootMutationTypeCreateCreditApplicationV2Args = {
  input: CreditApplicationCreateInput;
};

export type RootMutationTypeCreateDealV2Args = {
  input: DealInput;
};

export type RootMutationTypeCreateExternalDealUploadUrlArgs = {
  dealId: Scalars['ID']['input'];
  fileDescription: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type RootMutationTypeCreateExternalTempInfoUploadUrlArgs = {
  fileDescription: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  guid: Scalars['ID']['input'];
};

export type RootMutationTypeCreateFeedbackArgs = {
  deal_id: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  improvements: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
};

export type RootMutationTypeCreateJdpBookSheetArgs = {
  customerState: Scalars['String']['input'];
  dealId: Scalars['ID']['input'];
};

export type RootMutationTypeCreateKbbBookSheetArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootMutationTypeCreateManualEntryTemporaryInfoArgs = {
  device_type?: InputMaybe<Scalars['String']['input']>;
  extra_info?: InputMaybe<ExtraTempInfoInputType>;
  fuel_type?: InputMaybe<Scalars['String']['input']>;
  kbb_trim_name?: InputMaybe<Scalars['String']['input']>;
  license_plate?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  query_params?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
  vin: Scalars['String']['input'];
  year?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeCreateNewEstimateAndSetFollowUpArgs = {
  address_line?: InputMaybe<Scalars['String']['input']>;
  address_line_2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  date_utc: Scalars['DateTime']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  no_email?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone_number: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeCreateNewEstimateFromFilesArgs = {
  data?: InputMaybe<TemporaryDataInput>;
  files?: InputMaybe<Array<InputMaybe<NewEstimateFileInput>>>;
};

export type RootMutationTypeCreateOptEventArgs = {
  event: OptEventInput;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeCreatePayoffRequestArgs = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeCreatePotentialUserArgs = {
  potential_user?: InputMaybe<PotentialUserInput>;
};

export type RootMutationTypeCreateReviewArgs = {
  review?: InputMaybe<ReviewInput>;
};

export type RootMutationTypeCreateStructuringFollowUpArgs = {
  dealId: Scalars['ID']['input'];
  notes: Scalars['String']['input'];
};

export type RootMutationTypeCreateTemporaryInfoArgs = {
  device_type?: InputMaybe<Scalars['String']['input']>;
  extra_info?: InputMaybe<ExtraTempInfoInputType>;
  ignore_existing_deal?: InputMaybe<Scalars['Boolean']['input']>;
  license_plate_number?: InputMaybe<Scalars['String']['input']>;
  license_plate_state?: InputMaybe<Scalars['String']['input']>;
  query_params?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeCreateTransactionArgs = {
  input?: InputMaybe<CreateTransactionInput>;
};

export type RootMutationTypeCreateUserArgs = {
  createUserInput: CreateUserInput;
};

export type RootMutationTypeCreditAppUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeCustomDatesUpdateArgs = {
  date: Scalars['DateTime']['input'];
  date_key: Scalars['String']['input'];
  deal_id: Scalars['ID']['input'];
};

export type RootMutationTypeCustomerInfoUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeDealAssignArgs = {
  allow_reassign?: InputMaybe<Scalars['Boolean']['input']>;
  assignee_id?: InputMaybe<Scalars['String']['input']>;
  deal_id: Scalars['ID']['input'];
  deal_state?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
};

export type RootMutationTypeDealCompletedArgs = {
  id: Scalars['ID']['input'];
  state: Scalars['String']['input'];
};

export type RootMutationTypeDealDetailNetsuiteArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDealFinalizeAcquisitionArgs = {
  address_line: Scalars['String']['input'];
  address_line_2?: InputMaybe<Scalars['String']['input']>;
  cash_to_customer: Scalars['Float']['input'];
  city: Scalars['String']['input'];
  county: Scalars['String']['input'];
  dob: Scalars['Date']['input'];
  id: Scalars['ID']['input'];
  mileage?: InputMaybe<Scalars['Float']['input']>;
  state: Scalars['String']['input'];
  vehicle_payoff?: InputMaybe<Scalars['Float']['input']>;
  zip: Scalars['String']['input'];
};

export type RootMutationTypeDealHasProblemSwitchArgs = {
  has_problem: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  note?: InputMaybe<NoteInput>;
};

export type RootMutationTypeDealInfoUpsertArgs = {
  car?: InputMaybe<CarInput>;
  customer?: InputMaybe<CustomerInput>;
  financialInfo?: InputMaybe<FinancialInfoInput>;
};

export type RootMutationTypeDealSeenArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootMutationTypeDealTagsUpsertArgs = {
  dealId: Scalars['ID']['input'];
  tagIds: Array<Scalars['ID']['input']>;
};

export type RootMutationTypeDealUnwoundArgs = {
  boot_reason: BootReasonInput;
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDealUpdateAssignmentsArgs = {
  closer2_id?: InputMaybe<Scalars['String']['input']>;
  closer_id?: InputMaybe<Scalars['String']['input']>;
  deal_source?: InputMaybe<Scalars['String']['input']>;
  funding_clerk_id?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  setter_id?: InputMaybe<Scalars['String']['input']>;
  structuring_manager_id?: InputMaybe<Scalars['String']['input']>;
  title_clerk2_id?: InputMaybe<Scalars['String']['input']>;
  title_clerk_id?: InputMaybe<Scalars['String']['input']>;
  titling_pod_id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<DealType>;
};

export type RootMutationTypeDealUpdateMissingRequiredDocumentsArgs = {
  id: Scalars['ID']['input'];
  missing_required_external_documents?: InputMaybe<Scalars['Boolean']['input']>;
  new_missing_required_external_documents: Scalars['Boolean']['input'];
};

export type RootMutationTypeDealUpdateRequestBootArgs = {
  boot_deal?: InputMaybe<Scalars['Boolean']['input']>;
  boot_reason: BootReasonInput;
  id: Scalars['ID']['input'];
  partial_note?: InputMaybe<NoteInput>;
  request_boot: Scalars['Boolean']['input'];
};

export type RootMutationTypeDealUpdateStateArgs = {
  clearSetDate?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeDealUpdateStateAndSendEmailsArgs = {
  contactType?: InputMaybe<ContactType>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  dealIsSigningOnCom?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isR1ContractUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  isR1ContractValidated?: InputMaybe<Scalars['Boolean']['input']>;
  state: Scalars['String']['input'];
};

export type RootMutationTypeDealUpdateTypeArgs = {
  id: Scalars['ID']['input'];
  type: DealType;
};

export type RootMutationTypeDeleteAndReplaceFilesArgs = {
  deal_id: Scalars['ID']['input'];
  files_to_delete?: InputMaybe<Array<InputMaybe<AddDocumentsToMediaCenterInput>>>;
  files_to_upload?: InputMaybe<Array<InputMaybe<AddDocumentsToMediaCenterInput>>>;
};

export type RootMutationTypeDeleteArticleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeDeleteComFlowFileArgs = {
  key: Scalars['String']['input'];
};

export type RootMutationTypeDeleteContractsArgs = {
  filePaths: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RootMutationTypeDeleteCreditApplicationV2Args = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteD2dDocumentArgs = {
  key: Scalars['String']['input'];
};

export type RootMutationTypeDeleteDealV2Args = {
  id: Scalars['Int']['input'];
};

export type RootMutationTypeDeleteReviewArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeDeleteStructuringFollowUpArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDetermineApprovalMutationArgs = {
  data: DetermineApprovalInput;
};

export type RootMutationTypeDocumentsInfoUpdateArgs = {
  data: DocumentsInfoUpdateInput;
};

export type RootMutationTypeEditD2dDocumentNameArgs = {
  key: Scalars['String']['input'];
  newKey: Scalars['String']['input'];
};

export type RootMutationTypeEndSalesFlowArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  importType: ImportType;
  reason: EndSalesFlowReasonEnum;
  tempInfoId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeEstimateUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeExecuteCardDownPaymentArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fraudSessionId?: InputMaybe<Scalars['String']['input']>;
  idempotencyId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeExecuteDownPaymentArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  bank?: InputMaybe<Scalars['String']['input']>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fraudSessionId?: InputMaybe<Scalars['String']['input']>;
  idempotencyId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  plaidAccountId?: InputMaybe<Scalars['String']['input']>;
  plaidToken?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeExecuteExternalSoftPullArgs = {
  consentVersion: Scalars['Int']['input'];
  devCreditScore?: InputMaybe<Scalars['Int']['input']>;
  devOverride?: InputMaybe<Scalars['Boolean']['input']>;
  isHardPull: Scalars['Boolean']['input'];
  paymentEstimateInput: PaymentEstimateInput;
};

export type RootMutationTypeExternalCustomerUpdateArgs = {
  customer?: InputMaybe<ExternalCustomerInput>;
  customerId: Scalars['ID']['input'];
};

export type RootMutationTypeExternalUpdateDealArgs = {
  data?: InputMaybe<ExternalDealInputType>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  tempInfoId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeFileSendArgs = {
  dealPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeFinalizeR1SigningArgs = {
  dealId: Scalars['ID']['input'];
  dealJacketID: Scalars['String']['input'];
};

export type RootMutationTypeFinancialInfoAcquisitionUpsertArgs = {
  car_id?: InputMaybe<Scalars['ID']['input']>;
  clear_override?: InputMaybe<Scalars['Boolean']['input']>;
  financialInfoAcquisition?: InputMaybe<FinancialInfoAcquisitionInput>;
  id: Scalars['ID']['input'];
  mmr?: InputMaybe<Scalars['Float']['input']>;
  override?: InputMaybe<Scalars['Boolean']['input']>;
  state: Scalars['String']['input'];
  vehicle_payoff?: InputMaybe<Scalars['Float']['input']>;
};

export type RootMutationTypeFollowUpDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeFollowUpInsertArgs = {
  date_utc: Scalars['DateTime']['input'];
  deal_id: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  type: FollowUpTypeEnum;
  tz: Scalars['String']['input'];
};

export type RootMutationTypeFollowUpUpdateArgs = {
  date_utc: Scalars['DateTime']['input'];
  deal_id: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  type: FollowUpTypeEnum;
  tz: Scalars['String']['input'];
};

export type RootMutationTypeGetMarketPrequalificationArgs = {
  addressLine?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  prequalId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeHangUpCallArgs = {
  conference_id: Scalars['String']['input'];
};

export type RootMutationTypeImportDealArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeLinkAccountArgs = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  ssn_last_4?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeLogSigningErrorArgs = {
  error: SigningErrorInput;
};

export type RootMutationTypeLogViewedDocumentEventArgs = {
  documentViewedEvent: SigningAuditDocumentViewedEvent;
};

export type RootMutationTypeManuallySelectBankArgs = {
  bank: Scalars['String']['input'];
  dealId: Scalars['ID']['input'];
};

export type RootMutationTypeMarkMissedSipNotificationAsSeenArgs = {
  callIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RootMutationTypeMediaDeleteArgs = {
  filePath: Scalars['String']['input'];
};

export type RootMutationTypeMediaDeleteFromComArgs = {
  dealId: Scalars['ID']['input'];
  filePath: Scalars['String']['input'];
};

export type RootMutationTypeMediaInsertArgs = {
  dealId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeMediaInsertComArgs = {
  dealId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type RootMutationTypeMediaRenameArgs = {
  dealId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
};

export type RootMutationTypeMediaUpdateArgs = {
  dealId: Scalars['ID']['input'];
  has_verified_digital_signature?: InputMaybe<Scalars['Boolean']['input']>;
  has_wet_signature?: InputMaybe<Scalars['Boolean']['input']>;
  is_notarized?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  newFileName?: InputMaybe<Scalars['String']['input']>;
  oldKey: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootMutationTypeMediaUpdateComArgs = {
  dealId: Scalars['ID']['input'];
  newFileName?: InputMaybe<Scalars['String']['input']>;
  oldKey: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeMergeCreditApplicationV2Args = {
  id: Scalars['ID']['input'];
  imported_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  set_date?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<DealStateEnumV2>;
};

export type RootMutationTypeMergeFilesArgs = {
  deal_id: Scalars['ID']['input'];
  docs?: InputMaybe<Array<InputMaybe<DealMediaInput>>>;
  missing_required_media_list?: InputMaybe<Array<InputMaybe<DealMediaTypeEnum>>>;
};

export type RootMutationTypeMessageSendArgs = {
  dealPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeMockDealProcessingArgs = {
  deal_id: Scalars['ID']['input'];
  has_gap: Scalars['Boolean']['input'];
  has_vsc: Scalars['Boolean']['input'];
  is_conditioned: Scalars['Boolean']['input'];
};

export type RootMutationTypeNewEstimateUpsertArgs = {
  newEstimateUpsertInput?: InputMaybe<NewEstimateUpsertInput>;
};

export type RootMutationTypeNoteCreateArgs = {
  note?: InputMaybe<NoteInput>;
};

export type RootMutationTypeNoteDeleteArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeNoteUpdateArgs = {
  note?: InputMaybe<NoteInput>;
};

export type RootMutationTypeNotificationSeenArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  notificationId: Scalars['ID']['input'];
  seen: Scalars['Boolean']['input'];
};

export type RootMutationTypeNotificationsSeenArgs = {
  dealId: Scalars['ID']['input'];
  notificationIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type RootMutationTypeOptOutArgs = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootMutationTypeOverrideCalledByFinancialSpecialistArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootMutationTypePayoffRequestCancelArgs = {
  payoffRequestId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypePayoffRequestConfirmArgs = {
  payoffRequest: PayoffRequestConfirmInput;
};

export type RootMutationTypePayoffRequestFailArgs = {
  payoffRequest: PayoffRequestInput;
};

export type RootMutationTypePayoffRequestFailureRequestArgs = {
  payoffRequest: PayoffRequestInput;
};

export type RootMutationTypePayoffRequestUpdateArgs = {
  payoffRequest: PayoffRequestInput;
};

export type RootMutationTypePayoffUpdateArgs = {
  payoffInfo: PayoffInput;
};

export type RootMutationTypePodUpsertArgs = {
  pod?: InputMaybe<BasePodInput>;
};

export type RootMutationTypePodUpsertAndPodUsersUpdateArgs = {
  newPod: PodInput;
  oldPod?: InputMaybe<PodInput>;
};

export type RootMutationTypeProcessPaperworkArgs = {
  id: Scalars['ID']['input'];
  paperwork_type: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type RootMutationTypeRegisterDealerArgs = {
  dealer?: InputMaybe<DealerInput>;
};

export type RootMutationTypeRegisterProductArgs = {
  dealer_id: Scalars['ID']['input'];
  product_id: Scalars['ID']['input'];
  provider_dealer_code: Scalars['String']['input'];
};

export type RootMutationTypeResendEmailsArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootMutationTypeResetPasswordAndSendEmailArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeReviveBootArgs = {
  id: Scalars['ID']['input'];
  setterId?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeRevivePodArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type RootMutationTypeSaveConsentArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ConsentNameEnumType>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type RootMutationTypeSaveNewCustomerCallOutcomeArgs = {
  call_id: Scalars['String']['input'];
  outcome?: InputMaybe<Scalars['String']['input']>;
  outcome_description?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeSelectCreditDecisionArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeSelectCreditDecisionV2Args = {
  amountFinanced: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
  moneyDown: Scalars['Float']['input'];
  sellRate: Scalars['Float']['input'];
};

export type RootMutationTypeSendContractsArgs = {
  deal_id: Scalars['ID']['input'];
  keys: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RootMutationTypeSendDocumentsWithAdobeAndUpdateStateArgs = {
  deal_id: Scalars['ID']['input'];
  keys: Array<InputMaybe<Scalars['String']['input']>>;
  state: DealStateEnum;
};

export type RootMutationTypeSendPaymentEstimateEmailAndTextArgs = {
  data?: InputMaybe<PaymentEstimateInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeSetReadyForSignaturesStatusArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  documentProgressStatus?: InputMaybe<DocumentProgressStatus>;
  jacketId?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeSkipTimelineStepArgs = {
  documentProgressStatus?: InputMaybe<DocumentProgressStatus>;
  jacketId?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeSoftDeleteUserArgs = {
  id: Scalars['String']['input'];
};

export type RootMutationTypeSsnLogCreateArgs = {
  log?: InputMaybe<SsnLogInput>;
};

export type RootMutationTypeStoreSignatureDataArgs = {
  customer_id: Scalars['ID']['input'];
  initials_data_url?: InputMaybe<Scalars['String']['input']>;
  initials_strokes?: InputMaybe<Scalars['String']['input']>;
  signature_data_url?: InputMaybe<Scalars['String']['input']>;
  signature_strokes?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeSubmitCreditApplicationV2Args = {
  id: Scalars['ID']['input'];
  lenderIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RootMutationTypeSubmitToRouteOneArgs = {
  deal?: InputMaybe<DealInput>;
  lenderIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RootMutationTypeTemporaryInfoImportArgs = {
  id: Scalars['ID']['input'];
  importType?: InputMaybe<ImportType>;
  transformType: TransformType;
};

export type RootMutationTypeTemporaryInfoUpdateArgs = {
  info?: InputMaybe<TemporaryInfoInput>;
};

export type RootMutationTypeTemporaryInfoUpdateAndImportArgs = {
  info?: InputMaybe<TemporaryInfoInput>;
  transformType?: InputMaybe<TransformType>;
};

export type RootMutationTypeUnlinkTemporaryInfoArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeUpdateArticleArgs = {
  article?: InputMaybe<ArticleInput>;
};

export type RootMutationTypeUpdateCarArgs = {
  car?: InputMaybe<CarInput>;
};

export type RootMutationTypeUpdateCarRegistrationExpirationArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  registration_expiration?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RootMutationTypeUpdateCreditAppOnDealArgs = {
  input: CreditAppInput;
};

export type RootMutationTypeUpdateCreditApplicationV2Args = {
  input: CreditApplicationUpdateInput;
};

export type RootMutationTypeUpdateCustomerArgs = {
  customer?: InputMaybe<CustomerInput>;
};

export type RootMutationTypeUpdateCustomerAndAddressArgs = {
  customer?: InputMaybe<CustomerInput>;
};

export type RootMutationTypeUpdateDealNeedsElectronicSignatureVerificationArgs = {
  deal_id: Scalars['ID']['input'];
  needs_electronic_signature_verification: Scalars['Boolean']['input'];
};

export type RootMutationTypeUpdateDealV2Args = {
  input: DealInput;
};

export type RootMutationTypeUpdateFinancialInfoArgs = {
  input: UpdateFinancialInfoInput;
};

export type RootMutationTypeUpdateInsuranceInfoArgs = {
  expiration_date?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  insurance_company?: InputMaybe<Scalars['String']['input']>;
  policy_no?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeUpdatePayoffClerkArgs = {
  payoff_clerk_id?: InputMaybe<Scalars['String']['input']>;
  payoff_request_id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeUpdateProcessorArgs = {
  deal_id: Scalars['ID']['input'];
  fi_id: Scalars['ID']['input'];
  processor?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeUpdateProofOfInsuranceArgs = {
  customer_id: Scalars['ID']['input'];
  proof_of_insurance?: InputMaybe<ProofOfInsuranceInput>;
};

export type RootMutationTypeUpdateReviewArgs = {
  review?: InputMaybe<ReviewInput>;
};

export type RootMutationTypeUpdateStructuringFollowUpNotesArgs = {
  id: Scalars['ID']['input'];
  notes: Scalars['String']['input'];
};

export type RootMutationTypeUpdateSubscriptionToNotificationsStatusArgs = {
  dealId: Scalars['ID']['input'];
  subscribeToNotifications: Scalars['Boolean']['input'];
};

export type RootMutationTypeUpdateUserArgs = {
  allAuth0RoleIds: Array<Scalars['String']['input']>;
  updateUserInput: UpdateUserInput;
};

export type RootMutationTypeUpdateUserCallStatusArgs = {
  status: Scalars['String']['input'];
};

export type RootMutationTypeUpdateUserSettingsArgs = {
  auto_assign_deals: Scalars['Boolean']['input'];
  hours: Scalars['String']['input'];
  id: Scalars['String']['input'];
  max_auto_assign_deals: Scalars['Int']['input'];
  on_vacation: Scalars['Boolean']['input'];
  overnight_deals: Scalars['Boolean']['input'];
};

export type RootMutationTypeUploadDocumentArgs = {
  bytes?: InputMaybe<Scalars['String']['input']>;
  documentType?: InputMaybe<TtDocumentName>;
};

export type RootMutationTypeUploadDocumentToRouteOneArgs = {
  doc?: InputMaybe<UploadDocumentsInput>;
  jacketId?: InputMaybe<Scalars['String']['input']>;
  withCobuyer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootMutationTypeUploadDriversLicenseArgs = {
  bytes?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeVehicleInfoUpsertArgs = {
  deal?: InputMaybe<DealInput>;
};

export type RootMutationTypeVerifyAccountArgs = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  ssn_last_4?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeVoidContractArgs = {
  deal_id: Scalars['ID']['input'];
  void_gap?: InputMaybe<Scalars['Boolean']['input']>;
  void_vsc?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  acquisitionOffer?: Maybe<AcquisitionOffer>;
  addressDetail?: Maybe<Address>;
  affiliateDetails?: Maybe<Affiliate>;
  articleImageUploadUrl?: Maybe<ArticleImageUploadUrl>;
  articles?: Maybe<Array<Maybe<Article>>>;
  auctionAverageTransportCost?: Maybe<Scalars['Int']['output']>;
  auctionDistances?: Maybe<AuctionDistances>;
  auctions?: Maybe<Array<Maybe<Auction>>>;
  bankInfo?: Maybe<Bank>;
  bankLogo?: Maybe<Scalars['String']['output']>;
  banks?: Maybe<Array<Maybe<Bank>>>;
  bootedDeals?: Maybe<Array<Maybe<Deal>>>;
  calculateOptions?: Maybe<Array<Maybe<KbbOption>>>;
  callInProgress?: Maybe<Conference>;
  carDetailLicensePlate?: Maybe<Car>;
  carDetailVin?: Maybe<Car>;
  carDistinctMake?: Maybe<Array<Maybe<CarDistinctMake>>>;
  carDistinctModel?: Maybe<Array<Maybe<CarDistinctModel>>>;
  checkExistingAccountByEmail?: Maybe<Scalars['Boolean']['output']>;
  checkFlowStatus?: Maybe<FlowStatus>;
  completedDeals?: Maybe<Array<Maybe<Deal>>>;
  createFetchUrlsFromCom: Array<Scalars['String']['output']>;
  creditApplicationSubmissions: Array<Maybe<CreditApplicationSubmission>>;
  creditApplicationV2?: Maybe<CreditApplication>;
  creditDecisionV2?: Maybe<CreditDecision>;
  currentConsentAgreementText?: Maybe<ConsentAgreement>;
  customer?: Maybe<Customer>;
  dashboardDeals?: Maybe<Array<Maybe<Deal>>>;
  deal?: Maybe<Deal>;
  dealAdvisors?: Maybe<Deal>;
  dealSetter?: Maybe<DealSetter>;
  dealStates?: Maybe<Array<Maybe<DealState>>>;
  dealV2?: Maybe<Deal>;
  dealerProducts?: Maybe<Array<Maybe<Product>>>;
  dealsV2: DealsConnection;
  dealsWithNotification?: Maybe<DealsWithNotification>;
  decodeAffiliateCarDetails?: Maybe<TemporaryData>;
  decryptSSN?: Maybe<Scalars['String']['output']>;
  determineApprovalQuery?: Maybe<DetermineApproval>;
  duplicateDeals?: Maybe<Array<Maybe<Deal>>>;
  experianCreditReport?: Maybe<PrequalificationCreditReports>;
  fetchProofOfInsurance?: Maybe<ProofOfInsurance>;
  financialInfoPaymentEstimate?: Maybe<Array<Maybe<FinancialInfoPaymentEstimate>>>;
  followUp?: Maybe<FollowUp>;
  gapRates?: Maybe<Array<Maybe<Rate>>>;
  generateContracts?: Maybe<Contracts>;
  generateDocuments?: Maybe<Documents>;
  generatePurchaseOrder?: Maybe<PurchaseOrder>;
  generateR1EContract: Scalars['Boolean']['output'];
  generateTestDocuments?: Maybe<Scalars['Boolean']['output']>;
  getAllHuntGroups?: Maybe<Array<Maybe<HuntGroup>>>;
  getApprovedCreditDecisionsByDealId?: Maybe<Array<Maybe<CreditDecision>>>;
  getAuth0Roles?: Maybe<Array<Auth0Role>>;
  getAvailableUsers?: Maybe<Array<Maybe<GetAvailableUsers>>>;
  getBase64StringFromS3Object?: Maybe<Scalars['String']['output']>;
  getByAuth0Id?: Maybe<Customer>;
  getComPackageOptions?: Maybe<GetPackageOptions>;
  getCreditDecisionByR1FSID?: Maybe<CreditDecision>;
  getD2dDocuments?: Maybe<Array<Maybe<D2dDocument>>>;
  getDDSoftPull: DdSoftPull;
  getDownPaymentAmountByMethod?: Maybe<Scalars['Float']['output']>;
  getExternalAutoStructureBankLogos?: Maybe<Array<Maybe<AutoStructureBankLogo>>>;
  getFees?: Maybe<FeeGql>;
  getFollowUpDealsPaginated?: Maybe<PaginatedDeals>;
  getOperatingHoursAndTimeSlots?: Maybe<OperatingHoursTimeSlots>;
  getR1CustomerCopy?: Maybe<Array<Maybe<DealDocument>>>;
  getR1DealDocument?: Maybe<DealDocument>;
  getRelationshipToDealByPhoneNumber?: Maybe<GetRelationshipToDealByPhoneNumberResponse>;
  getThunderConversationHistoryByPhoneNumber?: Maybe<ThunderConversationHistory>;
  getTwilioNumbers?: Maybe<Array<Maybe<TwilioNumber>>>;
  getUnlinkedDeals?: Maybe<Array<Maybe<Deal>>>;
  getUploadUrl?: Maybe<MediaUploadUrl>;
  getUserCallHistory?: Maybe<PaginatedCallHistory>;
  getUserCreationData?: Maybe<UserCreationData>;
  getUserTwilioNumber?: Maybe<Scalars['String']['output']>;
  getUsers?: Maybe<PaginatedUser>;
  getWaitingCalls?: Maybe<GetWaitingCalls>;
  getWarrantyInfo?: Maybe<CarWarranty>;
  inbox?: Maybe<InboxMessagesType>;
  jdpValues?: Maybe<Car>;
  jurisdiction?: Maybe<TtJurisdiction>;
  kbbValues?: Maybe<KbbValuesResult>;
  kbbVin?: Maybe<KbbVinObjectResult>;
  keyMetrics?: Maybe<Array<Maybe<KeyMetric>>>;
  laneKeyMetrics?: Maybe<Array<Maybe<LaneKeyMetric>>>;
  leaderboardSetter: Array<LeaderboardSetterStats>;
  lenders?: Maybe<Array<Maybe<Lender>>>;
  lienholderUtility?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  loggedUser?: Maybe<User>;
  media?: Maybe<Array<Maybe<DealMedia>>>;
  mediaFromCom?: Maybe<Array<DealMedia>>;
  messages?: Maybe<MessagesWithErrors>;
  messagesDbPaginated?: Maybe<MessagesDbPaginated>;
  metricsPages?: Maybe<Array<Maybe<MetricsPages>>>;
  mmr?: Maybe<MmrResult>;
  notes?: Maybe<Array<Maybe<Note>>>;
  paymentEstimate?: Maybe<PaymentEstimate>;
  paymentOptions?: Maybe<PaymentOptions>;
  payoffCountMake?: Maybe<Array<Maybe<PayoffCountMake>>>;
  payoffCountModel?: Maybe<Array<Maybe<PayoffCountModel>>>;
  payoffDashboardDeals?: Maybe<PayoffDashboard>;
  payoffRequest?: Maybe<PayoffRequest>;
  payoffStatsMakeModelYear?: Maybe<Array<Maybe<PayoffStatsMakeModel>>>;
  pod?: Maybe<Pod>;
  podHours?: Maybe<Array<Maybe<PodHours>>>;
  pods?: Maybe<Array<Maybe<Pod>>>;
  podsAll?: Maybe<Array<Maybe<Pod>>>;
  products?: Maybe<Array<Maybe<Product>>>;
  providers?: Maybe<Array<Maybe<Provider>>>;
  retrieveDealDocsUploadedToR1?: Maybe<Array<Maybe<DealDocument>>>;
  reviews?: Maybe<Array<Maybe<Review>>>;
  searchDeals?: Maybe<Array<Deal>>;
  searchWebLeads?: Maybe<Array<Maybe<TemporaryInfo>>>;
  sendPurchaseOrder?: Maybe<Scalars['Boolean']['output']>;
  startR1DocumentsFlow?: Maybe<Scalars['Boolean']['output']>;
  structuringFollowUp?: Maybe<StructuringFollowUp>;
  structuringFollowUpDeals?: Maybe<PaginatedDeals>;
  suggestedMessage?: Maybe<Scalars['String']['output']>;
  surcharges?: Maybe<Array<Maybe<Surcharge>>>;
  tags: Array<Tag>;
  templateTexts?: Maybe<Array<Maybe<TemplateText>>>;
  temporaryInfo?: Maybe<TemporaryInfo>;
  temporaryInfoAuthenticated?: Maybe<TemporaryInfo>;
  temporaryInfoVip?: Maybe<TemporaryInfoVip>;
  temporaryRegistrationTagsDeals?: Maybe<PaginatedDeals>;
  titlingDashboardDeals?: Maybe<PaginatedDeals>;
  titlingPodsAll?: Maybe<Array<Maybe<Pod>>>;
  titlingUsers?: Maybe<Array<Maybe<PodUser>>>;
  totalCompletedDeals?: Maybe<Scalars['Int']['output']>;
  userDeal?: Maybe<Deal>;
  userDealReady?: Maybe<DealReadyType>;
  userDeals?: Maybe<Array<Maybe<Deals>>>;
  userPermissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  userTitlingPods?: Maybe<Array<Maybe<Pod>>>;
  validZip?: Maybe<Scalars['Boolean']['output']>;
  validateBankContract?: Maybe<Scalars['Boolean']['output']>;
  validateCreditApplicationV2?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleMileage?: Maybe<VehicleMileageGql>;
  visibleArticles?: Maybe<VisibleArticles>;
  visibleReviews?: Maybe<Array<Maybe<Review>>>;
  vscRates?: Maybe<Array<Maybe<Rate>>>;
};

export type RootQueryTypeAcquisitionOfferArgs = {
  accidents: AccidentsEnumType;
  book_value?: InputMaybe<Scalars['Int']['input']>;
  lienholder?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  mmr_value?: InputMaybe<Scalars['Float']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeAddressDetailArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeAffiliateDetailsArgs = {
  affiliateExternalId?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeAuctionAverageTransportCostArgs = {
  customerAddress?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeAuctionDistancesArgs = {
  data?: InputMaybe<AuctionDistancesInput>;
};

export type RootQueryTypeBankInfoArgs = {
  deal_id: Scalars['ID']['input'];
};

export type RootQueryTypeBankLogoArgs = {
  bank_name: Scalars['String']['input'];
};

export type RootQueryTypeBanksArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  selectedBank?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeBootedDealsArgs = {
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']['input']>;
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']['input']>;
  end_date: Scalars['DateTime']['input'];
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']['input']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']['input']>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  start_date: Scalars['DateTime']['input'];
  types: Array<InputMaybe<DealType>>;
  unread_messages_filter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeCalculateOptionsArgs = {
  allKbbVehicleOptions?: InputMaybe<Array<InputMaybe<KbbOptionInput>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  configToModify?: InputMaybe<Array<InputMaybe<KbbConfigType>>>;
  startingVehicleOptions?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
  vin: Scalars['String']['input'];
};

export type RootQueryTypeCarDetailLicensePlateArgs = {
  license_plate_number: Scalars['String']['input'];
  license_plate_state: Scalars['String']['input'];
};

export type RootQueryTypeCarDetailVinArgs = {
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeCarDistinctModelArgs = {
  make: Scalars['String']['input'];
};

export type RootQueryTypeCheckExistingAccountByEmailArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeCheckFlowStatusArgs = {
  guid?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeCompletedDealsArgs = {
  address_state?: InputMaybe<Scalars['String']['input']>;
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']['input']>;
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']['input']>;
  boot_requested_filter?: InputMaybe<Scalars['Boolean']['input']>;
  deal_state?: InputMaybe<Scalars['String']['input']>;
  end_date: Scalars['DateTime']['input'];
  notifications?: InputMaybe<Scalars['Boolean']['input']>;
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']['input']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']['input']>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  start_date: Scalars['DateTime']['input'];
  types: Array<InputMaybe<DealType>>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeCreateFetchUrlsFromComArgs = {
  keys: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RootQueryTypeCreditApplicationSubmissionsArgs = {
  filter?: InputMaybe<CreditApplicationSubmissionFilter>;
};

export type RootQueryTypeCreditApplicationV2Args = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeCreditDecisionV2Args = {
  id: Scalars['Int']['input'];
};

export type RootQueryTypeCurrentConsentAgreementTextArgs = {
  agreementType?: InputMaybe<ConsentAgreementTypeEnum>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeCustomerArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeDashboardDealsArgs = {
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']['input']>;
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']['input']>;
  boot_requested_filter?: InputMaybe<Scalars['Boolean']['input']>;
  closer_location?: InputMaybe<LocationType>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']['input']>;
  showFollowUps?: InputMaybe<Scalars['Boolean']['input']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']['input']>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  types: Array<InputMaybe<DealType>>;
  unread_messages_filter?: InputMaybe<Scalars['Boolean']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeDealArgs = {
  id: Scalars['ID']['input'];
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeDealAdvisorsArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeDealSetterArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeDealStatesArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeDealV2Args = {
  id?: InputMaybe<Scalars['Int']['input']>;
  leaseId?: InputMaybe<Scalars['GUID']['input']>;
};

export type RootQueryTypeDealerProductsArgs = {
  dealer_id: Scalars['ID']['input'];
};

export type RootQueryTypeDealsV2Args = {
  filter?: InputMaybe<DealsFilter>;
  pagination?: InputMaybe<PaginationInputType>;
  sorting?: InputMaybe<SortingTypeV2>;
};

export type RootQueryTypeDealsWithNotificationArgs = {
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeDecodeAffiliateCarDetailsArgs = {
  license_plate_number?: InputMaybe<Scalars['String']['input']>;
  license_plate_state?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeDecryptSsnArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeDetermineApprovalQueryArgs = {
  data?: InputMaybe<DetermineApprovalQueryInput>;
};

export type RootQueryTypeDuplicateDealsArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  homePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  matchBootedDeals: Scalars['Boolean']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeExperianCreditReportArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootQueryTypeFetchProofOfInsuranceArgs = {
  customer_id: Scalars['ID']['input'];
};

export type RootQueryTypeFinancialInfoPaymentEstimateArgs = {
  end_date: Scalars['String']['input'];
  make: Scalars['String']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  start_date: Scalars['String']['input'];
  year?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeFollowUpArgs = {
  deal_id: Scalars['ID']['input'];
};

export type RootQueryTypeGapRatesArgs = {
  bank?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<RateInput>;
  deal_id: Scalars['ID']['input'];
};

export type RootQueryTypeGenerateContractsArgs = {
  data?: InputMaybe<ContractsInput>;
};

export type RootQueryTypeGenerateDocumentsArgs = {
  data?: InputMaybe<DocumentsInput>;
};

export type RootQueryTypeGeneratePurchaseOrderArgs = {
  deal_id: Scalars['ID']['input'];
};

export type RootQueryTypeGenerateR1EContractArgs = {
  dealJacketId: Scalars['String']['input'];
};

export type RootQueryTypeGenerateTestDocumentsArgs = {
  update_deal_id: Scalars['ID']['input'];
};

export type RootQueryTypeGetAllHuntGroupsArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeGetApprovedCreditDecisionsByDealIdArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootQueryTypeGetBase64StringFromS3ObjectArgs = {
  key: Scalars['String']['input'];
};

export type RootQueryTypeGetComPackageOptionsArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeGetCreditDecisionByR1FsidArgs = {
  deal_id: Scalars['ID']['input'];
  r1_fsid: Scalars['String']['input'];
};

export type RootQueryTypeGetDdSoftPullArgs = {
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  paymentEstimateInput: PaymentEstimateInput;
  shouldForceRetry?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeGetDownPaymentAmountByMethodArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  paymentType?: InputMaybe<PaymentInstrumentType>;
};

export type RootQueryTypeGetFeesArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  bookValue?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cobuyerAddress?: InputMaybe<Scalars['String']['input']>;
  cobuyerCity?: InputMaybe<Scalars['String']['input']>;
  cobuyerFirstName?: InputMaybe<Scalars['String']['input']>;
  cobuyerLastName?: InputMaybe<Scalars['String']['input']>;
  cobuyerMiddleName?: InputMaybe<Scalars['String']['input']>;
  cobuyerState?: InputMaybe<StateAbbreviation>;
  cobuyerZip?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  dealId?: InputMaybe<Scalars['String']['input']>;
  docFee?: InputMaybe<Scalars['Float']['input']>;
  estimatedPayoff?: InputMaybe<Scalars['Float']['input']>;
  financialInfoId?: InputMaybe<Scalars['ID']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fuelType?: InputMaybe<TtFuelType>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  newBank?: InputMaybe<Scalars['String']['input']>;
  newBankAddress?: InputMaybe<Scalars['String']['input']>;
  newBankAddress2?: InputMaybe<Scalars['String']['input']>;
  newBankCity?: InputMaybe<Scalars['String']['input']>;
  newBankState?: InputMaybe<StateAbbreviation>;
  newBankZip?: InputMaybe<Scalars['String']['input']>;
  newBankZipPlus4?: InputMaybe<Scalars['String']['input']>;
  odometerReading?: InputMaybe<Scalars['Int']['input']>;
  payoff?: InputMaybe<Scalars['Float']['input']>;
  previouslyTitledState?: InputMaybe<StateAbbreviation>;
  retailBookValue?: InputMaybe<Scalars['Float']['input']>;
  signedDate?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<StateAbbreviation>;
  transferringPlates?: InputMaybe<Scalars['Boolean']['input']>;
  uniqueId?: InputMaybe<Scalars['String']['input']>;
  vehicleType?: InputMaybe<TtVehicleType>;
  vin?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['Float']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeGetFollowUpDealsPaginatedArgs = {
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']['input']>;
  assigned_to_me_filter?: InputMaybe<Scalars['Boolean']['input']>;
  boot_requested?: InputMaybe<Scalars['Boolean']['input']>;
  notifications?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  results_per_page?: InputMaybe<Scalars['Int']['input']>;
  scheduledByMeFilter?: InputMaybe<Scalars['Boolean']['input']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']['input']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']['input']>;
  sort_column: Scalars['String']['input'];
  sort_direction: Scalars['String']['input'];
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeGetR1CustomerCopyArgs = {
  dealJacketID: Scalars['String']['input'];
  includeExtraPDFBinaries?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeGetR1DealDocumentArgs = {
  dealJacketID: Scalars['String']['input'];
  documentID: Scalars['String']['input'];
  includePdfBinary?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeGetRelationshipToDealByPhoneNumberArgs = {
  phoneNumber: Scalars['String']['input'];
};

export type RootQueryTypeGetThunderConversationHistoryByPhoneNumberArgs = {
  phoneNumber: Scalars['String']['input'];
};

export type RootQueryTypeGetUploadUrlArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  fileName: Scalars['String']['input'];
  useD2dDocumentCenterBucket?: InputMaybe<Scalars['Boolean']['input']>;
  useD2dVoiceRecordingsBucket?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeGetUserCallHistoryArgs = {
  items_per_page?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeGetUsersArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  podId?: InputMaybe<Scalars['ID']['input']>;
  roleNames?: InputMaybe<Array<InputMaybe<Auth0RoleName>>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  sortColumn?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  teamType?: InputMaybe<TeamType>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RootQueryTypeGetWarrantyInfoArgs = {
  make: Scalars['String']['input'];
  mileage: Scalars['Int']['input'];
  year: Scalars['String']['input'];
};

export type RootQueryTypeInboxArgs = {
  filterBy?: InputMaybe<InboxFilterByEnum>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InboxOrderByEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  podIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  unreadOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeJdpValuesArgs = {
  mileage: Scalars['Float']['input'];
  stateCode?: InputMaybe<Scalars['String']['input']>;
  vin: Scalars['String']['input'];
};

export type RootQueryTypeJurisdictionArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<StateAbbreviation>;
};

export type RootQueryTypeKbbValuesArgs = {
  data: KbbValuesDataType;
};

export type RootQueryTypeKbbVinArgs = {
  vin: Scalars['String']['input'];
};

export type RootQueryTypeKeyMetricsArgs = {
  end_date: Scalars['DateTime']['input'];
  pod_ids: Array<InputMaybe<Scalars['String']['input']>>;
  start_date: Scalars['DateTime']['input'];
};

export type RootQueryTypeLaneKeyMetricsArgs = {
  podId?: InputMaybe<Scalars['ID']['input']>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  today?: InputMaybe<Scalars['DateTime']['input']>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeLeaderboardSetterArgs = {
  after: Scalars['DateTime']['input'];
  before: Scalars['DateTime']['input'];
  teamType?: InputMaybe<TeamType>;
};

export type RootQueryTypeLendersArgs = {
  query: Scalars['String']['input'];
};

export type RootQueryTypeLienholderUtilityArgs = {
  make?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeMediaArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootQueryTypeMediaFromComArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeMessagesArgs = {
  dealPhoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeMessagesDbPaginatedArgs = {
  dealId: Scalars['String']['input'];
  dealPhoneNumber: Scalars['String']['input'];
  page: Scalars['Int']['input'];
};

export type RootQueryTypeMmrArgs = {
  color: Scalars['String']['input'];
  grade: Scalars['Float']['input'];
  mileage: Scalars['Int']['input'];
  state: Scalars['String']['input'];
  trim: Scalars['String']['input'];
  vin: Scalars['String']['input'];
};

export type RootQueryTypeNotesArgs = {
  deal_id: Scalars['ID']['input'];
};

export type RootQueryTypePaymentEstimateArgs = {
  data?: InputMaybe<PaymentEstimateInput>;
};

export type RootQueryTypePaymentOptionsArgs = {
  data?: InputMaybe<PaymentOptionsInput>;
};

export type RootQueryTypePayoffCountMakeArgs = {
  make: Scalars['String']['input'];
};

export type RootQueryTypePayoffCountModelArgs = {
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
};

export type RootQueryTypePayoffRequestArgs = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypePayoffStatsMakeModelYearArgs = {
  end_date: Scalars['String']['input'];
  make: Scalars['String']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  start_date: Scalars['String']['input'];
  year?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypePodArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypePodsArgs = {
  hideArchived?: InputMaybe<Scalars['Boolean']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pod_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  team_type?: InputMaybe<Array<InputMaybe<TeamType>>>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeProductsArgs = {
  provider_id: Scalars['ID']['input'];
};

export type RootQueryTypeRetrieveDealDocsUploadedToR1Args = {
  dealJacketID: Scalars['String']['input'];
  includePdfBinary?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootQueryTypeSearchDealsArgs = {
  query: Scalars['String']['input'];
};

export type RootQueryTypeSearchWebLeadsArgs = {
  query: Scalars['String']['input'];
};

export type RootQueryTypeSendPurchaseOrderArgs = {
  deal_id: Scalars['ID']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeStartR1DocumentsFlowArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootQueryTypeStructuringFollowUpArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootQueryTypeStructuringFollowUpDealsArgs = {
  assignedToMe?: InputMaybe<Scalars['Boolean']['input']>;
  bootRequested?: InputMaybe<Scalars['Boolean']['input']>;
  dealTypes: Array<InputMaybe<DealType>>;
  notifications?: InputMaybe<Scalars['Boolean']['input']>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  podId?: InputMaybe<Scalars['ID']['input']>;
  showActiveTags?: InputMaybe<Scalars['Boolean']['input']>;
  showMatureFollowUps?: InputMaybe<Scalars['Boolean']['input']>;
  sortColumn: StructuringFollowUpSortColumn;
  sortDirection: StructuringFollowUpSortDirection;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RootQueryTypeSuggestedMessageArgs = {
  customerId: Scalars['ID']['input'];
  dealId: Scalars['ID']['input'];
  draftMessage?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};

export type RootQueryTypeSurchargesArgs = {
  product_id: Scalars['Int']['input'];
};

export type RootQueryTypeTagsArgs = {
  input?: InputMaybe<TagsInput>;
};

export type RootQueryTypeTemporaryInfoArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeTemporaryInfoAuthenticatedArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeTemporaryInfoVipArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeTemporaryRegistrationTagsDealsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  sortColumn?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type RootQueryTypeTitlingDashboardDealsArgs = {
  assigned_to_deleted_users_filter?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<FilterType>;
  includeTitleNotes?: InputMaybe<Scalars['Boolean']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  sorting?: InputMaybe<SortingType>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootQueryTypeTitlingUsersArgs = {
  titling_pod_id?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeUserDealArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isCobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  tempInfoId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootQueryTypeUserDealReadyArgs = {
  tempInfoId: Scalars['ID']['input'];
};

export type RootQueryTypeUserPermissionsArgs = {
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeValidZipArgs = {
  zipCode: Scalars['String']['input'];
};

export type RootQueryTypeValidateBankContractArgs = {
  deal_id: Scalars['ID']['input'];
};

export type RootQueryTypeValidateCreditApplicationV2Args = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeVehicleMileageArgs = {
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeVisibleArticlesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeVscRatesArgs = {
  data?: InputMaybe<RateInput>;
  deal_id: Scalars['ID']['input'];
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  inProgressCalls?: Maybe<Array<Maybe<Conference>>>;
  onAffiliateDuplicateFound?: Maybe<AffiliateDuplicateFound>;
  onContractValidation?: Maybe<Array<Maybe<Deal>>>;
  onDashboardDocumentUpload?: Maybe<DashboardDocumentUpload>;
  onDealComplete?: Maybe<Scalars['Boolean']['output']>;
  onDealImported?: Maybe<DealImported>;
  onDealUpdate?: Maybe<Array<Maybe<Deal>>>;
  onDownPaymentReceived?: Maybe<DownPaymentReceived>;
  onDuplicatedDealImport?: Maybe<DuplicatedDealImport>;
  onDuplicatedInfo?: Maybe<DuplicatedInfo>;
  onFollowUpUpdate?: Maybe<Array<Maybe<Deal>>>;
  onMissedSipCall?: Maybe<OnMissedSipCall>;
  onNewMessage?: Maybe<Message>;
  onNoDealNotification?: Maybe<Notification>;
  onNotification?: Maybe<Array<Maybe<Notification>>>;
  onPayoffRequestStatusUpdate?: Maybe<PayoffRequestStatusUpdate>;
  onPayoffRequestUpdate?: Maybe<PayoffRequestPopulated>;
  onReceivedMessage?: Maybe<MessageData>;
  onStructuringFollowUpUpdate?: Maybe<Array<Maybe<Deal>>>;
  onTagsUpdated?: Maybe<Array<Maybe<Deal>>>;
  onTemporaryRegistrationTagsDealsUpdated?: Maybe<Array<Maybe<Deal>>>;
  onTransactionCreated?: Maybe<FinancialInfo>;
  onWaitingQueue?: Maybe<OnWaitingQueue>;
  switchCallStatus?: Maybe<Scalars['String']['output']>;
};

export type RootSubscriptionTypeOnContractValidationArgs = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  fundingClerkOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootSubscriptionTypeOnDealCompleteArgs = {
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  types: Array<InputMaybe<DealType>>;
};

export type RootSubscriptionTypeOnDealUpdateArgs = {
  dealIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sources: Array<InputMaybe<Scalars['String']['input']>>;
  types?: InputMaybe<Array<InputMaybe<DealType>>>;
};

export type RootSubscriptionTypeOnFollowUpUpdateArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  followUpStatus?: InputMaybe<FollowUpStatusEnum>;
  followUpType?: InputMaybe<FollowUpTypeEnum>;
  scheduledByMeFilter?: InputMaybe<Scalars['Boolean']['input']>;
  sources?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  types?: InputMaybe<Array<InputMaybe<DealType>>>;
};

export type RootSubscriptionTypeOnNewMessageArgs = {
  dealPhoneNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RootSubscriptionTypeOnNotificationArgs = {
  dealIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type RootSubscriptionTypeOnPayoffRequestStatusUpdateArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootSubscriptionTypeOnReceivedMessageArgs = {
  activePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  dealPhoneNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isNotification?: InputMaybe<Scalars['Boolean']['input']>;
  isWidgetOpen?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootSubscriptionTypeOnStructuringFollowUpUpdateArgs = {
  assignedToMe?: InputMaybe<Scalars['Boolean']['input']>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  podId?: InputMaybe<Scalars['ID']['input']>;
  sources?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  types?: InputMaybe<Array<InputMaybe<DealType>>>;
};

export type RootSubscriptionTypeOnTagsUpdatedArgs = {
  dealId: Scalars['ID']['input'];
};

export type RootSubscriptionTypeOnTransactionCreatedArgs = {
  dealId: Scalars['ID']['input'];
};

export type SaveConsent = {
  __typename?: 'SaveConsent';
  consent?: Maybe<Consent>;
  isNew?: Maybe<Scalars['Boolean']['output']>;
};

export type SelectCreditDecision = {
  __typename?: 'SelectCreditDecision';
  creditDecision: CreditDecision;
  financialInfo: FinancialInfo;
};

export type ShippingLabel = {
  __typename?: 'ShippingLabel';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  purpose?: Maybe<Scalars['String']['output']>;
  tracker_url?: Maybe<Scalars['String']['output']>;
  tracking_number?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type SignatureData = {
  __typename?: 'SignatureData';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  initials_data_url?: Maybe<Scalars['String']['output']>;
  initials_strokes?: Maybe<Scalars['String']['output']>;
  signature_data_url?: Maybe<Scalars['String']['output']>;
  signature_strokes?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum SigningErrorEnum {
  ApplyDealerSignature = 'APPLY_DEALER_SIGNATURE',
  ApplySignature = 'APPLY_SIGNATURE',
  FinalizeSigning = 'FINALIZE_SIGNING',
  GenerationDate = 'GENERATION_DATE',
  RetrieveDocuments = 'RETRIEVE_DOCUMENTS',
}

export enum SigningSolutionEnum {
  AdobeSign = 'adobe_sign',
  RouteOne = 'route_one',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum SortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortingType = {
  direction?: InputMaybe<Scalars['String']['input']>;
  field?: InputMaybe<Scalars['String']['input']>;
};

export type SortingTypeV2 = {
  direction?: InputMaybe<SortDirectionEnum>;
  field?: InputMaybe<DealSortableColumnsEnum>;
};

export enum StateAbbreviation {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mh = 'MH',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Pw = 'PW',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
}

export type StructuringFollowUp = {
  __typename?: 'StructuringFollowUp';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  creator_id?: Maybe<Scalars['ID']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  status?: Maybe<StructuringFollowUpStatus>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type StructuringFollowUpInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creator_id?: InputMaybe<Scalars['ID']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<StructuringFollowUpStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum StructuringFollowUpSortColumn {
  Advisor = 'advisor',
  Customer = 'customer',
  Date = 'date',
  DealState = 'dealState',
  StructuringManager = 'structuringManager',
}

export enum StructuringFollowUpSortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum StructuringFollowUpStatus {
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS',
}

export type TtConditionFilterInput = {
  buyerIsNotLessee?: InputMaybe<Scalars['Boolean']['input']>;
  canVerifyDigitalSignatures?: InputMaybe<Scalars['Boolean']['input']>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  driversLicenseAddress?: InputMaybe<Scalars['String']['input']>;
  driversLicenseState?: InputMaybe<Scalars['String']['input']>;
  hasCobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  jurisdictionState?: InputMaybe<Scalars['String']['input']>;
  lienholder?: InputMaybe<Scalars['String']['input']>;
  movedStateDuringLease?: InputMaybe<Scalars['Boolean']['input']>;
  newBank?: InputMaybe<TtNewBankEnum>;
  transferringPlates?: InputMaybe<Scalars['Boolean']['input']>;
  transferringRegistration?: InputMaybe<Scalars['Boolean']['input']>;
  vehicleAge?: InputMaybe<Scalars['Int']['input']>;
};

export type TtDocumentConditionsFields = {
  __typename?: 'TTDocumentConditionsFields';
  description?: Maybe<Scalars['String']['output']>;
  variables?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type TtDocumentFields = {
  __typename?: 'TTDocumentFields';
  conditions?: Maybe<Array<Maybe<TtDocumentConditionsFields>>>;
  documentTypeId?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  supportsImageExtraction?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<TtDocumentTypeFields>;
  validations?: Maybe<Array<Maybe<TtValidation>>>;
};

export enum TtDocumentName {
  AcuraSpecificOdometerStatement = 'AcuraSpecificOdometerStatement',
  BackOfDriversLicense = 'BackOfDriversLicense',
  BillOfSale = 'BillOfSale',
  CobuyerBackOfDriversLicense = 'CobuyerBackOfDriversLicense',
  CobuyerDriversLicense = 'CobuyerDriversLicense',
  CobuyerJointTenancyWithRightsOfSurvivorship = 'CobuyerJointTenancyWithRightsOfSurvivorship',
  CobuyerOdometerStatement = 'CobuyerOdometerStatement',
  CobuyerPowerOfAttorney = 'CobuyerPowerOfAttorney',
  CobuyerRegistrationSpecificPowerOfAttorney = 'CobuyerRegistrationSpecificPowerOfAttorney',
  CobuyerSecurePowerOfAttorney = 'CobuyerSecurePowerOfAttorney',
  CobuyerStateSpecificOdometerStatement = 'CobuyerStateSpecificOdometerStatement',
  CobuyerStateSpecificPowerOfAttorney = 'CobuyerStateSpecificPowerOfAttorney',
  CobuyerSupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause = 'CobuyerSupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause',
  DriversLicense = 'DriversLicense',
  ElectronicRegistrationAndTitleAgreement = 'ElectronicRegistrationAndTitleAgreement',
  ElectronicSignatureAttestation = 'ElectronicSignatureAttestation',
  ElectronicSignatureCertification = 'ElectronicSignatureCertification',
  Emissions = 'Emissions',
  GmLeasePacket = 'GMLeasePacket',
  HondaSpecificOdometerStatement = 'HondaSpecificOdometerStatement',
  IdahoSalesTaxExemption = 'IdahoSalesTaxExemption',
  JointOdometerStatement = 'JointOdometerStatement',
  LicensePlate = 'LicensePlate',
  Odometer = 'Odometer',
  OdometerStatement = 'OdometerStatement',
  OhioSpecificHondaSpecificPowerOfAttorney = 'OhioSpecificHondaSpecificPowerOfAttorney',
  PlateTransferLetter = 'PlateTransferLetter',
  PorscheSpecificOdometerStatement = 'PorscheSpecificOdometerStatement',
  PorscheSpecificPayoffInstructions = 'PorscheSpecificPayoffInstructions',
  PowerOfAttorney = 'PowerOfAttorney',
  ProofOfInsurance = 'ProofOfInsurance',
  ProofOfResidence = 'ProofOfResidence',
  ProofOfResidence2 = 'ProofOfResidence2',
  PropertyTaxReceipt = 'PropertyTaxReceipt',
  PurchaseOrder = 'PurchaseOrder',
  Registration = 'Registration',
  RegistrationSpecificPowerOfAttorney = 'RegistrationSpecificPowerOfAttorney',
  SafetyInspection = 'SafetyInspection',
  SalesTaxExemption = 'SalesTaxExemption',
  SecurePowerOfAttorney = 'SecurePowerOfAttorney',
  StateSpecificBillOfSale = 'StateSpecificBillOfSale',
  StateSpecificDamageDisclosure = 'StateSpecificDamageDisclosure',
  StateSpecificOdometerStatement = 'StateSpecificOdometerStatement',
  StateSpecificPowerOfAttorney = 'StateSpecificPowerOfAttorney',
  SupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause = 'SupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause',
  TitleApp = 'TitleApp',
  VinInspection = 'VINInspection',
}

export type TtDocumentTypeFields = {
  __typename?: 'TTDocumentTypeFields';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parameters?: Maybe<Array<Maybe<TtParameterFields>>>;
};

export type TtFeesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  bookValue?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cobuyerAddress?: InputMaybe<Scalars['String']['input']>;
  cobuyerCity?: InputMaybe<Scalars['String']['input']>;
  cobuyerFirstName?: InputMaybe<Scalars['String']['input']>;
  cobuyerLastName?: InputMaybe<Scalars['String']['input']>;
  cobuyerMiddleName?: InputMaybe<Scalars['String']['input']>;
  cobuyerState?: InputMaybe<StateAbbreviation>;
  cobuyerZip?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  dealId?: InputMaybe<Scalars['String']['input']>;
  docFee?: InputMaybe<Scalars['Float']['input']>;
  estimatedPayoff?: InputMaybe<Scalars['Float']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fuelType?: InputMaybe<TtFuelType>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  newBank?: InputMaybe<Scalars['String']['input']>;
  newBankAddress?: InputMaybe<Scalars['String']['input']>;
  newBankAddress2?: InputMaybe<Scalars['String']['input']>;
  newBankCity?: InputMaybe<Scalars['String']['input']>;
  newBankState?: InputMaybe<StateAbbreviation>;
  newBankZip?: InputMaybe<Scalars['String']['input']>;
  newBankZipPlus4?: InputMaybe<Scalars['String']['input']>;
  odometerReading?: InputMaybe<Scalars['Int']['input']>;
  payoff?: InputMaybe<Scalars['Float']['input']>;
  previouslyTitledState?: InputMaybe<StateAbbreviation>;
  retailBookValue?: InputMaybe<Scalars['Float']['input']>;
  signedDate?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<StateAbbreviation>;
  transferringPlates?: InputMaybe<Scalars['Boolean']['input']>;
  uniqueId?: InputMaybe<Scalars['String']['input']>;
  vehicleType?: InputMaybe<TtVehicleType>;
  vin?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['Float']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum TtFuelType {
  Diesel = 'Diesel',
  Electric = 'Electric',
  Gasoline = 'Gasoline',
  Hybrid = 'Hybrid',
  PlugInHybrid = 'PlugInHybrid',
}

export enum TtGetFeesSourceType {
  ComDevTool = 'COM_DEV_TOOL',
  ComEnterPayoff = 'COM_ENTER_PAYOFF',
  ComImportInfo = 'COM_IMPORT_INFO',
  ComPaymentCalculator = 'COM_PAYMENT_CALCULATOR',
  ComPrequalification = 'COM_PREQUALIFICATION',
  ComSoftPull = 'COM_SOFT_PULL',
  D2DGetTaxesAndFees = 'D2D_GET_TAXES_AND_FEES',
  D2DPrequalification = 'D2D_PREQUALIFICATION',
  DdAutoStructure = 'DD_AUTO_STRUCTURE',
  DdCalcFees = 'DD_CALC_FEES',
  DdLendingTree = 'DD_LENDING_TREE',
  DdPaymentEstimate = 'DD_PAYMENT_ESTIMATE',
  DdPayoffTax = 'DD_PAYOFF_TAX',
  DdPrequalification = 'DD_PREQUALIFICATION',
  MktPaymentCalculator = 'MKT_PAYMENT_CALCULATOR',
  NoneGetTaxesAndFeesInput = 'NONE_GET_TAXES_AND_FEES_INPUT',
  NonePaymentEstimateLogic = 'NONE_PAYMENT_ESTIMATE_LOGIC',
}

export type TtJurisdiction = {
  __typename?: 'TTJurisdiction';
  allowPlateTransfer?: Maybe<Scalars['Boolean']['output']>;
  allowRegistrationTransfer?: Maybe<Scalars['Boolean']['output']>;
  hasRegistrationOnly?: Maybe<Scalars['Boolean']['output']>;
  hasTitleAndRegistration?: Maybe<Scalars['Boolean']['output']>;
  hasTitleOnly?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  products?: Maybe<TtttProductFieldsConnection>;
  registrationProduct?: Maybe<TtProductFields>;
  requireWalkIn?: Maybe<Scalars['Boolean']['output']>;
  supportedProcessors?: Maybe<Array<Maybe<Processor>>>;
  titleProduct?: Maybe<TtProductFields>;
};

export type TtJurisdictionProductsArgs = {
  filter?: InputMaybe<TtProductFilter>;
};

export enum TtNewBankEnum {
  Afcu = 'AFCU',
  AllyFinancial = 'AllyFinancial',
  BankOfAmerica = 'BankOfAmerica',
  CapitalOne = 'CapitalOne',
  Chase = 'Chase',
  FifthThird = 'FifthThird',
  FirstInterstate = 'FirstInterstate',
  Iccu = 'ICCU',
  LentegrityAutoFinance = 'LentegrityAutoFinance',
  Lookout = 'Lookout',
  Mechanics = 'Mechanics',
  MountainAmerica = 'MountainAmerica',
  Pnc = 'PNC',
  PrestigeFinancial = 'PrestigeFinancial',
  Santander = 'Santander',
  Td = 'TD',
  Upgrade = 'Upgrade',
  WellsFargo = 'WellsFargo',
  Westlake = 'Westlake',
  Wings = 'Wings',
}

export type TtPageInfo = {
  __typename?: 'TTPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type TtParameterFields = {
  __typename?: 'TTParameterFields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TtProductFields = {
  __typename?: 'TTProductFields';
  documents?: Maybe<TtttDocumentFieldsConnection>;
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<TtProductType>;
  validations?: Maybe<Array<Maybe<TtValidation>>>;
};

export type TtProductFieldsDocumentsArgs = {
  conditions?: InputMaybe<TtConditionFilterInput>;
};

export type TtProductFilter = {
  types?: InputMaybe<Array<InputMaybe<TtProductType>>>;
};

export enum TtProductType {
  Payoff = 'Payoff',
  Registration = 'Registration',
  Title = 'Title',
}

export type TtttDocumentFieldsConnection = {
  __typename?: 'TTTTDocumentFieldsConnection';
  count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<TtDocumentFields>>>;
  pageInfo?: Maybe<TtPageInfo>;
};

export type TtttProductFieldsConnection = {
  __typename?: 'TTTTProductFieldsConnection';
  count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<TtProductFields>>>;
  pageInfo?: Maybe<TtPageInfo>;
};

export type TtValidation = {
  __typename?: 'TTValidation';
  description?: Maybe<Scalars['String']['output']>;
  internalDescription?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum TtVehicleType {
  ExtendedCabPickup = 'ExtendedCabPickup',
  Motorhome = 'Motorhome',
  MultipurposePassengerVehicle = 'MultipurposePassengerVehicle',
  PassengerCar = 'PassengerCar',
  Pickup = 'Pickup',
  Suv = 'SUV',
  Sedan = 'Sedan',
  Truck = 'Truck',
}

export type Tag = {
  __typename?: 'Tag';
  color: Scalars['String']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  display_name: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  is_active: Scalars['Boolean']['output'];
  is_dashboard_visible: Scalars['Boolean']['output'];
  slug: TagSlugEnum;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum TagSlugEnum {
  ContractExpired = 'contract_expired',
  CreditFrozen = 'credit_frozen',
  DirectPay = 'direct_pay',
  DoubleTax = 'double_tax',
  DownPaymentCompleted = 'down_payment_completed',
  EmailPreferred = 'email_preferred',
  HeldAtBank = 'held_at_bank',
  LendingTree = 'lending_tree',
  NeedsCobuyer = 'needs_cobuyer',
  NeedsConsent = 'needs_consent',
  NeedsIncome = 'needs_income',
  NeedsMoneyDown = 'needs_money_down',
  NeedsRecontracting = 'needs_recontracting',
  PaymentCalculatorLead = 'payment_calculator_lead',
  PayoffFailed = 'payoff_failed',
  PayoffVerified = 'payoff_verified',
  PromoJuly2024 = 'promo_july2024',
  ProofOfIncome = 'proof_of_income',
  ProofOfResidency = 'proof_of_residency',
  RateReductionOffer = 'rate_reduction_offer',
  ReleaseOfInterest = 'release_of_interest',
  Repo = 'repo',
  SentToBank = 'sent_to_bank',
  SpanishSpeaker = 'spanish_speaker',
  TextPreferred = 'text_preferred',
  TwoCarDriver = 'two_car_driver',
  VerifyingInfo = 'verifying_info',
  Vip = 'vip',
  VscGapContracts = 'vsc_gap_contracts',
  WaitingForDownPayment = 'waiting_for_down_payment',
  WaitingForInterview = 'waiting_for_interview',
  WaitingForPayoff = 'waiting_for_payoff',
  WaitingForPics = 'waiting_for_pics',
  WalkIn = 'walk_in',
}

export type TagsInput = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TemplateText = {
  __typename?: 'TemplateText';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ThunderConversationHistory = {
  __typename?: 'ThunderConversationHistory';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  messages: Array<Maybe<ThunderConversationMessage>>;
};

export type ThunderConversationMessage = {
  __typename?: 'ThunderConversationMessage';
  direction: Scalars['String']['output'];
  text: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export enum TitleRegistrationOption {
  TitleAndNewRegistration = 'title_and_new_registration',
  TitleAndRegistrationTransfer = 'title_and_registration_transfer',
  TitleOnly = 'title_only',
}

export type UpdateCreditAppOnDealResponse = {
  __typename?: 'UpdateCreditAppOnDealResponse';
  cobuyer?: Maybe<Customer>;
  contact?: Maybe<DealContact>;
  customer: Customer;
  financial_info: FinancialInfo;
  id: Scalars['Int']['output'];
  odometer_status?: Maybe<OdometerStatus>;
  second_contact?: Maybe<DealContact>;
  state: Scalars['String']['output'];
};

export type UpdateUserInput = {
  auth0Roles: Array<InputMaybe<Auth0RoleInput>>;
  email: Scalars['String']['input'];
  huntGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id: Scalars['String']['input'];
  location?: InputMaybe<LocationType>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  podIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  recruiterId?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreationData = {
  __typename?: 'UserCreationData';
  huntGroups?: Maybe<Array<HuntGroup>>;
  pods?: Maybe<Array<Pod>>;
};

export type UserRoles = {
  __typename?: 'UserRoles';
  roles?: Maybe<Array<Maybe<Auth0Role>>>;
};

export type VehicleMileageGql = {
  __typename?: 'VehicleMileageGQL';
  estimatedAverageMiles?: Maybe<Scalars['Float']['output']>;
  eventCount?: Maybe<Scalars['Int']['output']>;
  lastOdometer?: Maybe<Scalars['Int']['output']>;
  lastOdometerDate?: Maybe<Scalars['String']['output']>;
  resultCode?: Maybe<Scalars['Int']['output']>;
  resultMessage?: Maybe<Scalars['String']['output']>;
  rollback?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
};

export enum AccidentsEnumType {
  NoAccidents = 'no_accidents',
  OneAccident = 'one_accident',
  TwoOrMoreAccidents = 'two_or_more_accidents',
}

export type AcquisitionOffer = {
  __typename?: 'acquisitionOffer';
  appraisedValue?: Maybe<Scalars['Float']['output']>;
  estimatedFees?: Maybe<Scalars['Float']['output']>;
  offer?: Maybe<Scalars['Float']['output']>;
};

export enum ActorType {
  Applicant = 'Applicant',
  Buyer = 'Buyer',
  CoApplicant = 'CoApplicant',
  CoBuyer = 'CoBuyer',
  Customer = 'Customer',
  Dealer = 'Dealer',
  Guarantor = 'Guarantor',
  Sales = 'Sales',
}

export type AddDocumentsToMediaCenterInput = {
  key?: InputMaybe<Scalars['String']['input']>;
};

export type Address = {
  __typename?: 'address';
  address_line?: Maybe<Scalars['String']['output']>;
  address_line_2?: Maybe<Scalars['String']['output']>;
  address_type?: Maybe<AddressTypeEnum>;
  city?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  monthly_payment?: Maybe<Scalars['Float']['output']>;
  months_at_home?: Maybe<Scalars['Int']['output']>;
  moved_states?: Maybe<Scalars['Boolean']['output']>;
  residence_type?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  time_zone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  years_at_home?: Maybe<Scalars['Int']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  address_line?: InputMaybe<Scalars['String']['input']>;
  address_line_2?: InputMaybe<Scalars['String']['input']>;
  address_type?: InputMaybe<AddressTypeEnum>;
  city?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  monthly_payment?: InputMaybe<Scalars['Float']['input']>;
  months_at_home?: InputMaybe<Scalars['Int']['input']>;
  moved_states?: InputMaybe<Scalars['Boolean']['input']>;
  residence_type?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  years_at_home?: InputMaybe<Scalars['Int']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum AddressTypeEnum {
  Buyer = 'Buyer',
  CoBuyer = 'CoBuyer',
  Current = 'Current',
  DriversLicense = 'DriversLicense',
  Previous = 'Previous',
}

export type Affiliate = {
  __typename?: 'affiliate';
  affiliate_provider?: Maybe<Affiliate_Provider>;
  affiliate_provider_id?: Maybe<Scalars['ID']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  decision_accepted?: Maybe<Scalars['Boolean']['output']>;
  decision_response?: Maybe<Lending_Tree_Decision_Response>;
  external_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum AffiliateProviderSlug {
  LendingTree = 'lending_tree',
}

export type Affiliate_Provider = {
  __typename?: 'affiliate_provider';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<AffiliateProviderSlug>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Article = {
  __typename?: 'article';
  com_visible?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  thumbnail_key?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ArticleImageUploadUrl = {
  __typename?: 'articleImageUploadUrl';
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ArticleInput = {
  com_visible?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  thumbnail_key?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type AssignmentLog = {
  __typename?: 'assignmentLog';
  assigned_at?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type AssignmentLogInput = {
  assigned_at?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type AssignmentLogs = {
  __typename?: 'assignmentLogs';
  logs?: Maybe<Array<Maybe<AssignmentLog>>>;
};

export type AssignmentLogsInput = {
  logs?: InputMaybe<Array<InputMaybe<AssignmentLogInput>>>;
};

export type Auction = {
  __typename?: 'auction';
  address_line?: Maybe<Scalars['String']['output']>;
  average_transport_cost?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type AuctionDistances = {
  __typename?: 'auctionDistances';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  transport_cost?: Maybe<Scalars['Int']['output']>;
};

export type AuctionDistancesInput = {
  auctions?: InputMaybe<Array<InputMaybe<Destinations>>>;
  customerAddress?: InputMaybe<Scalars['String']['input']>;
  customerState?: InputMaybe<Scalars['String']['input']>;
};

export type AuctionInput = {
  address_line?: InputMaybe<Scalars['String']['input']>;
  average_transport_cost?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum AutoImportVariation {
  DisplayCustomizePackage = 'displayCustomizePackage',
  ImmediateImport = 'immediateImport',
}

export type Bank = {
  __typename?: 'bank';
  active?: Maybe<Scalars['Boolean']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  auto_structure_buyout?: Maybe<Scalars['Boolean']['output']>;
  auto_structure_refi?: Maybe<Scalars['Boolean']['output']>;
  bank_markups?: Maybe<Array<Maybe<BankMarkup>>>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  logo_url?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  r1_fsid?: Maybe<Scalars['String']['output']>;
  reserve_structure?: Maybe<Array<Maybe<ReserveStructure>>>;
  signing_solution?: Maybe<SigningSolutionEnum>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  use_in_states?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type BankInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  auto_structure_buyout?: InputMaybe<Scalars['Boolean']['input']>;
  auto_structure_refi?: InputMaybe<Scalars['Boolean']['input']>;
  bank_markups?: InputMaybe<Array<InputMaybe<BankMarkupInput>>>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  logo_url?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  r1_fsid?: InputMaybe<Scalars['String']['input']>;
  reserve_structure?: InputMaybe<Array<InputMaybe<ReserveStructureInput>>>;
  signing_solution?: InputMaybe<SigningSolutionEnum>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_in_states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type BankMarkup = {
  __typename?: 'bankMarkup';
  bank_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  max_markup?: Maybe<Scalars['Float']['output']>;
  term_max?: Maybe<Scalars['Int']['output']>;
  term_min?: Maybe<Scalars['Int']['output']>;
};

export type BankMarkupInput = {
  bank_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  max_markup?: InputMaybe<Scalars['Float']['input']>;
  term_max?: InputMaybe<Scalars['Int']['input']>;
  term_min?: InputMaybe<Scalars['Int']['input']>;
};

export type BasePod = {
  __typename?: 'basePod';
  archived?: Maybe<Scalars['Boolean']['output']>;
  closer_commission_rate?: Maybe<Scalars['Float']['output']>;
  closer_commission_type?: Maybe<CommissionType>;
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  hours?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  manager_commission_rate?: Maybe<Scalars['Float']['output']>;
  manager_commission_type?: Maybe<CommissionType>;
  name?: Maybe<Scalars['String']['output']>;
  parent_pod_id?: Maybe<Scalars['ID']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  problem_solver?: Maybe<Scalars['Boolean']['output']>;
  setter_commission_rate?: Maybe<Scalars['Float']['output']>;
  setter_commission_type?: Maybe<CommissionType>;
  special_commission_rate?: Maybe<Scalars['Float']['output']>;
  special_commission_type?: Maybe<CommissionType>;
  team_type?: Maybe<TeamType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  us_states_object?: Maybe<UsStatesObject>;
  vsc_markup?: Maybe<Scalars['Int']['output']>;
  vsc_multiplier?: Maybe<Scalars['Float']['output']>;
};

export type BasePodInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  closer_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  closer_commission_type?: InputMaybe<CommissionType>;
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hours?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  manager_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  manager_commission_type?: InputMaybe<CommissionType>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_pod_id?: InputMaybe<Scalars['ID']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  problem_solver?: InputMaybe<Scalars['Boolean']['input']>;
  setter_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  setter_commission_type?: InputMaybe<CommissionType>;
  special_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  special_commission_type?: InputMaybe<CommissionType>;
  team_type?: InputMaybe<TeamType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  us_states_object?: InputMaybe<UsStatesInputObject>;
  vsc_markup?: InputMaybe<Scalars['Int']['input']>;
  vsc_multiplier?: InputMaybe<Scalars['Float']['input']>;
};

export type BaseUser = {
  __typename?: 'baseUser';
  auto_assign_deals?: Maybe<Scalars['Boolean']['output']>;
  call_status?: Maybe<Scalars['String']['output']>;
  can_claim_as_closer?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  location?: Maybe<LocationType>;
  max_auto_assign_deals?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  on_vacation?: Maybe<Scalars['Boolean']['output']>;
  overnight_deals?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  recruiter_id?: Maybe<Scalars['String']['output']>;
  twilio_number?: Maybe<Scalars['String']['output']>;
};

export type BaseUserInput = {
  auto_assign_deals?: InputMaybe<Scalars['Boolean']['input']>;
  call_status?: InputMaybe<Scalars['String']['input']>;
  can_claim_as_closer?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hours?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationType>;
  max_auto_assign_deals?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  on_vacation?: InputMaybe<Scalars['Boolean']['input']>;
  overnight_deals?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  recruiter_id?: InputMaybe<Scalars['String']['input']>;
  twilio_number?: InputMaybe<Scalars['String']['input']>;
};

export type BootReasonInput = {
  author_id?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type Call = {
  __typename?: 'call';
  callDuration?: Maybe<Scalars['String']['output']>;
  callStatus?: Maybe<Scalars['String']['output']>;
  calledCity?: Maybe<Scalars['String']['output']>;
  calledNumber?: Maybe<Scalars['String']['output']>;
  calledState?: Maybe<Scalars['String']['output']>;
  callerName?: Maybe<Scalars['String']['output']>;
  caller_city?: Maybe<Scalars['String']['output']>;
  caller_state?: Maybe<Scalars['String']['output']>;
  conferenceId?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  direction?: Maybe<Scalars['String']['output']>;
  flow?: Maybe<Scalars['String']['output']>;
  fromNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isNewCustomer?: Maybe<Scalars['Boolean']['output']>;
  new_customer_call_outcome?: Maybe<Scalars['String']['output']>;
  new_customer_call_outcome_description?: Maybe<Scalars['String']['output']>;
  outcome?: Maybe<Scalars['String']['output']>;
  pod_id?: Maybe<Scalars['ID']['output']>;
  recordingSid?: Maybe<Scalars['String']['output']>;
  recordingUrl?: Maybe<Scalars['String']['output']>;
  selection?: Maybe<Scalars['String']['output']>;
  selection_message?: Maybe<Scalars['String']['output']>;
  twilioNumber?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['ID']['output']>;
};

export type Car = {
  __typename?: 'car';
  book_value?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  fuel_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  jdp_adjusted_clean_retail?: Maybe<Scalars['Float']['output']>;
  jdp_adjusted_clean_trade?: Maybe<Scalars['Float']['output']>;
  jdp_mileage_adjustment?: Maybe<Scalars['Float']['output']>;
  jdp_trim_body?: Maybe<Scalars['String']['output']>;
  jdp_valuation_date?: Maybe<Scalars['Date']['output']>;
  jdp_vehicle_accessories_object?: Maybe<JdpVehicleAccessoriesObject>;
  kbb_lending_mileage_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_lending_option_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_retail_mileage_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_retail_option_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_selected_options_object?: Maybe<KbbSelectedOptionsObject>;
  kbb_trim_name?: Maybe<Scalars['String']['output']>;
  kbb_valuation_date?: Maybe<Scalars['String']['output']>;
  kbb_vehicle_name?: Maybe<Scalars['String']['output']>;
  license_plate_number?: Maybe<Scalars['String']['output']>;
  license_plate_state?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  mileage?: Maybe<Scalars['Int']['output']>;
  mmr?: Maybe<Scalars['Float']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  odometer_status?: Maybe<OdometerStatus>;
  payoff?: Maybe<Payoff>;
  registration_expiration?: Maybe<Scalars['DateTime']['output']>;
  retail_book_value?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type CarDistinctMake = {
  __typename?: 'carDistinctMake';
  make?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type CarDistinctModel = {
  __typename?: 'carDistinctModel';
  model?: Maybe<Scalars['String']['output']>;
};

export type CarInput = {
  book_value?: InputMaybe<Scalars['Float']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  fuel_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jdp_adjusted_clean_retail?: InputMaybe<Scalars['Float']['input']>;
  jdp_adjusted_clean_trade?: InputMaybe<Scalars['Float']['input']>;
  jdp_mileage_adjustment?: InputMaybe<Scalars['Float']['input']>;
  jdp_trim_body?: InputMaybe<Scalars['String']['input']>;
  jdp_valuation_date?: InputMaybe<Scalars['Date']['input']>;
  jdp_vehicle_accessories_object?: InputMaybe<JdpVehicleAccessoriesObjectInput>;
  kbb_lending_mileage_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_lending_option_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_retail_mileage_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_retail_option_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_selected_options_object?: InputMaybe<KbbSelectedOptionsObjectInput>;
  kbb_trim_name?: InputMaybe<Scalars['String']['input']>;
  kbb_valuation_date?: InputMaybe<Scalars['String']['input']>;
  kbb_vehicle_name?: InputMaybe<Scalars['String']['input']>;
  license_plate_number?: InputMaybe<Scalars['String']['input']>;
  license_plate_state?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  mmr?: InputMaybe<Scalars['Float']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  odometer_status?: InputMaybe<OdometerStatus>;
  payoff?: InputMaybe<PayoffInput>;
  registration_expiration?: InputMaybe<Scalars['DateTime']['input']>;
  retail_book_value?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type CarWarranty = {
  __typename?: 'carWarranty';
  full_miles?: Maybe<Scalars['Int']['output']>;
  full_status?: Maybe<Scalars['String']['output']>;
  full_years?: Maybe<Scalars['Int']['output']>;
  power_train_miles?: Maybe<Scalars['Int']['output']>;
  power_train_status?: Maybe<Scalars['String']['output']>;
  power_train_years?: Maybe<Scalars['Int']['output']>;
};

export enum CardType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  HsaFsa = 'HSA_FSA',
  NonReloadablePrepaid = 'NON_RELOADABLE_PREPAID',
  ReloadablePrepaid = 'RELOADABLE_PREPAID',
  Unknown = 'UNKNOWN',
}

export enum CommissionType {
  Flat = 'flat',
  Percentage = 'percentage',
}

export type ConditionRejection = {
  __typename?: 'conditionRejection';
  conditionRejectionText?: Maybe<Scalars['String']['output']>;
};

export type ConditionReport = {
  __typename?: 'conditionReport';
  accidents?: Maybe<AccidentsEnumType>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  exterior?: Maybe<Array<Maybe<ExteriorEnumType>>>;
  id?: Maybe<Scalars['ID']['output']>;
  interior?: Maybe<Array<Maybe<InteriorEnumType>>>;
  lights_on_dash?: Maybe<Scalars['Boolean']['output']>;
  overall_condition?: Maybe<OverallConditionEnumType>;
  score?: Maybe<Scalars['Float']['output']>;
  smoked_in?: Maybe<Scalars['Boolean']['output']>;
  tires?: Maybe<TiresEnumType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ConditionReportInput = {
  accidents?: InputMaybe<AccidentsEnumType>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  exterior?: InputMaybe<Array<InputMaybe<ExteriorEnumType>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  interior?: InputMaybe<Array<InputMaybe<InteriorEnumType>>>;
  lights_on_dash?: InputMaybe<Scalars['Boolean']['input']>;
  overall_condition?: InputMaybe<OverallConditionEnumType>;
  score?: InputMaybe<Scalars['Float']['input']>;
  smoked_in?: InputMaybe<Scalars['Boolean']['input']>;
  tires?: InputMaybe<TiresEnumType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConditionReportUpsert = {
  __typename?: 'conditionReportUpsert';
  accidents?: Maybe<AccidentsEnumType>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  exterior?: Maybe<Array<Maybe<ExteriorEnumType>>>;
  id?: Maybe<Scalars['ID']['output']>;
  interior?: Maybe<Array<Maybe<InteriorEnumType>>>;
  lights_on_dash?: Maybe<Scalars['Boolean']['output']>;
  overall_condition?: Maybe<OverallConditionEnumType>;
  smoked_in?: Maybe<Scalars['Boolean']['output']>;
  tires?: Maybe<TiresEnumType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ConditionReportUpsertInput = {
  accidents?: InputMaybe<AccidentsEnumType>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  exterior?: InputMaybe<Array<InputMaybe<ExteriorEnumType>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  interior?: InputMaybe<Array<InputMaybe<InteriorEnumType>>>;
  lights_on_dash?: InputMaybe<Scalars['Boolean']['input']>;
  overall_condition?: InputMaybe<OverallConditionEnumType>;
  smoked_in?: InputMaybe<Scalars['Boolean']['input']>;
  tires?: InputMaybe<TiresEnumType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type Conference = {
  __typename?: 'conference';
  agent_number?: Maybe<Scalars['String']['output']>;
  agent_user_id?: Maybe<Scalars['String']['output']>;
  conference_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['Int']['output']>;
  deal_state?: Maybe<Scalars['String']['output']>;
  deal_type?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  hunt_group_slug?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_outbound?: Maybe<Scalars['Boolean']['output']>;
  is_thunder?: Maybe<Scalars['Boolean']['output']>;
  next_participant?: Maybe<Scalars['String']['output']>;
  participants_count?: Maybe<Scalars['Int']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  started_at?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  transferred_from?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Consent = {
  __typename?: 'consent';
  affiliate_id?: Maybe<Scalars['Int']['output']>;
  consent_agreement_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<ConsentNameEnumType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type ConsentAgreement = {
  __typename?: 'consentAgreement';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_active?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ConsentAgreementTypeEnum>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export enum ConsentAgreementTypeEnum {
  AchPayment = 'ach_payment',
  HardPull = 'hard_pull',
  SoftPull = 'soft_pull',
  TermsAndConditions = 'terms_and_conditions',
}

export enum ConsentNameEnumType {
  AchPayment = 'ach_payment',
  CreditScoreHardPull = 'credit_score_hard_pull',
  CreditScoreSoftPull = 'credit_score_soft_pull',
  HistoricalCreditScoreHardPull = 'historical_credit_score_hard_pull',
  TermsAndConditions = 'terms_and_conditions',
}

export enum ContactType {
  Email = 'email',
  Text = 'text',
}

export type ContractTerm = {
  __typename?: 'contractTerm';
  financedTermQuantity?: Maybe<Scalars['Int']['output']>;
  totalDownPaymentAmount?: Maybe<Scalars['Float']['output']>;
};

export type Contracts = {
  __typename?: 'contracts';
  files?: Maybe<Array<Maybe<Files>>>;
};

export type ContractsInput = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateNewEstimateFromFiles = {
  __typename?: 'createNewEstimateFromFiles';
  deal?: Maybe<Deal>;
  uploadUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CreditApplication = {
  __typename?: 'creditApplication';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  credit_application_data?: Maybe<CreditApplicationData>;
  credit_decisions?: Maybe<Array<Maybe<CreditDecision>>>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  r1_conversation_id?: Maybe<Scalars['String']['output']>;
  r1_credit_app_guid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type CreditApplicationAddress = {
  __typename?: 'creditApplicationAddress';
  address_line?: Maybe<Scalars['String']['output']>;
  address_line_2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  monthly_payment?: Maybe<Scalars['Float']['output']>;
  months_at_home?: Maybe<Scalars['Int']['output']>;
  residence_type?: Maybe<ResidenceEnumType>;
  state?: Maybe<Scalars['String']['output']>;
  years_at_home?: Maybe<Scalars['Int']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type CreditApplicationAddressInput = {
  address_line?: InputMaybe<Scalars['String']['input']>;
  address_line_2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  monthly_payment?: InputMaybe<Scalars['Float']['input']>;
  months_at_home?: InputMaybe<Scalars['Int']['input']>;
  residence_type?: InputMaybe<ResidenceEnumType>;
  state?: InputMaybe<Scalars['String']['input']>;
  years_at_home?: InputMaybe<Scalars['Int']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CreditApplicationBuyer = {
  __typename?: 'creditApplicationBuyer';
  address?: Maybe<CreditApplicationAddress>;
  dob?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employment?: Maybe<CreditApplicationEmployment>;
  first_name?: Maybe<Scalars['String']['output']>;
  home_phone_number?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  marital_status?: Maybe<MaritalStatusEnum>;
  middle_name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  prev_address?: Maybe<CreditApplicationAddress>;
  prev_employment?: Maybe<CreditApplicationEmployment>;
  ssn?: Maybe<Scalars['String']['output']>;
};

export type CreditApplicationBuyerInput = {
  address?: InputMaybe<CreditApplicationAddressInput>;
  dob?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employment?: InputMaybe<CreditApplicationEmploymentInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  home_phone_number?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  marital_status?: InputMaybe<MaritalStatusEnum>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  prev_address?: InputMaybe<CreditApplicationAddressInput>;
  prev_employment?: InputMaybe<CreditApplicationEmploymentInput>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type CreditApplicationCar = {
  __typename?: 'creditApplicationCar';
  book_value?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  kbb_trim_name?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  mileage?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  retail_book_value?: Maybe<Scalars['Float']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type CreditApplicationCarInput = {
  book_value?: InputMaybe<Scalars['Float']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  kbb_trim_name?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  retail_book_value?: InputMaybe<Scalars['Float']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type CreditApplicationCreateInput = {
  credit_application_data: CreditApplicationDataInput;
  deal_id: Scalars['ID']['input'];
};

export type CreditApplicationData = {
  __typename?: 'creditApplicationData';
  car?: Maybe<CreditApplicationCar>;
  cobuyer?: Maybe<CreditApplicationBuyer>;
  customer?: Maybe<CreditApplicationBuyer>;
  finance?: Maybe<CreditApplicationFinance>;
  relationshipToBuyer?: Maybe<RelationToBuyerEnum>;
};

export type CreditApplicationDataInput = {
  car?: InputMaybe<CreditApplicationCarInput>;
  cobuyer?: InputMaybe<CreditApplicationBuyerInput>;
  customer?: InputMaybe<CreditApplicationBuyerInput>;
  finance?: InputMaybe<CreditApplicationFinanceInput>;
  relationshipToBuyer?: InputMaybe<RelationToBuyerEnum>;
};

export type CreditApplicationEmployment = {
  __typename?: 'creditApplicationEmployment';
  gross_income?: Maybe<Scalars['Float']['output']>;
  job_title?: Maybe<Scalars['String']['output']>;
  months_at_job?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pay_frequency?: Maybe<IncomeIntervalEnumType>;
  phone_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EmploymentStatusType>;
  years_at_job?: Maybe<Scalars['Int']['output']>;
};

export type CreditApplicationEmploymentInput = {
  gross_income?: InputMaybe<Scalars['Float']['input']>;
  job_title?: InputMaybe<Scalars['String']['input']>;
  months_at_job?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pay_frequency?: InputMaybe<IncomeIntervalEnumType>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmploymentStatusType>;
  years_at_job?: InputMaybe<Scalars['Int']['input']>;
};

export type CreditApplicationFinance = {
  __typename?: 'creditApplicationFinance';
  amount_financed?: Maybe<Scalars['Float']['output']>;
  base_tax_amount?: Maybe<Scalars['Float']['output']>;
  buyer_not_lessee?: Maybe<Scalars['Boolean']['output']>;
  days_to_payment?: Maybe<Scalars['Int']['output']>;
  doc_fee?: Maybe<Scalars['Float']['output']>;
  gap_price?: Maybe<Scalars['Float']['output']>;
  money_down?: Maybe<Scalars['Float']['output']>;
  new_registration_fee?: Maybe<Scalars['Float']['output']>;
  payment?: Maybe<Scalars['Float']['output']>;
  payoff?: Maybe<Scalars['Float']['output']>;
  registration_transfer_fee?: Maybe<Scalars['Float']['output']>;
  sell_rate?: Maybe<Scalars['Float']['output']>;
  term?: Maybe<Scalars['Int']['output']>;
  title_fee?: Maybe<Scalars['Float']['output']>;
  vsc_price?: Maybe<Scalars['Float']['output']>;
  vsc_term?: Maybe<Scalars['String']['output']>;
  warranty_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type CreditApplicationFinanceInput = {
  amount_financed?: InputMaybe<Scalars['Float']['input']>;
  base_tax_amount?: InputMaybe<Scalars['Float']['input']>;
  buyer_not_lessee?: InputMaybe<Scalars['Boolean']['input']>;
  days_to_payment?: InputMaybe<Scalars['Int']['input']>;
  doc_fee?: InputMaybe<Scalars['Float']['input']>;
  gap_price?: InputMaybe<Scalars['Float']['input']>;
  money_down?: InputMaybe<Scalars['Float']['input']>;
  new_registration_fee?: InputMaybe<Scalars['Float']['input']>;
  payment?: InputMaybe<Scalars['Float']['input']>;
  payoff?: InputMaybe<Scalars['Float']['input']>;
  registration_transfer_fee?: InputMaybe<Scalars['Float']['input']>;
  sell_rate?: InputMaybe<Scalars['Float']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
  title_fee?: InputMaybe<Scalars['Float']['input']>;
  vsc_price?: InputMaybe<Scalars['Float']['input']>;
  vsc_term?: InputMaybe<Scalars['String']['input']>;
  warranty_tax_amount?: InputMaybe<Scalars['Float']['input']>;
};

export type CreditApplicationUpdateInput = {
  credit_application_data: CreditApplicationDataInput;
  id: Scalars['ID']['input'];
};

export type CreditDecision = {
  __typename?: 'creditDecision';
  annual_percentage_rate?: Maybe<Scalars['Float']['output']>;
  application_status?: Maybe<CreditDecisionStatusEnum>;
  balance_amount?: Maybe<Scalars['Float']['output']>;
  bank?: Maybe<Bank>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lender_fees?: Maybe<Scalars['Float']['output']>;
  max_markup?: Maybe<Scalars['Float']['output']>;
  r1_application_number?: Maybe<Scalars['String']['output']>;
  r1_conversation_id?: Maybe<Scalars['String']['output']>;
  r1_credit_decision_data?: Maybe<R1CreditDecisionData>;
  r1_fsid?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['Int']['output']>;
  tier?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum CreditDecisionStatusEnum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  I = 'I',
  N = 'N',
  O = 'O',
  Ot = 'OT',
  P = 'P',
  R = 'R',
  S = 'S',
}

export enum CreditScore {
  BelowAverage = 'BelowAverage',
  Fair = 'Fair',
  Good = 'Good',
  Great = 'Great',
}

export enum CreditScoreTierEnumType {
  Fair = 'fair',
  Good = 'good',
  NoResponse = 'no_response',
  Poor = 'poor',
  VeryGood = 'very_good',
}

export type Customer = {
  __typename?: 'customer';
  address?: Maybe<Address>;
  auth0_id?: Maybe<Scalars['String']['output']>;
  contact_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dashboard_visited?: Maybe<Scalars['Boolean']['output']>;
  dl_expiration_date?: Maybe<Scalars['DateTime']['output']>;
  dob?: Maybe<Scalars['Date']['output']>;
  drivers_license_address?: Maybe<Address>;
  email?: Maybe<Scalars['String']['output']>;
  employment?: Maybe<Employment>;
  finished_signing?: Maybe<Scalars['Boolean']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  has_same_address_as_cobuyer?: Maybe<Scalars['Boolean']['output']>;
  home_phone_number?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  last_authenticated_route?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  marital_status?: Maybe<Scalars['String']['output']>;
  middle_name?: Maybe<Scalars['String']['output']>;
  name_suffix?: Maybe<Scalars['String']['output']>;
  no_email?: Maybe<Scalars['Boolean']['output']>;
  payoff_email_sent_at?: Maybe<Scalars['DateTime']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  prequalification?: Maybe<Prequalification>;
  prev_address?: Maybe<Address>;
  prev_employment?: Maybe<Employment>;
  proof_of_insurance?: Maybe<ProofOfInsurance>;
  relation_to_buyer?: Maybe<RelationToBuyerEnum>;
  sent_account_email?: Maybe<Scalars['Boolean']['output']>;
  sent_finalized_email?: Maybe<Scalars['DateTime']['output']>;
  sent_review_email?: Maybe<Scalars['DateTime']['output']>;
  sent_to_processor_email_sent_at?: Maybe<Scalars['DateTime']['output']>;
  signature_data?: Maybe<SignatureData>;
  ssn?: Maybe<Scalars['String']['output']>;
  title_received_email_sent_at?: Maybe<Scalars['DateTime']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomerInput = {
  address?: InputMaybe<AddressInput>;
  auth0_id?: InputMaybe<Scalars['String']['input']>;
  contact_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  dashboard_visited?: InputMaybe<Scalars['Boolean']['input']>;
  dl_expiration_date?: InputMaybe<Scalars['DateTime']['input']>;
  dob?: InputMaybe<Scalars['Date']['input']>;
  drivers_license_address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  employment?: InputMaybe<EmploymentInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  has_same_address_as_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  home_phone_number?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last_authenticated_route?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  marital_status?: InputMaybe<Scalars['String']['input']>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  name_suffix?: InputMaybe<Scalars['String']['input']>;
  no_email?: InputMaybe<Scalars['Boolean']['input']>;
  payoff_email_sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  prev_address?: InputMaybe<AddressInput>;
  prev_employment?: InputMaybe<EmploymentInput>;
  proof_of_insurance?: InputMaybe<ProofOfInsuranceInput>;
  relation_to_buyer?: InputMaybe<RelationToBuyerEnum>;
  sent_account_email?: InputMaybe<Scalars['Boolean']['input']>;
  sent_finalized_email?: InputMaybe<Scalars['DateTime']['input']>;
  sent_review_email?: InputMaybe<Scalars['DateTime']['input']>;
  sent_to_processor_email_sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  title_received_email_sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Deal = {
  __typename?: 'deal';
  auto_import_variation?: Maybe<AutoImportVariation>;
  boot_reason?: Maybe<Scalars['JSONObject']['output']>;
  called_by_financial_specialist?: Maybe<Scalars['DateTime']['output']>;
  car?: Maybe<Car>;
  closer?: Maybe<User>;
  closer2?: Maybe<User>;
  closer2_id?: Maybe<Scalars['ID']['output']>;
  closer_id?: Maybe<Scalars['ID']['output']>;
  cobuyer?: Maybe<Customer>;
  completion_date_tz?: Maybe<Scalars['String']['output']>;
  completion_date_utc?: Maybe<Scalars['DateTime']['output']>;
  condition_report?: Maybe<ConditionReport>;
  consents?: Maybe<Array<Maybe<Consent>>>;
  contact?: Maybe<DealContact>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  creation_date_tz?: Maybe<Scalars['String']['output']>;
  creation_date_utc?: Maybe<Scalars['DateTime']['output']>;
  credit_applications?: Maybe<Array<Maybe<CreditApplication>>>;
  customer?: Maybe<Customer>;
  deal_assignment_log?: Maybe<DealAssignmentLog>;
  deal_dates?: Maybe<DealDates>;
  deal_states?: Maybe<Array<Maybe<DealState>>>;
  default_sort_date?: Maybe<Scalars['DateTime']['output']>;
  direct_pay_state?: Maybe<Scalars['Boolean']['output']>;
  document_info?: Maybe<DocumentInfo>;
  document_progress_status?: Maybe<Scalars['String']['output']>;
  extracted_document_data?: Maybe<ExtractedDocumentData>;
  finalized_date?: Maybe<Scalars['DateTime']['output']>;
  financial_info?: Maybe<FinancialInfo>;
  financial_info_acquisition?: Maybe<FinancialInfoAcquisition>;
  follow_up?: Maybe<FollowUp>;
  force_title_only_helper_link?: Maybe<ForceTitleOnlyHelperLink>;
  funding_clerk?: Maybe<User>;
  funding_clerk_id?: Maybe<Scalars['ID']['output']>;
  hasRecentCommunication?: Maybe<Scalars['Boolean']['output']>;
  has_problem?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  import_type?: Maybe<ImportType>;
  imported_date_utc?: Maybe<Scalars['DateTime']['output']>;
  isCobuyer?: Maybe<Scalars['Boolean']['output']>;
  lane_arrival_utc?: Maybe<Scalars['DateTime']['output']>;
  last_outgoing_communication_date_utc?: Maybe<Scalars['DateTime']['output']>;
  lease_id?: Maybe<Scalars['GUID']['output']>;
  marketing_source?: Maybe<Scalars['String']['output']>;
  missing_required_external_documents?: Maybe<Scalars['Boolean']['output']>;
  needs_electronic_signature_verification?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  notification_subscribers?: Maybe<Array<Maybe<NotificationSubscriber>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  paperwork_type?: Maybe<PaperworkType>;
  pod?: Maybe<Pod>;
  pod_id?: Maybe<Scalars['ID']['output']>;
  product?: Maybe<Product>;
  r1_contract_generation_date_utc?: Maybe<Scalars['DateTime']['output']>;
  r1_contract_manually_validated?: Maybe<Scalars['Boolean']['output']>;
  r1_contract_validation_errors_object?: Maybe<R1ContractValidationErrors>;
  r1_contract_validation_started_at?: Maybe<Scalars['DateTime']['output']>;
  r1_contract_validation_warnings_object?: Maybe<R1ContractValidationWarnings>;
  r1_contract_worksheet_created_date_utc?: Maybe<Scalars['DateTime']['output']>;
  r1_jacket_id?: Maybe<Scalars['String']['output']>;
  r1_jacket_id_created_date_utc?: Maybe<Scalars['DateTime']['output']>;
  referral_source?: Maybe<ReferralSource>;
  request_boot?: Maybe<Scalars['Boolean']['output']>;
  sales_visibility?: Maybe<Scalars['Boolean']['output']>;
  second_contact?: Maybe<DealContact>;
  set_date?: Maybe<Scalars['DateTime']['output']>;
  setter?: Maybe<User>;
  setter_id?: Maybe<Scalars['ID']['output']>;
  shipping_labels?: Maybe<Array<Maybe<ShippingLabel>>>;
  signing_on_com?: Maybe<Scalars['Boolean']['output']>;
  sold_date?: Maybe<Scalars['DateTime']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  structuring_follow_up?: Maybe<StructuringFollowUp>;
  structuring_manager?: Maybe<User>;
  structuring_manager_id?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Array<Tag>>;
  temporary_registration_tags_user?: Maybe<User>;
  temporary_registration_tags_user_id?: Maybe<Scalars['ID']['output']>;
  title_clerk?: Maybe<User>;
  title_clerk2?: Maybe<User>;
  title_clerk2_id?: Maybe<Scalars['ID']['output']>;
  title_clerk_id?: Maybe<Scalars['ID']['output']>;
  titling_pod?: Maybe<Pod>;
  titling_pod_id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<DealType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type DealAssignmentLog = {
  __typename?: 'dealAssignmentLog';
  assignment_logs_object?: Maybe<AssignmentLogs>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type DealAssignmentLogInput = {
  assignment_logs_object?: InputMaybe<AssignmentLogsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DealContact = {
  __typename?: 'dealContact';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  middle_name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  relation_to_buyer?: Maybe<RelationToBuyerEnum>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type DealContactInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  relation_to_buyer?: InputMaybe<RelationToBuyerEnum>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DealDates = {
  __typename?: 'dealDates';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  custom_dates?: Maybe<Scalars['JSON']['output']>;
  dates?: Maybe<Scalars['JSON']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type DealDatesInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_dates?: InputMaybe<Scalars['JSON']['input']>;
  dates?: InputMaybe<Scalars['JSON']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DealInput = {
  auto_import_variation?: InputMaybe<AutoImportVariation>;
  boot_reason?: InputMaybe<Scalars['JSONObject']['input']>;
  called_by_financial_specialist?: InputMaybe<Scalars['DateTime']['input']>;
  car?: InputMaybe<CarInput>;
  closer2_id?: InputMaybe<Scalars['ID']['input']>;
  closer_id?: InputMaybe<Scalars['ID']['input']>;
  cobuyer?: InputMaybe<CustomerInput>;
  completion_date_tz?: InputMaybe<Scalars['String']['input']>;
  completion_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  condition_report?: InputMaybe<ConditionReportInput>;
  contact?: InputMaybe<DealContactInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creation_date_tz?: InputMaybe<Scalars['String']['input']>;
  creation_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerInput>;
  deal_assignment_log?: InputMaybe<DealAssignmentLogInput>;
  deal_dates?: InputMaybe<DealDatesInput>;
  deal_states?: InputMaybe<Array<InputMaybe<DealStateInput>>>;
  direct_pay_state?: InputMaybe<Scalars['Boolean']['input']>;
  document_info?: InputMaybe<DocumentInfoInput>;
  document_progress_status?: InputMaybe<Scalars['String']['input']>;
  financial_info?: InputMaybe<FinancialInfoInput>;
  financial_info_acquisition?: InputMaybe<FinancialInfoAcquisitionInput>;
  follow_up?: InputMaybe<FollowUpInput>;
  funding_clerk_id?: InputMaybe<Scalars['ID']['input']>;
  has_problem?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  import_type?: InputMaybe<ImportType>;
  imported_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  lease_id?: InputMaybe<Scalars['GUID']['input']>;
  marketing_source?: InputMaybe<Scalars['String']['input']>;
  missing_required_external_documents?: InputMaybe<Scalars['Boolean']['input']>;
  needs_electronic_signature_verification?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  notification_subscribers?: InputMaybe<Array<InputMaybe<NotificationSubscriberInput>>>;
  notifications?: InputMaybe<Array<InputMaybe<NotificationInput>>>;
  paperwork_type?: InputMaybe<PaperworkType>;
  pod?: InputMaybe<PodInput>;
  pod_id?: InputMaybe<Scalars['ID']['input']>;
  r1_contract_generation_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  r1_contract_manually_validated?: InputMaybe<Scalars['Boolean']['input']>;
  r1_contract_validation_errors_object?: InputMaybe<R1ContractValidationErrorsInput>;
  r1_contract_validation_started_at?: InputMaybe<Scalars['DateTime']['input']>;
  r1_contract_validation_warnings_object?: InputMaybe<R1ContractValidationWarningsInput>;
  r1_contract_worksheet_created_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  r1_jacket_id?: InputMaybe<Scalars['String']['input']>;
  r1_jacket_id_created_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  referral_source?: InputMaybe<ReferralSourceInput>;
  request_boot?: InputMaybe<Scalars['Boolean']['input']>;
  sales_visibility?: InputMaybe<Scalars['Boolean']['input']>;
  second_contact?: InputMaybe<DealContactInput>;
  set_date?: InputMaybe<Scalars['DateTime']['input']>;
  setter_id?: InputMaybe<Scalars['ID']['input']>;
  signing_on_com?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  structuring_follow_up?: InputMaybe<StructuringFollowUpInput>;
  structuring_manager_id?: InputMaybe<Scalars['ID']['input']>;
  temporary_registration_tags_user_id?: InputMaybe<Scalars['ID']['input']>;
  title_clerk2_id?: InputMaybe<Scalars['ID']['input']>;
  title_clerk_id?: InputMaybe<Scalars['ID']['input']>;
  titling_pod?: InputMaybe<PodInput>;
  titling_pod_id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<DealType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DealMedia = {
  __typename?: 'dealMedia';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  has_verified_digital_signature?: Maybe<Scalars['Boolean']['output']>;
  has_wet_signature?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_notarized?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  signed_url?: Maybe<Scalars['String']['output']>;
  source?: Maybe<DealMediaSourceEnum>;
  state?: Maybe<DealMediaStateEnum>;
  type?: Maybe<DealMediaTypeEnum>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  uploaded_by_customer?: Maybe<Scalars['Boolean']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
  wet_signature_required?: Maybe<Scalars['Boolean']['output']>;
};

export type DealMediaInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  has_verified_digital_signature?: InputMaybe<Scalars['Boolean']['input']>;
  has_wet_signature?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_notarized?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  signed_url?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<DealMediaSourceEnum>;
  state?: InputMaybe<DealMediaStateEnum>;
  type?: InputMaybe<DealMediaTypeEnum>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  uploaded_by_customer?: InputMaybe<Scalars['Boolean']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
  wet_signature_required?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum DealMediaTypeEnum {
  AcuraSpecificOdometerDoc = 'acura_specific_odometer_doc',
  AgreementToFurnishInsurance = 'agreement_to_furnish_insurance',
  BackOfCoBuyersInsurance = 'back_of_co_buyers_insurance',
  BackOfCoBuyersLicense = 'back_of_co_buyers_license',
  BackOfDriversLicense = 'back_of_drivers_license',
  BackOfInsuranceCard = 'back_of_insurance_card',
  BookSheet = 'book_sheet',
  BuyersGuide = 'buyers_guide',
  CaliforniaStatementOfFacts = 'california_statement_of_facts',
  CaliforniaStatementOfFactsCobuyer = 'california_statement_of_facts_cobuyer',
  CobuyerRegistrationSpecificPowerOfAttorney = 'cobuyer_registration_specific_power_of_attorney',
  CobuyerSecurePowerOfAttorney = 'cobuyer_secure_power_of_attorney',
  CobuyerStateSpecificOdoDoc = 'cobuyer_state_specific_odo_doc',
  ColoradoCobuyerSupplementalIdentificationClause = 'colorado_cobuyer_supplemental_identification_clause',
  ColoradoJointTenancy = 'colorado_joint_tenancy',
  ColoradoSupplementalIdentificationClause = 'colorado_supplemental_identification_clause',
  ConnecticutElectronicRegistrationAndTitleAgreement = 'connecticut_electronic_registration_and_title_agreement',
  Contract = 'contract',
  CreditApplication = 'credit_application',
  CreditApproval = 'credit_approval',
  DamageDisclosure = 'damage_disclosure',
  DemandLetter = 'demand_letter',
  DownPayment = 'down_payment',
  ESignConsent = 'e_sign_consent',
  ElectronicSignatureAttestation = 'electronic_signature_attestation',
  ElectronicSignatureCertification = 'electronic_signature_certification',
  ElectronicSignatureVerification = 'electronic_signature_verification',
  Emissions = 'emissions',
  FrontOfCoBuyersInsurance = 'front_of_co_buyers_insurance',
  FrontOfCoBuyersLicense = 'front_of_co_buyers_license',
  FrontOfDriversLicense = 'front_of_drivers_license',
  FrontOfInsuranceCard = 'front_of_insurance_card',
  GmLeasePacket = 'gm_lease_packet',
  HondaSpecificOdometerDoc = 'honda_specific_odometer_doc',
  IdahoSalesTaxExemption = 'idaho_sales_tax_exemption',
  LeaseAgreement = 'lease_agreement',
  LeaseEndAcqContract = 'lease_end_acq_contract',
  LeaseEndCashback = 'lease_end_cashback',
  LeaseendOdoDoc = 'leaseend_odo_doc',
  LeaseendOdoDocCobuyer = 'leaseend_odo_doc_cobuyer',
  LeaseendOdoDocJoint = 'leaseend_odo_doc_joint',
  LicensePlate = 'license_plate',
  MembershipForm = 'membership_form',
  OdoDoc = 'odo_doc',
  OdometerPicture = 'odometer_picture',
  Other = 'other',
  PayoffAuthorization = 'payoff_authorization',
  PayoffDocs = 'payoff_docs',
  PhysicalSignatureCoverLetter = 'physical_signature_cover_letter',
  PicturesOfVehicle = 'pictures_of_vehicle',
  PlateTransferLetter = 'plate_transfer_letter',
  PorscheSpecificOdometerDoc = 'porsche_specific_odometer_doc',
  PorscheSpecificPayoffInstructions = 'porsche_specific_payoff_instructions',
  PowerOfAttorney = 'power_of_attorney',
  PowerOfAttorneyCobuyer = 'power_of_attorney_cobuyer',
  PrivacyPolicy = 'privacy_policy',
  ProofOfIncome = 'proof_of_income',
  ProofOfResidence = 'proof_of_residence',
  ProofOfResidence2 = 'proof_of_residence2',
  PropertyTaxReceipt = 'property_tax_receipt',
  PurchaseOrder = 'purchase_order',
  R1EContract = 'r1_e_contract',
  RateExceptionForm = 'rate_exception_form',
  ReassignmentDisclosure = 'reassignment_disclosure',
  Registration = 'registration',
  RegistrationComplete = 'registration_complete',
  RegistrationSpecificPowerOfAttorney = 'registration_specific_power_of_attorney',
  ReleaseOfInterest = 'release_of_interest',
  SafetyInspection = 'safety_inspection',
  SalesTaxExemption = 'sales_tax_exemption',
  SecurePowerOfAttorney = 'secure_power_of_attorney',
  SignedDocs = 'signed_docs',
  StateAndLienholderSpecificPowerOfAttorney = 'state_and_lienholder_specific_power_of_attorney',
  StateSpecificDamageDisclosure = 'state_specific_damage_disclosure',
  StateSpecificDocs = 'state_specific_docs',
  StateSpecificOdoDoc = 'state_specific_odo_doc',
  StateSpecificPowerOfAttorney = 'state_specific_power_of_attorney',
  StateSpecificPowerOfAttorneyCobuyer = 'state_specific_power_of_attorney_cobuyer',
  StateSpecificPurchaseOrder = 'state_specific_purchase_order',
  TaterDocs = 'tater_docs',
  TaxExemption = 'tax_exemption',
  TempTag = 'temp_tag',
  TexasHidalgoDoc = 'texas_hidalgo_doc',
  Title = 'title',
  TitleApplication = 'title_application',
  TitlePacket = 'title_packet',
  TitleRelease = 'title_release',
  VinInspection = 'vin_inspection',
  VscAndGap = 'vsc_and_gap',
  WetSignature = 'wet_signature',
}

export enum DealReadyType {
  Conditioned = 'CONDITIONED',
  Failed = 'FAILED',
  ImmediateImport = 'IMMEDIATE_IMPORT',
  Loading = 'LOADING',
  Ready = 'READY',
}

export type DealSetter = {
  __typename?: 'dealSetter';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  setter?: Maybe<User>;
  setter_id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type DealState = {
  __typename?: 'dealState';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  updated_date_utc?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['ID']['output']>;
};

export enum DealStateEnum {
  Auction = 'AUCTION',
  Booted = 'BOOTED',
  Closed = 'CLOSED',
  Closing = 'CLOSING',
  Estimate = 'ESTIMATE',
  Finalized = 'FINALIZED',
  Floor = 'FLOOR',
  Funded = 'FUNDED',
  PaidOff = 'PAID_OFF',
  ReadyForPickup = 'READY_FOR_PICKUP',
  SendPayoff = 'SEND_PAYOFF',
  SentForSign = 'SENT_FOR_SIGN',
  SentToProcessor = 'SENT_TO_PROCESSOR',
  Signed = 'SIGNED',
  SoftClose = 'SOFT_CLOSE',
  Sold = 'SOLD',
  Structuring = 'STRUCTURING',
  StructuringProgress = 'STRUCTURING_PROGRESS',
  TitleReceived = 'TITLE_RECEIVED',
  TitleSent = 'TITLE_SENT',
  WaitingForTitle = 'WAITING_FOR_TITLE',
}

export type DealStateInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserInput>;
  user_id?: InputMaybe<Scalars['ID']['input']>;
};

export type DealerInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contact_name?: InputMaybe<Scalars['String']['input']>;
  dealership_name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  observes_daylight_saving?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type Deals = {
  __typename?: 'deals';
  auto_import_variation?: Maybe<AutoImportVariation>;
  boot_reason?: Maybe<Scalars['JSONObject']['output']>;
  called_by_financial_specialist?: Maybe<Scalars['DateTime']['output']>;
  car?: Maybe<Car>;
  closer2_id?: Maybe<Scalars['ID']['output']>;
  closer_id?: Maybe<Scalars['ID']['output']>;
  cobuyer?: Maybe<Customer>;
  completion_date_tz?: Maybe<Scalars['String']['output']>;
  completion_date_utc?: Maybe<Scalars['DateTime']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  creation_date_tz?: Maybe<Scalars['String']['output']>;
  creation_date_utc?: Maybe<Scalars['DateTime']['output']>;
  customer?: Maybe<Customer>;
  document_progress_status?: Maybe<Scalars['String']['output']>;
  finalized_date?: Maybe<Scalars['DateTime']['output']>;
  funding_clerk_id?: Maybe<Scalars['ID']['output']>;
  hasRecentCommunication?: Maybe<Scalars['Boolean']['output']>;
  has_problem?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  import_type?: Maybe<ImportType>;
  imported_date_utc?: Maybe<Scalars['DateTime']['output']>;
  isCobuyer?: Maybe<Scalars['Boolean']['output']>;
  lease_id?: Maybe<Scalars['GUID']['output']>;
  marketing_source?: Maybe<Scalars['String']['output']>;
  missing_required_external_documents?: Maybe<Scalars['Boolean']['output']>;
  needs_electronic_signature_verification?: Maybe<Scalars['Boolean']['output']>;
  paperwork_type?: Maybe<PaperworkType>;
  pod_id?: Maybe<Scalars['ID']['output']>;
  r1_contract_generation_date_utc?: Maybe<Scalars['DateTime']['output']>;
  r1_contract_manually_validated?: Maybe<Scalars['Boolean']['output']>;
  r1_contract_validation_errors_object?: Maybe<R1ContractValidationErrors>;
  r1_contract_validation_started_at?: Maybe<Scalars['DateTime']['output']>;
  r1_contract_validation_warnings_object?: Maybe<R1ContractValidationWarnings>;
  r1_contract_worksheet_created_date_utc?: Maybe<Scalars['DateTime']['output']>;
  r1_jacket_id?: Maybe<Scalars['String']['output']>;
  r1_jacket_id_created_date_utc?: Maybe<Scalars['DateTime']['output']>;
  request_boot?: Maybe<Scalars['Boolean']['output']>;
  sales_visibility?: Maybe<Scalars['Boolean']['output']>;
  set_date?: Maybe<Scalars['DateTime']['output']>;
  setter_id?: Maybe<Scalars['ID']['output']>;
  signing_on_com?: Maybe<Scalars['Boolean']['output']>;
  sold_date?: Maybe<Scalars['DateTime']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  structuring_manager_id?: Maybe<Scalars['ID']['output']>;
  temporary_registration_tags_user_id?: Maybe<Scalars['ID']['output']>;
  title_clerk2_id?: Maybe<Scalars['ID']['output']>;
  title_clerk_id?: Maybe<Scalars['ID']['output']>;
  titling_pod_id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<DealType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type DealsWithNotification = {
  __typename?: 'dealsWithNotification';
  bootedDealsNotificationsMessages?: Maybe<Scalars['Int']['output']>;
  bootedDealsNotificationsNotMessages?: Maybe<Scalars['Int']['output']>;
  completedDealsNotificationsMessages?: Maybe<Scalars['Int']['output']>;
  completedDealsNotificationsNotMessages?: Maybe<Scalars['Int']['output']>;
  followUpDealsNotificationsMessages?: Maybe<Scalars['Int']['output']>;
  followUpDealsNotificationsNotMessages?: Maybe<Scalars['Int']['output']>;
};

export type Decision = {
  __typename?: 'decision';
  conditionRejection?: Maybe<Array<Maybe<ConditionRejection>>>;
  decisionComments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  financing?: Maybe<Financing>;
  stipulations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Deductible = {
  __typename?: 'deductible';
  amount?: Maybe<Scalars['String']['output']>;
  dealer_cost?: Maybe<Scalars['String']['output']>;
  deductible_type?: Maybe<Scalars['String']['output']>;
  f_i_markup?: Maybe<Scalars['String']['output']>;
  max_retail_price?: Maybe<Scalars['String']['output']>;
  min_retail_price?: Maybe<Scalars['String']['output']>;
  rate_id?: Maybe<Scalars['Int']['output']>;
  reduced_amount?: Maybe<Scalars['String']['output']>;
  retail_price?: Maybe<Scalars['String']['output']>;
};

export type Destinations = {
  address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DetermineApproval = {
  __typename?: 'determineApproval';
  approved?: Maybe<Scalars['Boolean']['output']>;
  failedCriteria?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DetermineApprovalInput = {
  accidents?: InputMaybe<AccidentsEnumType>;
  book_value?: InputMaybe<Scalars['Float']['input']>;
  exterior?: InputMaybe<Array<InputMaybe<ExteriorEnumType>>>;
  interior?: InputMaybe<Array<InputMaybe<InteriorEnumType>>>;
  kbb_trim?: InputMaybe<Scalars['String']['input']>;
  lights_on_dash?: InputMaybe<Scalars['Boolean']['input']>;
  overall_condition?: InputMaybe<OverallConditionEnumType>;
  smoked_in?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  temporary_info_id?: InputMaybe<Scalars['String']['input']>;
  tires?: InputMaybe<TiresEnumType>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
};

export type DetermineApprovalQueryInput = {
  accidents?: InputMaybe<AccidentsEnumType>;
  book_value?: InputMaybe<Scalars['Float']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  exterior?: InputMaybe<Array<InputMaybe<ExteriorEnumType>>>;
  financial_info_acquisition_id?: InputMaybe<Scalars['ID']['input']>;
  interior?: InputMaybe<Array<InputMaybe<InteriorEnumType>>>;
  kbb_trim?: InputMaybe<Scalars['String']['input']>;
  lights_on_dash?: InputMaybe<Scalars['Boolean']['input']>;
  mileage?: InputMaybe<Scalars['Float']['input']>;
  overall_condition?: InputMaybe<OverallConditionEnumType>;
  payoff?: InputMaybe<Scalars['Float']['input']>;
  smoked_in?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tires?: InputMaybe<TiresEnumType>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentInfo = {
  __typename?: 'documentInfo';
  adobe_agreement_id?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type DocumentInfoInput = {
  adobe_agreement_id?: InputMaybe<Scalars['String']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Documents = {
  __typename?: 'documents';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  files?: Maybe<Array<Maybe<Files>>>;
};

export type DocumentsInfoUpdateInput = {
  hasCobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  movedStates?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  vehicleYear?: InputMaybe<Scalars['Int']['input']>;
};

export type DocumentsInput = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  internal_media?: InputMaybe<Array<InputMaybe<DealMediaTypeEnum>>>;
};

export enum DownPaymentStatus {
  Completed = 'COMPLETED',
  ManuallyCompleted = 'MANUALLY_COMPLETED',
  Na = 'NA',
  Requested = 'REQUESTED',
  Sent = 'SENT',
}

export type Employment = {
  __typename?: 'employment';
  customer_id?: Maybe<Scalars['ID']['output']>;
  employment_type?: Maybe<EmploymentTypeType>;
  gross_income?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  job_title?: Maybe<Scalars['String']['output']>;
  months_at_job?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pay_frequency?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EmploymentStatusType>;
  years_at_job?: Maybe<Scalars['Int']['output']>;
};

export enum EmploymentEnumType {
  FullTime = 'full_time',
  PartTime = 'part_time',
}

export type EmploymentInput = {
  customer_id?: InputMaybe<Scalars['ID']['input']>;
  employment_type?: InputMaybe<EmploymentTypeType>;
  gross_income?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  job_title?: InputMaybe<Scalars['String']['input']>;
  months_at_job?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pay_frequency?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmploymentStatusType>;
  years_at_job?: InputMaybe<Scalars['Int']['input']>;
};

export enum EmploymentStatusType {
  Employed = 'employed',
  NotEmployed = 'not_employed',
  Retired = 'retired',
}

export enum EmploymentTypeType {
  Current = 'current',
  Previous = 'previous',
}

export enum EndSalesFlowReasonEnum {
  ConditionedFailedDealProcessing = 'CONDITIONED_FAILED_DEAL_PROCESSING',
  DocumentsFinishedFlow = 'DOCUMENTS_FINISHED_FLOW',
  FailedDealProcessing = 'FAILED_DEAL_PROCESSING',
  FinishedFlow = 'FINISHED_FLOW',
  ImmediateImport = 'IMMEDIATE_IMPORT',
  NoKbbValuesFailure = 'NO_KBB_VALUES_FAILURE',
  OneDayInactivity = 'ONE_DAY_INACTIVITY',
  TimedOut = 'TIMED_OUT',
}

export enum ErrorCodeEnum {
  CardPaymentLimitExceeded = 'CARD_PAYMENT_LIMIT_EXCEEDED',
  CardTypeMismatch = 'CARD_TYPE_MISMATCH',
  Forbidden = 'FORBIDDEN',
  ResetGuidError = 'RESET_GUID_ERROR',
  TempInfoNotFound = 'TEMP_INFO_NOT_FOUND',
  Unauthenticated = 'UNAUTHENTICATED',
}

export enum ExteriorEnumType {
  DentsOrDings = 'dents_or_dings',
  GlassWindshieldDamage = 'glass_windshield_damage',
  HailDamage = 'hail_damage',
  MinorScuffs = 'minor_scuffs',
  PaintDamage = 'paint_damage',
  Rusting = 'rusting',
}

export type ExternalDealUploadUrl = {
  __typename?: 'externalDealUploadUrl';
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ExternalTempInfoUploadUrl = {
  __typename?: 'externalTempInfoUploadUrl';
  url?: Maybe<Scalars['String']['output']>;
};

export type ExtraTempInfoInputType = {
  address_line?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  prequal_id?: InputMaybe<Scalars['ID']['input']>;
  shopping_cart?: InputMaybe<TemporaryShoppingCartDataInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ExtractedDocumentData = {
  __typename?: 'extractedDocumentData';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  dl_address?: Maybe<Scalars['String']['output']>;
  dl_city?: Maybe<Scalars['String']['output']>;
  dl_expiration_date?: Maybe<Scalars['String']['output']>;
  dl_first_name?: Maybe<Scalars['String']['output']>;
  dl_last_name?: Maybe<Scalars['String']['output']>;
  dl_middle_name?: Maybe<Scalars['String']['output']>;
  dl_state?: Maybe<Scalars['String']['output']>;
  dl_zip?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  insurance_company?: Maybe<Scalars['String']['output']>;
  insurance_company_confidence?: Maybe<Scalars['Int']['output']>;
  insurance_expiration?: Maybe<Scalars['String']['output']>;
  insurance_expiration_confidence?: Maybe<Scalars['Int']['output']>;
  insurance_name?: Maybe<Scalars['String']['output']>;
  insurance_name_confidence?: Maybe<Scalars['Int']['output']>;
  insurance_policy_number?: Maybe<Scalars['String']['output']>;
  insurance_policy_number_confidence?: Maybe<Scalars['Int']['output']>;
  insurance_state?: Maybe<Scalars['String']['output']>;
  insurance_state_confidence?: Maybe<Scalars['Int']['output']>;
  insurance_vin?: Maybe<Scalars['String']['output']>;
  insurance_vin_confidence?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Files = {
  __typename?: 'files';
  filename?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  mediaType?: Maybe<DealMediaTypeEnum>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FinancialInfo = {
  __typename?: 'financialInfo';
  amount_financed?: Maybe<Scalars['Float']['output']>;
  bank?: Maybe<Scalars['String']['output']>;
  bank_fees?: Maybe<Scalars['Float']['output']>;
  base_tax_amount?: Maybe<Scalars['Float']['output']>;
  buy_rate?: Maybe<Scalars['Float']['output']>;
  buyer_not_lessee?: Maybe<Scalars['Boolean']['output']>;
  closer_commission?: Maybe<Scalars['Float']['output']>;
  com_rate_markup?: Maybe<Scalars['Float']['output']>;
  commissionable_profit?: Maybe<Scalars['Float']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  credit_card_payment_amount_limit?: Maybe<Scalars['Float']['output']>;
  credit_debit_payment_amount_allowed?: Maybe<Scalars['Float']['output']>;
  credit_payment_amount_allowed?: Maybe<Scalars['Float']['output']>;
  credit_payment_amount_limit?: Maybe<Scalars['Float']['output']>;
  days_to_payment?: Maybe<Scalars['Int']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  debit_payment_amount_allowed?: Maybe<Scalars['Float']['output']>;
  debit_payment_amount_limit?: Maybe<Scalars['Float']['output']>;
  doc_fee?: Maybe<Scalars['Float']['output']>;
  down_payment_status?: Maybe<DownPaymentStatus>;
  fee_processor?: Maybe<Scalars['String']['output']>;
  first_payment_date?: Maybe<Scalars['Date']['output']>;
  gap_cost?: Maybe<Scalars['Float']['output']>;
  gap_price?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  money_down?: Maybe<Scalars['Float']['output']>;
  needs_temporary_registration_tags?: Maybe<Scalars['Boolean']['output']>;
  new_lienholder?: Maybe<Bank>;
  new_registration_fee?: Maybe<Scalars['Float']['output']>;
  option_type?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<Scalars['Float']['output']>;
  pen_gap_form_id?: Maybe<Scalars['Int']['output']>;
  pen_gap_rate_id?: Maybe<Scalars['Int']['output']>;
  pen_gap_session_id?: Maybe<Scalars['String']['output']>;
  pen_vsc_form_id?: Maybe<Scalars['Int']['output']>;
  pen_vsc_rate_id?: Maybe<Scalars['Int']['output']>;
  pen_vsc_session_id?: Maybe<Scalars['String']['output']>;
  plate_transfer?: Maybe<Scalars['Boolean']['output']>;
  processor?: Maybe<Scalars['String']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
  quick_notes?: Maybe<Scalars['String']['output']>;
  registration_transfer_fee?: Maybe<Scalars['Float']['output']>;
  reserve?: Maybe<Scalars['Float']['output']>;
  sales_tax_rate?: Maybe<Scalars['Float']['output']>;
  selected_credit_decision?: Maybe<CreditDecision>;
  selected_credit_decision_id?: Maybe<Scalars['ID']['output']>;
  sell_rate?: Maybe<Scalars['Float']['output']>;
  setter_commission?: Maybe<Scalars['Float']['output']>;
  tax_processor?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['Int']['output']>;
  title_fee?: Maybe<Scalars['Float']['output']>;
  title_registration_option?: Maybe<TitleRegistrationOption>;
  tt_transaction_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user_entered_reserve?: Maybe<Scalars['Float']['output']>;
  vsc_cost?: Maybe<Scalars['Float']['output']>;
  vsc_price?: Maybe<Scalars['Float']['output']>;
  vsc_term?: Maybe<Scalars['String']['output']>;
  vsc_type?: Maybe<Scalars['String']['output']>;
  warranty_tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type FinancialInfoAcquisition = {
  __typename?: 'financialInfoAcquisition';
  appraised_value?: Maybe<Scalars['Float']['output']>;
  auction_fees?: Maybe<Scalars['Float']['output']>;
  auction_id?: Maybe<Scalars['ID']['output']>;
  cash_to_customer?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  estimated_dealer_fees?: Maybe<Scalars['Float']['output']>;
  gross_profit?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_approved?: Maybe<Scalars['Boolean']['output']>;
  kbb_lending?: Maybe<Scalars['Float']['output']>;
  max_cash_to_customer?: Maybe<Scalars['Float']['output']>;
  max_total_cost?: Maybe<Scalars['Float']['output']>;
  offer?: Maybe<Scalars['Float']['output']>;
  overriding_user_id?: Maybe<Scalars['String']['output']>;
  sell_price?: Maybe<Scalars['Float']['output']>;
  total_cost?: Maybe<Scalars['Float']['output']>;
  transport_cost?: Maybe<Scalars['Float']['output']>;
};

export type FinancialInfoAcquisitionInput = {
  appraised_value?: InputMaybe<Scalars['Float']['input']>;
  auction_fees?: InputMaybe<Scalars['Float']['input']>;
  auction_id?: InputMaybe<Scalars['ID']['input']>;
  cash_to_customer?: InputMaybe<Scalars['Float']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  estimated_dealer_fees?: InputMaybe<Scalars['Float']['input']>;
  gross_profit?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  is_approved?: InputMaybe<Scalars['Boolean']['input']>;
  kbb_lending?: InputMaybe<Scalars['Float']['input']>;
  max_cash_to_customer?: InputMaybe<Scalars['Float']['input']>;
  max_total_cost?: InputMaybe<Scalars['Float']['input']>;
  offer?: InputMaybe<Scalars['Float']['input']>;
  overriding_user_id?: InputMaybe<Scalars['String']['input']>;
  sell_price?: InputMaybe<Scalars['Float']['input']>;
  total_cost?: InputMaybe<Scalars['Float']['input']>;
  transport_cost?: InputMaybe<Scalars['Float']['input']>;
};

export type FinancialInfoInput = {
  amount_financed?: InputMaybe<Scalars['Float']['input']>;
  bank?: InputMaybe<Scalars['String']['input']>;
  bank_fees?: InputMaybe<Scalars['Float']['input']>;
  base_tax_amount?: InputMaybe<Scalars['Float']['input']>;
  buy_rate?: InputMaybe<Scalars['Float']['input']>;
  buyer_not_lessee?: InputMaybe<Scalars['Boolean']['input']>;
  closer_commission?: InputMaybe<Scalars['Float']['input']>;
  com_rate_markup?: InputMaybe<Scalars['Float']['input']>;
  commissionable_profit?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  credit_card_payment_amount_limit?: InputMaybe<Scalars['Float']['input']>;
  days_to_payment?: InputMaybe<Scalars['Int']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  doc_fee?: InputMaybe<Scalars['Float']['input']>;
  down_payment_status?: InputMaybe<DownPaymentStatus>;
  fee_processor?: InputMaybe<Scalars['String']['input']>;
  first_payment_date?: InputMaybe<Scalars['Date']['input']>;
  gap_cost?: InputMaybe<Scalars['Float']['input']>;
  gap_price?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  money_down?: InputMaybe<Scalars['Float']['input']>;
  needs_temporary_registration_tags?: InputMaybe<Scalars['Boolean']['input']>;
  new_lienholder?: InputMaybe<BankInput>;
  new_registration_fee?: InputMaybe<Scalars['Float']['input']>;
  option_type?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<Scalars['Float']['input']>;
  pen_gap_form_id?: InputMaybe<Scalars['Int']['input']>;
  pen_gap_rate_id?: InputMaybe<Scalars['Int']['input']>;
  pen_gap_session_id?: InputMaybe<Scalars['String']['input']>;
  pen_vsc_form_id?: InputMaybe<Scalars['Int']['input']>;
  pen_vsc_rate_id?: InputMaybe<Scalars['Int']['input']>;
  pen_vsc_session_id?: InputMaybe<Scalars['String']['input']>;
  plate_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  processor?: InputMaybe<Scalars['String']['input']>;
  profit?: InputMaybe<Scalars['Float']['input']>;
  quick_notes?: InputMaybe<Scalars['String']['input']>;
  registration_transfer_fee?: InputMaybe<Scalars['Float']['input']>;
  reserve?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_rate?: InputMaybe<Scalars['Float']['input']>;
  selected_credit_decision_id?: InputMaybe<Scalars['ID']['input']>;
  sell_rate?: InputMaybe<Scalars['Float']['input']>;
  setter_commission?: InputMaybe<Scalars['Float']['input']>;
  tax_processor?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
  title_fee?: InputMaybe<Scalars['Float']['input']>;
  title_registration_option?: InputMaybe<TitleRegistrationOption>;
  tt_transaction_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_entered_reserve?: InputMaybe<Scalars['Float']['input']>;
  vsc_cost?: InputMaybe<Scalars['Float']['input']>;
  vsc_price?: InputMaybe<Scalars['Float']['input']>;
  vsc_term?: InputMaybe<Scalars['String']['input']>;
  vsc_type?: InputMaybe<Scalars['String']['input']>;
  warranty_tax_amount?: InputMaybe<Scalars['Float']['input']>;
};

export type FinancialInfoPaymentEstimate = {
  __typename?: 'financialInfoPaymentEstimate';
  avg_payment?: Maybe<Scalars['Float']['output']>;
  max_payment?: Maybe<Scalars['Float']['output']>;
  min_payment?: Maybe<Scalars['Float']['output']>;
};

export type Financing = {
  __typename?: 'financing';
  annualPercentageRate?: Maybe<Scalars['Float']['output']>;
  downPaymentAmount?: Maybe<Scalars['Float']['output']>;
  finalAmount?: Maybe<Scalars['Float']['output']>;
  term?: Maybe<Scalars['Int']['output']>;
};

export type FinixTransfer = {
  __typename?: 'finixTransfer';
  amount?: Maybe<Scalars['Float']['output']>;
  cardBrand?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<CardType>;
  failureCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maskedNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<PaymentState>;
};

export type FlowStatus = {
  __typename?: 'flowStatus';
  car?: Maybe<Car>;
  customerId?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['String']['output']>;
  guid?: Maybe<Scalars['String']['output']>;
  inFlow?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FollowUp = {
  __typename?: 'followUp';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  creator_id?: Maybe<Scalars['ID']['output']>;
  date_utc?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  status?: Maybe<FollowUpStatusEnum>;
  type?: Maybe<FollowUpTypeEnum>;
  tz?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type FollowUpInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creator_id?: InputMaybe<Scalars['ID']['input']>;
  date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<FollowUpStatusEnum>;
  type?: InputMaybe<FollowUpTypeEnum>;
  tz?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum FollowUpStatusEnum {
  Completed = 'completed',
  Deleted = 'deleted',
  Due = 'due',
  Scheduled = 'scheduled',
}

export enum FollowUpTypeEnum {
  Appointment = 'appointment',
  Reminder = 'reminder',
}

export type ForceTitleOnlyHelperLink = {
  __typename?: 'forceTitleOnlyHelperLink';
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type GetAvailableUsers = {
  __typename?: 'getAvailableUsers';
  auto_assign_deals?: Maybe<Scalars['Boolean']['output']>;
  call_status?: Maybe<Scalars['String']['output']>;
  can_claim_as_closer?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  in_call?: Maybe<Scalars['String']['output']>;
  location?: Maybe<LocationType>;
  max_auto_assign_deals?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  on_vacation?: Maybe<Scalars['Boolean']['output']>;
  overnight_deals?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  recruiter_id?: Maybe<Scalars['String']['output']>;
  twilio_number?: Maybe<Scalars['String']['output']>;
};

export type GetPackageOptions = {
  __typename?: 'getPackageOptions';
  gapMonthlyCost?: Maybe<Scalars['Float']['output']>;
  isGapOffered?: Maybe<Scalars['Boolean']['output']>;
  isVscOffered?: Maybe<Scalars['Boolean']['output']>;
  vscPackageOptions?: Maybe<Vsc>;
};

export type GetWaitingCalls = {
  __typename?: 'getWaitingCalls';
  data?: Maybe<Scalars['JSONObject']['output']>;
};

export enum ImportType {
  AcquisitionAutoImport = 'ACQUISITION_AUTO_IMPORT',
  AcquisitionFlowFinished = 'ACQUISITION_FLOW_FINISHED',
  Affiliate = 'AFFILIATE',
  AffiliateInactivityImport = 'AFFILIATE_INACTIVITY_IMPORT',
  AutoStructureConditioned = 'AUTO_STRUCTURE_CONDITIONED',
  AutoStructureFailure = 'AUTO_STRUCTURE_FAILURE',
  AutoStructureSuccessImmediateImport = 'AUTO_STRUCTURE_SUCCESS_IMMEDIATE_IMPORT',
  AutoStructureTimeout = 'AUTO_STRUCTURE_TIMEOUT',
  DocumentsPageFinished = 'DOCUMENTS_PAGE_FINISHED',
  FrozenOrLockedCredit = 'FROZEN_OR_LOCKED_CREDIT',
  HighLtvRatioAutoImport = 'HIGH_LTV_RATIO_AUTO_IMPORT',
  InactivityImport = 'INACTIVITY_IMPORT',
  ManualImportPostHardPull = 'MANUAL_IMPORT_POST_HARD_PULL',
  ManualImportPostSoftPull = 'MANUAL_IMPORT_POST_SOFT_PULL',
  ManualImportPreSoftPull = 'MANUAL_IMPORT_PRE_SOFT_PULL',
  MarketingNurture = 'MARKETING_NURTURE',
  MarketingNurturePaymentCalculator = 'MARKETING_NURTURE_PAYMENT_CALCULATOR',
  NoKbbValuesFailure = 'NO_KBB_VALUES_FAILURE',
  OtherLienholder = 'OTHER_LIENHOLDER',
  PayoffRequestFailure = 'PAYOFF_REQUEST_FAILURE',
  PostHardPullAutoImport = 'POST_HARD_PULL_AUTO_IMPORT',
  SoftPullFailure = 'SOFT_PULL_FAILURE',
  SoftPullFailureAutoImport = 'SOFT_PULL_FAILURE_AUTO_IMPORT',
  SoftPullSuccessAutoImport = 'SOFT_PULL_SUCCESS_AUTO_IMPORT',
}

export enum InboxFilterByEnum {
  All = 'all',
  AllSalesPods = 'all_sales_pods',
  AllTitlePods = 'all_title_pods',
  MyLanes = 'my_lanes',
  MyTeams = 'my_teams',
  SalesPod = 'sales_pod',
  TitlePod = 'title_pod',
  YourMessages = 'your_messages',
}

export type InboxMessagesType = {
  __typename?: 'inboxMessagesType';
  messages?: Maybe<Array<Maybe<Message>>>;
  totalRecords?: Maybe<Scalars['Int']['output']>;
};

export enum InboxOrderByEnum {
  DateNewest = 'date_newest',
  DateOldest = 'date_oldest',
  Deal = 'deal',
}

export enum IncomeIntervalEnumType {
  Biweekly = 'biweekly',
  Monthly = 'monthly',
  Weekly = 'weekly',
  Yearly = 'yearly',
}

export enum InteriorEnumType {
  EntertainmentConsoleDamage = 'entertainment_console_damage',
  RipsOrTears = 'rips_or_tears',
  SmellsOrOdors = 'smells_or_odors',
  Stains = 'stains',
}

export type JdpVehicleAccessories = {
  __typename?: 'jdpVehicleAccessories';
  acccode?: Maybe<Scalars['String']['output']>;
  accdesc?: Maybe<Scalars['String']['output']>;
  accessorycategory?: Maybe<Scalars['String']['output']>;
  excludes?: Maybe<Scalars['String']['output']>;
  includes?: Maybe<Scalars['String']['output']>;
  isadded?: Maybe<Scalars['Int']['output']>;
  isincluded?: Maybe<Scalars['Int']['output']>;
  loan?: Maybe<Scalars['Int']['output']>;
  retail?: Maybe<Scalars['Int']['output']>;
  tradein?: Maybe<Scalars['Int']['output']>;
};

export type JdpVehicleAccessoriesInput = {
  acccode?: InputMaybe<Scalars['String']['input']>;
  accdesc?: InputMaybe<Scalars['String']['input']>;
  accessorycategory?: InputMaybe<Scalars['String']['input']>;
  excludes?: InputMaybe<Scalars['String']['input']>;
  includes?: InputMaybe<Scalars['String']['input']>;
  isadded?: InputMaybe<Scalars['Int']['input']>;
  isincluded?: InputMaybe<Scalars['Int']['input']>;
  loan?: InputMaybe<Scalars['Int']['input']>;
  retail?: InputMaybe<Scalars['Int']['input']>;
  tradein?: InputMaybe<Scalars['Int']['input']>;
};

export type JdpVehicleAccessoriesObject = {
  __typename?: 'jdpVehicleAccessoriesObject';
  vehicle_accessories?: Maybe<Array<Maybe<JdpVehicleAccessories>>>;
};

export type JdpVehicleAccessoriesObjectInput = {
  vehicle_accessories?: InputMaybe<Array<InputMaybe<JdpVehicleAccessoriesInput>>>;
};

export type KbbConfigType = {
  action?: InputMaybe<Scalars['String']['input']>;
  optionId?: InputMaybe<Scalars['Int']['input']>;
};

export type KbbOption = {
  __typename?: 'kbbOption';
  categoryGroup?: Maybe<Scalars['String']['output']>;
  hasRelationships?: Maybe<Scalars['Boolean']['output']>;
  isConfigurable?: Maybe<Scalars['Boolean']['output']>;
  isConsumer?: Maybe<Scalars['Boolean']['output']>;
  isTypical?: Maybe<Scalars['Boolean']['output']>;
  isVinDecoded?: Maybe<Scalars['Boolean']['output']>;
  optionName?: Maybe<Scalars['String']['output']>;
  optionType?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  vehicleId?: Maybe<Scalars['Int']['output']>;
  vehicleOptionId?: Maybe<Scalars['Int']['output']>;
};

export type KbbOptionInput = {
  categoryGroup?: InputMaybe<Scalars['String']['input']>;
  hasRelationships?: InputMaybe<Scalars['Boolean']['input']>;
  isConfigurable?: InputMaybe<Scalars['Boolean']['input']>;
  isConsumer?: InputMaybe<Scalars['Boolean']['input']>;
  isTypical?: InputMaybe<Scalars['Boolean']['input']>;
  isVinDecoded?: InputMaybe<Scalars['Boolean']['input']>;
  optionName?: InputMaybe<Scalars['String']['input']>;
  optionType?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
  vehicleOptionId?: InputMaybe<Scalars['Int']['input']>;
};

export type KbbSelectedOptionsType = {
  __typename?: 'kbbSelectedOptionsType';
  id?: Maybe<Scalars['ID']['output']>;
  lendingOptionPrice?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  removed?: Maybe<Scalars['Boolean']['output']>;
  retailOptionPrice?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type KbbValuesDataType = {
  allKbbVehicleOptions?: InputMaybe<Array<InputMaybe<KbbOptionInput>>>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
  vehicleOptionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type KbbValuesResult = {
  __typename?: 'kbbValuesResult';
  kbbSelectedOptions?: Maybe<Array<Maybe<KbbSelectedOptionsType>>>;
  lending?: Maybe<Scalars['Float']['output']>;
  lendingMileageAdjustment?: Maybe<Scalars['Float']['output']>;
  lendingOptionAdjustment?: Maybe<Scalars['Float']['output']>;
  lendingOptionPrices?: Maybe<Scalars['JSON']['output']>;
  retail?: Maybe<Scalars['Float']['output']>;
  retailMileageAdjustment?: Maybe<Scalars['Float']['output']>;
  retailOptionAdjustment?: Maybe<Scalars['Float']['output']>;
  retailOptionPrices?: Maybe<Scalars['JSON']['output']>;
  valuationDate?: Maybe<Scalars['String']['output']>;
};

export type KbbVinObjectResult = {
  __typename?: 'kbbVinObjectResult';
  vinResults?: Maybe<Array<Maybe<KbbVinResult>>>;
  warning?: Maybe<Scalars['Boolean']['output']>;
};

export type KbbVinResult = {
  __typename?: 'kbbVinResult';
  makeName?: Maybe<Scalars['String']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  trimName?: Maybe<Scalars['String']['output']>;
  vehicleId?: Maybe<Scalars['Int']['output']>;
  vehicleName?: Maybe<Scalars['String']['output']>;
  vehicleOptions?: Maybe<Array<Maybe<KbbOption>>>;
  yearId?: Maybe<Scalars['String']['output']>;
};

export type KeyMetric = {
  __typename?: 'keyMetric';
  keyMetric?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Value>;
};

export type LaneKeyMetric = {
  __typename?: 'laneKeyMetric';
  day_count?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type LeaderboardSetterStats = {
  __typename?: 'leaderboardSetterStats';
  numApps?: Maybe<Scalars['Int']['output']>;
  repId?: Maybe<Scalars['String']['output']>;
  repName?: Maybe<Scalars['String']['output']>;
  teamType?: Maybe<TeamType>;
};

export type Lending_Tree_Decision_Response = {
  __typename?: 'lending_tree_decision_response';
  offers?: Maybe<Array<Maybe<Lending_Tree_Offer>>>;
  partnerDecision?: Maybe<Scalars['String']['output']>;
};

export type Lending_Tree_Offer = {
  __typename?: 'lending_tree_offer';
  amount?: Maybe<Scalars['Float']['output']>;
  apr?: Maybe<Scalars['Float']['output']>;
  monthlyPayment?: Maybe<Scalars['Float']['output']>;
  term?: Maybe<Scalars['Int']['output']>;
};

export enum MaritalStatusEnum {
  Married = 'married',
  NotMarried = 'not_married',
  Separated = 'separated',
  Widowed = 'widowed',
}

export type MarketPrequalification = {
  __typename?: 'marketPrequalification';
  creditScoreTier: Scalars['String']['output'];
  prequalId: Scalars['ID']['output'];
};

export type MediaListData = {
  __typename?: 'mediaListData';
  contentType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type MediaListDataInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type MediaListInputObj = {
  data?: InputMaybe<Array<InputMaybe<MediaListDataInput>>>;
};

export type MediaListObj = {
  __typename?: 'mediaListObj';
  data?: Maybe<Array<Maybe<MediaListData>>>;
};

export type MediaUploadUrl = {
  __typename?: 'mediaUploadUrl';
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type MergedDocuments = {
  __typename?: 'mergedDocuments';
  filename?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Message = {
  __typename?: 'message';
  body?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dateCreatedTz?: Maybe<Scalars['String']['output']>;
  deals?: Maybe<Array<Maybe<Deal>>>;
  from?: Maybe<Scalars['String']['output']>;
  fromCustomer?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  mediaListObj?: Maybe<MediaListObj>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  senderName?: Maybe<Scalars['String']['output']>;
  sid?: Maybe<Scalars['String']['output']>;
};

export type MessageData = {
  __typename?: 'messageData';
  deals?: Maybe<Array<Maybe<Deal>>>;
  message?: Maybe<Message>;
  messageDetail?: Maybe<MessageDetail>;
};

export type MessageDetail = {
  __typename?: 'messageDetail';
  dealIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  dealStates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  shortNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type MessagesDbPaginated = {
  __typename?: 'messagesDbPaginated';
  hasMoreMessages: Scalars['Boolean']['output'];
  messages: Array<Maybe<Message>>;
};

export type MessagesWithErrors = {
  __typename?: 'messagesWithErrors';
  hasErrors?: Maybe<Scalars['Boolean']['output']>;
  messages?: Maybe<Array<Maybe<Message>>>;
};

export type MetricsPages = {
  __typename?: 'metricsPages';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  iframe_url?: Maybe<Scalars['String']['output']>;
  link_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permission_name?: Maybe<Scalars['String']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type MmrResult = {
  __typename?: 'mmrResult';
  average?: Maybe<Scalars['Int']['output']>;
};

export type NewEstimateCustomerInput = {
  address?: InputMaybe<AddressInput>;
  auth0_id?: InputMaybe<Scalars['String']['input']>;
  contact_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  dashboard_visited?: InputMaybe<Scalars['Boolean']['input']>;
  dl_expiration_date?: InputMaybe<Scalars['DateTime']['input']>;
  dob?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employment?: InputMaybe<EmploymentInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  has_same_address_as_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  home_phone_number?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last_authenticated_route?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  marital_status?: InputMaybe<Scalars['String']['input']>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  name_suffix?: InputMaybe<Scalars['String']['input']>;
  no_email?: InputMaybe<Scalars['Boolean']['input']>;
  payoff_email_sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  relation_to_buyer?: InputMaybe<RelationToBuyerEnum>;
  sent_account_email?: InputMaybe<Scalars['Boolean']['input']>;
  sent_finalized_email?: InputMaybe<Scalars['DateTime']['input']>;
  sent_review_email?: InputMaybe<Scalars['DateTime']['input']>;
  sent_to_processor_email_sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  title_received_email_sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewEstimateFileInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type NewEstimateFinancialInfoInput = {
  amount_financed?: InputMaybe<Scalars['Float']['input']>;
  bank?: InputMaybe<Scalars['String']['input']>;
  bank_fees?: InputMaybe<Scalars['Float']['input']>;
  base_tax_amount?: InputMaybe<Scalars['Float']['input']>;
  buy_rate?: InputMaybe<Scalars['Float']['input']>;
  buyer_not_lessee?: InputMaybe<Scalars['Boolean']['input']>;
  closer_commission?: InputMaybe<Scalars['Float']['input']>;
  com_rate_markup?: InputMaybe<Scalars['Float']['input']>;
  commissionable_profit?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  credit_card_payment_amount_limit?: InputMaybe<Scalars['Float']['input']>;
  days_to_payment?: InputMaybe<Scalars['Int']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  doc_fee?: InputMaybe<Scalars['Float']['input']>;
  down_payment_status?: InputMaybe<DownPaymentStatus>;
  fee_processor?: InputMaybe<Scalars['String']['input']>;
  first_payment_date?: InputMaybe<Scalars['Date']['input']>;
  gap_cost?: InputMaybe<Scalars['Float']['input']>;
  gap_price?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  money_down?: InputMaybe<Scalars['Float']['input']>;
  needs_temporary_registration_tags?: InputMaybe<Scalars['Boolean']['input']>;
  new_registration_fee?: InputMaybe<Scalars['Float']['input']>;
  option_type?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<Scalars['Float']['input']>;
  pen_gap_form_id?: InputMaybe<Scalars['Int']['input']>;
  pen_gap_rate_id?: InputMaybe<Scalars['Int']['input']>;
  pen_gap_session_id?: InputMaybe<Scalars['String']['input']>;
  pen_vsc_form_id?: InputMaybe<Scalars['Int']['input']>;
  pen_vsc_rate_id?: InputMaybe<Scalars['Int']['input']>;
  pen_vsc_session_id?: InputMaybe<Scalars['String']['input']>;
  plate_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  processor?: InputMaybe<Scalars['String']['input']>;
  profit?: InputMaybe<Scalars['Float']['input']>;
  quick_notes?: InputMaybe<Scalars['String']['input']>;
  registration_transfer_fee?: InputMaybe<Scalars['Float']['input']>;
  reserve?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_rate?: InputMaybe<Scalars['Float']['input']>;
  selected_credit_decision_id?: InputMaybe<Scalars['ID']['input']>;
  sell_rate?: InputMaybe<Scalars['Float']['input']>;
  setter_commission?: InputMaybe<Scalars['Float']['input']>;
  tax_processor?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
  title_fee?: InputMaybe<Scalars['Float']['input']>;
  title_registration_option?: InputMaybe<TitleRegistrationOption>;
  tt_transaction_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_entered_reserve?: InputMaybe<Scalars['Float']['input']>;
  vsc_cost?: InputMaybe<Scalars['Float']['input']>;
  vsc_price?: InputMaybe<Scalars['Float']['input']>;
  vsc_term?: InputMaybe<Scalars['String']['input']>;
  vsc_type?: InputMaybe<Scalars['String']['input']>;
  warranty_tax_amount?: InputMaybe<Scalars['Float']['input']>;
};

export type NewEstimateUpsertInput = {
  called_by_financial_specialist?: InputMaybe<Scalars['DateTime']['input']>;
  car?: InputMaybe<CarInput>;
  closer_id?: InputMaybe<Scalars['String']['input']>;
  creation_date_tz?: InputMaybe<Scalars['String']['input']>;
  creation_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<NewEstimateCustomerInput>;
  financial_info?: InputMaybe<NewEstimateFinancialInfoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  missing_required_external_documents?: InputMaybe<Scalars['Boolean']['input']>;
  needs_electronic_signature_verification?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  referral_source?: InputMaybe<ReferralSourceInput>;
  request_boot?: InputMaybe<Scalars['Boolean']['input']>;
  setter_id?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DealType>;
};

export type Note = {
  __typename?: 'note';
  author?: Maybe<User>;
  author_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  creation_date_tz?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  note_type?: Maybe<Scalars['String']['output']>;
  notification_id?: Maybe<Scalars['ID']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  pinned?: Maybe<Scalars['Boolean']['output']>;
  recordingid?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type NoteInput = {
  author_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creation_date_tz?: InputMaybe<Scalars['String']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  note_type?: InputMaybe<Scalars['String']['input']>;
  notification_id?: InputMaybe<Scalars['ID']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  pinned?: InputMaybe<Scalars['Boolean']['input']>;
  recordingid?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Notification = {
  __typename?: 'notification';
  call_id?: Maybe<Scalars['ID']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  customer_id?: Maybe<Scalars['ID']['output']>;
  deal?: Maybe<Deal>;
  deal_contact_id?: Maybe<Scalars['ID']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  seen?: Maybe<Scalars['Boolean']['output']>;
  text_message?: Maybe<TextMessage>;
  text_message_id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationInput = {
  call_id?: InputMaybe<Scalars['ID']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customer_id?: InputMaybe<Scalars['ID']['input']>;
  deal?: InputMaybe<DealInput>;
  deal_contact_id?: InputMaybe<Scalars['ID']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  seen?: InputMaybe<Scalars['Boolean']['input']>;
  text_message?: InputMaybe<TextMessageInput>;
  text_message_id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OnMissedSipCall = {
  __typename?: 'onMissedSipCall';
  callInfo?: Maybe<Call>;
  calledUserId?: Maybe<Scalars['String']['output']>;
};

export type OnWaitingQueue = {
  __typename?: 'onWaitingQueue';
  data?: Maybe<Array<Maybe<WaitingCall>>>;
  hunt_group_slug?: Maybe<Scalars['String']['output']>;
};

export type OperatingHoursTimeSlots = {
  __typename?: 'operatingHoursTimeSlots';
  isOpen?: Maybe<Scalars['Boolean']['output']>;
  utcTimeSlots?: Maybe<Array<Maybe<Scalars['DateTime']['output']>>>;
};

export type OptEvent = {
  __typename?: 'optEvent';
  action?: Maybe<OptEventTypeEnum>;
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  opt_in_at?: Maybe<Scalars['DateTime']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  source?: Maybe<OptEventSourceEnum>;
  state?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type OptEventInput = {
  action?: InputMaybe<OptEventTypeEnum>;
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  opt_in_at?: InputMaybe<Scalars['DateTime']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<OptEventSourceEnum>;
  state?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum OptEventSourceEnum {
  Com = 'com',
  HubspotMigration = 'hubspot_migration',
  LendingTree = 'lending_tree',
  PreFlowPaymentCalculator = 'pre_flow_payment_calculator',
  ReOptDateSelected = 're_opt_date_selected',
  Verse = 'verse',
}

export enum OptEventTypeEnum {
  OptIn = 'opt_in',
  OptOut = 'opt_out',
}

export enum OverallConditionEnumType {
  LikeNew = 'like_new',
  NeedsWork = 'needs_work',
  SlightlyUsed = 'slightly_used',
}

export type PaymentEstimate = {
  __typename?: 'paymentEstimate';
  estimatedAnnualPercentageRate: Scalars['Float']['output'];
  estimatedTaxesAndFees: Scalars['Float']['output'];
  feeDetails: PaymentEstimateFeeDetails;
  paymentEstimateHigh: Scalars['Float']['output'];
  paymentEstimateLow: Scalars['Float']['output'];
  totalEstimatedLoan: Scalars['Float']['output'];
};

export type PaymentEstimateFeeDetails = {
  __typename?: 'paymentEstimateFeeDetails';
  baseTaxAmount: Scalars['Float']['output'];
  docFee: Scalars['Float']['output'];
  docFeeTaxAmount: Scalars['Float']['output'];
  registrationFee: Scalars['Float']['output'];
  titleFee: Scalars['Float']['output'];
  warrantyTaxAmount: Scalars['Float']['output'];
};

export type PaymentEstimateInput = {
  creditScore: CreditScore;
  feesInputFromDD?: InputMaybe<TtFeesInput>;
  financialInfoId?: InputMaybe<Scalars['ID']['input']>;
  fuelType?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  moneyDown: Scalars['Float']['input'];
  payoff: Scalars['Float']['input'];
  term: Scalars['Int']['input'];
  ttGetFeesSource?: InputMaybe<TtGetFeesSourceType>;
  vehicleType?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export enum PaymentInstrumentType {
  All = 'ALL',
  BankAccount = 'BANK_ACCOUNT',
  PaymentCard = 'PAYMENT_CARD',
}

export enum PaymentMethod {
  BankAccount = 'BANK_ACCOUNT',
  Credit = 'CREDIT',
  CreditDebit = 'CREDIT_DEBIT',
  Debit = 'DEBIT',
}

export type PaymentOptions = {
  __typename?: 'paymentOptions';
  gap?: Maybe<PaymentOptionsGqlTypes>;
  noProducts?: Maybe<PaymentOptionsGqlTypes>;
  vsc?: Maybe<PaymentOptionsGqlTypes>;
  vscPlusGap?: Maybe<PaymentOptionsGqlTypes>;
};

export type PaymentOptionsGqlTypes = {
  __typename?: 'paymentOptionsGQLTypes';
  amountFinanced?: Maybe<Scalars['Float']['output']>;
  buyPayment?: Maybe<Scalars['Float']['output']>;
  closerCommission?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
  reserve?: Maybe<Scalars['Float']['output']>;
  sellPayment?: Maybe<Scalars['Float']['output']>;
  setterCommission?: Maybe<Scalars['Float']['output']>;
};

export enum PaymentState {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Returned = 'RETURNED',
  Succeeded = 'SUCCEEDED',
  Unknown = 'UNKNOWN',
}

export type Payoff = {
  __typename?: 'payoff';
  account_number?: Maybe<Scalars['String']['output']>;
  cap_cost?: Maybe<Scalars['Float']['output']>;
  cap_reduction?: Maybe<Scalars['Float']['output']>;
  car_id?: Maybe<Scalars['ID']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  estimated_payoff?: Maybe<Scalars['Float']['output']>;
  good_through_date?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lease_term?: Maybe<Scalars['Float']['output']>;
  lender_name?: Maybe<Scalars['String']['output']>;
  lienholder_name?: Maybe<Scalars['String']['output']>;
  lienholder_slug?: Maybe<Scalars['String']['output']>;
  maturity_date?: Maybe<Scalars['Date']['output']>;
  money_down?: Maybe<Scalars['Float']['output']>;
  money_factor?: Maybe<Scalars['Float']['output']>;
  msrp?: Maybe<Scalars['Float']['output']>;
  next_payment_date?: Maybe<Scalars['Date']['output']>;
  old_lease_payment?: Maybe<Scalars['Float']['output']>;
  payoff_changes?: Maybe<Array<Maybe<PayoffChange>>>;
  payoff_includes_sales_tax?: Maybe<Scalars['Boolean']['output']>;
  remaining_payments?: Maybe<Scalars['Float']['output']>;
  residual_amount?: Maybe<Scalars['Float']['output']>;
  residual_percentage?: Maybe<Scalars['Float']['output']>;
  sales_price?: Maybe<Scalars['Float']['output']>;
  sales_tax_from_payoff?: Maybe<Scalars['Float']['output']>;
  sales_tax_from_payoff_entered_manually?: Maybe<Scalars['Boolean']['output']>;
  termination_fees?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user_entered_total_payoff?: Maybe<Scalars['Float']['output']>;
  vehicle_payoff?: Maybe<Scalars['Float']['output']>;
  verification_status?: Maybe<PayoffVerificationStatus>;
};

export type PayoffCountMake = {
  __typename?: 'payoffCountMake';
  total?: Maybe<Scalars['Float']['output']>;
};

export type PayoffCountModel = {
  __typename?: 'payoffCountModel';
  total?: Maybe<Scalars['Float']['output']>;
};

export type PayoffDashboard = {
  __typename?: 'payoffDashboard';
  payoffRequests?: Maybe<Array<Maybe<PayoffRequestPopulated>>>;
};

export type PayoffInput = {
  account_number?: InputMaybe<Scalars['String']['input']>;
  cap_cost?: InputMaybe<Scalars['Float']['input']>;
  cap_reduction?: InputMaybe<Scalars['Float']['input']>;
  car_id?: InputMaybe<Scalars['ID']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  estimated_payoff?: InputMaybe<Scalars['Float']['input']>;
  good_through_date?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lease_term?: InputMaybe<Scalars['Float']['input']>;
  lender_name?: InputMaybe<Scalars['String']['input']>;
  lienholder_name?: InputMaybe<Scalars['String']['input']>;
  lienholder_slug?: InputMaybe<Scalars['String']['input']>;
  maturity_date?: InputMaybe<Scalars['Date']['input']>;
  money_down?: InputMaybe<Scalars['Float']['input']>;
  money_factor?: InputMaybe<Scalars['Float']['input']>;
  msrp?: InputMaybe<Scalars['Float']['input']>;
  next_payment_date?: InputMaybe<Scalars['Date']['input']>;
  old_lease_payment?: InputMaybe<Scalars['Float']['input']>;
  payoff_changes?: InputMaybe<Array<InputMaybe<PayoffChangeInput>>>;
  payoff_includes_sales_tax?: InputMaybe<Scalars['Boolean']['input']>;
  remaining_payments?: InputMaybe<Scalars['Float']['input']>;
  residual_amount?: InputMaybe<Scalars['Float']['input']>;
  residual_percentage?: InputMaybe<Scalars['Float']['input']>;
  sales_price?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_from_payoff?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_from_payoff_entered_manually?: InputMaybe<Scalars['Boolean']['input']>;
  termination_fees?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_entered_total_payoff?: InputMaybe<Scalars['Float']['input']>;
  vehicle_payoff?: InputMaybe<Scalars['Float']['input']>;
  verification_status?: InputMaybe<PayoffVerificationStatus>;
};

export type PayoffRequest = {
  __typename?: 'payoffRequest';
  completed_date?: Maybe<Scalars['DateTime']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  fail_reason?: Maybe<Scalars['JSONObject']['output']>;
  good_through_date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  needs_payoff_documents?: Maybe<Scalars['Boolean']['output']>;
  payoff_clerk_id?: Maybe<Scalars['String']['output']>;
  payoff_documents_uploaded?: Maybe<Scalars['Boolean']['output']>;
  requestor_id?: Maybe<Scalars['String']['output']>;
  sales_tax?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<PayoffRequestStatus>;
  temporary_info_id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vehicle_payoff?: Maybe<Scalars['Float']['output']>;
};

export type PayoffRequestConfirmInput = {
  completed_date?: InputMaybe<Scalars['DateTime']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  double_tax_applied?: InputMaybe<Scalars['Boolean']['input']>;
  fail_reason?: InputMaybe<Scalars['JSONObject']['input']>;
  good_through_date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  needs_payoff_documents?: InputMaybe<Scalars['Boolean']['input']>;
  payoff_clerk_id?: InputMaybe<Scalars['String']['input']>;
  payoff_documents_uploaded?: InputMaybe<Scalars['Boolean']['input']>;
  payoff_includes_sales_tax?: InputMaybe<Scalars['Boolean']['input']>;
  requestor_id?: InputMaybe<Scalars['String']['input']>;
  sales_tax?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_from_payoff_entered_manually?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<PayoffRequestStatus>;
  temporary_info_id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_entered_total_payoff?: InputMaybe<Scalars['Float']['input']>;
  vehicle_payoff?: InputMaybe<Scalars['Float']['input']>;
};

export type PayoffRequestInput = {
  completed_date?: InputMaybe<Scalars['DateTime']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  fail_reason?: InputMaybe<Scalars['JSONObject']['input']>;
  good_through_date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  needs_payoff_documents?: InputMaybe<Scalars['Boolean']['input']>;
  payoff_clerk_id?: InputMaybe<Scalars['String']['input']>;
  payoff_documents_uploaded?: InputMaybe<Scalars['Boolean']['input']>;
  requestor_id?: InputMaybe<Scalars['String']['input']>;
  sales_tax?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<PayoffRequestStatus>;
  temporary_info_id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  vehicle_payoff?: InputMaybe<Scalars['Float']['input']>;
};

export type PayoffRequestPopulated = {
  __typename?: 'payoffRequestPopulated';
  completed_date?: Maybe<Scalars['DateTime']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  fail_reason?: Maybe<Scalars['JSONObject']['output']>;
  good_through_date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  needs_payoff_documents?: Maybe<Scalars['Boolean']['output']>;
  payoff_clerk?: Maybe<User>;
  payoff_clerk_id?: Maybe<Scalars['String']['output']>;
  payoff_documents_uploaded?: Maybe<Scalars['Boolean']['output']>;
  requestor?: Maybe<User>;
  requestor_id?: Maybe<Scalars['String']['output']>;
  sales_tax?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<PayoffRequestStatus>;
  temporary_info?: Maybe<TemporaryInfo>;
  temporary_info_id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vehicle_payoff?: Maybe<Scalars['Float']['output']>;
};

export enum PayoffRequestStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  FailureRequested = 'FAILURE_REQUESTED',
  Fetching = 'FETCHING',
  Pending = 'PENDING',
}

export type PayoffRequestStatusUpdate = {
  __typename?: 'payoffRequestStatusUpdate';
  completed_date?: Maybe<Scalars['DateTime']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  fail_reason?: Maybe<Scalars['JSONObject']['output']>;
  good_through_date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  needs_payoff_documents?: Maybe<Scalars['Boolean']['output']>;
  payoff_clerk_id?: Maybe<Scalars['String']['output']>;
  payoff_documents_uploaded?: Maybe<Scalars['Boolean']['output']>;
  requestor_id?: Maybe<Scalars['String']['output']>;
  sales_tax?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<PayoffRequestStatus>;
  temporary_info_id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vehicle_payoff?: Maybe<Scalars['Float']['output']>;
};

export type PayoffStatsMakeModel = {
  __typename?: 'payoffStatsMakeModel';
  avg_payoff?: Maybe<Scalars['Float']['output']>;
  max_payoff?: Maybe<Scalars['Float']['output']>;
  min_payoff?: Maybe<Scalars['Float']['output']>;
};

export enum PayoffVerificationStatus {
  CustomerProvided = 'customer_provided',
  Edited = 'edited',
  Verified = 'verified',
  VerifiedBySalesRep = 'verified_by_sales_rep',
}

export type Pod = {
  __typename?: 'pod';
  archived?: Maybe<Scalars['Boolean']['output']>;
  child_pods?: Maybe<Array<Maybe<BasePod>>>;
  closer_commission_rate?: Maybe<Scalars['Float']['output']>;
  closer_commission_type?: Maybe<CommissionType>;
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  hours?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  leads?: Maybe<Array<Maybe<BaseUser>>>;
  manager_commission_rate?: Maybe<Scalars['Float']['output']>;
  manager_commission_type?: Maybe<CommissionType>;
  managers?: Maybe<Array<Maybe<BaseUser>>>;
  name?: Maybe<Scalars['String']['output']>;
  parent_pod?: Maybe<BasePod>;
  parent_pod_id?: Maybe<Scalars['ID']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  pod_users?: Maybe<Array<Maybe<PodUser>>>;
  problem_solver?: Maybe<Scalars['Boolean']['output']>;
  setter_commission_rate?: Maybe<Scalars['Float']['output']>;
  setter_commission_type?: Maybe<CommissionType>;
  special_commission_rate?: Maybe<Scalars['Float']['output']>;
  special_commission_type?: Maybe<CommissionType>;
  team_type?: Maybe<TeamType>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  us_states_object?: Maybe<UsStatesObject>;
  usersWithoutTeamRole?: Maybe<Array<Maybe<BaseUser>>>;
  vsc_markup?: Maybe<Scalars['Int']['output']>;
  vsc_multiplier?: Maybe<Scalars['Float']['output']>;
};

export type PodHours = {
  __typename?: 'podHours';
  beginTime?: Maybe<Scalars['String']['output']>;
  day?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type PodInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  child_pods?: InputMaybe<Array<InputMaybe<BasePodInput>>>;
  closer_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  closer_commission_type?: InputMaybe<CommissionType>;
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hours?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  leads?: InputMaybe<Array<InputMaybe<BaseUserInput>>>;
  manager_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  manager_commission_type?: InputMaybe<CommissionType>;
  managers?: InputMaybe<Array<InputMaybe<BaseUserInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_pod?: InputMaybe<BasePodInput>;
  parent_pod_id?: InputMaybe<Scalars['ID']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pod_users?: InputMaybe<Array<InputMaybe<PodUserInput>>>;
  problem_solver?: InputMaybe<Scalars['Boolean']['input']>;
  setter_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  setter_commission_type?: InputMaybe<CommissionType>;
  special_commission_rate?: InputMaybe<Scalars['Float']['input']>;
  special_commission_type?: InputMaybe<CommissionType>;
  team_type?: InputMaybe<TeamType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  us_states_object?: InputMaybe<UsStatesInputObject>;
  users?: InputMaybe<Array<InputMaybe<BaseUserInput>>>;
  usersWithoutTeamRole?: InputMaybe<Array<InputMaybe<BaseUserInput>>>;
  vsc_markup?: InputMaybe<Scalars['Int']['input']>;
  vsc_multiplier?: InputMaybe<Scalars['Float']['input']>;
};

export type PodUser = {
  __typename?: 'podUser';
  id?: Maybe<Scalars['ID']['output']>;
  pod_id?: Maybe<Scalars['Int']['output']>;
  team_role?: Maybe<TeamRole>;
  user?: Maybe<BaseUser>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type PodUserInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  pod_id?: InputMaybe<Scalars['Int']['input']>;
  team_role?: InputMaybe<TeamRole>;
  user?: InputMaybe<BaseUserInput>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export enum PotentialRoleEnumType {
  Closer = 'closer',
  NotAssigned = 'not_assigned',
  Setter = 'setter',
  SetterLead = 'setter_lead',
}

export type PotentialUser = {
  __typename?: 'potentialUser';
  added_to_testflight: Scalars['Boolean']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  email_created: Scalars['Boolean']['output'];
  employment_status: EmploymentEnumType;
  hired: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  interview_scheduled: Scalars['Boolean']['output'];
  login_created: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  potential_role: PotentialRoleEnumType;
  reached_out: Scalars['Boolean']['output'];
  referral_code?: Maybe<Scalars['String']['output']>;
  signup_date: Scalars['DateTime']['output'];
  slack_created: Scalars['Boolean']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  zip: Scalars['Int']['output'];
};

export type PotentialUserInput = {
  email: Scalars['String']['input'];
  employment_status: EmploymentEnumType;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  referral_code?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['Int']['input'];
};

export type Prequalification = {
  __typename?: 'prequalification';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  credit_score?: Maybe<Scalars['Int']['output']>;
  credit_score_tier?: Maybe<CreditScoreTierEnumType>;
  customer_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  input?: Maybe<PrequalificationRequest>;
  ltv?: Maybe<Scalars['Float']['output']>;
  output?: Maybe<ExperianOutput>;
  payment_to_income?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Product = {
  __typename?: 'product';
  gap_selected?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  product_type?: Maybe<Scalars['String']['output']>;
  provider_dealer_code_name?: Maybe<Scalars['String']['output']>;
  provider_id?: Maybe<Scalars['String']['output']>;
  selected_package?: Maybe<Scalars['String']['output']>;
};

export type ProofOfInsurance = {
  __typename?: 'proofOfInsurance';
  company_name?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  middle_name?: Maybe<Scalars['String']['output']>;
  policy_number?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ProofOfInsuranceInput = {
  company_name?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  policy_number?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Provider = {
  __typename?: 'provider';
  name?: Maybe<Scalars['String']['output']>;
  provider_id?: Maybe<Scalars['String']['output']>;
};

export type PurchaseOrder = {
  __typename?: 'purchaseOrder';
  files?: Maybe<Array<Maybe<Files>>>;
};

export type R1CreditDecisionData = {
  __typename?: 'r1CreditDecisionData';
  applicationNumber?: Maybe<Scalars['String']['output']>;
  applicationStatus?: Maybe<CreditDecisionStatusEnum>;
  applicationStatusDetail?: Maybe<Scalars['String']['output']>;
  contractTerm?: Maybe<ContractTerm>;
  decision?: Maybe<Decision>;
};

export type Rate = {
  __typename?: 'rate';
  coverage_name?: Maybe<Scalars['String']['output']>;
  deductible?: Maybe<Deductible>;
  form_id?: Maybe<Scalars['Int']['output']>;
  form_name?: Maybe<Scalars['String']['output']>;
  form_number?: Maybe<Scalars['String']['output']>;
  product_type?: Maybe<Scalars['String']['output']>;
  session_id?: Maybe<Scalars['String']['output']>;
  term_miles?: Maybe<Scalars['String']['output']>;
  term_months?: Maybe<Scalars['String']['output']>;
};

export type RateInput = {
  apr?: InputMaybe<Scalars['Float']['input']>;
  financeTerms?: InputMaybe<Scalars['Int']['input']>;
  financeTermsMileage?: InputMaybe<Scalars['Int']['input']>;
  financedAmount?: InputMaybe<Scalars['Float']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  msrp?: InputMaybe<Scalars['Float']['input']>;
  purchasePrice?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type ReferralSource = {
  __typename?: 'referralSource';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  other_source_description?: Maybe<Scalars['String']['output']>;
  source_name?: Maybe<ReferralSourceEnum>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ReferralSourceInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  other_source_description?: InputMaybe<Scalars['String']['input']>;
  source_name?: InputMaybe<ReferralSourceEnum>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum RelationToBuyerEnum {
  Child = 'child',
  Other = 'other',
  Parent = 'parent',
  ResidesWith = 'resides_with',
  Spouse = 'spouse',
}

export type ReserveStructure = {
  __typename?: 'reserveStructure';
  bank_id?: Maybe<Scalars['ID']['output']>;
  flat_percentage?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  rate_adj_max?: Maybe<Scalars['Float']['output']>;
  rate_adj_min?: Maybe<Scalars['Float']['output']>;
  term_max?: Maybe<Scalars['Int']['output']>;
  term_min?: Maybe<Scalars['Int']['output']>;
};

export type ReserveStructureInput = {
  bank_id?: InputMaybe<Scalars['ID']['input']>;
  flat_percentage?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  rate_adj_max?: InputMaybe<Scalars['Float']['input']>;
  rate_adj_min?: InputMaybe<Scalars['Float']['input']>;
  term_max?: InputMaybe<Scalars['Int']['input']>;
  term_min?: InputMaybe<Scalars['Int']['input']>;
};

export enum ResidenceEnumType {
  Buying = 'buying',
  Family = 'family',
  Own = 'own',
  Rent = 'rent',
}

export type ResultAddParticipant = {
  __typename?: 'resultAddParticipant';
  call_already_answered?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Review = {
  __typename?: 'review';
  com_visible?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  review?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ReviewInput = {
  com_visible?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customer_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  review?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SigningAuditDocumentViewedEvent = {
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  document_id?: InputMaybe<Scalars['String']['input']>;
  document_name?: InputMaybe<Scalars['String']['input']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SigningError = {
  __typename?: 'signingError';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deal_id?: Maybe<Scalars['ID']['output']>;
  error_detail?: Maybe<Scalars['String']['output']>;
  error_message?: Maybe<Scalars['String']['output']>;
  error_type?: Maybe<SigningErrorEnum>;
  id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type SigningErrorInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  error_detail?: InputMaybe<Scalars['String']['input']>;
  error_message?: InputMaybe<Scalars['String']['input']>;
  error_type?: InputMaybe<SigningErrorEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SsnLog = {
  __typename?: 'ssnLog';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  customer_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  temp_info_id?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type SsnLogInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customer_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  temp_info_id?: InputMaybe<Scalars['ID']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type Surcharge = {
  __typename?: 'surcharge';
  car_status?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

export enum TeamRole {
  Lead = 'lead',
  Manager = 'manager',
}

export enum TeamType {
  D2d = 'd2d',
  Inbound = 'inbound',
  Outbound = 'outbound',
  Region = 'region',
  Titling = 'titling',
}

export type TemporaryData = {
  __typename?: 'temporaryData';
  address_line?: Maybe<Scalars['String']['output']>;
  address_line_2?: Maybe<Scalars['String']['output']>;
  affiliate_external_id?: Maybe<Scalars['String']['output']>;
  book_value?: Maybe<Scalars['Float']['output']>;
  cash_to_customer?: Maybe<Scalars['Float']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cobuyer_address_line?: Maybe<Scalars['String']['output']>;
  cobuyer_address_line_2?: Maybe<Scalars['String']['output']>;
  cobuyer_city?: Maybe<Scalars['String']['output']>;
  cobuyer_county?: Maybe<Scalars['String']['output']>;
  cobuyer_dob?: Maybe<Scalars['DateTime']['output']>;
  cobuyer_email?: Maybe<Scalars['String']['output']>;
  cobuyer_employer_name?: Maybe<Scalars['String']['output']>;
  cobuyer_employer_phone_number?: Maybe<Scalars['String']['output']>;
  cobuyer_employment_status?: Maybe<Scalars['String']['output']>;
  cobuyer_first_name?: Maybe<Scalars['String']['output']>;
  cobuyer_job_title?: Maybe<Scalars['String']['output']>;
  cobuyer_last_name?: Maybe<Scalars['String']['output']>;
  cobuyer_marital_status?: Maybe<MaritalStatusEnum>;
  cobuyer_months_at_job?: Maybe<Scalars['Int']['output']>;
  cobuyer_pay_frequency?: Maybe<Scalars['String']['output']>;
  cobuyer_phone_number?: Maybe<Scalars['String']['output']>;
  cobuyer_prequalification_errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  cobuyer_previous_address_line?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_address_line_2?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_city?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_county?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_employer_name?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_employer_phone_number?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_job_title?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_months_at_job?: Maybe<Scalars['Int']['output']>;
  cobuyer_previous_state?: Maybe<Scalars['String']['output']>;
  cobuyer_previous_years_at_job?: Maybe<Scalars['Int']['output']>;
  cobuyer_previous_zip?: Maybe<Scalars['String']['output']>;
  cobuyer_primary_residence_monthly_payment?: Maybe<Scalars['Float']['output']>;
  cobuyer_primary_residence_months?: Maybe<Scalars['Int']['output']>;
  cobuyer_primary_residence_type?: Maybe<Scalars['String']['output']>;
  cobuyer_primary_residence_years?: Maybe<Scalars['Int']['output']>;
  cobuyer_relation_to_buyer?: Maybe<RelationToBuyerEnum>;
  cobuyer_salary?: Maybe<Scalars['Float']['output']>;
  cobuyer_secondary_residence_months?: Maybe<Scalars['Int']['output']>;
  cobuyer_secondary_residence_years?: Maybe<Scalars['Int']['output']>;
  cobuyer_state?: Maybe<Scalars['String']['output']>;
  cobuyer_years_at_job?: Maybe<Scalars['Int']['output']>;
  cobuyer_zip?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  condition_report?: Maybe<Scalars['JSON']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  credit_score?: Maybe<Scalars['Int']['output']>;
  current_accordion_panel?: Maybe<Scalars['String']['output']>;
  device_type?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['DateTime']['output']>;
  doc_fee?: Maybe<Scalars['Float']['output']>;
  down_payment?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employer_name?: Maybe<Scalars['String']['output']>;
  employer_phone_number?: Maybe<Scalars['String']['output']>;
  employment_status?: Maybe<Scalars['String']['output']>;
  estimated_dealer_fees?: Maybe<Scalars['Float']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  fuel_type?: Maybe<Scalars['String']['output']>;
  good_through_date?: Maybe<Scalars['DateTime']['output']>;
  has_full_ssn?: Maybe<Scalars['Boolean']['output']>;
  has_same_address_as_cobuyer?: Maybe<Scalars['Boolean']['output']>;
  high_ltv_ratio?: Maybe<Scalars['Boolean']['output']>;
  include_cobuyer?: Maybe<Scalars['Boolean']['output']>;
  is_hard_pull?: Maybe<Scalars['Boolean']['output']>;
  job_title?: Maybe<Scalars['String']['output']>;
  kbb_all_options?: Maybe<Scalars['JSON']['output']>;
  kbb_lending_mileage_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_lending_option_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_retail_mileage_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_retail_option_adjustment?: Maybe<Scalars['Float']['output']>;
  kbb_selected_options?: Maybe<Scalars['JSON']['output']>;
  kbb_trim_name?: Maybe<Scalars['String']['output']>;
  kbb_valuation_date?: Maybe<Scalars['String']['output']>;
  kbb_vehicle_id?: Maybe<Scalars['Int']['output']>;
  kbb_vehicle_name?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  last_url?: Maybe<Scalars['String']['output']>;
  lh_account_number?: Maybe<Scalars['String']['output']>;
  license_plate_number?: Maybe<Scalars['String']['output']>;
  license_plate_state?: Maybe<Scalars['String']['output']>;
  lienholder?: Maybe<Scalars['String']['output']>;
  lienholderLogo?: Maybe<Scalars['String']['output']>;
  lienholder_slug?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  manual_entry?: Maybe<Scalars['Boolean']['output']>;
  marital_status?: Maybe<MaritalStatusEnum>;
  middle_name?: Maybe<Scalars['String']['output']>;
  mileage?: Maybe<Scalars['Int']['output']>;
  mmr_value?: Maybe<Scalars['Float']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  months_at_job?: Maybe<Scalars['Int']['output']>;
  moved_states?: Maybe<Scalars['Boolean']['output']>;
  odometer_status?: Maybe<OdometerStatus>;
  old_lease_payment?: Maybe<Scalars['Float']['output']>;
  other_lienholder?: Maybe<Scalars['String']['output']>;
  pay_frequency?: Maybe<Scalars['String']['output']>;
  payment_estimate_high?: Maybe<Scalars['Float']['output']>;
  payment_estimate_low?: Maybe<Scalars['Float']['output']>;
  payoff_includes_sales_tax?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  prequal_id?: Maybe<Scalars['ID']['output']>;
  prequalification_errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  prequalification_success?: Maybe<Scalars['Boolean']['output']>;
  previous_address_line?: Maybe<Scalars['String']['output']>;
  previous_address_line_2?: Maybe<Scalars['String']['output']>;
  previous_city?: Maybe<Scalars['String']['output']>;
  previous_county?: Maybe<Scalars['String']['output']>;
  previous_employer_name?: Maybe<Scalars['String']['output']>;
  previous_employer_phone_number?: Maybe<Scalars['String']['output']>;
  previous_job_title?: Maybe<Scalars['String']['output']>;
  previous_months_at_job?: Maybe<Scalars['Int']['output']>;
  previous_state?: Maybe<Scalars['String']['output']>;
  previous_years_at_job?: Maybe<Scalars['Int']['output']>;
  previous_zip?: Maybe<Scalars['String']['output']>;
  primary_residence_monthly_payment?: Maybe<Scalars['Float']['output']>;
  primary_residence_months?: Maybe<Scalars['Int']['output']>;
  primary_residence_type?: Maybe<Scalars['String']['output']>;
  primary_residence_years?: Maybe<Scalars['Int']['output']>;
  query_params?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  retail_book_value?: Maybe<Scalars['Float']['output']>;
  salary?: Maybe<Scalars['Float']['output']>;
  sales_tax_from_payoff?: Maybe<Scalars['Float']['output']>;
  sales_tax_from_payoff_entered_manually?: Maybe<Scalars['Boolean']['output']>;
  scheduled_call_datetime?: Maybe<Scalars['DateTime']['output']>;
  scheduled_call_datetime_tz?: Maybe<Scalars['String']['output']>;
  secondary_residence_months?: Maybe<Scalars['Int']['output']>;
  secondary_residence_years?: Maybe<Scalars['Int']['output']>;
  shopping_cart?: Maybe<TemporaryShoppingCartData>;
  ssn?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  taxes?: Maybe<Scalars['Decimal']['output']>;
  taxes_fees?: Maybe<Scalars['Float']['output']>;
  term?: Maybe<Scalars['Int']['output']>;
  title_only?: Maybe<Scalars['Boolean']['output']>;
  uploaded_files?: Maybe<Scalars['JSON']['output']>;
  user_entered_total_payoff?: Maybe<Scalars['Float']['output']>;
  vehicle_offer?: Maybe<Scalars['Float']['output']>;
  vehicle_payoff?: Maybe<Scalars['Float']['output']>;
  vehicle_registration_fee?: Maybe<Scalars['Float']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
  years_at_job?: Maybe<Scalars['Int']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type TemporaryDataInput = {
  address_line?: InputMaybe<Scalars['String']['input']>;
  address_line_2?: InputMaybe<Scalars['String']['input']>;
  affiliate_external_id?: InputMaybe<Scalars['String']['input']>;
  book_value?: InputMaybe<Scalars['Float']['input']>;
  cash_to_customer?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cobuyer_address_line?: InputMaybe<Scalars['String']['input']>;
  cobuyer_address_line_2?: InputMaybe<Scalars['String']['input']>;
  cobuyer_city?: InputMaybe<Scalars['String']['input']>;
  cobuyer_county?: InputMaybe<Scalars['String']['input']>;
  cobuyer_dob?: InputMaybe<Scalars['DateTime']['input']>;
  cobuyer_email?: InputMaybe<Scalars['String']['input']>;
  cobuyer_employer_name?: InputMaybe<Scalars['String']['input']>;
  cobuyer_employer_phone_number?: InputMaybe<Scalars['String']['input']>;
  cobuyer_employment_status?: InputMaybe<Scalars['String']['input']>;
  cobuyer_first_name?: InputMaybe<Scalars['String']['input']>;
  cobuyer_job_title?: InputMaybe<Scalars['String']['input']>;
  cobuyer_last_name?: InputMaybe<Scalars['String']['input']>;
  cobuyer_marital_status?: InputMaybe<MaritalStatusEnum>;
  cobuyer_months_at_job?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_pay_frequency?: InputMaybe<Scalars['String']['input']>;
  cobuyer_phone_number?: InputMaybe<Scalars['String']['input']>;
  cobuyer_prequalification_errors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cobuyer_previous_address_line?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_address_line_2?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_city?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_county?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_employer_name?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_employer_phone_number?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_job_title?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_months_at_job?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_previous_state?: InputMaybe<Scalars['String']['input']>;
  cobuyer_previous_years_at_job?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_previous_zip?: InputMaybe<Scalars['String']['input']>;
  cobuyer_primary_residence_monthly_payment?: InputMaybe<Scalars['Float']['input']>;
  cobuyer_primary_residence_months?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_primary_residence_type?: InputMaybe<Scalars['String']['input']>;
  cobuyer_primary_residence_years?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_relation_to_buyer?: InputMaybe<RelationToBuyerEnum>;
  cobuyer_salary?: InputMaybe<Scalars['Float']['input']>;
  cobuyer_secondary_residence_months?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_secondary_residence_years?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_ssn?: InputMaybe<Scalars['String']['input']>;
  cobuyer_state?: InputMaybe<Scalars['String']['input']>;
  cobuyer_years_at_job?: InputMaybe<Scalars['Int']['input']>;
  cobuyer_zip?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  condition_report?: InputMaybe<Scalars['JSON']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  credit_score?: InputMaybe<Scalars['Int']['input']>;
  current_accordion_panel?: InputMaybe<Scalars['String']['input']>;
  device_type?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  doc_fee?: InputMaybe<Scalars['Float']['input']>;
  down_payment?: InputMaybe<Scalars['Float']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employer_name?: InputMaybe<Scalars['String']['input']>;
  employer_phone_number?: InputMaybe<Scalars['String']['input']>;
  employment_status?: InputMaybe<Scalars['String']['input']>;
  estimated_dealer_fees?: InputMaybe<Scalars['Float']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  fuel_type?: InputMaybe<Scalars['String']['input']>;
  good_through_date?: InputMaybe<Scalars['DateTime']['input']>;
  has_full_ssn?: InputMaybe<Scalars['Boolean']['input']>;
  has_same_address_as_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  high_ltv_ratio?: InputMaybe<Scalars['Boolean']['input']>;
  include_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
  is_hard_pull?: InputMaybe<Scalars['Boolean']['input']>;
  job_title?: InputMaybe<Scalars['String']['input']>;
  kbb_all_options?: InputMaybe<Scalars['JSON']['input']>;
  kbb_lending_mileage_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_lending_option_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_retail_mileage_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_retail_option_adjustment?: InputMaybe<Scalars['Float']['input']>;
  kbb_selected_options?: InputMaybe<Scalars['JSON']['input']>;
  kbb_trim_name?: InputMaybe<Scalars['String']['input']>;
  kbb_valuation_date?: InputMaybe<Scalars['String']['input']>;
  kbb_vehicle_id?: InputMaybe<Scalars['Int']['input']>;
  kbb_vehicle_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_url?: InputMaybe<Scalars['String']['input']>;
  lh_account_number?: InputMaybe<Scalars['String']['input']>;
  license_plate_number?: InputMaybe<Scalars['String']['input']>;
  license_plate_state?: InputMaybe<Scalars['String']['input']>;
  lienholder?: InputMaybe<Scalars['String']['input']>;
  lienholderLogo?: InputMaybe<Scalars['String']['input']>;
  lienholder_slug?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  manual_entry?: InputMaybe<Scalars['Boolean']['input']>;
  marital_status?: InputMaybe<MaritalStatusEnum>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  mmr_value?: InputMaybe<Scalars['Float']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  months_at_job?: InputMaybe<Scalars['Int']['input']>;
  moved_states?: InputMaybe<Scalars['Boolean']['input']>;
  odometer_status?: InputMaybe<OdometerStatus>;
  old_lease_payment?: InputMaybe<Scalars['Float']['input']>;
  other_lienholder?: InputMaybe<Scalars['String']['input']>;
  pay_frequency?: InputMaybe<Scalars['String']['input']>;
  payment_estimate_high?: InputMaybe<Scalars['Float']['input']>;
  payment_estimate_low?: InputMaybe<Scalars['Float']['input']>;
  payoff_includes_sales_tax?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  prequal_id?: InputMaybe<Scalars['ID']['input']>;
  prequalification_errors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prequalification_success?: InputMaybe<Scalars['Boolean']['input']>;
  previous_address_line?: InputMaybe<Scalars['String']['input']>;
  previous_address_line_2?: InputMaybe<Scalars['String']['input']>;
  previous_city?: InputMaybe<Scalars['String']['input']>;
  previous_county?: InputMaybe<Scalars['String']['input']>;
  previous_employer_name?: InputMaybe<Scalars['String']['input']>;
  previous_employer_phone_number?: InputMaybe<Scalars['String']['input']>;
  previous_job_title?: InputMaybe<Scalars['String']['input']>;
  previous_months_at_job?: InputMaybe<Scalars['Int']['input']>;
  previous_state?: InputMaybe<Scalars['String']['input']>;
  previous_years_at_job?: InputMaybe<Scalars['Int']['input']>;
  previous_zip?: InputMaybe<Scalars['String']['input']>;
  primary_residence_monthly_payment?: InputMaybe<Scalars['Float']['input']>;
  primary_residence_months?: InputMaybe<Scalars['Int']['input']>;
  primary_residence_type?: InputMaybe<Scalars['String']['input']>;
  primary_residence_years?: InputMaybe<Scalars['Int']['input']>;
  query_params?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  retail_book_value?: InputMaybe<Scalars['Float']['input']>;
  salary?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_from_payoff?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_from_payoff_entered_manually?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled_call_datetime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduled_call_datetime_tz?: InputMaybe<Scalars['String']['input']>;
  secondary_residence_months?: InputMaybe<Scalars['Int']['input']>;
  secondary_residence_years?: InputMaybe<Scalars['Int']['input']>;
  shopping_cart?: InputMaybe<TemporaryShoppingCartDataInput>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  taxes?: InputMaybe<Scalars['Decimal']['input']>;
  taxes_fees?: InputMaybe<Scalars['Float']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
  title_only?: InputMaybe<Scalars['Boolean']['input']>;
  uploaded_files?: InputMaybe<Scalars['JSON']['input']>;
  user_entered_total_payoff?: InputMaybe<Scalars['Float']['input']>;
  vehicle_offer?: InputMaybe<Scalars['Float']['input']>;
  vehicle_payoff?: InputMaybe<Scalars['Float']['input']>;
  vehicle_registration_fee?: InputMaybe<Scalars['Float']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
  years_at_job?: InputMaybe<Scalars['Int']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type TemporaryDataVip = {
  __typename?: 'temporaryDataVip';
  first_name?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type TemporaryInfo = {
  __typename?: 'temporaryInfo';
  consents?: Maybe<Array<Maybe<Consent>>>;
  data: TemporaryData;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<TemporaryInfoStatusEnumType>;
};

export type TemporaryInfoInput = {
  data: TemporaryDataInput;
  id?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<TemporaryInfoStatusEnumType>;
};

export enum TemporaryInfoStatusEnumType {
  Duplicate = 'duplicate',
  Imported = 'imported',
}

export type TemporaryInfoVip = {
  __typename?: 'temporaryInfoVip';
  data: TemporaryDataVip;
  deal_id?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<TemporaryInfoStatusEnumType>;
};

export type TemporaryShoppingCartData = {
  __typename?: 'temporaryShoppingCartData';
  buyout?: Maybe<Scalars['Float']['output']>;
  credit_score?: Maybe<Scalars['Int']['output']>;
  down_payment?: Maybe<Scalars['Float']['output']>;
  estimated_fees?: Maybe<Scalars['Float']['output']>;
  loan_term?: Maybe<Scalars['Int']['output']>;
  payment_estimate_high?: Maybe<Scalars['Float']['output']>;
  payment_estimate_low?: Maybe<Scalars['Float']['output']>;
};

export type TemporaryShoppingCartDataInput = {
  buyout?: InputMaybe<Scalars['Float']['input']>;
  credit_score?: InputMaybe<Scalars['Int']['input']>;
  down_payment?: InputMaybe<Scalars['Float']['input']>;
  estimated_fees?: InputMaybe<Scalars['Float']['input']>;
  loan_term?: InputMaybe<Scalars['Int']['input']>;
  payment_estimate_high?: InputMaybe<Scalars['Float']['input']>;
  payment_estimate_low?: InputMaybe<Scalars['Float']['input']>;
};

export type TextMessage = {
  __typename?: 'textMessage';
  account_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  date_created?: Maybe<Scalars['String']['output']>;
  date_sent?: Maybe<Scalars['String']['output']>;
  date_updated?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  media?: Maybe<Scalars['String']['output']>;
  media_list_object?: Maybe<MediaListObj>;
  message?: Maybe<Scalars['String']['output']>;
  message_id?: Maybe<Scalars['String']['output']>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  recipient_phone_number?: Maybe<Scalars['String']['output']>;
  resolved_media?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type TextMessageInput = {
  account_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_created?: InputMaybe<Scalars['String']['input']>;
  date_sent?: InputMaybe<Scalars['String']['input']>;
  date_updated?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  media?: InputMaybe<Scalars['String']['input']>;
  media_list_object?: InputMaybe<MediaListInputObj>;
  message?: InputMaybe<Scalars['String']['input']>;
  message_id?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<Array<InputMaybe<NotificationInput>>>;
  recipient_phone_number?: InputMaybe<Scalars['String']['input']>;
  resolved_media?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserInput>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export enum TiresEnumType {
  Fine = 'fine',
  NeedReplacing = 'need_replacing',
  New = 'new',
}

export enum TransformType {
  AcquisitionApproved = 'ACQUISITION_APPROVED',
  AcquisitionNotApproved = 'ACQUISITION_NOT_APPROVED',
  Affiliate = 'AFFILIATE',
  AffiliateInactivityImport = 'AFFILIATE_INACTIVITY_IMPORT',
  AutoStructureBegin = 'AUTO_STRUCTURE_BEGIN',
  CallBackPayoff = 'CALL_BACK_PAYOFF',
  FrozenOrLockedCredit = 'FROZEN_OR_LOCKED_CREDIT',
  HighLtvRatioAutoImport = 'HIGH_LTV_RATIO_AUTO_IMPORT',
  InactivityImport = 'INACTIVITY_IMPORT',
  ManualImportPostSoftPull = 'MANUAL_IMPORT_POST_SOFT_PULL',
  ManualImportPreSoftPull = 'MANUAL_IMPORT_PRE_SOFT_PULL',
  MarketingNurture = 'MARKETING_NURTURE',
  MarketingNurturePaymentCalculator = 'MARKETING_NURTURE_PAYMENT_CALCULATOR',
  NoKbbFailure = 'NO_KBB_FAILURE',
  OtherLienholder = 'OTHER_LIENHOLDER',
  PayoffRequestFailure = 'PAYOFF_REQUEST_FAILURE',
  PayoffRequestSuccess = 'PAYOFF_REQUEST_SUCCESS',
  SoftPullFailure = 'SOFT_PULL_FAILURE',
  SoftPullFailureAutoImport = 'SOFT_PULL_FAILURE_AUTO_IMPORT',
  SoftPullSuccessAutoImport = 'SOFT_PULL_SUCCESS_AUTO_IMPORT',
}

export type TwilioNumber = {
  __typename?: 'twilioNumber';
  friendlyName?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type UpdateFinancialInfoInput = {
  amount_financed?: InputMaybe<Scalars['Float']['input']>;
  bank?: InputMaybe<Scalars['String']['input']>;
  bank_fees?: InputMaybe<Scalars['Float']['input']>;
  base_tax_amount?: InputMaybe<Scalars['Float']['input']>;
  buy_rate?: InputMaybe<Scalars['Float']['input']>;
  buyer_not_lessee?: InputMaybe<Scalars['Boolean']['input']>;
  closer_commission?: InputMaybe<Scalars['Float']['input']>;
  com_rate_markup?: InputMaybe<Scalars['Float']['input']>;
  commissionable_profit?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  credit_card_payment_amount_limit?: InputMaybe<Scalars['Float']['input']>;
  days_to_payment?: InputMaybe<Scalars['Int']['input']>;
  deal_id: Scalars['ID']['input'];
  doc_fee?: InputMaybe<Scalars['Float']['input']>;
  down_payment_status?: InputMaybe<DownPaymentStatus>;
  fee_processor?: InputMaybe<Scalars['String']['input']>;
  first_payment_date?: InputMaybe<Scalars['Date']['input']>;
  gap_cost?: InputMaybe<Scalars['Float']['input']>;
  gap_price?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  money_down?: InputMaybe<Scalars['Float']['input']>;
  needs_temporary_registration_tags?: InputMaybe<Scalars['Boolean']['input']>;
  new_lienholder?: InputMaybe<BankInput>;
  new_registration_fee?: InputMaybe<Scalars['Float']['input']>;
  option_type?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<Scalars['Float']['input']>;
  pen_gap_form_id?: InputMaybe<Scalars['Int']['input']>;
  pen_gap_rate_id?: InputMaybe<Scalars['Int']['input']>;
  pen_gap_session_id?: InputMaybe<Scalars['String']['input']>;
  pen_vsc_form_id?: InputMaybe<Scalars['Int']['input']>;
  pen_vsc_rate_id?: InputMaybe<Scalars['Int']['input']>;
  pen_vsc_session_id?: InputMaybe<Scalars['String']['input']>;
  plate_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  processor?: InputMaybe<Scalars['String']['input']>;
  profit?: InputMaybe<Scalars['Float']['input']>;
  quick_notes?: InputMaybe<Scalars['String']['input']>;
  registration_transfer_fee?: InputMaybe<Scalars['Float']['input']>;
  reserve?: InputMaybe<Scalars['Float']['input']>;
  sales_tax_rate?: InputMaybe<Scalars['Float']['input']>;
  selected_credit_decision_id?: InputMaybe<Scalars['ID']['input']>;
  sell_rate?: InputMaybe<Scalars['Float']['input']>;
  setter_commission?: InputMaybe<Scalars['Float']['input']>;
  tax_processor?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
  title_fee?: InputMaybe<Scalars['Float']['input']>;
  title_registration_option?: InputMaybe<TitleRegistrationOption>;
  tt_transaction_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_entered_reserve?: InputMaybe<Scalars['Float']['input']>;
  vsc_cost?: InputMaybe<Scalars['Float']['input']>;
  vsc_price?: InputMaybe<Scalars['Float']['input']>;
  vsc_term?: InputMaybe<Scalars['String']['input']>;
  vsc_type?: InputMaybe<Scalars['String']['input']>;
  warranty_tax_amount?: InputMaybe<Scalars['Float']['input']>;
};

export type UploadDocumentsInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UsStatesInputObject = {
  states?: InputMaybe<Array<InputMaybe<StateAbbreviation>>>;
};

export type UsStatesObject = {
  __typename?: 'usStatesObject';
  states?: Maybe<Array<Maybe<StateAbbreviation>>>;
};

export type User = {
  __typename?: 'user';
  auth0_roles?: Maybe<UserRoles>;
  auto_assign_deals?: Maybe<Scalars['Boolean']['output']>;
  call_status?: Maybe<Scalars['String']['output']>;
  can_claim_as_closer?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['String']['output']>;
  huntGroups?: Maybe<Array<Maybe<HuntGroup>>>;
  id?: Maybe<Scalars['String']['output']>;
  location?: Maybe<LocationType>;
  max_auto_assign_deals?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  on_vacation?: Maybe<Scalars['Boolean']['output']>;
  overnight_deals?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  pods?: Maybe<Array<Maybe<Pod>>>;
  recruiter_id?: Maybe<Scalars['String']['output']>;
  titlingPods?: Maybe<Array<Maybe<Pod>>>;
  twilio_number?: Maybe<Scalars['String']['output']>;
};

export type UserInput = {
  auto_assign_deals?: InputMaybe<Scalars['Boolean']['input']>;
  call_status?: InputMaybe<Scalars['String']['input']>;
  can_claim_as_closer?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hours?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationType>;
  max_auto_assign_deals?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  on_vacation?: InputMaybe<Scalars['Boolean']['input']>;
  overnight_deals?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  pods?: InputMaybe<Array<InputMaybe<PodInput>>>;
  recruiter_id?: InputMaybe<Scalars['String']['input']>;
  titlingPods?: InputMaybe<Array<InputMaybe<PodInput>>>;
  twilio_number?: InputMaybe<Scalars['String']['input']>;
};

export type Value = {
  __typename?: 'value';
  buyout?: Maybe<Scalars['Float']['output']>;
  refi?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type VisibleArticles = {
  __typename?: 'visibleArticles';
  results?: Maybe<Array<Maybe<Article>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Vsc = {
  __typename?: 'vsc';
  noProductsPmt?: Maybe<Scalars['Float']['output']>;
  startingIndex?: Maybe<Scalars['Int']['output']>;
  vscOffers?: Maybe<Array<Maybe<VscOffers>>>;
};

export type VscCoverages = {
  __typename?: 'vscCoverages';
  category?: Maybe<Scalars['String']['output']>;
  components?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type VscDetails = {
  __typename?: 'vscDetails';
  cost?: Maybe<Scalars['Float']['output']>;
  deductible?: Maybe<Scalars['Float']['output']>;
  miles?: Maybe<Scalars['Int']['output']>;
  payment?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  years?: Maybe<Scalars['Float']['output']>;
};

export type VscOffers = {
  __typename?: 'vscOffers';
  coverage?: Maybe<Array<Maybe<VscCoverages>>>;
  details?: Maybe<VscDetails>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type WaitingCall = {
  __typename?: 'waitingCall';
  conference_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['Int']['output']>;
  deal_state?: Maybe<Scalars['String']['output']>;
  deal_type?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  hunt_group_slug?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_thunder?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  processing_started_at?: Maybe<Scalars['String']['output']>;
  started_at?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  transferred_from?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type AddressDetailQueryVariables = Exact<{
  address?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddressDetailQuery = {
  __typename?: 'RootQueryType';
  addressDetail?:
    | {
        __typename?: 'address';
        city?: string | null | undefined;
        state?: string | null | undefined;
        county?: string | null | undefined;
      }
    | null
    | undefined;
};

export type ValidZipQueryVariables = Exact<{
  zipCode: Scalars['String']['input'];
}>;

export type ValidZipQuery = { __typename?: 'RootQueryType'; validZip?: boolean | null | undefined };

export type GetVisibleArticlesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetVisibleArticlesQuery = {
  __typename?: 'RootQueryType';
  visibleArticles?:
    | {
        __typename?: 'visibleArticles';
        total?: number | null | undefined;
        results?:
          | Array<
              | {
                  __typename?: 'article';
                  id?: string | null | undefined;
                  title?: string | null | undefined;
                  headline?: string | null | undefined;
                  url?: string | null | undefined;
                  created_at?: any | null | undefined;
                  thumbnail?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CheckFlowStatusQueryVariables = Exact<{
  guid?: InputMaybe<Scalars['String']['input']>;
}>;

export type CheckFlowStatusQuery = {
  __typename?: 'RootQueryType';
  checkFlowStatus?:
    | {
        __typename?: 'flowStatus';
        inFlow?: boolean | null | undefined;
        guid?: string | null | undefined;
        url?: string | null | undefined;
        dealId?: string | null | undefined;
        customerId?: string | null | undefined;
        car?:
          | {
              __typename?: 'car';
              id?: string | null | undefined;
              make?: string | null | undefined;
              model?: string | null | undefined;
              year?: string | null | undefined;
              mileage?: number | null | undefined;
              vin?: string | null | undefined;
              vehicle_type?: string | null | undefined;
              color?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BankLogoQueryVariables = Exact<{
  bank_name: Scalars['String']['input'];
}>;

export type BankLogoQuery = { __typename?: 'RootQueryType'; bankLogo?: string | null | undefined };

export type CarDistinctMakeQueryVariables = Exact<{ [key: string]: never }>;

export type CarDistinctMakeQuery = {
  __typename?: 'RootQueryType';
  carDistinctMake?:
    | Array<
        | {
            __typename?: 'carDistinctMake';
            make?: string | null | undefined;
            total?: number | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type CarDistinctModelQueryVariables = Exact<{
  make: Scalars['String']['input'];
}>;

export type CarDistinctModelQuery = {
  __typename?: 'RootQueryType';
  carDistinctModel?:
    | Array<
        { __typename?: 'carDistinctModel'; model?: string | null | undefined } | null | undefined
      >
    | null
    | undefined;
};

export type PayoffStatsMakeModelYearQueryVariables = Exact<{
  make: Scalars['String']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
  start_date: Scalars['String']['input'];
  end_date: Scalars['String']['input'];
}>;

export type PayoffStatsMakeModelYearQuery = {
  __typename?: 'RootQueryType';
  payoffStatsMakeModelYear?:
    | Array<
        | {
            __typename?: 'payoffStatsMakeModel';
            avg_payoff?: number | null | undefined;
            min_payoff?: number | null | undefined;
            max_payoff?: number | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type PayoffCountMakeQueryVariables = Exact<{
  make: Scalars['String']['input'];
}>;

export type PayoffCountMakeQuery = {
  __typename?: 'RootQueryType';
  payoffCountMake?:
    | Array<
        { __typename?: 'payoffCountMake'; total?: number | null | undefined } | null | undefined
      >
    | null
    | undefined;
};

export type PayoffCountModelQueryVariables = Exact<{
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
}>;

export type PayoffCountModelQuery = {
  __typename?: 'RootQueryType';
  payoffCountModel?:
    | Array<
        { __typename?: 'payoffCountModel'; total?: number | null | undefined } | null | undefined
      >
    | null
    | undefined;
};

export type FinancialInfoPaymentEstimateQueryVariables = Exact<{
  make: Scalars['String']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
  start_date: Scalars['String']['input'];
  end_date: Scalars['String']['input'];
}>;

export type FinancialInfoPaymentEstimateQuery = {
  __typename?: 'RootQueryType';
  financialInfoPaymentEstimate?:
    | Array<
        | {
            __typename?: 'financialInfoPaymentEstimate';
            avg_payment?: number | null | undefined;
            min_payment?: number | null | undefined;
            max_payment?: number | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type TotalCompletedDealsQueryVariables = Exact<{ [key: string]: never }>;

export type TotalCompletedDealsQuery = {
  __typename?: 'RootQueryType';
  totalCompletedDeals?: number | null | undefined;
};

export type UnlinkTemporaryInfoMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UnlinkTemporaryInfoMutation = {
  __typename?: 'RootMutationType';
  unlinkTemporaryInfo?:
    | { __typename?: 'temporaryInfo'; id?: string | null | undefined }
    | null
    | undefined;
};

export type GetMarketPrequalificationMutationVariables = Exact<{
  prequalId?: InputMaybe<Scalars['ID']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  addressLine?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMarketPrequalificationMutation = {
  __typename?: 'RootMutationType';
  getMarketPrequalification: {
    __typename?: 'marketPrequalification';
    prequalId: string;
    creditScoreTier: string;
  };
};

export type KbbVinQueryVariables = Exact<{
  vin: Scalars['String']['input'];
}>;

export type KbbVinQuery = {
  __typename?: 'RootQueryType';
  kbbVin?:
    | {
        __typename?: 'kbbVinObjectResult';
        warning?: boolean | null | undefined;
        vinResults?:
          | Array<
              | {
                  __typename?: 'kbbVinResult';
                  vehicleId?: number | null | undefined;
                  modelName?: string | null | undefined;
                  trimName?: string | null | undefined;
                  makeName?: string | null | undefined;
                  yearId?: string | null | undefined;
                  vehicleName?: string | null | undefined;
                  vehicleOptions?:
                    | Array<
                        | {
                            __typename?: 'kbbOption';
                            categoryGroup?: string | null | undefined;
                            hasRelationships?: boolean | null | undefined;
                            isConfigurable?: boolean | null | undefined;
                            isConsumer?: boolean | null | undefined;
                            isTypical?: boolean | null | undefined;
                            isVinDecoded?: boolean | null | undefined;
                            optionName?: string | null | undefined;
                            optionType?: string | null | undefined;
                            sortOrder?: number | null | undefined;
                            vehicleId?: number | null | undefined;
                            vehicleOptionId?: number | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type KbbValuesQueryVariables = Exact<{
  data: KbbValuesDataType;
}>;

export type KbbValuesQuery = {
  __typename?: 'RootQueryType';
  kbbValues?:
    | {
        __typename?: 'kbbValuesResult';
        retail?: number | null | undefined;
        retailMileageAdjustment?: number | null | undefined;
        retailOptionAdjustment?: number | null | undefined;
        retailOptionPrices?: any | null | undefined;
        lending?: number | null | undefined;
        lendingMileageAdjustment?: number | null | undefined;
        lendingOptionAdjustment?: number | null | undefined;
        lendingOptionPrices?: any | null | undefined;
        valuationDate?: string | null | undefined;
        kbbSelectedOptions?:
          | Array<
              | {
                  __typename?: 'kbbSelectedOptionsType';
                  id?: string | null | undefined;
                  name?: string | null | undefined;
                  type?: string | null | undefined;
                  removed?: boolean | null | undefined;
                  lendingOptionPrice?: number | null | undefined;
                  retailOptionPrice?: number | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CalculateOptionsQueryVariables = Exact<{
  vehicleId: Scalars['Int']['input'];
  allKbbVehicleOptions?: InputMaybe<Array<InputMaybe<KbbOptionInput>> | InputMaybe<KbbOptionInput>>;
  startingVehicleOptions?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>
  >;
  configToModify?: InputMaybe<Array<InputMaybe<KbbConfigType>> | InputMaybe<KbbConfigType>>;
  vin: Scalars['String']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
}>;

export type CalculateOptionsQuery = {
  __typename?: 'RootQueryType';
  calculateOptions?:
    | Array<
        | {
            __typename?: 'kbbOption';
            vehicleOptionId?: number | null | undefined;
            optionName?: string | null | undefined;
            optionType?: string | null | undefined;
            categoryGroup?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetOperatingHoursAndTimeSlotsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOperatingHoursAndTimeSlotsQuery = {
  __typename?: 'RootQueryType';
  getOperatingHoursAndTimeSlots?:
    | {
        __typename?: 'operatingHoursTimeSlots';
        isOpen?: boolean | null | undefined;
        utcTimeSlots?: Array<any | null | undefined> | null | undefined;
      }
    | null
    | undefined;
};

export type CreateOptEventMutationVariables = Exact<{
  event: OptEventInput;
  token?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateOptEventMutation = {
  __typename?: 'RootMutationType';
  createOptEvent?: { __typename?: 'optEvent'; id?: string | null | undefined } | null | undefined;
};

export type OptOutMutationVariables = Exact<{
  deal_id?: InputMaybe<Scalars['ID']['input']>;
  is_cobuyer?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type OptOutMutation = { __typename?: 'RootMutationType'; optOut?: any | null | undefined };

export type PaymentEstimateQueryVariables = Exact<{
  data?: InputMaybe<PaymentEstimateInput>;
}>;

export type PaymentEstimateQuery = {
  __typename?: 'RootQueryType';
  paymentEstimate?:
    | {
        __typename?: 'paymentEstimate';
        paymentEstimateLow: number;
        paymentEstimateHigh: number;
        estimatedTaxesAndFees: number;
        totalEstimatedLoan: number;
        estimatedAnnualPercentageRate: number;
        feeDetails: {
          __typename?: 'paymentEstimateFeeDetails';
          baseTaxAmount: number;
          warrantyTaxAmount: number;
          docFeeTaxAmount: number;
          docFee: number;
          titleFee: number;
          registrationFee: number;
        };
      }
    | null
    | undefined;
};

export type SendPaymentEstimateEmailAndTextMutationVariables = Exact<{
  data: PaymentEstimateInput;
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type SendPaymentEstimateEmailAndTextMutation = {
  __typename?: 'RootMutationType';
  sendPaymentEstimateEmailAndText?: boolean | null | undefined;
};

export type TemporaryInfoUpdateMutationVariables = Exact<{
  info?: InputMaybe<TemporaryInfoInput>;
}>;

export type TemporaryInfoUpdateMutation = {
  __typename?: 'RootMutationType';
  temporaryInfoUpdate?:
    | {
        __typename?: 'temporaryInfo';
        id?: string | null | undefined;
        data: { __typename?: 'temporaryData'; phone_number?: string | null | undefined };
      }
    | null
    | undefined;
};

export type TemporaryInfoImportMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  transformType: TransformType;
  importType?: InputMaybe<ImportType>;
}>;

export type TemporaryInfoImportMutation = {
  __typename?: 'RootMutationType';
  temporaryInfoImport?:
    | {
        __typename?: 'deal';
        id?: string | null | undefined;
        customer?: { __typename?: 'customer'; id?: string | null | undefined } | null | undefined;
      }
    | null
    | undefined;
};

export type GetPodHoursQueryVariables = Exact<{ [key: string]: never }>;

export type GetPodHoursQuery = {
  __typename?: 'RootQueryType';
  podHours?:
    | Array<
        | {
            __typename?: 'podHours';
            id?: number | null | undefined;
            label?: string | null | undefined;
            day?: string | null | undefined;
            beginTime?: string | null | undefined;
            endTime?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetVisibleReviewsQueryVariables = Exact<{ [key: string]: never }>;

export type GetVisibleReviewsQuery = {
  __typename?: 'RootQueryType';
  visibleReviews?:
    | Array<
        | {
            __typename?: 'review';
            id?: string | null | undefined;
            customer_name?: string | null | undefined;
            review?: string | null | undefined;
            created_at?: any | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type CreateTemporaryInfoMutationVariables = Exact<{
  vin?: InputMaybe<Scalars['String']['input']>;
  license_plate_number?: InputMaybe<Scalars['String']['input']>;
  license_plate_state?: InputMaybe<Scalars['String']['input']>;
  device_type?: InputMaybe<Scalars['String']['input']>;
  extra_info?: InputMaybe<ExtraTempInfoInputType>;
  query_params?: InputMaybe<Scalars['String']['input']>;
  ignore_existing_deal?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CreateTemporaryInfoMutation = {
  __typename?: 'RootMutationType';
  createTemporaryInfo?:
    | {
        __typename?: 'temporaryInfo';
        id?: string | null | undefined;
        data: {
          __typename?: 'temporaryData';
          vin?: string | null | undefined;
          year?: string | null | undefined;
          make?: string | null | undefined;
          model?: string | null | undefined;
          fuel_type?: string | null | undefined;
          vehicle_type?: string | null | undefined;
          phone_number?: string | null | undefined;
          email?: string | null | undefined;
          first_name?: string | null | undefined;
          middle_name?: string | null | undefined;
          last_name?: string | null | undefined;
          address_line?: string | null | undefined;
          address_line_2?: string | null | undefined;
          city?: string | null | undefined;
          state?: string | null | undefined;
          county?: string | null | undefined;
          zip?: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type TemporaryInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TemporaryInfoQuery = {
  __typename?: 'RootQueryType';
  temporaryInfo?:
    | {
        __typename?: 'temporaryInfo';
        id?: string | null | undefined;
        data: {
          __typename?: 'temporaryData';
          vin?: string | null | undefined;
          year?: string | null | undefined;
          make?: string | null | undefined;
          model?: string | null | undefined;
          fuel_type?: string | null | undefined;
          vehicle_type?: string | null | undefined;
          color?: string | null | undefined;
          mileage?: number | null | undefined;
          vehicle_payoff?: number | null | undefined;
          user_entered_total_payoff?: number | null | undefined;
          phone_number?: string | null | undefined;
          email?: string | null | undefined;
          current_accordion_panel?: string | null | undefined;
          lienholder?: string | null | undefined;
          lienholderLogo?: string | null | undefined;
          lienholder_slug?: string | null | undefined;
          other_lienholder?: string | null | undefined;
          first_name?: string | null | undefined;
          middle_name?: string | null | undefined;
          last_name?: string | null | undefined;
          address_line?: string | null | undefined;
          address_line_2?: string | null | undefined;
          city?: string | null | undefined;
          state?: string | null | undefined;
          county?: string | null | undefined;
          zip?: string | null | undefined;
          taxes?: any | null | undefined;
          vehicle_registration_fee?: number | null | undefined;
          doc_fee?: number | null | undefined;
          has_full_ssn?: boolean | null | undefined;
          previous_address_line?: string | null | undefined;
          previous_address_line_2?: string | null | undefined;
          previous_city?: string | null | undefined;
          previous_state?: string | null | undefined;
          previous_zip?: string | null | undefined;
          previous_county?: string | null | undefined;
          scheduled_call_datetime?: any | null | undefined;
          scheduled_call_datetime_tz?: string | null | undefined;
          primary_residence_type?: string | null | undefined;
          primary_residence_years?: number | null | undefined;
          primary_residence_months?: number | null | undefined;
          primary_residence_monthly_payment?: number | null | undefined;
          has_same_address_as_cobuyer?: boolean | null | undefined;
          secondary_residence_years?: number | null | undefined;
          secondary_residence_months?: number | null | undefined;
          dob?: any | null | undefined;
          employment_status?: string | null | undefined;
          employer_name?: string | null | undefined;
          job_title?: string | null | undefined;
          employer_phone_number?: string | null | undefined;
          years_at_job?: number | null | undefined;
          months_at_job?: number | null | undefined;
          salary?: number | null | undefined;
          pay_frequency?: string | null | undefined;
          previous_employer_name?: string | null | undefined;
          previous_job_title?: string | null | undefined;
          previous_employer_phone_number?: string | null | undefined;
          previous_years_at_job?: number | null | undefined;
          previous_months_at_job?: number | null | undefined;
          retail_book_value?: number | null | undefined;
          kbb_retail_mileage_adjustment?: number | null | undefined;
          kbb_retail_option_adjustment?: number | null | undefined;
          book_value?: number | null | undefined;
          kbb_lending_mileage_adjustment?: number | null | undefined;
          kbb_lending_option_adjustment?: number | null | undefined;
          kbb_valuation_date?: string | null | undefined;
          kbb_vehicle_name?: string | null | undefined;
          kbb_vehicle_id?: number | null | undefined;
          kbb_all_options?: any | null | undefined;
          kbb_trim_name?: string | null | undefined;
          kbb_selected_options?: any | null | undefined;
          good_through_date?: any | null | undefined;
          credit_score?: number | null | undefined;
          term?: number | null | undefined;
          rate?: number | null | undefined;
          down_payment?: number | null | undefined;
          taxes_fees?: number | null | undefined;
          payment_estimate_low?: number | null | undefined;
          payment_estimate_high?: number | null | undefined;
          payoff_includes_sales_tax?: boolean | null | undefined;
          lh_account_number?: string | null | undefined;
          include_cobuyer?: boolean | null | undefined;
          cobuyer_first_name?: string | null | undefined;
          cobuyer_last_name?: string | null | undefined;
          cobuyer_employment_status?: string | null | undefined;
          cobuyer_employer_name?: string | null | undefined;
          cobuyer_employer_phone_number?: string | null | undefined;
          cobuyer_job_title?: string | null | undefined;
          cobuyer_years_at_job?: number | null | undefined;
          cobuyer_months_at_job?: number | null | undefined;
          cobuyer_salary?: number | null | undefined;
          cobuyer_pay_frequency?: string | null | undefined;
          cobuyer_previous_employer_name?: string | null | undefined;
          cobuyer_previous_employer_phone_number?: string | null | undefined;
          cobuyer_previous_job_title?: string | null | undefined;
          cobuyer_previous_years_at_job?: number | null | undefined;
          cobuyer_previous_months_at_job?: number | null | undefined;
          cobuyer_phone_number?: string | null | undefined;
          cobuyer_email?: string | null | undefined;
          cobuyer_dob?: any | null | undefined;
          cobuyer_address_line?: string | null | undefined;
          cobuyer_address_line_2?: string | null | undefined;
          cobuyer_city?: string | null | undefined;
          cobuyer_state?: string | null | undefined;
          cobuyer_zip?: string | null | undefined;
          cobuyer_county?: string | null | undefined;
          cobuyer_primary_residence_monthly_payment?: number | null | undefined;
          cobuyer_primary_residence_type?: string | null | undefined;
          cobuyer_primary_residence_years?: number | null | undefined;
          cobuyer_primary_residence_months?: number | null | undefined;
          cobuyer_previous_address_line?: string | null | undefined;
          cobuyer_previous_address_line_2?: string | null | undefined;
          cobuyer_previous_city?: string | null | undefined;
          cobuyer_previous_state?: string | null | undefined;
          cobuyer_previous_zip?: string | null | undefined;
          cobuyer_previous_county?: string | null | undefined;
          cobuyer_secondary_residence_years?: number | null | undefined;
          cobuyer_secondary_residence_months?: number | null | undefined;
          cobuyer_relation_to_buyer?: RelationToBuyerEnum | null | undefined;
          cobuyer_marital_status?: MaritalStatusEnum | null | undefined;
          old_lease_payment?: number | null | undefined;
          vehicle_offer?: number | null | undefined;
          estimated_dealer_fees?: number | null | undefined;
          condition_report?: any | null | undefined;
          mmr_value?: number | null | undefined;
          license_plate_number?: string | null | undefined;
          license_plate_state?: string | null | undefined;
          marital_status?: MaritalStatusEnum | null | undefined;
          sales_tax_from_payoff?: number | null | undefined;
          last_url?: string | null | undefined;
          query_params?: string | null | undefined;
          manual_entry?: boolean | null | undefined;
          uploaded_files?: any | null | undefined;
          prequalification_success?: boolean | null | undefined;
          is_hard_pull?: boolean | null | undefined;
          prequalification_errors?: Array<string | null | undefined> | null | undefined;
          cobuyer_prequalification_errors?: Array<string | null | undefined> | null | undefined;
          prequal_id?: string | null | undefined;
          shopping_cart?:
            | {
                __typename?: 'temporaryShoppingCartData';
                buyout?: number | null | undefined;
                credit_score?: number | null | undefined;
                down_payment?: number | null | undefined;
                loan_term?: number | null | undefined;
                payment_estimate_low?: number | null | undefined;
                payment_estimate_high?: number | null | undefined;
                estimated_fees?: number | null | undefined;
              }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type TemporaryInfoVipQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TemporaryInfoVipQuery = {
  __typename?: 'RootQueryType';
  temporaryInfoVip?:
    | {
        __typename?: 'temporaryInfoVip';
        id?: string | null | undefined;
        deal_id?: string | null | undefined;
        data: {
          __typename?: 'temporaryDataVip';
          year?: string | null | undefined;
          make?: string | null | undefined;
          model?: string | null | undefined;
          first_name?: string | null | undefined;
          last_name?: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type DealSetterQueryVariables = Exact<{
  dealId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type DealSetterQuery = {
  __typename?: 'RootQueryType';
  dealSetter?:
    | {
        __typename?: 'dealSetter';
        setter?: { __typename?: 'user'; name?: string | null | undefined } | null | undefined;
      }
    | null
    | undefined;
};

export const AddressDetailDocument = gql`
  query addressDetail($address: String) {
    addressDetail(address: $address) {
      city
      state
      county
    }
  }
`;

/**
 * __useAddressDetailQuery__
 *
 * To run a query within a React component, call `useAddressDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressDetailQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useAddressDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<AddressDetailQuery, AddressDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressDetailQuery, AddressDetailQueryVariables>(
    AddressDetailDocument,
    options,
  );
}
export function useAddressDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AddressDetailQuery, AddressDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressDetailQuery, AddressDetailQueryVariables>(
    AddressDetailDocument,
    options,
  );
}
export type AddressDetailQueryHookResult = ReturnType<typeof useAddressDetailQuery>;
export type AddressDetailLazyQueryHookResult = ReturnType<typeof useAddressDetailLazyQuery>;
export type AddressDetailQueryResult = Apollo.QueryResult<
  AddressDetailQuery,
  AddressDetailQueryVariables
>;
export const ValidZipDocument = gql`
  query validZip($zipCode: String!) {
    validZip(zipCode: $zipCode)
  }
`;

/**
 * __useValidZipQuery__
 *
 * To run a query within a React component, call `useValidZipQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidZipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidZipQuery({
 *   variables: {
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useValidZipQuery(
  baseOptions: Apollo.QueryHookOptions<ValidZipQuery, ValidZipQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidZipQuery, ValidZipQueryVariables>(ValidZipDocument, options);
}
export function useValidZipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidZipQuery, ValidZipQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidZipQuery, ValidZipQueryVariables>(ValidZipDocument, options);
}
export type ValidZipQueryHookResult = ReturnType<typeof useValidZipQuery>;
export type ValidZipLazyQueryHookResult = ReturnType<typeof useValidZipLazyQuery>;
export type ValidZipQueryResult = Apollo.QueryResult<ValidZipQuery, ValidZipQueryVariables>;
export const GetVisibleArticlesDocument = gql`
  query getVisibleArticles($page: Int, $pageSize: Int) {
    visibleArticles(page: $page, pageSize: $pageSize) {
      results {
        id
        title
        headline
        url
        created_at
        thumbnail
      }
      total
    }
  }
`;

/**
 * __useGetVisibleArticlesQuery__
 *
 * To run a query within a React component, call `useGetVisibleArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisibleArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisibleArticlesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetVisibleArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVisibleArticlesQuery, GetVisibleArticlesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVisibleArticlesQuery, GetVisibleArticlesQueryVariables>(
    GetVisibleArticlesDocument,
    options,
  );
}
export function useGetVisibleArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVisibleArticlesQuery,
    GetVisibleArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVisibleArticlesQuery, GetVisibleArticlesQueryVariables>(
    GetVisibleArticlesDocument,
    options,
  );
}
export type GetVisibleArticlesQueryHookResult = ReturnType<typeof useGetVisibleArticlesQuery>;
export type GetVisibleArticlesLazyQueryHookResult = ReturnType<
  typeof useGetVisibleArticlesLazyQuery
>;
export type GetVisibleArticlesQueryResult = Apollo.QueryResult<
  GetVisibleArticlesQuery,
  GetVisibleArticlesQueryVariables
>;
export const CheckFlowStatusDocument = gql`
  query checkFlowStatus($guid: String) {
    checkFlowStatus(guid: $guid) {
      inFlow
      guid
      url
      car {
        id
        make
        model
        year
        mileage
        vin
        vehicle_type
        color
      }
      dealId
      customerId
    }
  }
`;

/**
 * __useCheckFlowStatusQuery__
 *
 * To run a query within a React component, call `useCheckFlowStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckFlowStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckFlowStatusQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useCheckFlowStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<CheckFlowStatusQuery, CheckFlowStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckFlowStatusQuery, CheckFlowStatusQueryVariables>(
    CheckFlowStatusDocument,
    options,
  );
}
export function useCheckFlowStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckFlowStatusQuery, CheckFlowStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckFlowStatusQuery, CheckFlowStatusQueryVariables>(
    CheckFlowStatusDocument,
    options,
  );
}
export type CheckFlowStatusQueryHookResult = ReturnType<typeof useCheckFlowStatusQuery>;
export type CheckFlowStatusLazyQueryHookResult = ReturnType<typeof useCheckFlowStatusLazyQuery>;
export type CheckFlowStatusQueryResult = Apollo.QueryResult<
  CheckFlowStatusQuery,
  CheckFlowStatusQueryVariables
>;
export const BankLogoDocument = gql`
  query bankLogo($bank_name: String!) {
    bankLogo(bank_name: $bank_name)
  }
`;

/**
 * __useBankLogoQuery__
 *
 * To run a query within a React component, call `useBankLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankLogoQuery({
 *   variables: {
 *      bank_name: // value for 'bank_name'
 *   },
 * });
 */
export function useBankLogoQuery(
  baseOptions: Apollo.QueryHookOptions<BankLogoQuery, BankLogoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BankLogoQuery, BankLogoQueryVariables>(BankLogoDocument, options);
}
export function useBankLogoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BankLogoQuery, BankLogoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BankLogoQuery, BankLogoQueryVariables>(BankLogoDocument, options);
}
export type BankLogoQueryHookResult = ReturnType<typeof useBankLogoQuery>;
export type BankLogoLazyQueryHookResult = ReturnType<typeof useBankLogoLazyQuery>;
export type BankLogoQueryResult = Apollo.QueryResult<BankLogoQuery, BankLogoQueryVariables>;
export const CarDistinctMakeDocument = gql`
  query carDistinctMake {
    carDistinctMake {
      make
      total
    }
  }
`;

/**
 * __useCarDistinctMakeQuery__
 *
 * To run a query within a React component, call `useCarDistinctMakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarDistinctMakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarDistinctMakeQuery({
 *   variables: {
 *   },
 * });
 */
export function useCarDistinctMakeQuery(
  baseOptions?: Apollo.QueryHookOptions<CarDistinctMakeQuery, CarDistinctMakeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CarDistinctMakeQuery, CarDistinctMakeQueryVariables>(
    CarDistinctMakeDocument,
    options,
  );
}
export function useCarDistinctMakeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CarDistinctMakeQuery, CarDistinctMakeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CarDistinctMakeQuery, CarDistinctMakeQueryVariables>(
    CarDistinctMakeDocument,
    options,
  );
}
export type CarDistinctMakeQueryHookResult = ReturnType<typeof useCarDistinctMakeQuery>;
export type CarDistinctMakeLazyQueryHookResult = ReturnType<typeof useCarDistinctMakeLazyQuery>;
export type CarDistinctMakeQueryResult = Apollo.QueryResult<
  CarDistinctMakeQuery,
  CarDistinctMakeQueryVariables
>;
export const CarDistinctModelDocument = gql`
  query carDistinctModel($make: String!) {
    carDistinctModel(make: $make) {
      model
    }
  }
`;

/**
 * __useCarDistinctModelQuery__
 *
 * To run a query within a React component, call `useCarDistinctModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarDistinctModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarDistinctModelQuery({
 *   variables: {
 *      make: // value for 'make'
 *   },
 * });
 */
export function useCarDistinctModelQuery(
  baseOptions: Apollo.QueryHookOptions<CarDistinctModelQuery, CarDistinctModelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CarDistinctModelQuery, CarDistinctModelQueryVariables>(
    CarDistinctModelDocument,
    options,
  );
}
export function useCarDistinctModelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CarDistinctModelQuery, CarDistinctModelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CarDistinctModelQuery, CarDistinctModelQueryVariables>(
    CarDistinctModelDocument,
    options,
  );
}
export type CarDistinctModelQueryHookResult = ReturnType<typeof useCarDistinctModelQuery>;
export type CarDistinctModelLazyQueryHookResult = ReturnType<typeof useCarDistinctModelLazyQuery>;
export type CarDistinctModelQueryResult = Apollo.QueryResult<
  CarDistinctModelQuery,
  CarDistinctModelQueryVariables
>;
export const PayoffStatsMakeModelYearDocument = gql`
  query payoffStatsMakeModelYear(
    $make: String!
    $model: String
    $year: String
    $start_date: String!
    $end_date: String!
  ) {
    payoffStatsMakeModelYear(
      make: $make
      model: $model
      year: $year
      start_date: $start_date
      end_date: $end_date
    ) {
      avg_payoff
      min_payoff
      max_payoff
    }
  }
`;

/**
 * __usePayoffStatsMakeModelYearQuery__
 *
 * To run a query within a React component, call `usePayoffStatsMakeModelYearQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoffStatsMakeModelYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoffStatsMakeModelYearQuery({
 *   variables: {
 *      make: // value for 'make'
 *      model: // value for 'model'
 *      year: // value for 'year'
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function usePayoffStatsMakeModelYearQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayoffStatsMakeModelYearQuery,
    PayoffStatsMakeModelYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PayoffStatsMakeModelYearQuery, PayoffStatsMakeModelYearQueryVariables>(
    PayoffStatsMakeModelYearDocument,
    options,
  );
}
export function usePayoffStatsMakeModelYearLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayoffStatsMakeModelYearQuery,
    PayoffStatsMakeModelYearQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PayoffStatsMakeModelYearQuery, PayoffStatsMakeModelYearQueryVariables>(
    PayoffStatsMakeModelYearDocument,
    options,
  );
}
export type PayoffStatsMakeModelYearQueryHookResult = ReturnType<
  typeof usePayoffStatsMakeModelYearQuery
>;
export type PayoffStatsMakeModelYearLazyQueryHookResult = ReturnType<
  typeof usePayoffStatsMakeModelYearLazyQuery
>;
export type PayoffStatsMakeModelYearQueryResult = Apollo.QueryResult<
  PayoffStatsMakeModelYearQuery,
  PayoffStatsMakeModelYearQueryVariables
>;
export const PayoffCountMakeDocument = gql`
  query payoffCountMake($make: String!) {
    payoffCountMake(make: $make) {
      total
    }
  }
`;

/**
 * __usePayoffCountMakeQuery__
 *
 * To run a query within a React component, call `usePayoffCountMakeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoffCountMakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoffCountMakeQuery({
 *   variables: {
 *      make: // value for 'make'
 *   },
 * });
 */
export function usePayoffCountMakeQuery(
  baseOptions: Apollo.QueryHookOptions<PayoffCountMakeQuery, PayoffCountMakeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PayoffCountMakeQuery, PayoffCountMakeQueryVariables>(
    PayoffCountMakeDocument,
    options,
  );
}
export function usePayoffCountMakeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PayoffCountMakeQuery, PayoffCountMakeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PayoffCountMakeQuery, PayoffCountMakeQueryVariables>(
    PayoffCountMakeDocument,
    options,
  );
}
export type PayoffCountMakeQueryHookResult = ReturnType<typeof usePayoffCountMakeQuery>;
export type PayoffCountMakeLazyQueryHookResult = ReturnType<typeof usePayoffCountMakeLazyQuery>;
export type PayoffCountMakeQueryResult = Apollo.QueryResult<
  PayoffCountMakeQuery,
  PayoffCountMakeQueryVariables
>;
export const PayoffCountModelDocument = gql`
  query payoffCountModel($make: String!, $model: String!) {
    payoffCountModel(make: $make, model: $model) {
      total
    }
  }
`;

/**
 * __usePayoffCountModelQuery__
 *
 * To run a query within a React component, call `usePayoffCountModelQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoffCountModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoffCountModelQuery({
 *   variables: {
 *      make: // value for 'make'
 *      model: // value for 'model'
 *   },
 * });
 */
export function usePayoffCountModelQuery(
  baseOptions: Apollo.QueryHookOptions<PayoffCountModelQuery, PayoffCountModelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PayoffCountModelQuery, PayoffCountModelQueryVariables>(
    PayoffCountModelDocument,
    options,
  );
}
export function usePayoffCountModelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PayoffCountModelQuery, PayoffCountModelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PayoffCountModelQuery, PayoffCountModelQueryVariables>(
    PayoffCountModelDocument,
    options,
  );
}
export type PayoffCountModelQueryHookResult = ReturnType<typeof usePayoffCountModelQuery>;
export type PayoffCountModelLazyQueryHookResult = ReturnType<typeof usePayoffCountModelLazyQuery>;
export type PayoffCountModelQueryResult = Apollo.QueryResult<
  PayoffCountModelQuery,
  PayoffCountModelQueryVariables
>;
export const FinancialInfoPaymentEstimateDocument = gql`
  query financialInfoPaymentEstimate(
    $make: String!
    $model: String
    $year: String
    $start_date: String!
    $end_date: String!
  ) {
    financialInfoPaymentEstimate(
      make: $make
      model: $model
      year: $year
      start_date: $start_date
      end_date: $end_date
    ) {
      avg_payment
      min_payment
      max_payment
    }
  }
`;

/**
 * __useFinancialInfoPaymentEstimateQuery__
 *
 * To run a query within a React component, call `useFinancialInfoPaymentEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialInfoPaymentEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialInfoPaymentEstimateQuery({
 *   variables: {
 *      make: // value for 'make'
 *      model: // value for 'model'
 *      year: // value for 'year'
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function useFinancialInfoPaymentEstimateQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinancialInfoPaymentEstimateQuery,
    FinancialInfoPaymentEstimateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FinancialInfoPaymentEstimateQuery,
    FinancialInfoPaymentEstimateQueryVariables
  >(FinancialInfoPaymentEstimateDocument, options);
}
export function useFinancialInfoPaymentEstimateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinancialInfoPaymentEstimateQuery,
    FinancialInfoPaymentEstimateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FinancialInfoPaymentEstimateQuery,
    FinancialInfoPaymentEstimateQueryVariables
  >(FinancialInfoPaymentEstimateDocument, options);
}
export type FinancialInfoPaymentEstimateQueryHookResult = ReturnType<
  typeof useFinancialInfoPaymentEstimateQuery
>;
export type FinancialInfoPaymentEstimateLazyQueryHookResult = ReturnType<
  typeof useFinancialInfoPaymentEstimateLazyQuery
>;
export type FinancialInfoPaymentEstimateQueryResult = Apollo.QueryResult<
  FinancialInfoPaymentEstimateQuery,
  FinancialInfoPaymentEstimateQueryVariables
>;
export const TotalCompletedDealsDocument = gql`
  query totalCompletedDeals {
    totalCompletedDeals
  }
`;

/**
 * __useTotalCompletedDealsQuery__
 *
 * To run a query within a React component, call `useTotalCompletedDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalCompletedDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalCompletedDealsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalCompletedDealsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TotalCompletedDealsQuery,
    TotalCompletedDealsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TotalCompletedDealsQuery, TotalCompletedDealsQueryVariables>(
    TotalCompletedDealsDocument,
    options,
  );
}
export function useTotalCompletedDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TotalCompletedDealsQuery,
    TotalCompletedDealsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TotalCompletedDealsQuery, TotalCompletedDealsQueryVariables>(
    TotalCompletedDealsDocument,
    options,
  );
}
export type TotalCompletedDealsQueryHookResult = ReturnType<typeof useTotalCompletedDealsQuery>;
export type TotalCompletedDealsLazyQueryHookResult = ReturnType<
  typeof useTotalCompletedDealsLazyQuery
>;
export type TotalCompletedDealsQueryResult = Apollo.QueryResult<
  TotalCompletedDealsQuery,
  TotalCompletedDealsQueryVariables
>;
export const UnlinkTemporaryInfoDocument = gql`
  mutation unlinkTemporaryInfo($id: ID!) {
    unlinkTemporaryInfo(id: $id) {
      id
    }
  }
`;
export type UnlinkTemporaryInfoMutationFn = Apollo.MutationFunction<
  UnlinkTemporaryInfoMutation,
  UnlinkTemporaryInfoMutationVariables
>;

/**
 * __useUnlinkTemporaryInfoMutation__
 *
 * To run a mutation, you first call `useUnlinkTemporaryInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkTemporaryInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkTemporaryInfoMutation, { data, loading, error }] = useUnlinkTemporaryInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlinkTemporaryInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkTemporaryInfoMutation,
    UnlinkTemporaryInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnlinkTemporaryInfoMutation, UnlinkTemporaryInfoMutationVariables>(
    UnlinkTemporaryInfoDocument,
    options,
  );
}
export type UnlinkTemporaryInfoMutationHookResult = ReturnType<
  typeof useUnlinkTemporaryInfoMutation
>;
export type UnlinkTemporaryInfoMutationResult = Apollo.MutationResult<UnlinkTemporaryInfoMutation>;
export type UnlinkTemporaryInfoMutationOptions = Apollo.BaseMutationOptions<
  UnlinkTemporaryInfoMutation,
  UnlinkTemporaryInfoMutationVariables
>;
export const GetMarketPrequalificationDocument = gql`
  mutation getMarketPrequalification(
    $prequalId: ID
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $addressLine: String
    $city: String
    $state: String
    $zip: String
  ) {
    getMarketPrequalification(
      prequalId: $prequalId
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      addressLine: $addressLine
      city: $city
      state: $state
      zip: $zip
    ) {
      prequalId
      creditScoreTier
    }
  }
`;
export type GetMarketPrequalificationMutationFn = Apollo.MutationFunction<
  GetMarketPrequalificationMutation,
  GetMarketPrequalificationMutationVariables
>;

/**
 * __useGetMarketPrequalificationMutation__
 *
 * To run a mutation, you first call `useGetMarketPrequalificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetMarketPrequalificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getMarketPrequalificationMutation, { data, loading, error }] = useGetMarketPrequalificationMutation({
 *   variables: {
 *      prequalId: // value for 'prequalId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      addressLine: // value for 'addressLine'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useGetMarketPrequalificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetMarketPrequalificationMutation,
    GetMarketPrequalificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetMarketPrequalificationMutation,
    GetMarketPrequalificationMutationVariables
  >(GetMarketPrequalificationDocument, options);
}
export type GetMarketPrequalificationMutationHookResult = ReturnType<
  typeof useGetMarketPrequalificationMutation
>;
export type GetMarketPrequalificationMutationResult =
  Apollo.MutationResult<GetMarketPrequalificationMutation>;
export type GetMarketPrequalificationMutationOptions = Apollo.BaseMutationOptions<
  GetMarketPrequalificationMutation,
  GetMarketPrequalificationMutationVariables
>;
export const KbbVinDocument = gql`
  query kbbVin($vin: String!) {
    kbbVin(vin: $vin) {
      warning
      vinResults {
        vehicleId
        vehicleOptions {
          categoryGroup
          hasRelationships
          isConfigurable
          isConsumer
          isTypical
          isVinDecoded
          optionName
          optionType
          sortOrder
          vehicleId
          vehicleOptionId
        }
        modelName
        trimName
        makeName
        yearId
        vehicleName
      }
    }
  }
`;

/**
 * __useKbbVinQuery__
 *
 * To run a query within a React component, call `useKbbVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useKbbVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKbbVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useKbbVinQuery(
  baseOptions: Apollo.QueryHookOptions<KbbVinQuery, KbbVinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KbbVinQuery, KbbVinQueryVariables>(KbbVinDocument, options);
}
export function useKbbVinLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KbbVinQuery, KbbVinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KbbVinQuery, KbbVinQueryVariables>(KbbVinDocument, options);
}
export type KbbVinQueryHookResult = ReturnType<typeof useKbbVinQuery>;
export type KbbVinLazyQueryHookResult = ReturnType<typeof useKbbVinLazyQuery>;
export type KbbVinQueryResult = Apollo.QueryResult<KbbVinQuery, KbbVinQueryVariables>;
export const KbbValuesDocument = gql`
  query kbbValues($data: kbbValuesDataType!) {
    kbbValues(data: $data) {
      retail
      retailMileageAdjustment
      retailOptionAdjustment
      retailOptionPrices
      lending
      lendingMileageAdjustment
      lendingOptionAdjustment
      lendingOptionPrices
      valuationDate
      kbbSelectedOptions {
        id
        name
        type
        removed
        lendingOptionPrice
        retailOptionPrice
      }
    }
  }
`;

/**
 * __useKbbValuesQuery__
 *
 * To run a query within a React component, call `useKbbValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKbbValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKbbValuesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useKbbValuesQuery(
  baseOptions: Apollo.QueryHookOptions<KbbValuesQuery, KbbValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KbbValuesQuery, KbbValuesQueryVariables>(KbbValuesDocument, options);
}
export function useKbbValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KbbValuesQuery, KbbValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KbbValuesQuery, KbbValuesQueryVariables>(KbbValuesDocument, options);
}
export type KbbValuesQueryHookResult = ReturnType<typeof useKbbValuesQuery>;
export type KbbValuesLazyQueryHookResult = ReturnType<typeof useKbbValuesLazyQuery>;
export type KbbValuesQueryResult = Apollo.QueryResult<KbbValuesQuery, KbbValuesQueryVariables>;
export const CalculateOptionsDocument = gql`
  query calculateOptions(
    $vehicleId: Int!
    $allKbbVehicleOptions: [kbbOptionInput]
    $startingVehicleOptions: [Int]
    $configToModify: [kbbConfigType]
    $vin: String!
    $color: String
  ) {
    calculateOptions(
      vehicleId: $vehicleId
      allKbbVehicleOptions: $allKbbVehicleOptions
      startingVehicleOptions: $startingVehicleOptions
      configToModify: $configToModify
      vin: $vin
      color: $color
    ) {
      vehicleOptionId
      optionName
      optionType
      categoryGroup
    }
  }
`;

/**
 * __useCalculateOptionsQuery__
 *
 * To run a query within a React component, call `useCalculateOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateOptionsQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      allKbbVehicleOptions: // value for 'allKbbVehicleOptions'
 *      startingVehicleOptions: // value for 'startingVehicleOptions'
 *      configToModify: // value for 'configToModify'
 *      vin: // value for 'vin'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useCalculateOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<CalculateOptionsQuery, CalculateOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalculateOptionsQuery, CalculateOptionsQueryVariables>(
    CalculateOptionsDocument,
    options,
  );
}
export function useCalculateOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CalculateOptionsQuery, CalculateOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalculateOptionsQuery, CalculateOptionsQueryVariables>(
    CalculateOptionsDocument,
    options,
  );
}
export type CalculateOptionsQueryHookResult = ReturnType<typeof useCalculateOptionsQuery>;
export type CalculateOptionsLazyQueryHookResult = ReturnType<typeof useCalculateOptionsLazyQuery>;
export type CalculateOptionsQueryResult = Apollo.QueryResult<
  CalculateOptionsQuery,
  CalculateOptionsQueryVariables
>;
export const GetOperatingHoursAndTimeSlotsDocument = gql`
  query getOperatingHoursAndTimeSlots {
    getOperatingHoursAndTimeSlots {
      isOpen
      utcTimeSlots
    }
  }
`;

/**
 * __useGetOperatingHoursAndTimeSlotsQuery__
 *
 * To run a query within a React component, call `useGetOperatingHoursAndTimeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatingHoursAndTimeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatingHoursAndTimeSlotsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOperatingHoursAndTimeSlotsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOperatingHoursAndTimeSlotsQuery,
    GetOperatingHoursAndTimeSlotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOperatingHoursAndTimeSlotsQuery,
    GetOperatingHoursAndTimeSlotsQueryVariables
  >(GetOperatingHoursAndTimeSlotsDocument, options);
}
export function useGetOperatingHoursAndTimeSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOperatingHoursAndTimeSlotsQuery,
    GetOperatingHoursAndTimeSlotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOperatingHoursAndTimeSlotsQuery,
    GetOperatingHoursAndTimeSlotsQueryVariables
  >(GetOperatingHoursAndTimeSlotsDocument, options);
}
export type GetOperatingHoursAndTimeSlotsQueryHookResult = ReturnType<
  typeof useGetOperatingHoursAndTimeSlotsQuery
>;
export type GetOperatingHoursAndTimeSlotsLazyQueryHookResult = ReturnType<
  typeof useGetOperatingHoursAndTimeSlotsLazyQuery
>;
export type GetOperatingHoursAndTimeSlotsQueryResult = Apollo.QueryResult<
  GetOperatingHoursAndTimeSlotsQuery,
  GetOperatingHoursAndTimeSlotsQueryVariables
>;
export const CreateOptEventDocument = gql`
  mutation createOptEvent($event: optEventInput!, $token: String) {
    createOptEvent(event: $event, token: $token) {
      id
    }
  }
`;
export type CreateOptEventMutationFn = Apollo.MutationFunction<
  CreateOptEventMutation,
  CreateOptEventMutationVariables
>;

/**
 * __useCreateOptEventMutation__
 *
 * To run a mutation, you first call `useCreateOptEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOptEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOptEventMutation, { data, loading, error }] = useCreateOptEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCreateOptEventMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOptEventMutation, CreateOptEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOptEventMutation, CreateOptEventMutationVariables>(
    CreateOptEventDocument,
    options,
  );
}
export type CreateOptEventMutationHookResult = ReturnType<typeof useCreateOptEventMutation>;
export type CreateOptEventMutationResult = Apollo.MutationResult<CreateOptEventMutation>;
export type CreateOptEventMutationOptions = Apollo.BaseMutationOptions<
  CreateOptEventMutation,
  CreateOptEventMutationVariables
>;
export const OptOutDocument = gql`
  mutation optOut($deal_id: ID, $is_cobuyer: Boolean) {
    optOut(deal_id: $deal_id, is_cobuyer: $is_cobuyer)
  }
`;
export type OptOutMutationFn = Apollo.MutationFunction<OptOutMutation, OptOutMutationVariables>;

/**
 * __useOptOutMutation__
 *
 * To run a mutation, you first call `useOptOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optOutMutation, { data, loading, error }] = useOptOutMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      is_cobuyer: // value for 'is_cobuyer'
 *   },
 * });
 */
export function useOptOutMutation(
  baseOptions?: Apollo.MutationHookOptions<OptOutMutation, OptOutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OptOutMutation, OptOutMutationVariables>(OptOutDocument, options);
}
export type OptOutMutationHookResult = ReturnType<typeof useOptOutMutation>;
export type OptOutMutationResult = Apollo.MutationResult<OptOutMutation>;
export type OptOutMutationOptions = Apollo.BaseMutationOptions<
  OptOutMutation,
  OptOutMutationVariables
>;
export const PaymentEstimateDocument = gql`
  query paymentEstimate($data: paymentEstimateInput) {
    paymentEstimate(data: $data) {
      paymentEstimateLow
      paymentEstimateHigh
      estimatedTaxesAndFees
      totalEstimatedLoan
      estimatedAnnualPercentageRate
      feeDetails {
        baseTaxAmount
        warrantyTaxAmount
        docFeeTaxAmount
        docFee
        titleFee
        registrationFee
      }
    }
  }
`;

/**
 * __usePaymentEstimateQuery__
 *
 * To run a query within a React component, call `usePaymentEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentEstimateQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePaymentEstimateQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentEstimateQuery, PaymentEstimateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentEstimateQuery, PaymentEstimateQueryVariables>(
    PaymentEstimateDocument,
    options,
  );
}
export function usePaymentEstimateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentEstimateQuery, PaymentEstimateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentEstimateQuery, PaymentEstimateQueryVariables>(
    PaymentEstimateDocument,
    options,
  );
}
export type PaymentEstimateQueryHookResult = ReturnType<typeof usePaymentEstimateQuery>;
export type PaymentEstimateLazyQueryHookResult = ReturnType<typeof usePaymentEstimateLazyQuery>;
export type PaymentEstimateQueryResult = Apollo.QueryResult<
  PaymentEstimateQuery,
  PaymentEstimateQueryVariables
>;
export const SendPaymentEstimateEmailAndTextDocument = gql`
  mutation sendPaymentEstimateEmailAndText(
    $data: paymentEstimateInput!
    $email: String
    $phoneNumber: String
  ) {
    sendPaymentEstimateEmailAndText(data: $data, email: $email, phoneNumber: $phoneNumber)
  }
`;
export type SendPaymentEstimateEmailAndTextMutationFn = Apollo.MutationFunction<
  SendPaymentEstimateEmailAndTextMutation,
  SendPaymentEstimateEmailAndTextMutationVariables
>;

/**
 * __useSendPaymentEstimateEmailAndTextMutation__
 *
 * To run a mutation, you first call `useSendPaymentEstimateEmailAndTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPaymentEstimateEmailAndTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPaymentEstimateEmailAndTextMutation, { data, loading, error }] = useSendPaymentEstimateEmailAndTextMutation({
 *   variables: {
 *      data: // value for 'data'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useSendPaymentEstimateEmailAndTextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPaymentEstimateEmailAndTextMutation,
    SendPaymentEstimateEmailAndTextMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPaymentEstimateEmailAndTextMutation,
    SendPaymentEstimateEmailAndTextMutationVariables
  >(SendPaymentEstimateEmailAndTextDocument, options);
}
export type SendPaymentEstimateEmailAndTextMutationHookResult = ReturnType<
  typeof useSendPaymentEstimateEmailAndTextMutation
>;
export type SendPaymentEstimateEmailAndTextMutationResult =
  Apollo.MutationResult<SendPaymentEstimateEmailAndTextMutation>;
export type SendPaymentEstimateEmailAndTextMutationOptions = Apollo.BaseMutationOptions<
  SendPaymentEstimateEmailAndTextMutation,
  SendPaymentEstimateEmailAndTextMutationVariables
>;
export const TemporaryInfoUpdateDocument = gql`
  mutation temporaryInfoUpdate($info: temporaryInfoInput) {
    temporaryInfoUpdate(info: $info) {
      id
      data {
        phone_number
      }
    }
  }
`;
export type TemporaryInfoUpdateMutationFn = Apollo.MutationFunction<
  TemporaryInfoUpdateMutation,
  TemporaryInfoUpdateMutationVariables
>;

/**
 * __useTemporaryInfoUpdateMutation__
 *
 * To run a mutation, you first call `useTemporaryInfoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemporaryInfoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [temporaryInfoUpdateMutation, { data, loading, error }] = useTemporaryInfoUpdateMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useTemporaryInfoUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TemporaryInfoUpdateMutation,
    TemporaryInfoUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TemporaryInfoUpdateMutation, TemporaryInfoUpdateMutationVariables>(
    TemporaryInfoUpdateDocument,
    options,
  );
}
export type TemporaryInfoUpdateMutationHookResult = ReturnType<
  typeof useTemporaryInfoUpdateMutation
>;
export type TemporaryInfoUpdateMutationResult = Apollo.MutationResult<TemporaryInfoUpdateMutation>;
export type TemporaryInfoUpdateMutationOptions = Apollo.BaseMutationOptions<
  TemporaryInfoUpdateMutation,
  TemporaryInfoUpdateMutationVariables
>;
export const TemporaryInfoImportDocument = gql`
  mutation temporaryInfoImport($id: ID!, $transformType: transformType!, $importType: importType) {
    temporaryInfoImport(id: $id, transformType: $transformType, importType: $importType) {
      id
      customer {
        id
      }
    }
  }
`;
export type TemporaryInfoImportMutationFn = Apollo.MutationFunction<
  TemporaryInfoImportMutation,
  TemporaryInfoImportMutationVariables
>;

/**
 * __useTemporaryInfoImportMutation__
 *
 * To run a mutation, you first call `useTemporaryInfoImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemporaryInfoImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [temporaryInfoImportMutation, { data, loading, error }] = useTemporaryInfoImportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      transformType: // value for 'transformType'
 *      importType: // value for 'importType'
 *   },
 * });
 */
export function useTemporaryInfoImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TemporaryInfoImportMutation,
    TemporaryInfoImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TemporaryInfoImportMutation, TemporaryInfoImportMutationVariables>(
    TemporaryInfoImportDocument,
    options,
  );
}
export type TemporaryInfoImportMutationHookResult = ReturnType<
  typeof useTemporaryInfoImportMutation
>;
export type TemporaryInfoImportMutationResult = Apollo.MutationResult<TemporaryInfoImportMutation>;
export type TemporaryInfoImportMutationOptions = Apollo.BaseMutationOptions<
  TemporaryInfoImportMutation,
  TemporaryInfoImportMutationVariables
>;
export const GetPodHoursDocument = gql`
  query getPodHours {
    podHours {
      id
      label
      day
      beginTime
      endTime
    }
  }
`;

/**
 * __useGetPodHoursQuery__
 *
 * To run a query within a React component, call `useGetPodHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodHoursQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPodHoursQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPodHoursQuery, GetPodHoursQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPodHoursQuery, GetPodHoursQueryVariables>(GetPodHoursDocument, options);
}
export function useGetPodHoursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPodHoursQuery, GetPodHoursQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPodHoursQuery, GetPodHoursQueryVariables>(
    GetPodHoursDocument,
    options,
  );
}
export type GetPodHoursQueryHookResult = ReturnType<typeof useGetPodHoursQuery>;
export type GetPodHoursLazyQueryHookResult = ReturnType<typeof useGetPodHoursLazyQuery>;
export type GetPodHoursQueryResult = Apollo.QueryResult<
  GetPodHoursQuery,
  GetPodHoursQueryVariables
>;
export const GetVisibleReviewsDocument = gql`
  query getVisibleReviews {
    visibleReviews {
      id
      customer_name
      review
      created_at
    }
  }
`;

/**
 * __useGetVisibleReviewsQuery__
 *
 * To run a query within a React component, call `useGetVisibleReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisibleReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisibleReviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVisibleReviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVisibleReviewsQuery, GetVisibleReviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVisibleReviewsQuery, GetVisibleReviewsQueryVariables>(
    GetVisibleReviewsDocument,
    options,
  );
}
export function useGetVisibleReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVisibleReviewsQuery,
    GetVisibleReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVisibleReviewsQuery, GetVisibleReviewsQueryVariables>(
    GetVisibleReviewsDocument,
    options,
  );
}
export type GetVisibleReviewsQueryHookResult = ReturnType<typeof useGetVisibleReviewsQuery>;
export type GetVisibleReviewsLazyQueryHookResult = ReturnType<typeof useGetVisibleReviewsLazyQuery>;
export type GetVisibleReviewsQueryResult = Apollo.QueryResult<
  GetVisibleReviewsQuery,
  GetVisibleReviewsQueryVariables
>;
export const CreateTemporaryInfoDocument = gql`
  mutation createTemporaryInfo(
    $vin: String
    $license_plate_number: String
    $license_plate_state: String
    $device_type: String
    $extra_info: extraTempInfoInputType
    $query_params: String
    $ignore_existing_deal: Boolean
  ) {
    createTemporaryInfo(
      vin: $vin
      license_plate_number: $license_plate_number
      license_plate_state: $license_plate_state
      device_type: $device_type
      extra_info: $extra_info
      query_params: $query_params
      ignore_existing_deal: $ignore_existing_deal
    ) {
      id
      data {
        vin
        year
        make
        model
        fuel_type
        vehicle_type
        phone_number
        email
        first_name
        middle_name
        last_name
        address_line
        address_line_2
        city
        state
        county
        zip
      }
    }
  }
`;
export type CreateTemporaryInfoMutationFn = Apollo.MutationFunction<
  CreateTemporaryInfoMutation,
  CreateTemporaryInfoMutationVariables
>;

/**
 * __useCreateTemporaryInfoMutation__
 *
 * To run a mutation, you first call `useCreateTemporaryInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemporaryInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemporaryInfoMutation, { data, loading, error }] = useCreateTemporaryInfoMutation({
 *   variables: {
 *      vin: // value for 'vin'
 *      license_plate_number: // value for 'license_plate_number'
 *      license_plate_state: // value for 'license_plate_state'
 *      device_type: // value for 'device_type'
 *      extra_info: // value for 'extra_info'
 *      query_params: // value for 'query_params'
 *      ignore_existing_deal: // value for 'ignore_existing_deal'
 *   },
 * });
 */
export function useCreateTemporaryInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTemporaryInfoMutation,
    CreateTemporaryInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTemporaryInfoMutation, CreateTemporaryInfoMutationVariables>(
    CreateTemporaryInfoDocument,
    options,
  );
}
export type CreateTemporaryInfoMutationHookResult = ReturnType<
  typeof useCreateTemporaryInfoMutation
>;
export type CreateTemporaryInfoMutationResult = Apollo.MutationResult<CreateTemporaryInfoMutation>;
export type CreateTemporaryInfoMutationOptions = Apollo.BaseMutationOptions<
  CreateTemporaryInfoMutation,
  CreateTemporaryInfoMutationVariables
>;
export const TemporaryInfoDocument = gql`
  query temporaryInfo($id: ID!) {
    temporaryInfo(id: $id) {
      id
      data {
        vin
        year
        make
        model
        fuel_type
        vehicle_type
        color
        mileage
        vehicle_payoff
        user_entered_total_payoff
        phone_number
        email
        current_accordion_panel
        lienholder
        lienholderLogo
        lienholder_slug
        other_lienholder
        first_name
        middle_name
        last_name
        address_line
        address_line_2
        city
        state
        county
        zip
        taxes
        vehicle_registration_fee
        doc_fee
        has_full_ssn
        previous_address_line
        previous_address_line_2
        previous_city
        previous_state
        previous_zip
        previous_county
        scheduled_call_datetime
        scheduled_call_datetime_tz
        primary_residence_type
        primary_residence_years
        primary_residence_months
        primary_residence_monthly_payment
        has_same_address_as_cobuyer
        secondary_residence_years
        secondary_residence_months
        dob
        employment_status
        employer_name
        job_title
        employer_phone_number
        years_at_job
        months_at_job
        salary
        pay_frequency
        previous_employer_name
        previous_job_title
        previous_employer_phone_number
        previous_years_at_job
        previous_months_at_job
        retail_book_value
        kbb_retail_mileage_adjustment
        kbb_retail_option_adjustment
        book_value
        kbb_lending_mileage_adjustment
        kbb_lending_option_adjustment
        kbb_valuation_date
        kbb_vehicle_name
        kbb_vehicle_id
        kbb_all_options
        kbb_trim_name
        kbb_selected_options
        good_through_date
        credit_score
        term
        rate
        down_payment
        taxes_fees
        payment_estimate_low
        payment_estimate_high
        payoff_includes_sales_tax
        lh_account_number
        include_cobuyer
        cobuyer_first_name
        cobuyer_last_name
        cobuyer_employment_status
        cobuyer_employer_name
        cobuyer_employer_phone_number
        cobuyer_job_title
        cobuyer_years_at_job
        cobuyer_months_at_job
        cobuyer_salary
        cobuyer_pay_frequency
        cobuyer_previous_employer_name
        cobuyer_previous_employer_phone_number
        cobuyer_previous_job_title
        cobuyer_previous_years_at_job
        cobuyer_previous_months_at_job
        cobuyer_phone_number
        cobuyer_email
        cobuyer_dob
        cobuyer_address_line
        cobuyer_address_line_2
        cobuyer_city
        cobuyer_state
        cobuyer_zip
        cobuyer_county
        cobuyer_primary_residence_monthly_payment
        cobuyer_primary_residence_type
        cobuyer_primary_residence_years
        cobuyer_primary_residence_months
        cobuyer_previous_address_line
        cobuyer_previous_address_line_2
        cobuyer_previous_city
        cobuyer_previous_state
        cobuyer_previous_zip
        cobuyer_previous_county
        cobuyer_secondary_residence_years
        cobuyer_secondary_residence_months
        cobuyer_relation_to_buyer
        cobuyer_marital_status
        old_lease_payment
        vehicle_offer
        estimated_dealer_fees
        condition_report
        mmr_value
        license_plate_number
        license_plate_state
        shopping_cart {
          buyout
          credit_score
          down_payment
          loan_term
          payment_estimate_low
          payment_estimate_high
          estimated_fees
        }
        marital_status
        sales_tax_from_payoff
        last_url
        query_params
        manual_entry
        uploaded_files
        prequalification_success
        is_hard_pull
        prequalification_errors
        cobuyer_prequalification_errors
        prequal_id
      }
    }
  }
`;

/**
 * __useTemporaryInfoQuery__
 *
 * To run a query within a React component, call `useTemporaryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemporaryInfoQuery(
  baseOptions: Apollo.QueryHookOptions<TemporaryInfoQuery, TemporaryInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TemporaryInfoQuery, TemporaryInfoQueryVariables>(
    TemporaryInfoDocument,
    options,
  );
}
export function useTemporaryInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TemporaryInfoQuery, TemporaryInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TemporaryInfoQuery, TemporaryInfoQueryVariables>(
    TemporaryInfoDocument,
    options,
  );
}
export type TemporaryInfoQueryHookResult = ReturnType<typeof useTemporaryInfoQuery>;
export type TemporaryInfoLazyQueryHookResult = ReturnType<typeof useTemporaryInfoLazyQuery>;
export type TemporaryInfoQueryResult = Apollo.QueryResult<
  TemporaryInfoQuery,
  TemporaryInfoQueryVariables
>;
export const TemporaryInfoVipDocument = gql`
  query temporaryInfoVip($id: ID!) {
    temporaryInfoVip(id: $id) {
      id
      deal_id
      data {
        year
        make
        model
        first_name
        last_name
      }
    }
  }
`;

/**
 * __useTemporaryInfoVipQuery__
 *
 * To run a query within a React component, call `useTemporaryInfoVipQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryInfoVipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryInfoVipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemporaryInfoVipQuery(
  baseOptions: Apollo.QueryHookOptions<TemporaryInfoVipQuery, TemporaryInfoVipQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TemporaryInfoVipQuery, TemporaryInfoVipQueryVariables>(
    TemporaryInfoVipDocument,
    options,
  );
}
export function useTemporaryInfoVipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TemporaryInfoVipQuery, TemporaryInfoVipQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TemporaryInfoVipQuery, TemporaryInfoVipQueryVariables>(
    TemporaryInfoVipDocument,
    options,
  );
}
export type TemporaryInfoVipQueryHookResult = ReturnType<typeof useTemporaryInfoVipQuery>;
export type TemporaryInfoVipLazyQueryHookResult = ReturnType<typeof useTemporaryInfoVipLazyQuery>;
export type TemporaryInfoVipQueryResult = Apollo.QueryResult<
  TemporaryInfoVipQuery,
  TemporaryInfoVipQueryVariables
>;
export const DealSetterDocument = gql`
  query dealSetter($dealId: ID) {
    dealSetter(dealId: $dealId) {
      setter {
        name
      }
    }
  }
`;

/**
 * __useDealSetterQuery__
 *
 * To run a query within a React component, call `useDealSetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealSetterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealSetterQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useDealSetterQuery(
  baseOptions?: Apollo.QueryHookOptions<DealSetterQuery, DealSetterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealSetterQuery, DealSetterQueryVariables>(DealSetterDocument, options);
}
export function useDealSetterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DealSetterQuery, DealSetterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealSetterQuery, DealSetterQueryVariables>(
    DealSetterDocument,
    options,
  );
}
export type DealSetterQueryHookResult = ReturnType<typeof useDealSetterQuery>;
export type DealSetterLazyQueryHookResult = ReturnType<typeof useDealSetterLazyQuery>;
export type DealSetterQueryResult = Apollo.QueryResult<DealSetterQuery, DealSetterQueryVariables>;
