import { Flex, FlexProps, Image, Text } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';

import { GetStateImage } from '../../assets/Images';
import { statesArray } from '../../constants/states';
import { EstimatorAddress, EstimatorZipCodeFallBack } from '../../helpers/Atom';
import { EstimatorTemplate, capitolZipCodes } from '../../utils/paymentCalculator';
import { capitalizeWords } from '../../utils/text/text';
import Select from './Select';

interface StateSelectedProps extends FlexProps {
  setSelected?: (val: string | null) => void;
  setHasChanged?: (val: boolean) => void;
}

const StateSelected: React.FC<StateSelectedProps> = ({ setSelected, setHasChanged, ...props }) => {
  const [address, setAddress] = useAtom(EstimatorAddress);
  const [stateSelected, setStateSelected] = useState<string | null>(null);
  const [, setZipFallBack] = useAtom(EstimatorZipCodeFallBack);
  const handleState = (state: string | null) => {
    const details:
      | {
          label: string;
          value: string;
          name: string;
        }
      | undefined = statesArray.find((s) => s.value === state);
    if (setSelected) {
      setSelected(typeof details !== 'undefined' ? details.name : state);
    }
    setAddress({
      ...address,
      state,
      stateLabel: typeof details !== 'undefined' ? details.name : '',
    });
    setStateSelected(state);
    const zState = capitolZipCodes.find((s) => s.state === state);
    setZipFallBack(zState && typeof zState !== 'undefined' ? zState.zip : null);
  };
  const handleClear = () => {
    if (setHasChanged) {
      setHasChanged(true);
    }
    setStateSelected(null);
    setAddress({
      ...address,
      state: null,
      stateLabel: null,
    });
  };
  useEffect(() => {
    if (address && address?.state) {
      setStateSelected(address.state);
    }
  }, []);
  return (
    <>
      {stateSelected ? (
        <Flex
          flex={1}
          borderWidth={1}
          borderColor={stateSelected ? EstimatorTemplate.blue : EstimatorTemplate.borderLight}
          borderRadius={EstimatorTemplate.borderRadius}
          h="60px"
          alignItems="center"
          ps={4}
          gap={2}
          justifyContent="space-between"
          onClick={() => handleClear()}
          cursor="pointer"
          transition="200ms all ease-in-out"
          bg={stateSelected && EstimatorTemplate.lightBlue}
          {...props}
        >
          <Flex alignItems="center" gap={2}>
            {address?.state && (
              <Image src={GetStateImage(address?.state)} height="28px" opacity={0.5} />
            )}
            <Text
              fontFamily={EstimatorTemplate.fontFamily}
              fontSize={EstimatorTemplate.fontSizeXLarge}
              fontWeight={400}
            >
              {capitalizeWords(address?.stateLabel)}
            </Text>
          </Flex>
          <Flex color={EstimatorTemplate.blue} pe={4} fontWeight={300}>
            CHANGE
          </Flex>
        </Flex>
      ) : (
        <Select
          placeholder="Your state..."
          options={statesArray}
          handleChange={handleState}
          isDisabled={false}
          value={address?.state || ''}
          id="peS"
        />
      )}
    </>
  );
};
export default StateSelected;
