import {
  Button,
  Container,
  Flex,
  FlexProps,
  Icon,
  Image,
  ImageProps,
  Text,
  TextProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import { BsDownload } from 'react-icons/bs';

import {
  StaticPress_LeasedToLoved25_8000,
  StaticPress_LeasedToLoved25_AvgAPR,
  StaticPress_LeasedToLoved25_AvgNewPayment,
  StaticPress_LeasedToLoved25_Demo1,
  StaticPress_LeasedToLoved25_EVsHybrids,
  StaticPress_LeasedToLoved25_EquityRetained,
  StaticPress_LeasedToLoved25_Head2025,
  StaticPress_LeasedToLoved25_HeadBronco,
  StaticPress_LeasedToLoved25_HeadRidge,
  StaticPress_LeasedToLoved25_Intro,
  StaticPress_LeasedToLoved25_PillLogo,
  StaticPress_LeasedToLoved25_PopularBrands,
  StaticPress_LeasedToLoved25_TermLength,
  StaticPress_LeasedToLoved25_Thumb,
  StaticPress_LeasedToLoved25_Top10Manuf,
  StaticPress_LeasedToLoved25_Top10Models,
} from '../../assets/Images';
import Title from '../../components/Title';

const Section = ({ children, ...props }: FlexProps) => {
  return (
    <Flex flexDir="column" {...props}>
      {children}
    </Flex>
  );
};

const SectionHeader = ({ children }: FlexProps) => {
  return (
    <Title
      textAlign={{
        base: 'center',
        md: 'left',
      }}
      color="#ffc34a"
      fontSize={28}
      textTransform="uppercase"
      fontWeight={700}
      m={0}
      mb={{
        base: 2,
        md: 4,
      }}
      p={0}
    >
      {children}
    </Title>
  );
};

const SectionText = ({ children, ...props }: TextProps) => {
  return (
    <Text
      textAlign="left"
      justifyContent="flex-start"
      alignItems="flex-start"
      color="#222"
      fontSize={18}
      lineHeight={1.5}
      fontWeight={500}
      pb={4}
      {...props}
    >
      {children}
    </Text>
  );
};

const SectionImage = ({ ...props }: ImageProps) => {
  return (
    <Image
      my={{
        base: 4,
        md: 8,
      }}
      {...props}
    />
  );
};

const Tag = ({ children }: TextProps) => {
  return (
    <Text as="span" color="#087dc5" fontWeight={700}>
      {children}
    </Text>
  );
};

const LeasedToLoved = () => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });
  return (
    <>
      <Container maxW="container.xl" textAlign="center" px={{ md: 16 }}>
        <Flex
          bg="#19315c"
          justifyContent="center"
          pos="relative"
          minHeight="370px"
          pb={8}
          mb={{
            base: 8,
            md: 0,
          }}
        >
          <Flex
            pos="relative"
            zIndex={3}
            mt={8}
            flex={1}
            ps={{
              base: 0,
              md: 16,
            }}
            flexDir="column"
            justifyContent="flex-start"
            alignItems={{
              base: 'center',
              md: 'flex-start',
            }}
          >
            <Title
              textAlign={{
                base: 'center',
                md: 'left',
              }}
              color="#ffc34a"
              fontWeight={300}
              textTransform="uppercase"
              fontSize={60}
              m={0}
              p={0}
            >
              From Leased to{' '}
              <Text as="span" fontStyle="italic" fontWeight={500}>
                Loved
              </Text>
            </Title>
            {isMobile && <Image src={StaticPress_LeasedToLoved25_Head2025} width="150px" my={8} />}
            <Title
              textAlign={{
                base: 'center',
                md: 'left',
              }}
              color="#fff"
              fontWeight={300}
              m={0}
              px={8}
              textTransform="uppercase"
              fontSize={32}
              letterSpacing="1px"
            >
              annual lease buyout report
            </Title>
            <Image
              src={StaticPress_LeasedToLoved25_HeadBronco}
              width={{
                base: '300px',
                md: '40%',
              }}
              pt={12}
            />
            <SectionText
              color="white"
              fontSize={26}
              mx={8}
              mt={8}
              textAlign={{
                base: 'center',
                md: 'left',
              }}
            >
              Key lease buyout loan statistics, trends, and insights for drivers, dealers, lenders,
              and the media
            </SectionText>
            <Flex justifyContent="flex-end" flex={1} w="100%">
              <Image src={StaticPress_LeasedToLoved25_PillLogo} width="150px" me={16} />
            </Flex>
          </Flex>
          <Image
            src={StaticPress_LeasedToLoved25_HeadRidge}
            pos="absolute"
            top={0}
            left={0}
            right={0}
            zIndex={2}
          />
          {!isMobile && (
            <Image
              src={StaticPress_LeasedToLoved25_Head2025}
              pos="absolute"
              top={48}
              right={16}
              width="46%"
              zIndex={1}
            />
          )}
        </Flex>
        <Section
          flexDir={{
            base: 'column',
            md: 'row',
          }}
          justifyContent="center"
          gap={{
            base: 2,
            md: 8,
          }}
          pt={{
            base: 4,
            md: 8,
          }}
        >
          <Button
            onClick={() => {
              window.location.href = '/assets/2025 Lease Buyout Report - PR.pdf';
            }}
            leftIcon={<Icon as={BsDownload} boxSize="16px" />}
          >
            Press Release
          </Button>
          <Button
            onClick={() => {
              window.location.href = '/assets/2025 Lease Buyout Report - Full.pdf';
            }}
            leftIcon={<Icon as={BsDownload} boxSize="16px" />}
          >
            Full Report
          </Button>
          <Button
            onClick={() => {
              window.location.href = '/assets/2025 Lease Buyout Report - One-Pager.pdf';
            }}
            leftIcon={<Icon as={BsDownload} boxSize="16px" />}
          >
            One-Page Summary
          </Button>
        </Section>
        <Flex
          my={8}
          justifyContent="flex-start"
          flexDir="column"
          alignItems="center"
          borderBottomWidth={1}
          pb={{
            base: 4,
          }}
        >
          <SectionText
            color="#888"
            fontSize={14}
            textAlign={{
              base: 'center',
              md: 'left',
            }}
          >
            Lease End 2025 Buyout Report sourced from Jan 1 - Dec 31 2024 vehicle lease buyout
            transactions and 2025 YTD insights.
          </SectionText>
        </Flex>
        <Flex
          flex={1}
          w="100%"
          gap={{
            base: 8,
            md: 16,
          }}
          flexDir={{
            base: 'column',
            md: 'row',
          }}
        >
          <Flex
            flex={1}
            flexDir="column"
            gap={{
              base: 4,
              md: 8,
            }}
          >
            <Section>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                gap={{
                  base: 4,
                  md: 8,
                }}
                flexDir={{
                  base: 'column-reverse',
                  md: 'row',
                }}
              >
                <Flex
                  w={{
                    base: '100%',
                    md: '40%',
                  }}
                >
                  <SectionText>
                    This report serves as a real-world look at how lease buyouts are shaping today's
                    auto market. Statistics are based on Lease End's propietary nationwide 2024
                    lease buyout data.
                  </SectionText>
                </Flex>
                <Flex w="60%">
                  <Image src={StaticPress_LeasedToLoved25_Intro} w="100%" my={4} />
                </Flex>
              </Flex>
            </Section>
            <Section>
              <SectionHeader>demographic data</SectionHeader>
              <SectionImage src={StaticPress_LeasedToLoved25_Demo1} />
              <SectionText>
                Over half (53%) of lease buyouts came from drivers 45 and older, preferring to keep
                the vehicle they know and trust
              </SectionText>
            </Section>
            <Section>
              <SectionHeader>MOST POPULAR BRANDS & VEHICLE TYPES</SectionHeader>
              <Flex
                flexDir={{
                  base: 'column',
                  md: 'row',
                }}
                justifyContent={{
                  base: 'center',
                  md: 'space-between',
                }}
                alignItems={{
                  base: 'center',
                  md: 'flex-start',
                }}
                gap={{ base: 4, md: 8 }}
              >
                <Flex
                  w={{
                    base: '100%',
                    md: '35%',
                  }}
                >
                  <SectionText
                    textAlign={{
                      base: 'center',
                      md: 'left',
                    }}
                  >
                    SUVs and sedans were the most popular vehicle categories for lease buyouts,
                    reflecting family and commuter needs.
                  </SectionText>
                </Flex>
                <Flex
                  w={{
                    base: '100%',
                    md: '65%',
                  }}
                >
                  <SectionImage src={StaticPress_LeasedToLoved25_PopularBrands} />
                </Flex>
              </Flex>
              <SectionText>
                Despite continued EV talk, adoption is still very low for lease buyouts - EVs
                accounted for only 1% of all lease buyouts in 2024.
              </SectionText>
              <SectionImage src={StaticPress_LeasedToLoved25_EVsHybrids} />
              <SectionText>
                Honda and Toyota lead the top 10 lease buyouts for two reasons - people trust them,
                and they hold their value.
              </SectionText>
              <SectionImage src={StaticPress_LeasedToLoved25_Top10Manuf} />
              <SectionText>
                The top 8 most popular models for lease buyouts remained the same in 2024 as in
                2023. New top- 10-ers in 2024 were the Toyota Highlander and the Honda HR-V,
                replacing the Toyota Corolla and Volkswagen Jetta in the top 10 list.
              </SectionText>
              <SectionImage src={StaticPress_LeasedToLoved25_Top10Models} />
            </Section>
          </Flex>
          <Flex
            flex={1}
            flexDir="column"
            gap={{
              base: 4,
              md: 8,
            }}
          >
            <Section mt={8}>
              <SectionHeader>equity & loan trends</SectionHeader>
              <SectionText>
                When a leased car's market value is higher than the lease payoff amount, there is
                lease equity for lease buyout candidates. Equity retained minimizes the financing
                amount needed.
              </SectionText>
              <SectionText>
                Equity for the top 10 most popular models in 2024 ranged from $2,901 to 8,730.
                <SectionImage src={StaticPress_LeasedToLoved25_EquityRetained} />
                <SectionImage src={StaticPress_LeasedToLoved25_AvgNewPayment} />
              </SectionText>
              <SectionText>
                While monthly payment, term length, and rate vary from driver to driver, 2024 data
                show favorable loan rates and monthly payments for drivers with a higher credit
                score.
                <SectionImage src={StaticPress_LeasedToLoved25_AvgAPR} />
              </SectionText>
              <SectionText>
                Americans are trying to reduce their monthly payments by spreading their lease
                buyout loan payments out further year after year, even after leasing already for 36
                months.
                <SectionImage src={StaticPress_LeasedToLoved25_TermLength} />
              </SectionText>
            </Section>
            <Section>
              <SectionHeader>mileage overage fees</SectionHeader>
              <SectionText>
                Mileage overages increased, on average (nearly 3,000 miles per driver), from 2023 to
                2024, indicating overall driving increases that may reflect return-to-office
                mandates.
              </SectionText>
              <SectionText>
                Often, drivers who go over the limit go way over. For example. lessees driving the
                Range Rover Velar exceeded the lease mileage allowance by more than 8,000 miles on
                average. If not for their lease buyout, they each would have paid their dealers
                $800-$2,400 in overage fees.
                <SectionImage src={StaticPress_LeasedToLoved25_8000} />
              </SectionText>
            </Section>
          </Flex>
        </Flex>
        <Section
          py={{
            base: 4,
            md: 8,
          }}
          mt={4}
          borderTopWidth={1}
          paddingTop={{
            base: 4,
            md: 8,
          }}
          mb={{
            base: 4,
          }}
        >
          <SectionHeader>key industry trends</SectionHeader>
          <SectionText>
            <Tag>Equity Opportunity:</Tag> Drivers are realizing that cars are often worth thousands
            more than their payoff price, leaving many with positive equity in their leased
            vehicles.
          </SectionText>
          <SectionText>
            <Tag>Buyout Popularity:</Tag> With rising lease costs, more drivers are choosing lease
            buyout loans, which often means lower monthly payments than leasing a brand-new car.
          </SectionText>
          <SectionText>
            <Tag>Looking Ahead:</Tag> Rising inflation and inventory shortages are expected to shape
            the 2025 lease buyout market and auto industry as a whole.
          </SectionText>
        </Section>
      </Container>
    </>
  );
};

export const LeasedToLoveSnippet = () => {
  return (
    <Flex
      flexDir={{
        base: 'column',
        md: 'row',
      }}
      alignItems="flex-start"
      gap={16}
      p={4}
      py={8}
      borderBottomWidth={1}
    >
      <Flex
        cursor="pointer"
        onClick={() => {
          window.location.href = '/press/press-releases/2025-lease-buyout-report';
        }}
      >
        <Image
          src={StaticPress_LeasedToLoved25_Thumb}
          width={150}
          borderRadius={8}
          borderWidth={1}
          borderColor="rgb(170, 170, 170)"
        />
      </Flex>
      <Flex
        flexDir="column"
        maxWidth={{
          base: '1024px',
        }}
        w="100%"
        mx="auto"
        gap={8}
      >
        <Text fontSize={16} color="#444" fontWeight={600}>
          March 24, 2025
        </Text>
        <Title
          textAlign="left"
          fontSize={32}
          fontWeight={700}
          m={0}
          cursor="pointer"
          onClick={() => {
            window.location.href = '/press/press-releases/2025-lease-buyout-report';
          }}
        >
          2025 Lease Buyout Report
        </Title>
      </Flex>
    </Flex>
  );
};

export default LeasedToLoved;
