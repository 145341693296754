/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable func-names */
import { useMutation } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import StepButton from '../../components/PaymentEstimator/Button';
import StepInput from '../../components/PaymentEstimator/Input';
import StepSubTitle from '../../components/PaymentEstimator/SubTitle';
import StepTitle from '../../components/PaymentEstimator/Title';
import { VIPPhoneImportDocument } from '../../gql/vipPhoneGql';
import { EstimatorFirstName, EstimatorPhone, EstimatorTempInfo } from '../../helpers/Atom';
import { VIP_PHONE_CONSTANTS } from '../../utils/globals';
import { Estimator } from '../../utils/paymentCalculator';
import { rudderanalytics } from '../../utils/rudderstack';
import Container from './Container';
import { MainProps } from './Main';

const Wrap = ({ move }: MainProps) => {
  const [importVIPPhone] = useMutation(VIPPhoneImportDocument);
  const [animate, setAnimate] = useState<boolean>(false);
  const [, setImportError] = useState<boolean>(false);
  const [, setDealId] = useState<number | null>(null);
  const [, setDone] = useState<boolean>(false);
  const [, setSubmitting] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [unwind] = useState<boolean>(false);
  const [firstName] = useAtom(EstimatorFirstName);
  const [phone, setPhone] = useAtom(EstimatorPhone);
  const [info] = useAtom(EstimatorTempInfo);
  const handlePhone = (e: {
    target: { value: string | ((prev: string | null) => string | null) | null };
  }) => {
    setPhone(e.target.value);
  };
  const handleImport = async () => {
    await importVIPPhone({
      variables: {
        id: info?.id,
        transformType: VIP_PHONE_CONSTANTS.TransformType,
        importType: VIP_PHONE_CONSTANTS.ImportType,
      },
    })
      .then((response) => {
        if (response.errors) {
          setImportError(true);
          return;
        }
        setDone(true);
        rudderanalytics.identify({
          deal_id: response.data.temporaryInfoImport.id,
          customer_id: response.data.temporaryInfoImport.customer.id,
        });
        setDealId(response.data.temporaryInfoImport.id);
      })
      .catch(() => {
        setImportError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  useEffect(() => {
    if (!phone || phone.length < 10) {
      setCanSubmit(false);
      return;
    }
    setCanSubmit(true);
  }, [phone]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, Estimator.animationDuration);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Container
      className={`calculator-step ${unwind ? 'animate-unwind' : animate ? 'animate' : ''}`}
    >
      <StepTitle>Can we get this finished for you?</StepTitle>
      <StepSubTitle>
        {firstName}, a consultant will ring you and end your lease in 10 minutes. I'll need to add
        your contact number to your file.
      </StepSubTitle>
      <Flex w="100%" flexDir="column" gap={2}>
        <StepInput name="phone" label="Contact Number" handleChange={handlePhone} />
        <StepButton actionable={canSubmit} label={`let's go!`} />
      </Flex>
    </Container>
  );
};

export default Wrap;
