import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

import { EstimatorTemplate } from '../../utils/paymentCalculator';

interface StepTitleProps extends FlexProps {
  children?: React.ReactNode;
}

const StepTitle: React.FC<StepTitleProps> = ({ children, ...props }) => {
  if (!children) {
    return null;
  }
  return (
    <>
      <Flex
        color="#1b1c1b"
        fontSize={{
          base: '28px',
          md: '32px',
        }}
        fontWeight={700}
        m={0}
        textAlign={{
          base: 'center',
          md: 'center',
        }}
        justifyContent={{
          base: 'center',
          md: 'center',
        }}
        fontFamily={EstimatorTemplate.fontFamily}
        lineHeight={1.3}
        {...props}
        alignItems={{
          base: 'center',
        }}
        gap={{
          base: 4,
          md: 0,
        }}
        flexDir="column"
      >
        {children}
      </Flex>
    </>
  );
};

export default StepTitle;
