import { Influencer_DealerPlateGuy } from '../../../assets/Images';
import Wrapper from './Wrapper';

const DealerPlateGuy = () => {
  return (
    <Wrapper
      influencer={{
        name: 'Dealer Plate Guy',
        imagePath: Influencer_DealerPlateGuy,
        imageWidth: 220,
        styleImage: true,
      }}
    />
  );
};

export default DealerPlateGuy;
