import { Influencer_NoahKettle } from '../../../assets/Images';
import Wrapper from './Wrapper';

const NoahKettle = () => {
  return (
    <Wrapper
      influencer={{
        name: 'Noah Kettle',
        imagePath: Influencer_NoahKettle,
        imageWidth: 220,
      }}
    />
  );
};

export default NoahKettle;
