import { Influencer_RideWithYusuf } from '../../../assets/Images';
import Wrapper from './Wrapper';

const RideYusuf = () => {
  return (
    <Wrapper
      influencer={{
        name: 'Ride with Yusuf',
        imagePath: Influencer_RideWithYusuf,
        imageWidth: 220,
        styleImage: true,
      }}
    />
  );
};

export default RideYusuf;
