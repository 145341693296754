import { gql } from '@apollo/client';

export const CreateTemporaryInfo = gql`
  mutation createTemporaryInfo(
    $vin: String
    $license_plate_number: String
    $license_plate_state: String
    $device_type: String
    $extra_info: extraTempInfoInputType
    $query_params: String
    $ignore_existing_deal: Boolean
  ) {
    createTemporaryInfo(
      vin: $vin
      license_plate_number: $license_plate_number
      license_plate_state: $license_plate_state
      device_type: $device_type
      extra_info: $extra_info
      query_params: $query_params
      ignore_existing_deal: $ignore_existing_deal
    ) {
      id
      data {
        vin
        year
        make
        model
        fuel_type
        vehicle_type
        phone_number
        email
        first_name
        middle_name
        last_name
        address_line
        address_line_2
        city
        state
        county
        zip
      }
    }
  }
`;
