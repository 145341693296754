import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AUTOMATIC_URLS } from '../utils/globals';

const useAutomatic = () => {
  const { pathname } = useLocation();
  const [automatic, setAutomatic] = useState<boolean>(false);
  useEffect(() => {
    setAutomatic(AUTOMATIC_URLS.includes(pathname));
  }, []);

  return automatic;
};

export default useAutomatic;
