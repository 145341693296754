/* eslint-disable react/jsx-pascal-case */
import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';

import {
  AsSeenOnTv,
  CivicGuy,
  Influencer_ETMPanel1,
  Influencer_ETMPanel2,
  Influencer_ETMPanel3,
  Influencer_MovingMerch,
  NoDealership,
  OverMileage,
  VehicleDamage,
} from '../../../assets/Images';
import LicensePlateOrVinInput_LPSimple from '../../../components/LicensePlateOrVinInput_LPSimple';
import Title from '../../../components/Title';
import { fetchMarketingDeals } from '../../../utils/globals';
import Benefit from './Benefit';

const MovingMerch = () => {
  return (
    <>
      <Container maxW="1200px" my={4} pb={16}>
        <Flex w="100%" alignItems="flex-start" justifyContent="space-between">
          <Flex
            flexDir="column"
            justifyContent={{
              base: 'center',
              md: 'flex-start',
            }}
            flex={1}
            alignItems="flex-start"
            mt={{
              base: 8,
              md: 0,
            }}
          >
            <Flex
              flexDir={{
                base: 'column',
                md: 'row',
              }}
              justifyContent={{
                base: 'center',
                md: 'flex-start',
              }}
              alignItems={{
                base: 'center',
                md: 'flex-start',
              }}
              gap={6}
            >
              <Box
                pos="relative"
                display={{
                  base: 'block',
                  md: 'block',
                }}
              >
                <Image
                  display="none"
                  pos="absolute"
                  zIndex={2}
                  src={AsSeenOnTv}
                  width="64px !important"
                  top="-4px"
                  left="-12px"
                  transform="rotate(-2deg)"
                />
                <Image
                  pos="relative"
                  zIndex={1}
                  src={Influencer_MovingMerch}
                  width="210px !important"
                  background="white"
                  padding={1}
                  borderRadius={5}
                  boxShadow="0 0px 12px 12px rgba(0,0,0,0.1)"
                  transform="rotate(-2deg)"
                />
              </Box>
              <Title
                fontSize={{
                  base: 48,
                  md: 56,
                }}
                lineHeight={1.2}
                fontWeight={800}
                color="#000"
                textAlign={{
                  base: 'center',
                  md: 'left',
                }}
                m={0}
                p={0}
              >
                <Text fontWeight={500} as="span" display="block" fontSize={16}>
                  Recommended by{' '}
                  <Text as="span" fontWeight={700}>
                    Moving Merch
                  </Text>
                  !
                </Text>
                End your lease, keep your car & avoid the dealership!
              </Title>
            </Flex>
            <Flex mt="32px">
              <Title
                textAlign={{
                  base: 'center',
                  md: 'left',
                }}
                color="#000"
                fontSize={{
                  base: 24,
                  md: 28,
                }}
                m={0}
                p={0}
                fontWeight={800}
                lineHeight={1.4}
              >
                Don’t let the dealership trick you into extra fees
              </Title>
            </Flex>
            <Box
              borderBottomWidth={1}
              borderColor="#ddd"
              pb={8}
              mt="32px"
              display={{
                base: 'block',
                md: 'block',
              }}
            >
              <Title textAlign="left" fontSize={32} fontWeight={800} color="black">
                <Text as="div" fontWeight={400} fontSize={18} lineHeight={1.2}>
                  Some shady dealerships want to keep you leasing forever, because they get to own
                  your car and charge you extra fees every time you turn in your lease.
                  <Text as="span" pt={4} display="block" />
                  There’s a better way –{' '}
                  <Text as="span" fontWeight={600}>
                    buy out your lease on your terms with Lease End:
                  </Text>
                  <Box pt={0} lineHeight={1.4} mx={5} mt={10} fontWeight={700}>
                    ✅ Avoid unnecessary fees
                    <Text as="span" pt={4} display="block" />
                    ✅ Registration & titling taken care of
                    <Text as="span" pt={4} display="block" />✅ Protect the money you’ve already
                    invested in the car
                  </Box>
                </Text>
              </Title>
            </Box>
            <Title
              textAlign={{
                base: 'center',
                md: 'left',
              }}
              color="#012D5D"
              fontSize={{
                base: 16,
                md: 18,
              }}
              mx={0}
              mt={{
                base: 8,
              }}
              mb={{
                base: 4,
                md: 0,
              }}
              p={0}
              fontWeight={500}
              lineHeight={1.3}
              display="none"
            >
              We've helped more than{' '}
              <Text as="span" fontWeight={800}>
                {fetchMarketingDeals(false)} people
              </Text>{' '}
              end their lease and keep their car!
            </Title>
            <Flex
              mt={{
                base: '16px',
                md: '24px',
              }}
              flexDir="row"
              flex={1}
              w="100%"
              alignItems="flex-start"
              gap={8}
            >
              <LicensePlateOrVinInput_LPSimple cta="Find My Loan" />
              <Image
                display={{
                  sm: 'none',
                  md: 'block',
                }}
                src={CivicGuy}
                w={{
                  sm: '0',
                  md: '350px',
                  lg: '450px',
                }}
                mt="-58px"
              />
            </Flex>
            <Flex
              display={{
                base: 'flex',
                md: 'none',
              }}
              w="100%"
              mt={8}
            >
              <Image src={CivicGuy} w="100%" />
            </Flex>
          </Flex>
        </Flex>
        <Flex flex={1} w="100%" px={10} gap={3} mt={10} display="none">
          <Image src={Influencer_ETMPanel2} display="flex" w="100%" />
          <Image src={Influencer_ETMPanel3} display="flex" w="100%" />
          <Image src={Influencer_ETMPanel1} display="flex" w="100%" />
        </Flex>
        <Flex
          mt={{
            base: 8,
            md: 16,
          }}
          pt={{
            base: 8,
            md: 0,
          }}
          borderTopWidth={{
            base: 1,
            md: 0,
          }}
          justifyContent="space-between"
          gap={3}
          flexDir={{
            base: 'column-reverse',
            md: 'row',
          }}
        >
          <Benefit image={NoDealership} tag="No hassle at the dealership" />
          <Benefit image={OverMileage} tag="No fees for too many miles!" />
          <Benefit image={VehicleDamage} tag="No charge for damages!" />
        </Flex>
      </Container>
    </>
  );
};

export default MovingMerch;
