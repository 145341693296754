/* eslint-disable @typescript-eslint/ban-types */

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Input as ChakraInput,
  ChakraProvider,
  FormControl,
  FormLabel,
  InputProps,
  PropsOf,
  extendTheme,
} from '@chakra-ui/react';
import ReactInputMask from 'react-input-mask';

import { EstimatorTemplate } from '../../utils/paymentCalculator';

interface StepInputProps extends InputProps {
  placeholder?: string;
  name: string;
  id?: string;
  label?: string | null;
  mask?: string;
  value?: string;
  handleBlur?: (event: any) => void;
  handleChange?: (event: any) => void;
  debounce?: string;
  _input?: PropsOf<typeof ChakraInput>;
}

const activeLabelStyles = {
  transform: 'translateY(-28px) translateX(-4px)',
  color: EstimatorTemplate.blue,
  fontWeight: 500,
  fontSize: EstimatorTemplate.fontSizeMed,
  opacity: 1,
};
export const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 3,
              left: 0,
              zIndex: 1,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
              fontSize: 16,
              fontWeight: 500,
              opacity: 0.5,
              color: EstimatorTemplate.darkText,
            },
          },
        },
      },
    },
  },
});

const StepInput = ({
  name,
  id = '',
  placeholder = '',
  label = null,
  handleBlur,
  handleChange,
  mask = '',
  debounce = '',
  ...rest
}: StepInputProps) => {
  return (
    <>
      <ChakraProvider theme={theme}>
        <FormControl variant="floating" id="first-name">
          <ChakraInput
            as={ReactInputMask}
            onBlur={handleBlur}
            onChange={handleChange}
            name={name}
            id={id}
            mask={mask}
            _input={{
              as: ReactInputMask,
              mask,
              maskPlaceholder: '',
            }}
            placeholder={!label ? placeholder : ''}
            fontWeight={600}
            fontFamily={EstimatorTemplate.fontFamily}
            fontSize={16}
            borderWidth={1}
            borderColor={`${EstimatorTemplate.borderLight}`}
            borderRadius={EstimatorTemplate.borderRadius}
            h="60px"
            debounce={debounce}
            {...rest}
          />
          {label && <FormLabel>{label}</FormLabel>}
        </FormControl>
      </ChakraProvider>
    </>
  );
};
export default StepInput;
