/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-undef */

/* eslint-disable @typescript-eslint/ban-types */
import { Flex, FlexProps } from '@chakra-ui/react';
import { IconContext } from 'react-icons';
import { LuChevronsUpDown } from 'react-icons/lu';
import { PiXBold } from 'react-icons/pi';

import { EstimatorTemplate } from '../../utils/paymentCalculator';

interface StepButtonSmallProps extends FlexProps {
  label: string | null;
  handleClear?: (event: unknown) => void;
  handleClick?: (event: unknown) => void | null | undefined;
  clickIcon?: any;
  clickIconColor?: string;
  prependIcon?: any;
  disabled?: boolean;
}
const StepButtonSmall = ({
  label,
  handleClear,
  handleClick,
  clickIcon,
  clickIconColor,
  prependIcon,
  disabled,
  ...rest
}: StepButtonSmallProps) => {
  return (
    <>
      <Flex
        userSelect="none"
        borderRadius={EstimatorTemplate.borderRadius}
        fontSize={14}
        fontFamily={EstimatorTemplate.fontFamily}
        p={2}
        pe={prependIcon ? 4 : 2}
        as="span"
        fontWeight={500}
        bg={`${EstimatorTemplate.lightGray}`}
        display="inline-flex"
        alignItems="center"
        color={`${EstimatorTemplate.darkText}`}
        justifyContent="space-between"
        cursor={disabled ? 'not-allowed' : handleClick ? 'pointer' : 'default'}
        onClick={!disabled ? handleClick : () => null}
        opacity={disabled ? 0.5 : 1}
        {...rest}
      >
        {prependIcon && <>{prependIcon}</>}
        <>{label}</>
        {handleClick && (
          <Flex ms={2} alignItems="center">
            <IconContext.Provider
              value={{
                size: '14px',
                color: `${clickIconColor || EstimatorTemplate.blue}`,
              }}
            >
              {clickIcon ? <>{clickIcon}</> : <LuChevronsUpDown />}
            </IconContext.Provider>
          </Flex>
        )}
        {handleClear && (
          <Flex
            ms={2}
            alignItems="center"
            ps={2}
            borderStartWidth={1}
            cursor="pointer"
            title="Reset"
            onClick={handleClear || null}
          >
            <IconContext.Provider
              value={{
                size: `${EstimatorTemplate.fontSizeSmall}px`,
                color: `${EstimatorTemplate.lightText}`,
              }}
            >
              <PiXBold />
            </IconContext.Provider>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default StepButtonSmall;
