import { Flex } from '@chakra-ui/react';

const NoPosts = ({ label = '.' }: { label?: string }) => {
  return (
    <>
      <Flex
        p={4}
        bg="#fff"
        justifyContent="center"
        alignItems="center"
        fontSize={16}
        fontWeight={700}
      >
        No posts found{label}
      </Flex>
    </>
  );
};
export default NoPosts;
