import { Flex, Image, Text } from '@chakra-ui/react';

interface BenefitProps {
  image: string;
  tag: string;
}

const Benefit = ({ image = '', tag = '' }: BenefitProps) => {
  return (
    <>
      <Flex
        flex={1}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        borderWidth={1}
        background="#f7f7f7"
        borderRadius={8}
        p={4}
        px={8}
        gap={3}
      >
        <Text textTransform="capitalize" fontSize={16} fontWeight={800} color="#000">
          {tag}
        </Text>
        <Image src={image} h="100px" />
      </Flex>
    </>
  );
};
export default Benefit;
