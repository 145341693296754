/* eslint-disable @typescript-eslint/ban-types */

/* eslint-disable func-names */
import { useAtom } from 'jotai';

import Messaging from '../../components/PaymentEstimator/Messaging';
import { EstimatorConversation, EstimatorImportDone, EstimatorStep } from '../../helpers/Atom';
import { Estimator } from '../../utils/paymentCalculator';
import LicensePlateVIN from './LicensePlateVIN';
import MakeModelYear from './MakeModelYear';
import Name from './Name';
import Result from './Result';
import SoftPull from './SoftPull';
import State from './State';
import Wrap from './Wrap';

export interface MainProps {
  move: Function;
}

const Main = () => {
  const [step, setStep] = useAtom(EstimatorStep);
  const [messaging] = useAtom(EstimatorConversation);
  const [importDone] = useAtom(EstimatorImportDone);
  const move = (goStep: string) => {
    setTimeout(function () {
      setStep(goStep);
    }, Estimator.animationDuration);
  };
  return (
    <>
      {!importDone && <Messaging>{messaging}</Messaging>}
      {step === 'Result' ? (
        <Result move={move} />
      ) : step === 'SoftPull' ? (
        <SoftPull move={move} />
      ) : step === 'Wrap' ? (
        <Wrap move={move} />
      ) : step === 'MakeModelYear' ? (
        <MakeModelYear move={move} />
      ) : step === 'LicensePlate' ? (
        <LicensePlateVIN move={move} />
      ) : step === 'State' ? (
        <State move={move} />
      ) : (
        <Name move={move} />
      )}
    </>
  );
};

export default Main;
