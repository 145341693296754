import { Box, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Footer from '../../components/Footer';
import Nav from '../../components/Nav/Nav';
import { COMErrorCodes } from '../../constants/errors';
import { QueryParams } from '../../constants/queryParam';
import useRefSize from '../../hooks/useRefSize';
import { createErrorToast } from '../../utils/toast';
import ExpiredPayoffRequestModal from '../Home/components/ExpiredPayoffRequestModal';
import RefiBgImage from '../Home/components/RefiBgImage';
import RefiHero from '../Home/components/RefiHero';
import RefiSteps from '../Home/components/RefiSteps';
import ReviewLinks from '../Home/components/ReviewLinks';

const Home = () => {
  const [expiredPayoffRequestModalOpen, setExpiredPayoffRequestModalOpen] = useState(false);
  const [imgRef, { height }] = useRefSize<HTMLImageElement>();
  const {
    replace,
    location: { search },
  } = useHistory();
  const toast = useToast();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const errCode = params.get(QueryParams.ERR_CODE);
    if (errCode === COMErrorCodes.NoDealFound) {
      params.delete(QueryParams.ERR_CODE);
      replace({ search: params.toString() });
      toast(
        createErrorToast({
          description:
            "We weren't able to find any information associated with the email you " +
            'provided. Please enter your License Plate or VIN to get started. If you think this ' +
            'is an error, please get in touch with our support team for help.',
        }),
      );
    }
  }, [search]);

  return (
    <>
      <Box h="100%">
        <Nav />
        <RefiBgImage ref={imgRef} />
        <RefiHero h={height ? height - height * 0.165 : 910} />
        <RefiSteps />
        <ReviewLinks />
        <Footer bg="matisseBlue" color="white" _socialMediaIcon={{ color: 'white' }} />
      </Box>
      <ExpiredPayoffRequestModal
        isOpen={expiredPayoffRequestModalOpen}
        onClose={() => setExpiredPayoffRequestModalOpen(false)}
      />
    </>
  );
};

export default Home;
