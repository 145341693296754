/* eslint-disable no-empty */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Link, useBreakpointValue } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';

import StepButton from '../../components/PaymentEstimator/Button';
import Input from '../../components/PaymentEstimator/Input';
import { addMessaging } from '../../components/PaymentEstimator/MessagingText';
import StepOption from '../../components/PaymentEstimator/Option';
import ResultsContainer from '../../components/PaymentEstimator/ResultsContainer';
import { LDFlags } from '../../constants/experiments';
import {
  EstimatorAddress,
  EstimatorConversation,
  EstimatorConversationStrings,
  EstimatorFirstName,
  EstimatorLastName,
  EstimatorPulse,
  EstimatorPulseResult,
} from '../../helpers/Atom';
import { hashUrl } from '../../helpers/Estimator';
import { useFlag } from '../../hooks';
import { decodeGoogleApiResponse } from '../../utils/address';
import { Estimator, EstimatorTemplate } from '../../utils/paymentCalculator';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import Container from './Container';
import { AUTOMATIC_DIALOG } from './Dialog';
import { MainProps } from './Main';

const SoftPull = ({ move }: MainProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [firstName, setFirstName] = useAtom(EstimatorFirstName);
  const [address, setAddress] = useAtom(EstimatorAddress);
  const [lastName, setLastName] = useAtom(EstimatorLastName);
  const [, setPulse] = useAtom(EstimatorPulse);
  const [pulseResult, setPulseResult] = useAtom(EstimatorPulseResult);
  const [animate, setAnimate] = useState<boolean>(false);
  const [unwind, setUnwind] = useState<boolean>(false);
  const [googleAddress, setGoogleAddress] = useState<string | null>(null);
  const [autoCompleted, setAutoCompleted] = useState<boolean>(false);
  const [googleFocus, setGoogleFocus] = useState<boolean>(false);
  const [showGoogleResults, setShowGoogleResults] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [softPull, setSoftPull] = useState<boolean | undefined>(true);
  const [skipPrelim, setSkipPrelim] = useState<boolean>(false);
  const [skipPrelimCount, setSkipPrelimCount] = useState<number>(0);
  const [skipConfirm, setSkipConfirm] = useState<boolean>(false);
  const [somethingWrong, setSomethingWrong] = useState<boolean>(false);
  const [userCredit, setUserCredit] = useState<string | null>(null);
  const LM1755 = useFlag(LDFlags.LM1755);
  const INTRO = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'softpull-address');
  const INTRO_CONTROL = INTRO?.control || '';
  const INTRO_EXPERIMENT = INTRO?.experiment || '';
  const initialMessage = LM1755 ? INTRO_EXPERIMENT : INTRO_CONTROL;
  const [messageStrings, setMessageStrings] = useAtom(EstimatorConversationStrings);
  const [, setMessaging] = useAtom(EstimatorConversation);
  const tracking = () => {
    rudderanalytics.track(RudderEvent.LabTest, {
      name: 'LM-1755::SoftPull',
      value: LM1755,
    });
  };
  const handleSubmit = async () => {
    setSubmitting(true);
    const DONE = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'softpull-done');
    const DONE_EXPERIMENT = DONE?.experiment || '';
    const DONE_CONTROL = DONE?.control || '';
    const m = LM1755 ? DONE_EXPERIMENT : DONE_CONTROL;
    const ms = messageStrings.concat(m);
    setMessageStrings(ms);
    setMessaging(addMessaging(m, ms));
    try {
      setTimeout(() => {
        setUnwind(true);
        move('Result');
      }, Estimator.animationDuration * 6);
    } catch {}
    return null;
  };
  const handleUserCredit = (credit: string) => {
    setUserCredit(credit);
    handleSubmit();
    setTimeout(() => {
      setPulse(false);
      setPulseResult(credit);
    }, Estimator.animationDuration * 4);
  };
  const handleAddress = (decodedAddress: any) => {
    setAddress({
      street: `${decodedAddress.streetNumber || null} ${decodedAddress.route || null}`,
      apt_unit: null,
      city: decodedAddress.city || null,
      state: decodedAddress.state || null,
      zip: decodedAddress.zipCode || null,
      county: null,
    });
  };
  const handleSkipPrelim = (skip: boolean) => {
    setSkipPrelim(skip);
    if (skip === false) {
      const m = `Great! I'll be able to get your rate dialed in!  Now for your address...`;
      const ms = messageStrings.concat(m);
      setMessageStrings(ms);
      setMessaging(addMessaging(m, ms));
    }
  };
  useEffect(() => {
    if (skipPrelim) {
      setSkipPrelimCount(skipPrelimCount + 1);
      const ADDRESS_NO = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'softpull-address-no');
      const ADDRESS_NO_EXPERIMENT = ADDRESS_NO?.experiment || '';
      const ADDRESS_NO_CONTROL = ADDRESS_NO?.control || '';
      const m =
        skipPrelimCount > 0
          ? `No problem, to remind you this will not ding your credit and will help me get an accurate estimate. Are you still sure?`
          : LM1755
          ? ADDRESS_NO_EXPERIMENT
          : ADDRESS_NO_CONTROL;
      const ms = messageStrings.concat(m);
      setMessageStrings(ms);
      setMessaging(addMessaging(m, ms));
    }
  }, [skipPrelim]);
  useEffect(() => {
    if (somethingWrong) {
      const m = `⚠️ Good catch! Let's make sure everything is correct below.`;
      const ms = messageStrings.concat(m);
      setMessageStrings(ms);
      setMessaging(addMessaging(m, ms));
    }
  }, [somethingWrong]);
  useEffect(() => {
    if (skipConfirm) {
      const m = `👌 Alright, no worries! What best describes your credit?`;
      const ms = messageStrings.concat(m);
      setMessageStrings(ms);
      setMessaging(addMessaging(m, ms));
      setSkipPrelim(false);
    }
  }, [skipConfirm]);
  useEffect(() => {
    if (!address) {
      setCanSubmit(false);
      return;
    }
    setPulse(true);
    setCanSubmit(true);
  }, [address]);
  useEffect(() => {
    if (autoCompleted === true) {
      const ADDRESS_CONFIRM = AUTOMATIC_DIALOG.find(
        (dialog) => dialog.step === 'softpull-address-confirm',
      );
      const ADDRESS_CONFIRM_EXPERIMENT = ADDRESS_CONFIRM?.experiment || '';
      const ADDRESS_CONFIRM_CONTROL = ADDRESS_CONFIRM?.control || '';
      const m = LM1755 ? ADDRESS_CONFIRM_EXPERIMENT : ADDRESS_CONFIRM_CONTROL;
      const ms = messageStrings.concat(m);
      setMessageStrings(ms);
      setMessaging(addMessaging(m, ms));
    }
  }, [autoCompleted]);
  useEffect(() => {
    tracking();
    const timer = setTimeout(() => {
      setAnimate(true);
    }, Estimator.animationDuration);
    const handleKeyPress = (event: { key: any }) => {
      if (event.key === 'Enter' && typeof softPull === 'undefined') {
        setSoftPull(true);
        return;
      }
      if (event.key === 'Enter' && canSubmit && !submitting) {
        handleSubmit();
      }
    };
    window.addEventListener('keypress', handleKeyPress);
    const ms = messageStrings.concat(initialMessage);
    setMessageStrings(ms);
    setMessaging(addMessaging(initialMessage, ms));
    hashUrl('EstimatorSoftPull', true);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      <Container
        className={`calculator-step ${unwind ? 'animate-unwind' : animate ? 'animate' : ''}`}
      >
        <Flex flexDir="column" gap={4}>
          {skipConfirm && (
            <>
              <Flex flexDir="column" gap={2} flex={1} mt={4}>
                <StepOption
                  label="Very Good"
                  note="750-850"
                  optionSelected={userCredit === 'very_good'}
                  customOnClick={() => handleUserCredit('very_good')}
                />
                <StepOption
                  label="Good"
                  note="700-749"
                  optionSelected={userCredit === 'good'}
                  customOnClick={() => handleUserCredit('good')}
                />
                <StepOption
                  label="Fair"
                  note="650-699"
                  optionSelected={userCredit === 'fair'}
                  customOnClick={() => handleUserCredit('fair')}
                />
                <StepOption
                  label="Below Average"
                  note="less than 650"
                  optionSelected={userCredit === 'poor'}
                  customOnClick={() => handleUserCredit('poor')}
                />
              </Flex>
            </>
          )}
        </Flex>
        {typeof softPull === 'undefined' ? (
          <Flex gap={2} mt={4}>
            <StepOption
              label="No"
              optionSelected={softPull === false}
              customOnClick={() => setSoftPull(false)}
            />
            <StepOption
              label="Yes"
              optionSelected={softPull === true}
              customOnClick={() => setSoftPull(true)}
              reco
            />
          </Flex>
        ) : (
          !pulseResult && (
            <>
              <Flex justifyContent="center" gap={2} w="100%">
                <Flex flexDir="column" gap={2} w="100%">
                  <Flex w="100%" flexDir="row">
                    {autoCompleted ? (
                      <>
                        <ResultsContainer gap={4}>
                          <Box fontWeight={700} fontSize={EstimatorTemplate.fontSizeXLarge}>
                            {`${firstName?.toLocaleUpperCase()} ${lastName?.toLocaleUpperCase()}`}
                          </Box>
                          <Box>{googleAddress}</Box>
                        </ResultsContainer>
                      </>
                    ) : !skipPrelim ? (
                      <>
                        <Flex
                          flex={1}
                          flexDir="column"
                          width="100%"
                          gap={2}
                          justifyContent="flex-start"
                        >
                          {!skipConfirm && (
                            <>
                              {somethingWrong && (
                                <Flex gap={2} opacity={submitting ? 0.5 : 1} w="100%">
                                  <Input
                                    debounce="true"
                                    name="pqFirstName"
                                    placeholder="First name"
                                    label="First name"
                                    onChange={(e) => {
                                      setFirstName(e.target.value ? e.target.value : null);
                                    }}
                                    bg="white"
                                    isDisabled={submitting}
                                    autoFocus
                                    defaultValue={firstName || ''}
                                  />
                                  <Input
                                    debounce="true"
                                    name="pqLastName"
                                    placeholder="Last name"
                                    label="Last name"
                                    onChange={(e) => {
                                      setLastName(e.target.value ? e.target.value : null);
                                    }}
                                    bg="white"
                                    isDisabled={submitting}
                                    defaultValue={lastName || ''}
                                  />
                                </Flex>
                              )}
                              <Box w="100%">
                                <GooglePlacesAutocomplete
                                  apiKey="AIzaSyBT7f-_OiQ75OrbE_Ux0-onKUcz1HBQfZc"
                                  autocompletionRequest={{
                                    types: [],
                                    componentRestrictions: { country: 'us' },
                                  }}
                                  selectProps={{
                                    placeholder: 'Current Address',
                                    styles: {
                                      option: (provided: any) => ({
                                        ...provided,
                                        color: EstimatorTemplate.darkText,
                                      }),
                                      menu: (provided: any) => ({
                                        ...provided,
                                        marginTop: '0px',
                                        display: showGoogleResults ? 'flex' : 'none',
                                        width: '100%',
                                      }),
                                      menuList: (provided: any) => ({
                                        ...provided,
                                        fontSize: 14,
                                        color: EstimatorTemplate.darkText,
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                        fontWeight: 400,
                                        width: '100%',
                                        '&:hover': {
                                          bg: EstimatorTemplate.lightBlue,
                                        },
                                      }),
                                      control: (provided: any) => ({
                                        ...provided,
                                        boxShadow: 'none',
                                        borderRadius: '4px',
                                        borderWidth: googleFocus ? 2 : 1,
                                        borderColor: googleFocus
                                          ? `${EstimatorTemplate.blue} !important`
                                          : `${EstimatorTemplate.borderLight}`,
                                        backgroundColor: 'white',
                                        height: '60px',
                                        zIndex: 3,
                                      }),
                                      input: () => ({
                                        autoComplete: `address-line1`,
                                        fontSize: 16,
                                      }),
                                      valueContainer: (provided: any) => ({
                                        ...provided,
                                        overflow: 'visible',
                                      }),
                                      dropdownIndicator: () => ({
                                        display: 'none',
                                      }),
                                      indicatorSeparator: () => ({
                                        display: 'none',
                                      }),
                                      placeholder: () => ({
                                        fontSize: googleFocus ? 14 : 16,
                                        opacity: googleFocus ? 1 : 0.5,
                                        color: googleFocus
                                          ? EstimatorTemplate.blue
                                          : EstimatorTemplate.darkText,
                                        fontWeight: googleFocus ? 700 : 500,
                                        transition: `200ms all ease-in-out`,
                                        position: 'absolute',
                                        background: 'white',
                                        display: 'inline-flex',
                                        top: '0px',
                                        left: '8px',
                                        padding: '0 4px',
                                        transformOrigin: 'left top',
                                        zIndex: 3,
                                        transform: googleFocus
                                          ? `translateY(-27px) translateX(-4px)`
                                          : 'none',
                                      }),
                                    },
                                    value: googleAddress ? { googleAddress } : null,
                                    onChange: async (newValue: any) => {
                                      const gResult = await geocodeByAddress(newValue?.label);
                                      const decodedAddress = decodeGoogleApiResponse(
                                        gResult[0]?.address_components,
                                      );
                                      setGoogleAddress(
                                        `${decodedAddress.streetNumber} ${decodedAddress.route}, ${decodedAddress.city}, ${decodedAddress.state} ${decodedAddress.zipCode}`,
                                      );
                                      handleAddress(decodedAddress);
                                      setAutoCompleted(true);
                                      setGoogleFocus(false);
                                    },
                                    onInputChange: (newValue: any) => {
                                      setShowGoogleResults(newValue && newValue.length > 2);
                                    },
                                    onFocus: () => {
                                      setGoogleFocus(true);
                                    },
                                    onBlur: () => {
                                      setGoogleFocus(false);
                                    },
                                  }}
                                />
                              </Box>
                            </>
                          )}
                        </Flex>
                      </>
                    ) : (
                      <>
                        <Flex flex={1} flexDir="column">
                          <StepButton
                            handleClick={() => handleSkipPrelim(false)}
                            label={
                              skipPrelimCount > 1 ? "Let's do it" : 'go ahead and check credit'
                            }
                            actionable
                          />
                          <Link
                            fontFamily={EstimatorTemplate.fontFamily}
                            mt={4}
                            textAlign="center"
                            onClick={() => {
                              hashUrl('EstimatorSoftPullSkip', true);
                              setSkipConfirm(true);
                              setPulse(false);
                            }}
                          >
                            {skipPrelimCount > 0 ? 'Yes, please skip' : 'Please skip'}
                          </Link>
                        </Flex>
                      </>
                    )}
                  </Flex>
                  {!autoCompleted && !skipPrelim && !skipConfirm && (
                    <>
                      <Flex
                        justifyContent={{
                          base: 'center',
                        }}
                        ps={{
                          base: 2,
                          md: 0,
                        }}
                      >
                        <Link
                          display="inline-block"
                          fontFamily={EstimatorTemplate.fontFamily}
                          onClick={() => setSkipPrelim(true)}
                        >
                          Skip
                        </Link>
                      </Flex>
                    </>
                  )}
                  {canSubmit && !submitting && autoCompleted && (
                    <Flex gap={2}>
                      <StepOption
                        label="no"
                        optionSelected={false}
                        fontWeight={400}
                        color={EstimatorTemplate.lightText}
                        customOnClick={() => {
                          setGoogleAddress(null);
                          setSomethingWrong(true);
                          setCanSubmit(false);
                          setSubmitting(false);
                          setAutoCompleted(false);
                        }}
                      />
                      <StepOption
                        label="yes"
                        optionSelected={false}
                        customOnClick={() => (canSubmit && !submitting ? handleSubmit() : null)}
                        reco
                      />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </>
          )
        )}
      </Container>
    </>
  );
};

export default SoftPull;
// on enter behavior
// josh gradient images
// LOOKG INTO double page pushes to rudderstack on name
