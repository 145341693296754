/* eslint-disable jsx-a11y/media-has-caption */

/* eslint-disable react/jsx-pascal-case */
import { Box, Container, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import { useEffect } from 'react';

import {
  CivicGuy,
  LeaseLunacyLogo,
  NoDealership,
  OverMileage,
  VehicleDamage,
} from '../../assets/Images';
import LicensePlateOrVinInput_LPSimple from '../../components/LicensePlateOrVinInput_LPSimple';
import Title from '../../components/Title';
import { LDFlags } from '../../constants/experiments';
import { useFlag } from '../../hooks';
import { fetchMarketingDeals } from '../../utils/globals';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';

interface BenefitProps {
  image: string;
  tag: string;
}

const TARIFF_HOOK =
  'New car prices are poised to JUMP 4,000 to $12,500! Instead of paying more for a new car at the end of your lease take 12 minutes with us to end your lease and keep your car!';

const Benefit = ({ image = '', tag = '' }: BenefitProps) => {
  return (
    <>
      <Flex
        flex={1}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        borderWidth={1}
        background="#f7f7f7"
        borderRadius={8}
        p={4}
        px={8}
        gap={3}
      >
        <Text textTransform="capitalize" fontSize={16} fontWeight={800} color="#000">
          {tag}
        </Text>
        <Image src={image} h="100px" />
      </Flex>
    </>
  );
};

const SubHeading = () => {
  return (
    <Flex mt="32px">
      <Title
        textAlign={{
          base: 'center',
          md: 'left',
        }}
        color="#000"
        fontSize={{
          base: 20,
          md: 24,
        }}
        m={0}
        p={0}
        fontWeight={500}
        lineHeight={1.4}
      >
        We've helped more than{' '}
        <Text as="span" fontWeight={800}>
          {fetchMarketingDeals(false)} people
        </Text>{' '}
        end their lease and keep their car!
      </Title>
    </Flex>
  );
};

const LPSimple = () => {
  const LM1839 = useFlag(LDFlags.LM1839);
  const LM1875 = useFlag(LDFlags.LM1875);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const tracking = () => {
    rudderanalytics.track(RudderEvent.LabTest, {
      name: 'LM-1839',
      value: LM1839,
    });
  };
  useEffect(() => {
    tracking();
  }, []);
  return (
    <>
      <Container maxW="1200px" my={4} pb={16}>
        <Flex w="100%" alignItems="flex-start" justifyContent="space-between">
          <Flex
            flexDir="column"
            justifyContent={{
              base: 'center',
              md: 'flex-start',
            }}
            flex={1}
            alignItems="flex-start"
            mt={{
              base: 8,
              md: 0,
            }}
          >
            <Flex flexDir="row" justifyContent="flex-start" alignItems="center" gap={8}>
              <Title
                fontSize={{
                  base: 48,
                  md: 56,
                }}
                lineHeight={1.2}
                fontWeight={800}
                color="#000"
                textAlign={{
                  base: 'center',
                  md: 'left',
                }}
                m={0}
                p={0}
              >
                {LM1839
                  ? `There are no tariffs to end your lease and keep your car!`
                  : `End your lease, keep your car & avoid the dealership`}
              </Title>
            </Flex>
            {LM1839 && (
              <Flex
                display={{
                  base: 'flex',
                  md: 'none',
                }}
                mt={8}
                fontSize={16}
                fontWeight={400}
                px={4}
                textAlign="center"
                justifyContent="center"
              >
                {TARIFF_HOOK}
              </Flex>
            )}
            {!LM1839 && <SubHeading />}
            <Box
              mt="32px"
              display={{
                base: 'none',
                md: 'block',
              }}
            >
              {LM1839 ? (
                <>
                  <Text mt="32px" fontSize={16} fontWeight={500}>
                    {TARIFF_HOOK}
                  </Text>
                  <Text pt="22px" fontSize={16} fontWeight={500}>
                    End your car lease 100% online with Lease End - no haggling, no negotiating, and
                    no extra fees. We take care of your titling, too, so you can skip the lines at
                    the DMV.
                  </Text>
                </>
              ) : (
                <>
                  <Text mt="32px" fontSize={16} fontWeight={500}>
                    End your car lease 100% online with Lease End - no haggling, no negotiating, and
                    no extra fees. We take care of your titling, too, so you can skip the lines at
                    the DMV.
                  </Text>
                  <Text pt="22px" fontSize={16} fontWeight={500}>
                    Don't get pushed into going to the dealership or DMV when you don't have to.
                  </Text>
                </>
              )}
            </Box>
            {LM1839 && <SubHeading />}
            <Flex
              mt={{
                base: '32px',
                md: '48px',
              }}
              flexDir="row"
              flex={1}
              w="100%"
              alignItems="flex-start"
              gap={8}
            >
              <LicensePlateOrVinInput_LPSimple cta="Find My Loan" />
              <Image
                display={{
                  sm: 'none',
                  md: 'block',
                }}
                src={CivicGuy}
                w={{
                  sm: '0',
                  md: '350px',
                  lg: '450px',
                }}
                mt="-58px"
              />
            </Flex>
            <Flex
              display={{
                base: 'flex',
                md: 'none',
              }}
              w="100%"
              mt={8}
            >
              <Image src={CivicGuy} w="100%" />
            </Flex>
          </Flex>
        </Flex>
        {LM1875 && (
          <Flex
            display={{
              base: 'flex',
              md: 'none',
            }}
            flexDir="column"
            justifyContent="flex-start"
            alignItems="center"
            mt={16}
            borderWidth={1}
            bg="white"
            borderRadius={8}
            p={4}
          >
            <Image src={LeaseLunacyLogo} w="200px" />
            <Title color="black" fontSize={36} fontWeight={800} lineHeight={1.3}>
              Congratulations to our NCAA Lease Lunacy Winner!
            </Title>
            <Title m={0} color="white" bg="#002d5d" w="100%" p={4} borderTopRadius={8}>
              Alejandro Cane
            </Title>
            <video
              controls
              controlsList="nodownload noplaybackrate"
              disablePictureInPicture
              style={{
                background: 'white',
                margin: 0,
                padding: 0,
              }}
              width="100%"
            >
              {isMobile ? <source src="/videos/NCAA-winner.mp4" type="video/mp4" /> : null}
            </video>
            <Text
              textAlign="center"
              m={0}
              color="black"
              bg="#f5f5f5"
              px={4}
              pt={4}
              pb={2}
              fontSize={15}
            >
              We're sending Alejandro and a guest to the NCAA championship game, all expenses paid,
              in San Antonio on April 7th!
            </Text>
            <Text
              textAlign="center"
              m={0}
              color="black"
              bg="#f5f5f5"
              borderBottomRadius={8}
              px={4}
              pt={2}
              pb={4}
              fontSize={15}
            >
              We greatly value Alejandro and his confidence in us to help him end his lease and keep
              his car -{' '}
              <Text as="span" fontWeight={800}>
                have a great time Alejandro!
              </Text>
            </Text>
          </Flex>
        )}
        <Flex
          mt={{
            base: 8,
            md: 16,
          }}
          pt={{
            base: 8,
            md: 0,
          }}
          borderTopWidth={{
            base: 1,
            md: 0,
          }}
          justifyContent="space-between"
          gap={3}
          flexDir={{
            base: 'column-reverse',
            md: 'row',
          }}
        >
          <Benefit image={NoDealership} tag="No hassle at the dealership" />
          <Benefit image={OverMileage} tag="No fees for too many miles!" />
          <Benefit image={VehicleDamage} tag="No charge for damages!" />
        </Flex>
      </Container>
    </>
  );
};

export default LPSimple;
