/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Link, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import BlogImageComponent from '../Sanity/Blog/BlogImageComponent';
import { BlogPostProps } from '../Sanity/Model';
import Date from './Date';

interface AuthorProps {
  post: BlogPostProps;
  showDate?: boolean;
  allDates?: boolean;
  link?: boolean;
}

const Author = ({ link, post, showDate, allDates = false }: AuthorProps) => {
  const location = useLocation();
  const { updateDate } = post;
  const [publishDate, setPublishDate] = useState<string | null>(null);

  const dates = () => {
    let p = null;
    let u = null;
    if (post.publishDate) {
      p = moment(post.publishDate).format('M/D/YYY');
    }
    if (post.updateDate) {
      u = moment(post.updateDate).format('M/D/YYY');
    }
    setPublishDate(p && u && p === u ? null : p);
  };
  useEffect(() => {
    dates();
  }, []);
  return (
    <Flex alignItems="center" gap={2} flexDir="row" flex={1} w="100%" mb={3}>
      {post.authorImage && <BlogImageComponent value={post.authorImage} isInline isAuthor />}
      <Flex flexDir="column" alignItems="flex-start" gap={0}>
        {link ? (
          <Link
            className="le-blog-author"
            fontWeight={700}
            fontSize="12pt"
            href={`/author/${post.authorSlug}?ref=${location.pathname}`}
          >
            {post.authorName}
          </Link>
        ) : (
          <Text fontWeight={700} fontSize="12pt" className="le-blog-author" color="#222">
            {post.authorName}
          </Text>
        )}
        {showDate && <Date published={publishDate} updated={updateDate} />}
      </Flex>
    </Flex>
  );
};

export default Author;
