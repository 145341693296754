import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React from 'react';

import { EstimatorShowHow, EstimatorSignOff } from '../../helpers/Atom';
import { EstimatorTemplate } from '../../utils/paymentCalculator';
import AgentBubble from './AgentBubble';

interface MessagingProps extends FlexProps {
  children: React.ReactNode;
}

const Messaging: React.FC<MessagingProps> = ({ children, ...props }) => {
  const [signedOff] = useAtom(EstimatorSignOff);
  const [showHow] = useAtom(EstimatorShowHow);
  if (!children || showHow) {
    return null;
  }
  return (
    <>
      <Flex
        fontWeight={400}
        flexDir="row"
        alignItems="flex-end"
        gap={2}
        py={2}
        pe={4}
        mt={4}
        fontSize={EstimatorTemplate.fontSizeLarge}
        borderRadius={32}
        maxH="200px"
        pt={8}
        pos="relative"
        overflowY="hidden"
        {...props}
      >
        <Flex
          zIndex={2}
          pos="absolute"
          bottom={0}
          left={0}
          right={0}
          top={0}
          background="linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 50%);"
        />
        <Flex pos="relative" zIndex={1} alignItems="flex-end" gap={2}>
          <Flex w="32px" mb={1} opacity={signedOff ? 0.5 : 1}>
            <AgentBubble
              agent={{
                agentImageSize: 32,
              }}
              showBorder={false}
            />
          </Flex>
          <Flex
            className="automatic-messaging"
            flex={1}
            pos="relative"
            flexDir="column"
            borderStartWidth={1}
            ps={4}
            borderRadius={4}
          >
            {children}
          </Flex>
        </Flex>
      </Flex>
      {signedOff && (
        <Flex ms="40px">
          <Text fontSize={EstimatorTemplate.fontSizeMed} color={EstimatorTemplate.lightText}>
            Kelsi has signed off
          </Text>
        </Flex>
      )}
    </>
  );
};

export default Messaging;
