import { Box, Container, Flex } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

import Footer from '../../components/Footer';
import Nav from '../../components/Nav/Nav';
import NavAutomatic from '../../components/Nav/NavAutomatic';
import { PRESS } from '../../constants/urls';
import useAutomatic from '../../hooks/useAutomatic';
import useAutomaticLogo from '../../hooks/useAutomaticLogo';
import useDevice, { DevicesEnum } from '../../hooks/useDevice';

interface MainProps {
  children: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
  const device = useDevice();

  const { pathname } = useLocation();
  const isAutomatic = useAutomatic();
  const isAutomaticLogo = useAutomaticLogo();

  return (
    <Box minH={isAutomatic ? '80vh' : '100vh'}>
      {device === DevicesEnum.mobile ? (
        <>
          {isAutomatic ? <NavAutomatic /> : <Nav />}
          <Flex justifyContent="center" flexGrow={1}>
            <Container>{children}</Container>
          </Flex>
          {!isAutomatic && <Footer />}
        </>
      ) : (
        <>
          {isAutomatic ? <NavAutomatic /> : <Nav />}
          <Container
            maxW={pathname === PRESS ? '100%' : isAutomaticLogo ? '1920px' : 'container.xl'}
            minH={isAutomatic ? '80vh' : '100vh'}
          >
            <Flex
              justifyContent="center"
              flexGrow={1}
              flexDir={pathname === PRESS ? 'column' : 'row'}
            >
              {children}
            </Flex>
            {!isAutomatic && <Footer />}
          </Container>
        </>
      )}
    </Box>
  );
};

export default Main;
