import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AUTOMATIC_URLS_LOGO } from '../utils/globals';

const useAutomaticLogo = () => {
  const { pathname } = useLocation();
  const [automaticLogo, setAutomaticLogo] = useState<boolean>(false);
  useEffect(() => {
    setAutomaticLogo(AUTOMATIC_URLS_LOGO.includes(pathname));
  }, []);

  return automaticLogo;
};

export default useAutomaticLogo;
