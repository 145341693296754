/* eslint-disable @typescript-eslint/no-explicit-any */
import { TypeAnimation } from 'react-type-animation';

const MessagingText = ({ text }: { text: string[] }) => {
  return (
    <>
      <TypeAnimation
        sequence={text}
        wrapper="span"
        speed={85}
        repeat={1}
        cursor={false}
        style={{
          padding: '10px 0 0',
          lineHeight: '24px',
        }}
      />
    </>
  );
};
export const buildMessaging = (text: string[]) => {
  if (!text || text.length === 0) {
    return null;
  }
  return text.map((t) => {
    return <MessagingText text={[t]} />;
  });
};
export const addMessaging = (m: string, s: string[]) => {
  return buildMessaging(s?.concat(m));
};
export default MessagingText;
