/* eslint-disable @typescript-eslint/ban-types */
import { Button, ButtonProps } from '@chakra-ui/react';

import { EstimatorTemplate } from '../../utils/paymentCalculator';

interface StepButtonProps extends ButtonProps {
  label?: string;
  actionable: boolean;
  handleClick?: (event: unknown) => void;
}

const StepButton = ({ label, actionable = false, handleClick, ...rest }: StepButtonProps) => {
  return (
    <Button
      justifyContent="center"
      flex={1}
      gap={3}
      fontSize={16}
      letterSpacing={0}
      textTransform="uppercase"
      fontWeight={600}
      bg={actionable ? 'oceanBoatBlue' : '#f7f7f7'}
      color={actionable ? '#fff' : '#787878'}
      transition="160ms all cubic-bezier(1,1.1,1,1.1)"
      boxShadow={actionable ? '0 10px 20px rgba(0,0,0,0.08)' : 'none'}
      fontFamily={EstimatorTemplate.fontFamily}
      py={6}
      cursor={actionable ? 'pointer' : 'not-allowed'}
      _hover={{
        color: actionable ? 'white' : '#787878',
        bg: actionable ? '#1675be' : '#f7f7f7',
        boxShadow: actionable ? '0 20px 20px rgba(0,0,0,0.16)' : 'none',
      }}
      onClick={handleClick}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default StepButton;
