/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable func-names */
import { Flex } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import StepButton from '../../components/PaymentEstimator/Button';
import StepInput from '../../components/PaymentEstimator/Input';
import { addMessaging, buildMessaging } from '../../components/PaymentEstimator/MessagingText';
import { LDFlags } from '../../constants/experiments';
import {
  EstimatorConversation,
  EstimatorConversationStrings,
  EstimatorFirstName,
  EstimatorLastName,
} from '../../helpers/Atom';
import { hashUrl } from '../../helpers/Estimator';
import { useFlag } from '../../hooks';
import { Estimator } from '../../utils/paymentCalculator';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import Container from './Container';
import { AUTOMATIC_DIALOG } from './Dialog';
import { MainProps } from './Main';

const Name = ({ move }: MainProps) => {
  const [animate, setAnimate] = useState<boolean>(false);
  const [unwind, setUnwind] = useState<boolean>(false);
  const [intro, setIntro] = useState<boolean>(true);
  const [type, setType] = useState<boolean>(false);
  const [hideButtonDisabled, setHideButtonDisabled] = useState<boolean>(false);
  const [firstName, setFirstName] = useAtom(EstimatorFirstName);
  const [lastName, setLastName] = useAtom(EstimatorLastName);
  const [messageStrings, setMessageStrings] = useAtom(EstimatorConversationStrings);
  const [, setMessaging] = useAtom(EstimatorConversation);
  const LM1755 = useFlag(LDFlags.LM1755);
  const INTRO = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'intro-step1');
  const INTRO_CONTROL = INTRO?.control || '';
  const INTRO_EXPERIMENT = INTRO?.experiment || '';
  const initialMessage = LM1755 ? INTRO_EXPERIMENT : INTRO_CONTROL;
  const handleFirstNameChange = (name: string) => {
    setFirstName(name || null);
  };
  const handleLastNameChange = (name: string) => {
    setLastName(name || null);
  };
  const handleGo = () => {
    if (!firstName || !lastName) {
      return null;
    }
    setHideButtonDisabled(true);
    const INTRO_MEET = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'intro-meet');
    const INTRO_MEET_CONTROL = INTRO_MEET?.control.replace('{{firstName}}', firstName) || '';
    const INTRO_MEET_EXPERIMENT = INTRO_MEET?.experiment.replace('{{firstName}}', firstName) || '';
    const ms = messageStrings.concat(LM1755 ? INTRO_MEET_EXPERIMENT : INTRO_MEET_CONTROL);
    setMessageStrings(ms);
    setMessaging(buildMessaging(ms));
    setTimeout(() => {
      setUnwind(true);
      move('State');
    }, Estimator.animationDuration * 5);
    return null;
  };
  const tracking = () => {
    rudderanalytics.track(RudderEvent.LabTest, {
      name: 'LM-1755::Name',
      value: LM1755,
    });
  };
  useEffect(() => {
    const INTRO_STEP_2 = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'intro-step2');
    const INTRO_STEP_2_CONTROL = INTRO_STEP_2?.control || '';
    const INTRO_STEP_2_EXPERIMENT = INTRO_STEP_2?.experiment || '';
    const m = LM1755 ? INTRO_STEP_2_EXPERIMENT : INTRO_STEP_2_CONTROL;
    const ms = messageStrings.concat(m);
    if (type) {
      setMessaging(addMessaging(m, messageStrings));
      setMessageStrings(ms);
    }
  }, [type]);
  useEffect(() => {
    tracking();
    const timer = setTimeout(() => {
      setAnimate(true);
    }, Estimator.animationDuration);
    const timerIntro = setTimeout(() => {
      setIntro(false);
    }, Estimator.animationDuration * 8);
    const timerType = setTimeout(() => {
      setType(true);
    }, Estimator.animationDuration * 8);
    setMessageStrings([initialMessage]);
    setMessaging(addMessaging(initialMessage, []));
    hashUrl('EstimatorName', false);
    return () => {
      clearTimeout(timer);
      clearTimeout(timerIntro);
      clearTimeout(timerType);
    };
  }, []);
  return (
    <Container
      className={`calculator-step ${unwind ? 'animate-unwind' : animate ? 'animate' : ''}`}
    >
      {!intro && (
        <>
          <Flex flexDir="row" gap={2} mt={2} display={type ? 'flex' : 'none'}>
            <StepInput
              name="firstName"
              label={hideButtonDisabled ? null : 'First name'}
              handleChange={(e) => handleFirstNameChange(e.target?.value)}
              value={firstName || ''}
              autoFocus
              debounce="true"
              isDisabled={hideButtonDisabled}
            />
            <StepInput
              name="lastName"
              label={hideButtonDisabled ? null : 'Last name'}
              handleChange={(e) => handleLastNameChange(e.target?.value)}
              value={lastName || ''}
              debounce="true"
              isDisabled={hideButtonDisabled}
            />
          </Flex>
          <StepButton
            handleClick={() => handleGo()}
            label="next"
            actionable
            className={`btn-dynamic ${firstName && lastName && !hideButtonDisabled ? 'go' : ''}`}
          />
        </>
      )}
    </Container>
  );
};

export default Name;
