import { Flex, FlexProps } from '@chakra-ui/react';

import { Estimator, EstimatorTemplate } from '../../utils/paymentCalculator';

const Container = ({ children, ...props }: FlexProps) => {
  return (
    <>
      <Flex
        flexDir="column"
        fontFamily={EstimatorTemplate.fontFamily}
        mt={4}
        mx="auto"
        minW={Estimator.minWidth}
        w={Estimator.width}
        gap={4}
        {...props}
      >
        {children}
      </Flex>
    </>
  );
};
export default Container;
