/* eslint-disable @typescript-eslint/ban-types */
import { Box, Flex, Text } from '@chakra-ui/react';
import { useState } from 'react';

import { EstimatorTemplate } from '../../utils/paymentCalculator';
import PrimaryButton, { PrimaryButtonProps } from '../ui/buttons/PrimaryButton';

interface StepOptionProps extends PrimaryButtonProps {
  customOnClick?: (event: unknown) => void;
  label: string;
  optionSelected: boolean;
  meta?: string | null;
  radio?: boolean;
  note?: string | null;
  reco?: boolean;
}

const Radio = ({
  selected = false,
  active = false,
  ...rest
}: {
  selected: boolean;
  active?: boolean;
}) => {
  return (
    <>
      <Flex
        w="32px"
        height="32px"
        borderRadius="16px"
        bg="#fff !important"
        borderWidth={1}
        borderColor={
          active ? `${EstimatorTemplate.borderDark}` : `${EstimatorTemplate.borderLight}`
        }
        transition="200ms all cubic-bezier(1,1.35,1,1.35)"
        justifyContent="center"
        alignItems="center"
        {...rest}
      >
        <Box
          transition="78ms all cubic-bezier(1,1.54,1,1.54)"
          width={selected ? '18px' : 0}
          height={selected ? '18px' : 0}
          bg={`${EstimatorTemplate.blue}`}
          borderRadius="9px"
        />
      </Flex>
    </>
  );
};

const StepOption = ({
  customOnClick,
  label,
  optionSelected = false,
  meta = null,
  radio = true,
  note = null,
  reco = false,
  ...rest
}: StepOptionProps) => {
  const [optionActive, setOptionActive] = useState<boolean>(false);
  return (
    <PrimaryButton
      borderRadius={EstimatorTemplate.borderRadius}
      flexDir="row"
      justifyContent="flex-start"
      alignItems={(optionSelected && meta) || note ? 'flex-start' : 'center'}
      onClick={customOnClick}
      flex={1}
      gap={3}
      fontSize={17}
      letterSpacing={0}
      fontFamily={EstimatorTemplate.fontFamily}
      textTransform="uppercase"
      fontWeight={600}
      borderWidth={1}
      borderColor={reco ? EstimatorTemplate.blue : EstimatorTemplate.borderLight}
      bg={optionSelected ? `${EstimatorTemplate.lightBlue}` : `white`}
      color={`${EstimatorTemplate.darkText}`}
      _hover={{
        bg: optionSelected ? `${EstimatorTemplate.lightBlue}` : `${EstimatorTemplate.lightGray}`,
        color: EstimatorTemplate.darkText,
      }}
      transition="160ms all cubic-bezier(1,1.1,1,1.1)"
      px={4}
      py={4}
      onPointerOver={() => setOptionActive(true)}
      onPointerOut={() => setOptionActive(false)}
      height="auto"
      {...rest}
    >
      {radio && (
        <Flex justifyContent="flex-start" alignItems="flex-start">
          <Radio selected={optionSelected} active={optionActive} />
        </Flex>
      )}
      <Flex
        justifyContent={radio ? 'flex-start' : 'center'}
        w="100%"
        alignItems={radio ? 'flex-start' : 'center'}
        gap={2}
        flexDir="column"
      >
        {label}
        {note && (
          <Text
            as="span"
            fontSize={EstimatorTemplate.fontSizeSmall}
            fontWeight={300}
            color={EstimatorTemplate.lightText}
          >
            {note}
          </Text>
        )}
        {meta && optionSelected && (
          <Flex
            fontWeight={500}
            fontSize={14}
            textTransform="none"
            textAlign="left"
            lineHeight={1.4}
          >
            {meta}
          </Flex>
        )}
      </Flex>
    </PrimaryButton>
  );
};

export default StepOption;
