import { Box, Image, useMediaQuery } from '@chakra-ui/react';
import { forwardRef } from 'react';

import { CivicGuy, HomePageSimpleBackgroundWideSVG } from '../../../assets/Images';
import useCustomBreakpointCallback from '../../../hooks/useCustomBreakpointCallback';

const CarDudeBgImage = forwardRef<HTMLImageElement>((_: unknown, ref) => {
  const bpv = useCustomBreakpointCallback();
  const [heightUnder1050] = useMediaQuery('(max-height: 1050px)');

  return (
    <Box position="absolute" h="100vh" zIndex="-2" w="100%" top="0">
      <Image
        src={CivicGuy}
        alt="Car Dude"
        position="absolute"
        top={{
          base: bpv({
            500: '50%',
            625: '42%',
          }),
          md: heightUnder1050 ? '37.5%' : '450px',
        }}
        left={{ base: '37.5%', md: '50%' }}
        transform="translate(-50%, 0)"
        w={{
          md: bpv(
            {
              500: '500px',
              600: '550px',
              700: '625px',
              800: '750px',
              900: bpv({ 768: '700px', 800: '750px', 900: '850px' }),
            },
            true,
          ),
        }}
        minW={{ base: '550px', md: '0px' }}
        transition="opacity 0.5s ease-in-out"
        overflow="hidden"
        m="0 auto"
      />
      <Image
        ref={ref}
        src={HomePageSimpleBackgroundWideSVG}
        alt="Scenic Background"
        h="100%"
        minH="750px"
        maxH="1080px"
        objectFit="cover"
        objectPosition={{ base: '64% -35px', md: 'center -35px' }}
        zIndex="-1"
        m="0 auto"
      />
    </Box>
  );
});

export default CarDudeBgImage;
