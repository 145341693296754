import { Flex, Image, Link, useBreakpointValue } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { IconContext } from 'react-icons';
import { PiX } from 'react-icons/pi';

import { LogoAutomaticBy } from '../../assets/Images';
import { HOME } from '../../constants/urls';
import { Estimator, EstimatorTemplate } from '../../utils/paymentCalculator';

const NavAutomatic = () => {
  const logoRef = useRef<HTMLImageElement | null>(null);
  const isMobile = useBreakpointValue({ base: true, md: false });
  useEffect(() => {
    const timer = setTimeout(() => {
      logoRef.current?.classList.add('estimator-pop');
    }, Estimator.animationDuration);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      <Flex
        px={{
          base: 4,
        }}
        pb={{
          base: 2,
        }}
        maxW="container.xl"
        mx="auto"
        justifyContent={{
          base: 'space-between',
        }}
        pt={4}
        alignItems="center"
        gap={4}
        pos="relative"
        zIndex={2}
      >
        <Flex>
          <Image
            className="estimator-pop-pre"
            ref={logoRef}
            src={LogoAutomaticBy}
            w={{
              base: '150px',
              md: '200px',
            }}
          />
        </Flex>
        <Link
          onClick={() => {
            window.location.href = HOME;
          }}
          cursor="pointer"
        >
          <IconContext.Provider
            value={{
              size: isMobile ? '32px' : '48px',
              color: EstimatorTemplate.lightText,
            }}
          >
            <PiX />
          </IconContext.Provider>
        </Link>
      </Flex>
    </>
  );
};
export default NavAutomatic;
