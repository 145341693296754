import { Link } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { BLOG_SANITY } from '../../constants/urls';

/* eslint-disable react/destructuring-assignment */

interface TagLinkProps {
  tag: string;
  label?: string;
  subtag?: string;
  searchMatch?: boolean;
}

const TagLink = ({ label, tag, searchMatch, subtag }: TagLinkProps) => {
  const params: {
    tag: string;
  } = useParams();
  return (
    <Link
      p={1}
      px={2}
      key={tag}
      href={subtag ? `${BLOG_SANITY}/tag/${tag}/${subtag}` : `${BLOG_SANITY}/tag/${tag}`}
      style={{
        fontSize: 12,
        fontWeight: params.tag === tag ? 700 : 500,
        backgroundColor: searchMatch ? '#fff600' : tag === params.tag ? '#002d5d' : '#eee',
        color: tag === params.tag ? 'white' : params.tag ? '#888' : '#454545',
        borderRadius: 4,
      }}
      _hover={{
        backgroundColor: searchMatch
          ? '#fff600'
          : tag === params.tag
          ? '#002d5d'
          : 'rgba(255, 255, 255, 0.18)',
      }}
    >
      {label || tag}
    </Link>
  );
};

export default TagLink;
