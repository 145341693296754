import { Flex, FlexProps } from '@chakra-ui/react';

import { EstimatorTemplate } from '../../utils/paymentCalculator';

const StepSubTitle = ({ children, ...props }: FlexProps) => {
  if (!children) {
    return null;
  }
  return (
    <>
      <Flex
        color="#1b1c1b"
        fontSize={{
          base: '16px',
          md: '18px',
        }}
        fontWeight={300}
        textAlign={{
          baseTheme: 'center',
        }}
        justifyContent={{
          base: 'center',
        }}
        lineHeight={1.3}
        m={0}
        fontFamily={EstimatorTemplate.fontFamily}
        {...props}
        w="100%"
      >
        {children}
      </Flex>
    </>
  );
};

export default StepSubTitle;
