import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';

export interface PrimaryButtonProps extends ButtonProps {
  children?: React.ReactNode;
  loading?: boolean;
  value?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  loading = false,
  value = 'NEXT',
  ...props
}) => {
  return (
    <Button isLoading={loading} {...props}>
      {children ?? value}
    </Button>
  );
};

export default PrimaryButton;
