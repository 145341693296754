import { SedanWhite, SuvWhite, TruckWhite } from '../assets/Images';
import { vehicleType } from '../constants/vehicleType';

export const carIconWhite = (vehicle_type: string) => {
  if (vehicle_type === vehicleType.MPV) {
    return SuvWhite;
  }
  if (vehicle_type === vehicleType.TRUCK) {
    return TruckWhite;
  }
  return SedanWhite;
};
