/* eslint-disable @typescript-eslint/ban-types */
import { Select, SelectProps } from '@chakra-ui/react';
import { IconContext } from 'react-icons';
import { BsChevronDown } from 'react-icons/bs';

import { EstimatorTemplate } from '../../utils/paymentCalculator';
import { capitalizeWords } from '../../utils/text/text';

interface StepSelectProps extends SelectProps {
  ph?: string | null;
  options: { label: string; value: string }[];
  handleChange: Function;
  capitalize?: boolean;
  value?: string;
}

const StepSelect = ({ ph, options, handleChange, capitalize, value, ...rest }: StepSelectProps) => {
  return (
    <>
      <Select
        icon={
          <IconContext.Provider
            value={{
              size: '24px',
              color: `${EstimatorTemplate.borderDark}`,
              style: {
                position: 'relative',
                right: '16px',
              },
            }}
          >
            <BsChevronDown />
          </IconContext.Provider>
        }
        bg="white"
        fontFamily={EstimatorTemplate.fontFamily}
        onChange={(e) => (handleChange ? handleChange(e.target.value) : null)}
        borderWidth={1}
        borderColor={`${EstimatorTemplate.borderLight}`}
        borderRadius={EstimatorTemplate.borderRadius}
        color={`${EstimatorTemplate.darkText}`}
        fontWeight={500}
        textTransform="uppercase"
        fontSize={16}
        h="60px"
        {...rest}
      >
        {ph && <option value="">{ph}</option>}
        {options.map((o: { label: string; value: string; name?: string }) => {
          return (
            <option
              value={o.value}
              defaultValue={value && o.value === value ? value : ''}
              key={o.value}
            >
              {capitalize ? capitalizeWords(o.name || o.label) : o.name || o.label}
            </option>
          );
        })}
      </Select>
    </>
  );
};

export default StepSelect;
