/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable func-names */
import { Flex } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { GetStateImage } from '../../assets/Images';
import StepButton from '../../components/PaymentEstimator/Button';
import { addMessaging } from '../../components/PaymentEstimator/MessagingText';
import StateSelected from '../../components/PaymentEstimator/StateSelected';
import { LDFlags } from '../../constants/experiments';
import { statesArray } from '../../constants/states';
import {
  EstimatorAddress,
  EstimatorConversation,
  EstimatorConversationStrings,
  EstimatorFirstName,
} from '../../helpers/Atom';
import { hashUrl } from '../../helpers/Estimator';
import { useFlag } from '../../hooks';
import { Estimator, EstimatorTemplate } from '../../utils/paymentCalculator';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import Container from './Container';
import { AUTOMATIC_DIALOG } from './Dialog';
import { MainProps } from './Main';

const State = ({ move }: MainProps) => {
  const [animate, setAnimate] = useState<boolean>(false);
  const [unwind, setUnwind] = useState<boolean>(false);
  const [type, setType] = useState<boolean>(false);
  const [firstName] = useAtom(EstimatorFirstName);
  const [address] = useAtom(EstimatorAddress);
  const [, setMessaging] = useAtom(EstimatorConversation);
  const LM1755 = useFlag(LDFlags.LM1755);
  const INTRO = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'state-step1');
  const INTRO_CONTROL = INTRO?.control || '';
  const INTRO_EXPERIMENT = INTRO?.experiment || '';
  const initialMessage = LM1755 ? INTRO_EXPERIMENT : INTRO_CONTROL;
  const [messageStrings, setMessageStrings] = useAtom(EstimatorConversationStrings);
  const [hideButtonDisabled, setHideButtonDisabled] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [stateSelected, setStateSelected] = useState<string | null>(null);
  const init = () => {
    statesArray.forEach((state) => {
      const i = new Image();
      i.src = GetStateImage(state.value);
    });
  };
  const tracking = () => {
    rudderanalytics.track(RudderEvent.LabTest, {
      name: 'LM-1755::State',
      value: LM1755,
    });
  };
  const handleGo = () => {
    if (!address?.state) {
      return null;
    }
    setHideButtonDisabled(true);
    setUnwind(true);
    move('LicensePlate');
    return null;
  };
  useEffect(() => {
    if (stateSelected !== null) {
      const STATE_SELECTED = AUTOMATIC_DIALOG.find((dialog) => dialog.step === 'state-step2');
      const STATE_SELECTED_CONTROL =
        STATE_SELECTED?.control.replace('{{state}}', stateSelected) || '';
      const STATE_SELECTED_EXPERIMENT =
        STATE_SELECTED?.experiment.replace('{{state}}', stateSelected) || '';
      const m = hasChanged
        ? `👍 Got it, ${stateSelected}...`
        : LM1755
        ? STATE_SELECTED_EXPERIMENT
        : STATE_SELECTED_CONTROL;
      const ms = messageStrings.concat(m);
      setMessageStrings(ms);
      setMessaging(addMessaging(m, ms));
    }
  }, [stateSelected]);
  useEffect(() => {
    init();
    tracking();
    const timer = setTimeout(() => {
      setAnimate(true);
    }, Estimator.animationDuration);
    const timerType = setTimeout(() => {
      setType(true);
    }, Estimator.animationDuration * 2);
    setMessaging(addMessaging(initialMessage, messageStrings));
    hashUrl('EstimatorState', true);
    return () => {
      clearTimeout(timer);
      clearTimeout(timerType);
    };
  }, []);
  return (
    <Container
      className={`calculator-step ${unwind ? 'animate-unwind' : animate ? 'animate' : ''}`}
    >
      <Flex flexDir="row" gap={2} w="100%" flex={1}>
        <StateSelected
          setSelected={setStateSelected}
          bg={hideButtonDisabled ? EstimatorTemplate.lightGray : `white`}
          setHasChanged={setHasChanged}
        />
      </Flex>
      {!hideButtonDisabled && (
        <StepButton
          className={`btn-dynamic ${address?.state !== null ? 'go' : ''}`}
          handleClick={() => handleGo()}
          label="next"
          actionable={address?.state !== null}
        />
      )}
    </Container>
  );
};

export default State;
