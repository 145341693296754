import { Flex, FlexProps } from '@chakra-ui/react';

import { EstimatorTemplate } from '../../utils/paymentCalculator';

const ResultsContainer = ({ children, ...props }: FlexProps) => {
  return (
    <>
      <Flex
        fontFamily={EstimatorTemplate.fontFamily}
        borderWidth={1}
        borderRadius={8}
        p={2}
        className="calculator-step animate"
        w="100%"
        {...props}
      >
        <Flex
          flexDir="column"
          gap={2}
          lineHeight={1}
          justifyContent="center"
          flex={1}
          alignItems="center"
          bg={EstimatorTemplate.lightGray}
          p={4}
          borderRadius={4}
        >
          {children}
        </Flex>
      </Flex>
    </>
  );
};
export default ResultsContainer;
