import { Flex, FlexProps, Spinner, Text } from '@chakra-ui/react';
import React from 'react';

interface Props extends FlexProps {
  loadingText?: string;
  hideText?: boolean;
}

const Loader: React.FC<Props> = ({ loadingText = 'Loading...', hideText = false, ...props }) => (
  <Flex mt={2} overflow="hidden" {...props}>
    <Spinner />
    {!hideText && loadingText && <Text ml={2}>{loadingText}</Text>}
  </Flex>
);

export default Loader;
