import { gql } from '@apollo/client';

export const CarDistinctMake = gql`
  query carDistinctMake {
    carDistinctMake {
      make
      total
    }
  }
`;

export const CarDistinctModel = gql`
  query carDistinctModel($make: String!) {
    carDistinctModel(make: $make) {
      model
    }
  }
`;

export const PayoffStatsMakeModelYear = gql`
  query payoffStatsMakeModelYear(
    $make: String!
    $model: String
    $trim: String
    $year: String
    $start_date: String!
    $end_date: String!
  ) {
    payoffStatsMakeModelYear(
      make: $make
      model: $model
      trim: $trim
      year: $year
      start_date: $start_date
      end_date: $end_date
    ) {
      avg_payoff
      min_payoff
      max_payoff
    }
  }
`;

export const CarCountMake = gql`
  query payoffCountMake($make: String!) {
    payoffCountMake(make: $make) {
      total
    }
  }
`;

export const CarCountModel = gql`
  query payoffCountModel($make: String!, $model: String!) {
    payoffCountModel(make: $make, model: $model) {
      total
    }
  }
`;

export const PaymentEstimate = gql`
  query financialInfoPaymentEstimate(
    $make: String!
    $model: String
    $year: String
    $start_date: String!
    $end_date: String!
  ) {
    financialInfoPaymentEstimate(
      make: $make
      model: $model
      year: $year
      start_date: $start_date
      end_date: $end_date
    ) {
      avg_payment
      min_payment
      max_payment
    }
  }
`;

export const KbbVin = gql`
  query kbbVin($vin: String!) {
    kbbVin(vin: $vin) {
      warning
      vinResults {
        modelName
        trimName
        makeName
        yearId
        vehicleName
      }
    }
  }
`;
