/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, FlexProps, Image, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';

import { EstimatorAgent, LELoading, LogoAutomaticIconGradient } from '../../assets/Images';
import { LDFlags } from '../../constants/experiments';
import { useFlag } from '../../hooks';
import { EstimatorTemplate } from '../../utils/paymentCalculator';

interface AgentBubbleProps extends FlexProps {
  children?: React.ReactNode;
  loading?: boolean;
  agent?: {
    agentImageSize: number;
  };
  showBorder?: boolean;
  isNav?: boolean;
  isLoader?: boolean;
}
interface BubbleBorderProps extends FlexProps {
  children?: React.ReactNode;
  width: number;
}
interface BubbleImageProps extends FlexProps {
  children?: React.ReactNode;
  width: number;
}

const BubbleImage: React.FC<BubbleImageProps> = ({ children, width, ...props }) => {
  const LM1755 = useFlag(LDFlags.LM1755);
  return (
    <>
      <Flex
        width={`${width}px`}
        height={`${width}px`}
        bg={LM1755 ? 'transparent' : '#eee'}
        backgroundImage={LM1755 ? LogoAutomaticIconGradient : EstimatorAgent}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize={`${width}px ${width}px`}
        borderRadius={`${width / 2}px`}
        pos="relative"
        justifyContent="center"
        {...props}
      />
    </>
  );
};
const BubbleBorder: React.FC<BubbleBorderProps> = ({ children, width, ...props }) => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });
  return (
    <>
      <Flex
        width={`${width + 8}px`}
        borderRadius={`${(width + 8) / 2}px`}
        p="4px"
        height={`${width + 8}px`}
        bg={isMobile ? EstimatorTemplate.aiGradient : `white`}
        backgroundSize="150% 150%"
        {...props}
      >
        {children}
      </Flex>
    </>
  );
};

const AgentBubble: React.FC<AgentBubbleProps> = ({
  children,
  agent = null,
  isNav = false,
  isLoader = false,
  showBorder = true,
  ...props
}) => {
  const agentImageSize = agent?.agentImageSize || 84;
  return (
    <>
      <Flex
        width="100%"
        justifyContent="center"
        py={showBorder ? 4 : 0}
        mb={isNav ? `-${agentImageSize / 2 + 14}px` : 0}
        pos="relative"
        zIndex={2}
        {...props}
      >
        {showBorder && !isLoader ? (
          <BubbleBorder width={agentImageSize}>
            <BubbleImage width={agentImageSize} />
          </BubbleBorder>
        ) : isLoader ? (
          <>
            <Flex w="100%" pos="relative">
              <Box w="256px" mx="auto" pos="relative" height="128px">
                <Box className="loading-run" w="128px">
                  <Image
                    src={LELoading}
                    width="128px"
                    pos="absolute"
                    zIndex={1}
                    top={0}
                    left={0}
                    opacity={1}
                  />
                </Box>
              </Box>
            </Flex>
          </>
        ) : (
          <BubbleImage width={agentImageSize} />
        )}
      </Flex>
    </>
  );
};
export default AgentBubble;
