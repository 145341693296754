/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-restricted-globals */
import { useLazyQuery } from '@apollo/client';
import { Flex, Image } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { LELoading } from '../../assets/Images';
import ButtonSmall from '../../components/PaymentEstimator/ButtonSmall';
import Option from '../../components/PaymentEstimator/Option';
import StepSubTitle from '../../components/PaymentEstimator/SubTitle';
import Title from '../../components/PaymentEstimator/Title';
import {
  CarCountMake,
  CarCountModel,
  CarDistinctMake,
  CarDistinctModel,
} from '../../gql/calculatorGql';
import {
  EstimatorCarMake,
  EstimatorCarModel,
  EstimatorCarYear,
  EstimatorFirstName,
  EstimatorTempInfo,
  EstimatorWrap,
} from '../../helpers/Atom';
import { Estimator } from '../../utils/paymentCalculator';
import { capitalizeWords } from '../../utils/text/text';
import Container from './Container';
import { MainProps } from './Main';

const MakeModelYear = ({ move }: MainProps) => {
  const [selectedMake, setSelectedMake] = useAtom(EstimatorCarMake);
  const [selectedModel, setSelectedModel] = useAtom(EstimatorCarModel);
  const [selectedYear, setSelectedYear] = useAtom(EstimatorCarYear);
  const [info, setInfo] = useAtom(EstimatorTempInfo);
  const [wrap] = useAtom(EstimatorWrap);
  const [firstName] = useAtom(EstimatorFirstName);
  const [animate, setAnimate] = useState<boolean>(false);
  const [unwind, setUnwind] = useState<boolean>(false);
  const [dbMakes, setDbMakes] = useState<{ label: string; value: string }[] | null>(null);
  const [, setTopMakes] = useState<{ make: string; total: number }[] | null>(null);
  const [dbModels, setDbModels] = useState<{ label: string; value: string }[] | null>(null);
  const [countMake, setCountMake] = useState<string | null>(null);
  const [, setCountModel] = useState<string | null>(null);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 8 }, (_, index) => currentYear - index);
  const [done, setDone] = useState<boolean>(false);

  const [makesLoading, setMakesLoading] = useState<boolean>(false);
  const [modelsLoading, setModelsLoading] = useState<boolean>(false);

  const [getMakes] = useLazyQuery(CarDistinctMake, {
    fetchPolicy: 'no-cache',
  });
  const [getCountMake] = useLazyQuery(CarCountMake, {
    fetchPolicy: 'no-cache',
  });
  const [getCountModel] = useLazyQuery(CarCountModel, {
    fetchPolicy: 'no-cache',
  });
  const [getModels] = useLazyQuery(CarDistinctModel, {
    fetchPolicy: 'no-cache',
  });
  const makes = async () => {
    setDbMakes(null);
    setMakesLoading(true);
    await getMakes().then((response) => {
      setMakesLoading(false);
      const r = response;
      const rFull = r;
      const sorted = [...r.data.carDistinctMake]
        .sort((a, b) => b.total - a.total)
        .slice(0, 5)
        .sort((a, b) => {
          if (a.make.toLowerCase() < b.make.toLowerCase()) return -1;
          if (a.make.toLowerCase() > b.make.toLowerCase()) return 1;
          return 0;
        });
      setTopMakes(sorted || null);
      const allMakes = rFull.data.carDistinctMake.map((m: { make: string }) => ({
        label: m.make,
        value: m.make,
      }));
      setDbMakes(allMakes);
    });
  };
  const handleMakeModels = async (make: string) => {
    setSelectedMake(make);
    await getCountMake({
      variables: {
        make,
      },
    }).then((response) => {
      setCountMake(response.data.payoffCountMake ? response.data.payoffCountMake[0].total : null);
    });
    setDbModels(null);
    setModelsLoading(true);
    await getModels({
      variables: {
        make,
      },
    }).then((response) => {
      setModelsLoading(false);
      const allModels = response.data.carDistinctModel.map((m: { model: string }) => ({
        label: m.model,
        value: m.model,
      }));
      setDbModels(allModels);
    });
  };
  const handleMakeModelYear = async (year: string) => {
    setSelectedYear(year);
  };
  const handleMakeModel = async (model: string) => {
    setSelectedModel(model);
    await getCountModel({
      variables: {
        make: selectedMake,
        model,
      },
    }).then((response) => {
      setCountModel(
        response.data.payoffCountModel ? response.data.payoffCountModel[0].total : null,
      );
    });
  };
  const handleClear = (make?: boolean, model?: boolean, year?: boolean) => {
    setSelectedYear(year ? null : selectedYear);
    setSelectedModel(model ? null : selectedModel);
    setSelectedMake(make ? null : selectedMake);
  };
  useEffect(() => {
    if (!selectedMake || !selectedModel || !selectedYear) {
      return;
    }
    setInfo({
      make: selectedMake,
      model: selectedModel,
      year: selectedYear,
    });
    setDone(true);
    setTimeout(() => {
      setUnwind(true);
      move('SoftPull');
    }, Estimator.animationDuration);
  }, [selectedMake, selectedModel, selectedYear]);
  useEffect(() => {
    makes();
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, Estimator.animationDuration);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Container
      className={`calculator-step ${unwind ? 'animate-unwind' : animate ? 'animate' : ''}`}
    >
      {wrap ? (
        <Title>{firstName}, let's wrap this up!</Title>
      ) : selectedMake ? (
        <Title>
          <>What model?</>
        </Title>
      ) : (
        <Title>What kind of car are you leasing?</Title>
      )}
      {selectedMake && (
        <>
          {countMake !== null && Number.parseInt(countMake, 10) > 0 && (
            <StepSubTitle>
              We've helped {countMake} driver
              {Number.parseInt(countMake, 10) === 1 ? '' : 's'} end their{' '}
              {capitalizeWords(selectedMake)} lease!
            </StepSubTitle>
          )}
          <Flex
            gap={1}
            justifyContent={{
              base: 'center',
            }}
            w="100%"
          >
            <ButtonSmall
              label={capitalizeWords(selectedMake)}
              handleClear={() => handleClear(true, true, true)}
            />
            {selectedModel && (
              <ButtonSmall
                label={selectedModel}
                handleClear={() => handleClear(false, true, true)}
              />
            )}
            {selectedYear && (
              <ButtonSmall
                label={selectedYear}
                handleClear={() => handleClear(false, false, true)}
              />
            )}
          </Flex>
        </>
      )}
      {makesLoading && (
        <>
          <Flex w="100%" justifyContent="center">
            <Image src={LELoading} w="128px" />
          </Flex>
        </>
      )}
      {dbMakes && !selectedMake && (
        <>
          <Flex justifyContent="flex-start" flexDir="column" gap={1} mt={4}>
            {!selectedMake && (
              <>
                {dbMakes.map((o: { label: string; value: string }) => {
                  return (
                    <Option
                      customOnClick={() => handleMakeModels(o.value)}
                      label={o.value}
                      optionSelected={false}
                      key={o.value}
                    />
                  );
                })}
              </>
            )}
          </Flex>
        </>
      )}
      {modelsLoading && (
        <>
          <Flex w="100%" justifyContent="center">
            <Image src={LELoading} w="128px" />
          </Flex>
        </>
      )}
      {dbModels && !selectedModel && selectedMake && (
        <>
          <Flex justifyContent="flex-start" flexDir="column" gap={1}>
            {dbModels.map((o: { label: string; value: string }) => {
              if (o.label === '' || o.value === '') {
                return null;
              }
              return (
                <Option
                  customOnClick={() => handleMakeModel(o.value)}
                  label={o.value}
                  optionSelected={false}
                  key={o.value}
                />
              );
            })}
          </Flex>
        </>
      )}
      {selectedMake && selectedModel && (
        <>
          <Flex flexDir="column" gap={1} opacity={done ? '0.25' : 1}>
            {years.map((year) => {
              return (
                <Option
                  label={`${year}`}
                  optionSelected={false}
                  customOnClick={() => handleMakeModelYear(`${year}`)}
                  key={year}
                />
              );
            })}
          </Flex>
        </>
      )}
    </Container>
  );
};

export default MakeModelYear;
