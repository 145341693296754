import { Influencer_OmarDrives } from '../../../assets/Images';
import Wrapper from './Wrapper';

const OmarDrives = () => {
  return (
    <Wrapper
      influencer={{
        name: 'Omar Drives',
        imagePath: Influencer_OmarDrives,
        imageWidth: 220,
        styleImage: true,
      }}
    />
  );
};

export default OmarDrives;
