/* eslint-disable security/detect-unsafe-regex */
import { Maybe } from '../../gql/generated/graphql';

export function replaceUnderscores(str: string, replacement = ' ') {
  return str.replace(/_/g, replacement);
}

export const capitalizeFirstLetter = (str: Maybe<string>) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';
};

export const capitalizeWords = (str: Maybe<string>) => {
  return str
    ? str
        .toLowerCase()
        .split(' ')
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ')
    : null;
};

const tens = (n: number) => {
  return Math.floor(n / 5) * 5;
};

export const currencyFormat = (num: number) => {
  const n = typeof num !== 'number' ? Number(num) : num;
  const nTens = tens(n);
  return `$${nTens.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const phoneRegex = (phone: string) => {
  return phone.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
};
