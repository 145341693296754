export const AUTOMATIC_DIALOG = [
  {
    step: 'intro-step1',
    control: `👋 Hi there! I'm Kelsie. I just need 2 seconds and I'll be with you...`,
    experiment: `Hey! I'm Auto of Automatic AI. I can't wait to get you your new monthly payment estimate.`,
  },
  {
    step: 'intro-step2',
    control: `Alright I'm back! I can get you an estimate in just a couple minutes! I mentioned before my name is Kelsie...what's yours?`,
    experiment: `First things first, who am I talking to?`,
  },
  {
    step: 'intro-meet',
    control: `Nice to meet you {{firstName}}! Awaaaay we go! 😊`,
    experiment: `Great to meet you {{firstName}}!`,
  },
  {
    step: 'state-step1',
    control: `Ok, {{firstName}}, where do you live? Knowing the state you live in helps me figure out taxes and fees, if necessary.`,
    experiment: `Can I ask, where do you live? Knowing which state you live in helps me better calculate your payment.`,
  },
  {
    step: 'state-step2',
    control: `👍 {{state}}, what a great state! On to the next!...`,
    experiment: `I love it there! The digital weather in {{state}} is always great.`,
  },
  {
    step: 'car-step1',
    control: `Let's talk about your leased car {{firstName}}...can I get your license plate?  I'll use your plate to verify the exact vehicle your leasing. (You can also use your VIN!)`,
    experiment: `Time to get to know your car. Can I get your license plate info? (If you prefer, you can enter your VIN instead.)`,
  },
  {
    step: 'car-notfound',
    control: `Hmm...I didn't find a car with this info. Are you sure the info is correct?`,
    experiment: `Hmm...I didn't find a car with this info. Are you sure the info is correct?`,
  },
  {
    step: 'car-found',
    control: `I found a {{make}} {{model}}! Is this your car?`,
    experiment: `I think I found it. Is this your car?`,
  },
  {
    step: 'car-tryagain',
    control: `✅ Got it. Let's try again.`,
    experiment: `Got it. Let's try again.`,
  },
  {
    step: 'car-checking',
    control: `Hang tight, this won't take long.`,
    experiment: `Hang tight, this won't take long.`,
  },
  {
    step: 'car-checkagain',
    control: `Okay, checking this one.`,
    experiment: `Okay, checking this one.`,
  },
  {
    step: 'car-confirmed',
    control: `🚗 Awesome! Moving on...`,
    experiment: `Great! That's all the info I need, but if you'd like the most accurate estimate, I have a couple more questions.`,
  },
  {
    step: 'softpull-address',
    control: `I'll use your street address to confirm any state and local taxes.`,
    experiment: `Could I get your street address? This will help me review your credit tier. And don't worry, me checking on your score won't hurt your credit score.`,
  },
  {
    step: 'softpull-address-no',
    control: `I get it, checking credit sounds scary! I want to remind you I only see your credit "tier" - Good, Very Good etc - also this won't ding your credit! Knowing this helps me to quote you the most accurate rate. Are you sure you want to skip this?`,
    experiment: `Without an address, your estimate won't be as accurate. Is that okay?`,
  },
  {
    step: 'softpull-address-confirm',
    control: `🙏 Thank you! I now have everything I need to run numbers. If this address looks right just choose Yes. By choosing Yes you are also consenting for me to do a soft credit check. Remember this won't affect your credit score in any way!`,
    experiment: `Thank you! Does this info look right?`,
  },
  {
    step: 'softpull-done',
    control: `Woo! I think I have all I need to get your estimate. Let's do this!`,
    experiment: `Perfect! I have everything I need to get your estimate. Let's see...carry the 3...add the 4...`,
  },
  {
    step: 'result-done',
    control: `🎉 We did it!! Your payment estimate is below. To see the estimate breakdown enter your phone number a consultant will reach out to get your lease ended.  Thanks for your time today!`,
    experiment: `We did it! Your monthly payment is listed below. If you'd like a detailed breakdown of the payment, enter your phone number, and a Lease End consultant will reach out to you. Great chatting with you!`,
  },
  {
    step: 'result-5min',
    control: `Enter your phone below and an advisor will contact you shortly to get a bit more info and lock in this estimate!`,
    experiment: `Have 5 minutes and interested in buying out your lease? Enter your phone number here!`,
  },
];
