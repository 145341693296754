import { Flex } from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';

import { ptBlogComponents } from '../Sanity/Model';

interface SummaryProps {
  summary: [];
}

const Summary = ({ summary = [] }: SummaryProps) => {
  if (!summary.length) {
    return null;
  }
  return (
    <>
      <Flex
        bg="#fff7ea"
        p={4}
        borderBottomRadius={4}
        flex={1}
        w="100%"
        flexDir="column"
        mb={4}
        className="le-post-summary"
        borderTopWidth={3}
        borderColor="#545144"
      >
        <PortableText value={summary} components={ptBlogComponents} />
      </Flex>
    </>
  );
};
export default Summary;
