import moment from 'moment';

import {
  BAD_RATE,
  BEST_RATE,
  DEFAULT_INITIAL_SCORE,
  GOOD_RATE,
  WORST_RATE,
} from '../constants/paymentCalculator';
import { CreditScore } from '../gql/generated/graphql';

export const creditScores = [
  { name: CreditScore.BelowAverage, min: 300, max: 599, rate: WORST_RATE },
  { name: CreditScore.BelowAverage, min: 600, max: 649, rate: WORST_RATE },
  { name: CreditScore.Fair, min: 650, max: 699, rate: BAD_RATE },
  { name: CreditScore.Good, min: 700, max: 749, rate: GOOD_RATE },
  { name: CreditScore.Great, min: 750, max: 799, rate: BEST_RATE },
  { name: CreditScore.Great, min: 800, max: 850, rate: BEST_RATE },
];

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DEFAULT_SCORE = creditScores.find(
  (score) => score.max > DEFAULT_INITIAL_SCORE && score.min <= DEFAULT_INITIAL_SCORE,
)!;

export const DEFAULT_RATE = DEFAULT_SCORE?.rate;

export const getCreditScore = (score: number) => {
  const rate = creditScores.find(({ min, max }) => score >= min && score <= max);
  return rate || DEFAULT_SCORE;
};

export const getCreditScoreFromLabel = (tier: string) => {
  const rate = creditScores.find(({ name }) => name === tier);
  return rate || DEFAULT_SCORE;
};

export const getCreditScoreRangeText = (credit_score: number) => {
  const score = getCreditScore(credit_score);

  if (score.min === 800) {
    return '800+';
  }

  return `${score.min}-${score.max}`;
};

export const getCreditScoreGrade = (credit_score: number) => {
  const score = getCreditScore(credit_score);

  switch (score.rate) {
    case WORST_RATE:
      return 'Below Average';
    case BAD_RATE:
      return 'Fair';
    case GOOD_RATE:
      return 'Good';
    case BEST_RATE:
      return 'Great';
    default:
      return 'Good';
  }
};

export const termLengths = [
  { label: '48 MONTHS', key: '1', value: 48 },
  { label: '60 MONTHS', key: '2', value: 60 },
  { label: '72 MONTHS', key: '3', value: 72 },
  { label: '84 MONTHS', key: '4', value: 84 },
];

export const termsArr = termLengths.map((tl) => tl.value);

export enum WhatMattersLabel {
  PAYMENT = 'Monthly Payment',
  DOWN = 'Money Down',
  RATE = 'Interest Rate',
  UNSURE = 'Not Sure',
}
export enum WhatMattersDescription {
  PAYMENT = 'A data-driven monthly payment estimate in 1 minute.',
  DOWN = "We'll show how much money down will save you",
  RATE = "We'll estimate what rate we can secure for you",
  UNSURE = 'Tell us what you drive and we will take it from there',
}
export enum WhatMattersKey {
  PAYMENT = 'MONTHLY_PAYMENT',
  DOWN = 'MONEY_DOWN',
  RATE = 'INTEREST_RATE',
  UNSURE = 'NOT_SURE',
}
export const WhatMatters = [
  {
    label: WhatMattersLabel.PAYMENT,
    key: WhatMattersKey.PAYMENT,
    step: 2,
    // description: WhatMattersDescription.PAYMENT,
  },
  {
    label: WhatMattersLabel.DOWN,
    key: WhatMattersKey.DOWN,
    step: 3,
    // description: WhatMattersDescription.DOWN,
  },
  {
    label: WhatMattersLabel.RATE,
    key: WhatMattersKey.RATE,
    step: 4,
    // description: WhatMattersDescription.RATE,
  },
  // {
  //   label: WhatMattersLabel.UNSURE,
  //   key: WhatMattersKey.UNSURE,
  //   step: 5,
  //   // description: WhatMattersDescription.UNSURE,
  // },
];

export const Estimator = {
  width: {
    base: '100%',
    md: '500px',
  },
  minWidth: {
    base: '100%',
    md: '500px',
  },
  animationDuration: 350,
};

export const EstimatorTemplate = {
  lightGray: '#f7f7f7',
  lightBlue: 'rgba(8,125,197,0.05)',
  blue: 'rgba(8,125,197,1)',
  lightGreen: 'rgba(35,197,8,0.05)',
  green: 'rgba(35,197,8,1)',
  lightBrown: '#f9f5f0',
  lightRed: 'rgba(197,8,8,0.05)',
  red: 'rgba(197,8,8,1)',
  darkText: '#1b1c1b',
  lightText: '#787878',
  borderLight: '#e7e7e7',
  borderDark: '#bcbcbc',
  borderRadius: 4,
  aiGradient: 'linear-gradient(135deg, #97c4e2, #da3461, #7ebee1, #cf3356);',
  fontFamily: '-apple-system, sans-serif',
  fontSizeXSmall: 12,
  fontSizeSmall: 13,
  fontSizeMed: 14,
  fontSizeLarge: 16,
  fontSizeXLarge: 18,
};

export const EstimateDefault = {
  credit: 'Good',
  term: 72,
  down: 0,
};

export const EstimateAdjustments = {
  credit: [
    { score: 'Fair', adjust: 100 },
    { score: 'Good', adjust: 0 },
    { score: 'Very Good', adjust: -30 },
    { score: 'Excellent', adjust: -60 },
  ],
  term: [
    { mos: 36, adjust: 240 },
    { mos: 48, adjust: 160 },
    { mos: 60, adjust: 80 },
    { mos: 72, adjust: 0 },
  ],
  down: [
    { amount: 0, adjust: 0 },
    { amount: 1000, adjust: -16 },
    { amount: 2000, adjust: -32 },
    { amount: 3000, adjust: -48 },
    { amount: 4000, adjust: -60 },
  ],
};

export const CreditTiers = [
  {
    tier: 'poor',
    label: 'Poor',
  },
  {
    tier: 'fair',
    label: 'Fair',
  },
  {
    tier: 'good',
    label: 'Good',
  },
  {
    tier: 'very_good',
    label: 'Very Good',
  },
  {
    tier: 'excellent',
    label: 'Excellent',
  },
];

const EstimatorDurationMonths = 48;
const EstimatorDurationType = 'months';
const EstimatorDurationFormat = 'YYYY-MM-DD';

export const EstimatorDates = {
  start_date: moment()
    .subtract(EstimatorDurationMonths, EstimatorDurationType)
    .format(EstimatorDurationFormat),
  end_date: moment(new Date()).format(EstimatorDurationFormat),
};

export const MILEAGE_PER_YEAR = 10000;

export const ESTIMATOR_STATE_REACTIONS = [
  {
    state: 'AL',
    tag: '',
    reactions: [`I heard that Mardi Gras actually started in Mobile, that's pretty cool!`],
  },
  {
    state: 'AK',
    tag: 'North to the Future!',
    reactions: [
      `I've never been to Alaska! Being bigger than Texas it's definitley on my list of places to visit!`,
    ],
  },
  {
    state: 'AZ',
    tag: '',
    reactions: [
      `I read the other day that you can get fined $5,000 for cactus graffiti...who knew?!`,
    ],
  },
  {
    state: 'AK',
    tag: '',
    reactions: [
      `Shine like a diamond! I love state parks and I hear Crater Diamonds is a must-see!`,
    ],
  },
  {
    state: 'CA',
    tag: '',
    reactions: [
      `Ah California, what's not to love? Beaches, mountains, big 'ol redwoods! You guys really have a little bit of everything.`,
    ],
  },
  {
    state: 'CO',
    tag: '',
    reactions: [
      `Did you know that the 13th step on your capitol building is exactly 1 mile above sea level?`,
    ],
  },
  {
    state: 'CT',
    tag: '',
    reactions: [
      `I had a burger for lunch yesterday...thank you Connecticut! It's thought the burger was made in New Haven.`,
    ],
  },
  {
    state: 'DE',
    tag: '',
    reactions: [`Little Delaware! I can't believe there's only 3 counties there!`],
  },
  {
    state: 'DC',
    tag: '',
    reactions: [
      `I hear the Main Ave Fish Market is a must-see...along with SO many other amazing things!`,
    ],
  },
  {
    state: 'FL',
    tag: '',
    reactions: [`Orange groves, golf, alligators and 'crocs...oh my! Lots of sunshine too :)`],
  },
  {
    state: 'UT',
    tag: '',
    reactions: [
      `The world owes a debt of gratitude to Mr Walter Morrison...inventor of the Frisbee, from Richfield!`,
    ],
  },
  {
    state: 'ID',
    tag: 'The Potato State!',
    reactions: [
      `I think we all can agree that we owe Idaho a big thank you for the tater-tot. We're forever grateful!`,
    ],
  },
];

export const capitolZipCodes = [
  { state: 'AL', zip: '36104' },
  { state: 'AK', zip: '99801' },
  { state: 'AZ', zip: '85007' },
  { state: 'AR', zip: '72201' },
  { state: 'CA', zip: '95811' },
  { state: 'CO', zip: '80203' },
  { state: 'CT', zip: '06101' },
  { state: 'DE', zip: '19901' },
  { state: 'FL', zip: '32399' },
  { state: 'GA', zip: '30334' },
  { state: 'HI', zip: '96813' },
  { state: 'ID', zip: '83720' },
  { state: 'IL', zip: '62707' },
  { state: 'IN', zip: '46204' },
  { state: 'IA', zip: '50319' },
  { state: 'KS', zip: '66612' },
  { state: 'KY', zip: '40601' },
  { state: 'LA', zip: '70802' },
  { state: 'ME', zip: '04330' },
  { state: 'MD', zip: '20743' },
  { state: 'MA', zip: '02133' },
  { state: 'MI', zip: '48933' },
  { state: 'MN', zip: '55155' },
  { state: 'MS', zip: '39201' },
  { state: 'MO', zip: '65101' },
  { state: 'MT', zip: '59319' },
  { state: 'NE', zip: '68508' },
  { state: 'NV', zip: '89701' },
  { state: 'NH', zip: '03301' },
  { state: 'NJ', zip: '08625' },
  { state: 'NM', zip: '87501' },
  { state: 'NY', zip: '12224' },
  { state: 'NC', zip: '27601' },
  { state: 'ND', zip: '58505' },
  { state: 'OH', zip: '43215' },
  { state: 'OK', zip: '73105' },
  { state: 'OR', zip: '97301' },
  { state: 'PA', zip: '17120' },
  { state: 'RI', zip: '02903' },
  { state: 'SC', zip: '29208' },
  { state: 'SD', zip: '57501' },
  { state: 'TN', zip: '37243' },
  { state: 'TX', zip: '78701' },
  { state: 'UT', zip: '84114' },
  { state: 'VT', zip: '05633' },
  { state: 'VA', zip: '23218' },
  { state: 'WA', zip: '98504' },
  { state: 'WV', zip: '25305' },
  { state: 'WI', zip: '53702' },
  { state: 'WY', zip: '82001' },
  { state: 'DC', zip: '20001' },
];
